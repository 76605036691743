export const maxFileSizeCheck = (file, maxSize) => {
  if (!file) {
    return false
  }

  return file.size <= maxSize
}

export const isFileType = (file, acceptedFileTypes) => {
  if (!file) {
    return false
  }

  if (acceptedFileTypes.length === 0) {
    return true
  }

  return acceptedFileTypes.includes(file.type)
}


export const fileExtension = (file, acceptedExtensions) => {
  if (!file) {
    return false
  }

  return acceptedExtensions.includes(file.name.toLowerCase().substring(file.name.lastIndexOf(".") + 1))
}

export default {
  maxFileSizeCheck,
  isFileType,
  fileExtension
}