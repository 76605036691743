import App from '@/App'
import Axios from '@/plugins/axios'
import Echarts from 'vue-echarts'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueCountryCode from 'vue-country-code'
import VueGtag from 'vue-gtag'
import VueRouter from 'vue-router'
import gtagConfig from '@/plugins/gtag'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import Toast from "vue-toastification"
import ToastConfig from "@/plugins/toastification"
import "vue-toastification/dist/index.css"
import dayjs from "@/plugins/dayjs"

export const ServerBus = new Vue()
const gtag = Vue.use(VueGtag, { config: gtagConfig })

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueCountryCode)
Vue.use(VueAxios, Axios)
Vue.use(Echarts)

Vue.use(Toast, ToastConfig)

Vue.prototype.vuetify = vuetify
Vue.prototype.gtag = gtag
Vue.prototype.store = store
Vue.prototype.dayjs = dayjs

new Vue({
  vuetify,
  router: router,
  render: h => h(App),
  store,
}).$mount('#app')


