import Axios from "@/plugins/axios"
import { OTHER_ROUTES }  from "@/constants/backOfficeRoutes"
import { serialize } from '@/plugins/objectToFormdata'

export const getManualPDF = subdomain => {
  return Axios.get(OTHER_ROUTES.ROUTE_USER_MANUAL.replace("$subdomain$", subdomain))
}

export const getGuiaPDF = subdomain => {
  return Axios.get(OTHER_ROUTES.ROUTE_USER_GUIDE.replace("$subdomain$", subdomain))
}

export const getDocumentBase64 = s3Key => {
  const obj = {
    'key': s3Key
  }
  const keyData = serialize(obj)

  return Axios.post(OTHER_ROUTES.ROUTE_DOCUMENT_VIEW, keyData)
}

export const getDocumentFormatA4 = base64pdf => {
  const obj = {
    "file_name": "file",
    "file_content": base64pdf
  }
  const objData = serialize(obj)

  return Axios.post(OTHER_ROUTES.ROUTE_PDF_IS_A4, objData)
}
