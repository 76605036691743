<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :id="idExternal"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
         block
         class="b2b-standard-button-with-icon"
         small
         @click="$emit('click')"
      >
        <v-row>
          <v-col class="b2b-standard-button-with-icon__text text-truncate" style="width: 100px">
            {{ text }}
          </v-col>

          <v-col v-if="icon" cols="1" align-self="center">
            <v-icon
              class="float-right"
              small
            >{{ icon }}
            </v-icon>
          </v-col>
        </v-row>
      </v-btn>
      </template>
    <span> {{text}} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'standardButton',
  props: {
    idExternal:String,
    disabled: Boolean,
    text: String,
    icon: {
      type: String,
      default: null
    },
  }
}
</script>

<style lang="scss">

.b2b-standard-button-with-icon {
  background-color: var(--primary-alternative) !important;
  color: var(--clear) !important;
  box-shadow: none;
  font-size: 13px !important;
  max-height: 28px !important;
  height: 28px !important;
  width: 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
}

</style>
