<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <v-container class="cts-main-view col-12">
    <v-scroll-y-transition>
      <v-row v-if="users" dense class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-row dense class="ma-0 pa-0">
            <v-col cols="11" lg="10" offset-lg="1" class="mx-auto cts-view-header">
              <v-row dense class="cts-breadcrumbs ma-0 mt-1 pa-0">
                <a @click.prevent="backToMenu()" :title="$vuetify.lang.t('$vuetify.administrationTitle')">{{
                  $vuetify.lang.t('$vuetify.administrationTitle')
                  }} </a>
                <span class="cts-color-black_soft_text font-weight-regular pl-1">/ {{
                  $vuetify.lang.t('$vuetify.usersTitle')
                }} </span>
              </v-row>
              <view-title
                :icon="const_icons.USERS"
                :title="$vuetify.lang.t('$vuetify.usersTitle')"
                :subtitle="$vuetify.lang.t('$vuetify.usersDescription')"
                idName="users"
              />
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="11" xl="10" lg="10" md="11" sm="11" class="pa-0 offset-lg-1 mx-sm-auto mx-auto">
              <users-datatable :users="users" :group="group"></users-datatable>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-scroll-y-transition>
    <v-row v-if="!users" dense class="ma-0">
      <loading-container/>
    </v-row>
  </v-container>
</template>

<script>

  import { getInitials } from "@/util/utils"
  import const_icons from "@/constants/icons"
  import ViewTitle from "../../structures/viewTitle"
  import { redirect } from "@/util/router_utils"
  import UsersDatatable from "./usersView/usersDatatable"
  import { getUsers } from "@/services/userServices"
  import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE, ACTIONS as ADMINISTRATION_ACTIONS } from "@/constants/vuex/administration"
  import LoadingContainer from "../../structures/loadingContainer"
  import { checkPermission } from "@/util/store_utils"
  import { ADMINISTRATION } from "@/constants/router/routes"

  export default {
    name: "UsersViewer",
    components: { LoadingContainer, UsersDatatable, ViewTitle },
    data() {
      return {
        const_icons,
        group: {},
        filteredUsers: [],
      }
    },
    computed: {
      users() {
        return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_USERS]
      }
    },
    methods: {
      loadUsers() {
        getUsers().then((response) => {
          this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_USERS}`, response.data)
        })
      },

      initials(name) {
        return getInitials(name)
      },

      checkPermission(name) {
        return checkPermission(name)
      },

      backToMenu() {
        redirect(ADMINISTRATION)
      },

    },
    created() {
      this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_USERS}`, null)
      this.loadUsers()
    }
  }
</script>
