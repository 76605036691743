<template>
    <v-row class="gray_title_card" dense>
        <div class="cts-bgcolor-lighten px-2"
             style="">
            <v-icon color="var(--darken)" size="24">{{icon}}</v-icon>
        </div>
        <v-col class="px-4 cts-bgcolor-lighten ml-2">
            <v-row dense justify="space-between">
                <span class="cts-font-size-title">{{title}}</span>
                <span class="cts-font-size-3 font-italic">{{extra}}</span>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "TitleCard",
    props: {
        icon: String,
        title: String,
        extra: String
    }
}
</script>

<style lang="scss">
.gray_title_card {
    :first-child {
        border-radius: 4px 0 0 4px;
        align-content: space-evenly;
    }

    :last-child {
        border-radius: 0 4px 4px 0;
        align-content: space-evenly;
    }

    .cts-font-size-title {
        color: var(--darken)
    }
}
</style>