<template>

  <tr id="transactions-table-transaction_row_desktop" class=" hidden-md-and-down cts-transaction-row"
      @click="openTransaction(item.id)">
    <td class="text-start cts-transaction-datatable-process">
      <v-row class="ma-0 justify-space-between" dense>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <h4 class="cts-color-tertiary text-uppercase" v-bind="attrs" v-on="on">
              <v-avatar id="transactions-table-transaction_row_icon" class="cts-process-card-icon ma-1"
                        size="35" tile>
                <icon-base
                  :icon-name="item.transaction_name" :src="item.process_image"
                  height="35"
                  icon-color="var(--primary-alternative)"
                  width="35"
                />
              </v-avatar>
            </h4>
          </template>
          <span>{{item.transaction_name}}</span>
        </v-tooltip>
        <v-col class="cts-transaction-process-info my-auto cts-break-word">
          <v-row class="ma-0" dense>
            <v-col id="transactions-table-transaction_col_title"
                   class="cts-process-title cts-break-word ma-0 pa-0">
              <v-tooltip v-if="item.transaction_name.length > 40" bottom>
                <template v-slot:activator="{ on, attrs }">
                                    <span id="transactions-table-transaction_row_title_compressed"
                                          class="cts-process-title cts-break-word" v-bind="attrs"
                                          v-on="on">{{item.transaction_name}}</span>
                </template>
                <span
                  id="transactions-table-transaction_row_title_tooltip">{{item.transaction_name}}</span>
              </v-tooltip>
              <span v-else id="transactions-table-transaction_row_title"
                    class="cts-process-title">{{item.transaction_name}}</span>
            </v-col>
          </v-row>
          <v-row class="ma-0" dense>
            <v-col v-if="item.id.indexOf('INVALID') === -1"
                   id="transactions-table-transaction_col_subtitle"
                   class="cts-process-subtitle cts-break-word ma-0 pa-0">
                            <span id="transactions-table-transaction_row_subtitle"
                                  class="cts-color-tertiary">{{clearTransactionId(item.id)}}</span>
            </v-col>

            <v-col v-else
                   id="transactions-table-transaction_col_subtitle_invalid"
                   class="cts-process-subtitle cts-break-word">
                            <span id="transactions-table-transaction_row_subtitle_invalid"
                                  class="cts-color-tertiary">{{item.id}}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </td>
    <td class="text-start cts-transaction-datatable-date">
      <h4 id="transactions-table-transaction_created_at" class="cts-color-tertiary">
        {{dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A)}}
      </h4>
    </td>
    <td v-if="item.id != null" class="text-start cts-transaction-client-code expand-transition"
        style="padding:16px 0 !important;">
      <v-row v-if="recipientData && recipientData.contact_id" class="cts-color-tertiary cts-font-size-3" dense>
        <span class="font-weight-medium mr-1 cts-font-size-4"> {{recipientData.contact_complete_name}}</span>
        <span class="cts-font-size-4"> {{recipientData.contact_id ? `(${recipientData.contact_id})` : ''}}</span>
      </v-row>
      <v-row v-else dense>
        <v-col class="pa-0">
          <v-row
            v-for="(intervener, index) in showAllInterveners ? allIntervenersFormatted:allIntervenersFormatted.slice(0, 1)"
            :key="intervener.id + index"
            class="transaction-interveners-row" dense>
            <h4 class="font-weight-medium cts-color-tertiary">{{intervener.name}} </h4><span v-if="intervener.id"
                                                                                             class="ml-2">({{intervener.id}})</span>
            <v-btn v-if="allIntervenersFormatted && allIntervenersFormatted.length > 1 && index===0"
                   class="ml-4 more-interveners-button"
                   small
                   text
                   @click.stop="showAllInterveners = !showAllInterveners"
            >
                    <span v-if="!showAllInterveners">
                        <span
                          style="color: var(--primary-alternative)">{{allIntervenersFormatted.length - 1}} {{$vuetify.lang.t('$vuetify.more')}}</span>
                        <v-icon color="var(--primary-alternative)">arrow_drop_down</v-icon>
                    </span>
              <span v-else>
                        <span style="color: var(--primary-alternative)">{{$vuetify.lang.t('$vuetify.hide')}}</span>
                        <v-icon color="var(--primary-alternative)">arrow_drop_up</v-icon>
                    </span>
            </v-btn>

          </v-row>
        </v-col>
      </v-row>
    </td>

    <td class="text-start cts-transaction-datatable-state">
      <h4 v-if="item.id.indexOf('INVALID') !== -1" class=" font-weight-medium"
          style="color: var(--error);">Transacción no válida</h4>
      <span v-else>
                <span style="font-size: 12px; color: var(--tertiary)">
                    {{dayjs.unix(item.updated_at).format(const_global.DATE_TIME_FORMAT_A)}}</span>
                <v-tooltip v-if="item.state.length > 15" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <h4 class="cts-color-tertiary cts-break-word font-weight-medium" v-bind="attrs" v-on="on">
                            {{item.state}}</h4>
                    </template>
                    <span>{{item.state}}</span>
                </v-tooltip>
                <h4 v-else id="transactions-table-transaction_state"
                    class="cts-color-tertiary cts-break-word font-weight-medium">{{
                    item.state
                  }}</h4>
            </span>
    </td>
    <td class="text-center cts-transaction-datatable-certificate">
      <download-certificate
        v-if="item.certification !== '0' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
        id="transactions-table-transaction_download_certificate"
        :buttonDisabled="item.certification !== '0'" :hash="item.certification"
        class="d-block mx-auto"
        mode="icon"/>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-icon
              class="d-block mx-auto"
              color="var(--input-icons)"
              style="cursor: default"
              v-bind="attrs"
              v-on="on"
            >
              {{const_icons.DOWNLOAD_DOCUMENT}}
            </v-icon>
          </span>
        </template>
        <span>{{$vuetify.lang.t('$vuetify.withoutCertificated')}}</span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import {checkPermission} from "@/util/store_utils"
import const_global from "@/constants/global"
import IconBase from "@/assets/images/IconBase"
import const_icons from "@/constants/icons"
import const_permissions from "@/constants/permissions"
import DownloadCertificate from "@/components/structures/downloadCertificate"


export default {
  name: "transactionListRowDesktop",
  components: {DownloadCertificate, IconBase},
  props: {
    item: Object,
    recipientData: Object
  },
  data() {
    return {
      checkPermission,
      const_global,
      const_icons,
      const_permissions,
      showAllInterveners: false
    }
  },
  computed: {
    allIntervenersFormatted() {
      if (this.item.interveners[0] === undefined) {
        return [{
          id: null,
          name: this.$vuetify.lang.t('$vuetify.notAvailable')
        }]
      }
      let array = []
      const name = this.item.interveners[0].personal_info.name?.toString()

      const normalizedName = name?.normalize('NFD').replace(/[\u0300-\u036f]/g, "")

      if (this.item.interveners.length >= 1 && this.item.contact_complete_name !== normalizedName) {
        array = array.concat(this.item.interveners.map(intervener => {
          return {
            id: intervener.personal_info.identification_document.document_code,
            name: intervener.personal_info.name
          }
        }))
      }
      if (array.length === 0) {
        array = [{
          id: null,
          name: this.$vuetify.lang.t('$vuetify.notAvailable')
        }]
      }
      return array

    }
  },
  methods: {
    openTransaction(id) {
      this.$emit('clicked', id)
    },

    clearTransactionId(id) {
      return id.split(".").pop()
    }
  }
}
</script>
<style lang="scss">
.cts-transaction-client-code {

  span {
    font-size: 14px;
  }

  .v-avatar {
    span {
      font-size: 18px;
      color: var(--tertiary);
    }

  }


  .more-interveners-button {
    height: 21px !important;

    i {
      height: 14px;
    }

    span {
      font-size: 14px !important;
      text-transform: lowercase;
    }
  }

  .transaction-interveners-row {
    td {
      transition: height 2s;
    }

    span {
      color: var(--tertiary);
    }

  }
}
</style>


