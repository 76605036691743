<template>
  <v-list-item :tabindex="false" class="pa-0 my-2">
    <v-list-item-avatar :class="image ? 'cts-bgcolor-primary' : ''" class="ma-0 mr-1 cts-icon-title">
      <icon-base v-if="image" :id="`${idName}-image-icon`" :icon-name="title" :src="src" class="mx-auto mt-1"
                 height="25" icon-color="var(--clear)" width="30">
      </icon-base>
      <v-icon v-if="!image" :id="`${idName}-icon`" aria-hidden="false"
              class="cts-bgcolor-primary-alternative white--text">{{icon}}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content class="pa-0 pl-2">
      <v-list-item-title>
        <h1 :id="`${idName}-title`" class="cts-view-title">{{title}}</h1>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-tooltip v-if="subtitle.length > 100" bottom max-width="98%">
          <template v-slot:activator="{ on, attrs }">
            <h2 :id="`${idName}-subtitle`" class="cts-view-subtitle font-weight-regular" v-bind="attrs"
                v-on="on">{{subtitle}}</h2>
          </template>
          <span :id="`${idName}-tooltip-subtitle`" style="word-wrap: break-word">{{subtitle}}</span>
        </v-tooltip>
        <h2 v-else :id="`${idName}-subtitle`" class="cts-view-subtitle font-weight-regular">{{subtitle}}</h2>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="rightLink">
      <v-btn :id="`${idName}-right_link`" :loading="loadingRightLink" color="var(--primary-alternative)" text
             @click="emitEvent">
        <v-icon class="mr-2">{{rightLink.icon}}</v-icon>
        <span class="hidden-md-and-down">{{rightLink.text}}</span>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {ServerBus} from "@/main";
import IconBase from "@/assets/images/IconBase";

export default {
  name: "viewTitle",
  components: {IconBase},
  props: {
    icon: String,
    title: String,
    subtitle: String,
    rightLink: {
      type: Object,
      default: null
    },
    image: Boolean,
    idName: String
  },
  data() {
    return {
      src: null,
      loadingRightLink: false
    }
  },
  methods: {
    emitEvent() {
      this.loadingRightLink = true
      ServerBus.$emit(this.rightLink.event, this.rightLink.args)
      this.$emit(this.rightLink.event)

      setTimeout(() => {
        this.loadingRightLink = false
      }, 2000)
    }
  },
  created() {
    try {
      this.src = require('@/assets/images/svgIcons/' + this.$props.icon + '.vue')
      if (this.src) {
        this.src = this.$props.icon
        return this.src
      }
    } catch (e) {
      return this.src = 'default'
    }
  }
}
</script>

<style scoped>

</style>
