<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-view col-12 text-center">
    <v-row>
      <v-col class="cts-forbidden-box">
        <v-row dense class="ma-0 justify-center pb-4">
          <v-icon aria-hidden="false" large>{{ const_icons.ERROR }}</v-icon>
        </v-row>
        <v-row dense class="ma-0 justify-center">
          <span>{{ $vuetify.lang.t('$vuetify.error500Message') }} <a @click="goToContact()" class="cts-link">{{ $vuetify.lang.t('$vuetify.thisForm') }}</a></span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import const_icons from "@/constants/icons"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
  import { redirect } from "@/util/router_utils"
  import { LOGIN, REMEMBER_PASSWORD } from "@/constants/router/routes"
  
  export default {
    name: "Error500",
    data: function () {
      return {
        const_icons
      }
    },
    methods: {
      goLogin(){
        this.$store.dispatch(AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA)
        redirect(LOGIN)
      },
      goToContact(){

        this.$store.dispatch(AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA)
        redirect(REMEMBER_PASSWORD)
      }
    }
  }
</script>

<style scoped>

</style>
