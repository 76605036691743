<template>
  <v-row dense>
    <v-col>
      <v-row dense>
                <span
                  class="text-uppercase cts-color-tertiary">{{$vuetify.lang.t('$vuetify.transactionDigitalSignatureInfo')}}</span>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-list-item class="transaction-digital-card">
            <v-list-item-avatar>
              <v-icon color="var(--primary)">
                {{transaction_content.parameters.find(param => param.key === 'signature_type').value === 'face-to-face' ? 'mdi-account' : 'send'}}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{$vuetify.lang.t('$vuetify.signatureType')}}</v-list-item-title>
              <v-list-item-subtitle>
                <span
                  v-if="transaction_content.parameters.find(param => param.key === 'signature_type').value === 'face-to-face'">
                  {{$vuetify.lang.t('$vuetify.faceToFace')}}
                </span>
                <span v-else>
                  {{$vuetify.lang.t('$vuetify.remote')}}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item class="transaction-digital-card">
            <v-list-item-avatar>
              <v-icon color="var(--primary)">mdi-calendar</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $vuetify.lang.t('$vuetify.expiration') }}
              </v-list-item-title>

              <v-list-item-subtitle v-if="signatureCreationError">
                {{$vuetify.lang.t('$vuetify.notAvailable')}}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-else>
                <span v-if="dayjs(expirationTimestamp).isBefore(dayjs())">
                  {{$vuetify.lang.t('$vuetify.expired')}}
                </span>

                <span v-else>
                {{ dayjs(expirationTimestamp).format(const_global.DATE_TIME_FORMAT_B) }}

                ({{ dayjs(expirationTimestamp).fromNow() }})

                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item class="transaction-digital-card">
            <v-list-item-avatar>
              <v-icon color="var(--primary)">people</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{$vuetify.lang.t('$vuetify.signatureStatus')}}</v-list-item-title>
              <v-list-item-subtitle v-if="signatureCreationError">
                {{$vuetify.lang.t('$vuetify.notAvailable')}}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                <span v-if="intervenersProgress === transaction_content.interveners.length">
                  {{$vuetify.lang.t('$vuetify.completed')}}
                </span>
                <span v-else>
                  {{transaction_content.interveners.length - intervenersProgress}}
                  {{$vuetify.lang.t('$vuetify.remaining')}}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="js">
import dayjs from "../../../../../plugins/dayjs";
import const_global from "@/constants/global";

export default {
  name: "TransactionSectionDigitalSignature",
  props: {
    transaction_content: Object
  },
  data() {
    return {
      const_global,
      interveners: []
    }
  },
  computed: {
    expirationTimestamp() {
      const creationTimestamp = dayjs(this.transaction_content.creation_timestamp * 1000);
      const daysToExpire = parseInt(this.transaction_content.parameters.find(param => param.key === "expiration").value);

      return (creationTimestamp.add(daysToExpire, 'day'))
    },
    reminders() {
      const emails = this.transaction_content.parameters.find(param => param.key === "communications_email")
      const sms = this.transaction_content.parameters.find(param => param.key === "communications_sms")

      return {
        emails: emails && emails.value ? emails.value.length - 1 : [],
        sms: sms && emails.value ? sms.value.length - 1 : []
      }
    },
    signatureCreationError() {
      return this.transaction_content.events.find(e => e.key === "front_renderer_builder.transaction_invalid") !== undefined
    },

    intervenersProgress() {
      return this.transaction_content.events.filter(event => event.key === "front_renderer_builder.intervener_finished").length
    }
  },
  methods: {
    dayjs,

  }
}
</script>


<style lang="scss">
.transaction-digital-card {
  border: solid 1px var(--card-border);
  border-radius: 4px;
}
</style>