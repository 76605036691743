<template>
  <div style="z-index: 79">
    <v-snackbar
            class="cts-alert-top"
            v-model="open"
            transition="slide-y-reverse-transition"
            tile
            vertical
            :timeout="-1"
    >
      <h3 class="text-center">
        <v-icon id="alert-message-icon_check" v-if="error == 0" class="mr-3 py-1 cts-color-primary" size="22">{{const_icons.CHECK}}</v-icon>
        <v-icon id="alert-message-icon_error" v-if="error == 1" class="mr-3 cts-color-primary" size="30">{{const_icons.ERROR}}</v-icon>
        <span id="alert-message-text" :class="error == 0 ? 'cts-color-primary' : 'cts-color-clear'">{{ info }}</span>
      </h3>
    </v-snackbar>
  </div>
</template>

<script>

  import const_global from "@/constants/global";
  import { NAMESPACE as FUNCTIONALITY_NAMESPACE, STATE as FUNCTIONALITY_STATE, ACTIONS as FUNCTIONALITY_ACTIONS } from "@/constants/vuex/functionality"
  import const_icons from "@/constants/icons";

  export default {
    name: "alertMessagePopup",
    data() {
      return {
        const_global,
        const_icons
      }
    },
    computed: {
      open() {
        this.check()
        return this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_MESSAGE_POPUP_OPEN]
      },
      info() {
        return this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_MESSAGE_POPUP_INFO]
      },
      error() {
        return this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_MESSAGE_POPUP_ERROR]
      },
    },
    methods: {
      check() {
        if( this.info != null ) {
          let timeout = const_global.TIME_ALERT_MESSAGE_POPUP_OK
          if (this.error) {
            timeout = const_global.TIME_ALERT_MESSAGE_POPUP_ERROR
          }

          setTimeout(() => {
            this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_MESSAGE_POPUP, null)
          }, timeout)

        }
      }
    }
  }
</script>
<style scoped></style>
