import VUEX_CONSTANTS from "@/constants/vuex"
const state = {
  [VUEX_CONSTANTS.GLOBAL.STATE.LOADING_BOX]: false,
  [VUEX_CONSTANTS.GLOBAL.STATE.SUBDOMAIN_CONFIG]: {}
}


const mutations = {
  [VUEX_CONSTANTS.GLOBAL.MUTATIONS.CHANGE_STATUS_LOADING_BOX](state, payload) {
    state[VUEX_CONSTANTS.GLOBAL.STATE.LOADING_BOX] = payload
  },

  [VUEX_CONSTANTS.GLOBAL.MUTATIONS.MUTATE_SUBDOMAIN_CONFIG](state, payload) {
    state[VUEX_CONSTANTS.GLOBAL.STATE.SUBDOMAIN_CONFIG] = { ...state[VUEX_CONSTANTS.GLOBAL.STATE.SUBDOMAIN_CONFIG], ...payload }
  }
}


const actions = {
  [VUEX_CONSTANTS.GLOBAL.ACTIONS.SET_LOADING_BOX]({ commit }, payload) {
    commit(VUEX_CONSTANTS.GLOBAL.MUTATIONS.CHANGE_STATUS_LOADING_BOX, payload)
  },

  [VUEX_CONSTANTS.GLOBAL.ACTIONS.SET_SUBDOMAIN_CONFIG]({ commit }, payload) {
    commit(VUEX_CONSTANTS.GLOBAL.MUTATIONS.MUTATE_SUBDOMAIN_CONFIG, payload)
  }
}

const getters = {
  [VUEX_CONSTANTS.GLOBAL.GETTERS.GET_STATUS_LOADING_BOX] () {
    return state[VUEX_CONSTANTS.GLOBAL.STATE.LOADING_BOX]
  },
  [VUEX_CONSTANTS.GLOBAL.GETTERS.GET_SUBDOMAIN_CONFIG] (state) {
    return state[VUEX_CONSTANTS.GLOBAL.STATE.SUBDOMAIN_CONFIG]
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
