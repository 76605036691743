<template>
  <v-container class="pa-0 ma-0">
    <v-row
      class="ma-0"
      dense
      @click="caretToEnd"
    >
      <!-- COUNTER-->
      <v-col
        v-if="maxLength"
        cols="12"
        class="text-right pa-0 ma-0"
        style="position: absolute; right: 0; top: -22px"
      >
        <span
          id="df-counter"
          :class="inputLength > maxLength ? 'df-counter-error' : ''"
        >
          {{ inputLength }} / {{ maxLength }}
        </span>
      </v-col>

      <!-- TEXT INPUT-->
      <v-col cols="12" class="pa-0 ma-0" @click="handleFieldClick">
        <div
          :id="idName.toLowerCase().replace(/\s/g, '')"
          class="b2b-data-field v-input"
          style="outline: 0"
          :ref="`field_${fieldName}`"
          @click="handleInputClick"
          @focusin="onFocusIn"
        >
          <span
            v-for="(item, index) in arrayContent" :key="index"
            id="df-content"
          >
            <v-chip
              v-if="item.type === 'tag'"
              class="df-tag"
              :ripple="false"
              ref="items"
              label
              outlined
              @click:close="deleteTag(index)"
            >
              <span class="text-truncate">
                {{ item.text }}
              </span>

            </v-chip>
            <span
              v-else
              class="df-text"
              :class="{
                'tag-separator': item.text.trim() === ''
              }"
              contenteditable
              ref="items"
              @keydown="onKeydown($event)"
              @keyup="onKeyup(index, $event)"
              @click="onClick(index)"
              @focusout="onFocusout"
            >
              {{ item.text }}
            </span>
          </span>
        </div>
      </v-col>

      <!-- MENU BUTTON AND VAR MENU-->
      <v-col cols="12" class="text-right pa-0 mr-1 mb-0">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              id="df-selector"
              :title="$vuetify.lang.t('$vuetify.addVariables')"
              class="df-suggested-field-menu-button pr-2"
              text
              v-on="on"
            >
              <v-icon
                class="mb-1"
                color="var(--primary-alternative)"
                small
              >
                {{ const_icons.ADD }}
              </v-icon>
              <span>{{ $vuetify.lang.t('$vuetify.addVariables') }}</span>
            </v-btn>
          </template>
          <v-list
            class="cts-process-params-add-list"
            dense
            subheader
          >
            <v-list-item
                v-for="(field, index) in suggestedFields"
                :key="index"
            >
              <a
                  :id="`df-selector-${field}`"
                  class="cts-link"
                  @click="insertTag(field)"
              >
                {{ $vuetify.lang.t(`$vuetify.${field}`) }}
              </a>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- end menu button and var menu-->
    </v-row>
  </v-container>
</template>

<script>
import const_global from '@/constants/global'
import const_icons from "@/constants/icons"

export default {
  name: 'textDataField',
  props: {
    idName: String,
    value: String,
    fieldName: String,
    suggestedFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    suggestedValues: Object,
    maxLength: Number
  },

  data() {
    return {
      const_icons,
      arrayContent: [
        { type: "text", text: "" }
      ],
      arrayTransition: [],
      caretPosition: {
        index: 0,
        position: 0
      },
      hasFocus: false
    }
  },

  created() {
    if(this.value) {
      this.content2ArrayString()
    }
  },

  updated() {
    if (this.hasFocus && Object.keys(this.caretPosition).length > 0) {
      this.putCaretPosition()
    }
  },

  computed: {
    inputLength() {
      if (this.value) {
        let content = this.value

        for (const key in this.suggestedValues) {
          const re = new RegExp(`%%${key}%%`, "g")
          content = this.suggestedValues[key] === null ?  content.replace(re, '') : content.replace(re, this.suggestedValues[key])
        }

        return content.length
      }

      return 0
    },
    inputRef() {
      return this.$refs.items
    },
  },

  methods: {
    handleFieldClick() {
      this.$refs[`field_${this.fieldName}`].click()
    },

    async insertTag(field) {
      const index = this.caretPosition.index
      const position = this.caretPosition.position

      const leftContentText = `${this.arrayContent[index].text.slice(0,position)} `
      const rightContentText = ` ${this.arrayContent[index].text.slice(position, this.arrayContent[index].text.length)}`

      this.arrayTransition.push({index: index, content: { type: 'text', text: leftContentText }})
      this.arrayTransition.push({index: index + 1, content: { type: 'tag', text: this.$vuetify.lang.t(`$vuetify.${field}`), field: field }})
      this.arrayTransition.push({ index: index + 2, content: { type: 'text', text: rightContentText }})

      await this.updateChanges()

      this.caretPosition = { index: index + 2, position: 0 }
      this.putCaretPosition()
    },

    deleteTag(index) {
      if (this.arrayContent.length > 1 && this.arrayContent[index].type === "tag") {
        const caretPosition = this.arrayContent[index - 1].text.length
        this.arrayContent[index - 1].text += this.arrayContent[index + 1].text

        this.arrayContent.splice(index + 1, 1)
        this.arrayContent.splice(index, 1)
        this.$refs.items[index - 1].innerText = this.arrayContent[index - 1].text
        this.arrayContent2String()

        this.caretPosition = {
          index: index - 1,
          position: caretPosition
        }
      }

    },

    handleInputClick() {
      this.focusEditableContent()
      this.caretToEnd()
    },

    onFocusIn() {
      this.hasFocus = true
    },

    onFocusout() {
      this.hasFocus = false
      this.updateChanges()
    },

    onClick(index) {
      this.setCaretPosition(index, window.getSelection().getRangeAt(0).startOffset)
    },

    onKeydown(e) {
      if(e.key.toLowerCase() === "enter") {
        e.preventDefault()
      }
    },

    onKeyup(index, e) {
      let updateCare = true

      this.arrayTransition = [{
        index: index,
        content: {
          type: "text",
          text: e.target.innerText
        }
      }]

      if (this.caretPosition.position === 0 && index > 0) {
        if (e.key.toLowerCase() === "backspace") {
          this.updateChanges()
          this.setCaretPosition(index - 2, this.arrayContent[index - 2].text.length)
          this.deleteTag(index - 1)
          this.putCaretPosition()
          updateCare = false
        } else if (e.key.toLowerCase() === "arrowleft" && index > 1 ) {
          this.setCaretPosition(index - 2, this.arrayContent[index - 2].text.length)
          this.putCaretPosition()
          updateCare = false
        }
      } else if (this.caretPosition.position === e.target.innerText.length) {
        if(e.key.toLowerCase() === "delete") {
          this.setCaretPosition(index, this.arrayContent[index].text.length)
          this.updateChanges()
          this.deleteTag(index + 1)
          this.putCaretPosition()
          updateCare = false

        } else if (e.key.toLowerCase() === "arrowright" && index + 1 < this.arrayContent.length) {
          this.setCaretPosition(index + 2, 0)
          this.putCaretPosition()
          updateCare = false
        }
      }

      if(updateCare) {
        let caretPosition = window.getSelection().getRangeAt(0).startOffset
        this.setCaretPosition(index, caretPosition)
      }

      this.updateChanges()
    },

    async updateChanges () {
      if(this.arrayTransition.length !== 0) {
        this.arrayTransition.forEach((item, key) => {
          if(key === 0) {
            this.arrayContent.splice(item.index, 1, item.content)
          }
          else {
            this.arrayContent.splice(item.index, 0, item.content)
          }

          if(item.content.type === "text" && this.$refs.items[item.index] !== undefined) {
            this.$refs.items[item.index].innerText = item.content.text
          }
        })

        this.arrayTransition = []
        this.arrayContent2String()
      }
    },

    arrayContent2String() {
      let content = ""

      this.arrayContent.forEach(item => {

        if (item.type === "tag") {
          content += `%%${item.field}%%`
        }
        else {
          content += item.text
        }
      })

      this.$emit('input', this.formatValueFromLang(content))
    },

    content2ArrayString() {
      this.arrayContent = []
      const valueAsArray = this.value.split("%%")

      valueAsArray.forEach((item) => {
        if (this.suggestedFields.some((field) => field === item)) {
          this.arrayContent.push({ type: 'tag', text: this.$vuetify.lang.t(`$vuetify.${item}`), field: item })
        }
        else {
          this.arrayContent.push({  type: 'text', text: item })
        }
      })

      let index = this.arrayContent.length -1
      this.caretPosition = {
        index: index,
        position: this.arrayContent[index].text.length
      }
    },

    focusEditableContent() {
      if (this.$refs.items.length === 1) {
        const dfTextElement = this.$el.querySelector('.b2b-data-field .df-text')
        dfTextElement.click()
        dfTextElement.focus()
      }
    },

    caretToEnd() {
      if(event.target.id === "data-field") {
        const clickPosition = event.layerX

        let index
        let position
        index = 0
        if (clickPosition < 15) {
          index = 0
          position = 0
        } else {
          index = this.arrayContent.length - 1
          position = this.arrayContent[index].text.length
        }

        this.setCaretPosition(index, position)
        this.putCaretPosition()
      }
    },

    setCaretPosition(index, position) {
      this.caretPosition = {
        index: index,
        position: position
      }
    },

    putCaretPosition () {
      const el = this.inputRef[this.caretPosition.index]
      let cel

      if ("childNodes" in el && el.childNodes[0]) {
        cel = el.childNodes[0]
      }
      else {
        cel = el
      }

      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(cel, this.caretPosition.position);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus()
    },

    formatValueFromLang(value) {
      let result = value
      this.suggestedFields.forEach(sf => {
        const t = this.$vuetify.lang.t(`$vuetify.${sf}`)
        const findVarName = `${const_global.FIELD_SEPARATOR}${t}${const_global.FIELD_SEPARATOR}`

        if (result) {
          result = result.replace(findVarName, `${const_global.FIELD_SEPARATOR}${sf}${const_global.FIELD_SEPARATOR}`)
        }
      })

      return result
    }
  }
}
</script>

<style lang="scss">
.b2b-data-field {
  border: solid 1px var(--tertiary);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  min-height: 28px;
  width: 100%;
  min-width: 100%;
  word-break: break-word;
  white-space: nowrap;
  overflow-x: auto;
  cursor: text;
}

#df-counter {
  font-size: 13px;
}

.df-counter-error {
  color: var(--error)
}

.df-text {
  display: inline-block;
  min-width: 5px !important;
  line-height: 24px;
  max-width: 100%;
  white-space: nowrap;
}

.df-text[contenteditable] {
  outline: 0 solid transparent;
  line-height: 24px;
}

.df-tag {
  display: inline-block;
  margin: 2px 0 0 0;
  padding: 0 4px !important;
  font-size: 13px !important;
  font-weight: bolder;
  line-height: 16px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
  max-width: 100%;
  color: var(--primary) !important;
  background-color: var(--clear) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 4px;
}

.df-suggested-field-menu-button {
  font-size: 13px !important;
  height: 35px !important;
  margin-top: -4px;
  font-weight: bold;
  letter-spacing: normal;
}

.df-suggested-field-menu-button:hover::before {
  opacity: 0 !important;
}

.df-suggested-field-menu-button span {
  color: var(--primary-alternative)
}

.df-suggested-field-menu-button:hover span,
.df-suggested-field-menu-button:hover i {
  color: var(--primary-alternative-focus) !important;
  background-color: transparent !important;
}
</style>