<template>
    <v-card class="step-card" elevation="0" max-width="360px" tile width="100%">
        <v-list-item three-line>
            <v-list-item-avatar :class="colorIconByStatusCard">
                <v-icon :class="colorIconByStatusCard" size="32
">{{iconByStatusCard}}
                </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title :class="colorTitleByStatusCard">
                    {{title}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{subtitle}}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>

<script>

export default {
    name: "stepCard",

    props: {
        title: String,
        subtitle: String,
        icon: String,
        status: String,
    },

    components: {},
    data() {
        return {}
    },
    created() {
    },

    computed: {
        iconByStatusCard() {
            return this.status === 'success' ? 'check_circle_outline' : this.icon;
        },
        colorTitleByStatusCard() {
            const statusColors = {
                active: 'cts-step-title-active',
                success: 'cts-step-title-success'
            };

            return statusColors[this.status] || 'cts-step-title-disable';
        },
        colorIconByStatusCard() {
            const statusColors = {
                active: 'color-icon-active',
                success: 'color-icon-success'
            };

            return statusColors[this.status] || 'color-icon-disable';
        },
    },

    methods: {}
}
</script>

<style lang="scss">
.step-card {

    .v-avatar {
        margin: 0 !important;
        border: solid 1px var(--primary-alternative) !important;
        border-radius: 4px;
        margin-top: auto !important;
        margin-bottom: auto !important;
        height: 50px !important;
        width: 50px !important;

        .v-icon {
            color: var(--primary-alternative)
        }
    }

    .v-list-item__content {
        padding-left: 12px;
        align-self: center;
    }

    .color-icon-success {
        color: var(--success) !important;
        border-color: var(--success) !important;
    }

    .color-icon-active {
        color: var(--primary-alternative) !important;
        border-color: var(--primary-alternative) !important;
    }

    .color-icon-disable {
        color: var(--tertiary) !important;
        border-color: var(--tertiary) !important;
    }
}

/*
.wrapper {
    height: 60px;
    width: 260px;
}

.wrapper-icon {
    height: 45px;
    width: 45px;
    border: 1px solid;
    border-radius: 4px;
}

.subtitle-size {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

*/
</style>