<template>
    <v-card v-if="userInfo" class="pa-5 cts-login-box" width="400">
        <v-row v-if="userInfo === 'error_data' && userInfo != null" class="ma-0 pa-0" dense>
            <v-col class="mt-0 mb-2 mx-0 pa-0 cts-login-box-title" cols="8" sm="8">
                <h1 v-if="activate" class="cts-font-size-2 font-weight-regular">
                    {{$vuetify.lang.t('$vuetify.activateUserTitleTextWithoutHash')}}</h1>
                <h1 v-else class="cts-font-size-2 font-weight-regular">
                    {{$vuetify.lang.t('$vuetify.activateUserTitleTextWithoutHash')}}</h1>
            </v-col>
            <v-col class="mt-0 mb-2 mx-0 pa-0 cts-login-box-title" cols="4" sm="4">
                <a class="cts-color-primary-alternative cts-font-size-4 float-right py-0 text-right" @click="goLogin()">
                    <v-icon aria-hidden="false" class="mr-1" color="var(--primary-alternative)" size="13">
                        {{const_icons.BACK}}
                    </v-icon>
                    {{$vuetify.lang.t('$vuetify.goToLogin')}}</a>
            </v-col>
        </v-row>
        <v-row v-else class="ma-0 pa-0" dense>
            <v-col class="mt-0 mb-4 mx-0 pa-0 cts-login-box-title" cols="12">
                <h1 v-if="activate" class="cts-font-size-2 font-weight-regular">
                    {{$vuetify.lang.t('$vuetify.activateUserTitleText')}}</h1>
                <h1 v-else class="cts-font-size-2 font-weight-regular">
                    {{$vuetify.lang.t('$vuetify.changePasswordUserTitleText')}}</h1>
            </v-col>
        </v-row>
        <v-divider class="cts-bgcolor-clear mb-4"/>
        <v-row v-if="userInfo === 'error_data' && userInfo != null" dense>
            <div class="col-lg-12">
                <div class="cts-login-box-subtitle pb-4">
                    <span v-if="activate">{{$vuetify.lang.t('$vuetify.activateUserSubtitleTextWithoutHash')}}</span>
                    <span v-else>{{$vuetify.lang.t('$vuetify.activateUserSubtitleTextWithoutHash')}}</span>
                </div>
            </div>
        </v-row>
        <v-row v-else dense>
            <div class="col-lg-12">
                <div class="cts-login-box-subtitle pb-4">
                    <span v-if="activate">{{$vuetify.lang.t('$vuetify.activateUserSubtitleText')}}</span>
                    <span v-else>{{$vuetify.lang.t('$vuetify.changePasswordUserSubtitleText')}}</span>
                </div>
                <v-form
                        ref="form"
                        v-model="valid"
                        class="cts-form-login"
                        lazy-validation
                >

                    <v-text-field
                            v-model="input.password"
                            :aria-label="$vuetify.lang.t('$vuetify.newpassword')"
                            :placeholder="$vuetify.lang.t('$vuetify.newpassword')"
                            :rules="passwordRules"
                            :type="'password'"
                            :value="input.password"
                            autocomplete="on"
                            name="password"
                            outlined
                            prepend-inner-icon="vpn_key"
                    />
                    <v-text-field
                            v-model="input.repeat_password"
                            :aria-label="$vuetify.lang.t('$vuetify.repeatpassword')"
                            :placeholder="$vuetify.lang.t('$vuetify.repeatpassword')"
                            :rules="confirmPasswordRules"
                            :type="'password'"
                            autocomplete="on"
                            name="passwordRepeat"
                            outlined
                            prepend-inner-icon="vpn_key"
                            @keyup.enter="submit"
                    />
                </v-form>
            </div>
            <div class="col-lg-12">
                <transition appear name="hint">
                    <div class="cts-login-box-subtitle">
                        <span class="cts-form-labeled-input">{{
														$vuetify.lang.t('$vuetify.passwordrequirements')
                            }} </span>
                        <div class="cts-passwordrequirements-paragraph my-3">
                            <h4 v-for="(rule ,index) in rules" :key="index">
                                <v-row v-for="(error ,index) in passwordValidation.errors" :key="index"
                                       class="ma-0 pa-0"
                                       dense>
                                    <v-col class="ma-0 pa-0" cols="1">
                                        <v-icon v-if="rule.message === error.message"
                                                :class="error.validField ? 'cts-field-ok' : 'cts-field-error'"
                                                size="19">check_circle
                                        </v-icon>
                                    </v-col>
                                    <v-col class="ma-0 pa-0" cols="11">
                                        <span v-if="rule.message === error.message" class="ml-0">{{
																						error.message
                                            }}</span>
                                    </v-col>
                                </v-row>
                            </h4>

                            <div v-if='notSamePasswords'>
                <span
                        class="cts-form-labeled-input mt-4 cts-color-error font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.notequalpasswords')}}
                </span>
                            </div>
                        </div>
                    </div>
                </transition>
                <v-btn
                        :disabled="requirements <4 || !minRequirements || notSamePasswords || (input.password === '' || input.repeat_password === '' || (input.password.length < 8 || input.repeat_password < 8) || !$refs.form)"
                        :title="$vuetify.lang.t('$vuetify.save')" class="mt-4 mb-2 cts-button-login"
                        @click.prevent="submit"
                >
                    {{$vuetify.lang.t('$vuetify.updatePassword')}}
                </v-btn>
            </div>
        </v-row>
    </v-card>
</template>

<script>
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import {changePassword, checkUserHash} from "@/services/userServices"
import {redirect} from "@/util/router_utils"
import {changeSiteLang} from "@/util/store_utils"
import {LOGIN} from "@/constants/router/routes"
import locations from "@/constants/locations"
import {showToast} from "@/util/utils"

export default {
    name: "NewPasswordBox",
    props: {
        activate: Boolean
    },
    data() {
        return {
            userInfo: null,
            valid: true,
            minRequirements: false,
            requirements: null,
            groupId: null,
            activateValid: false,
            errorMessage: null,
            loggingIn: false,
            loader: false,
            email: null,
            token: null,
            visible: false,
            input: {
                password: "",
                repeat_password: ""
            },
            passwordRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.passwordRequired'),
                v => (v && v.length >= 8) || this.$vuetify.lang.t('$vuetify.passwordrequirementsMoreThanEightChars'),
                v => (
                    (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v)) ||
                    (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v)) ||
                    (/^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v)) ||
                    (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v))
                ) || this.$vuetify.lang.t('$vuetify.passwordFieldError'),
            ],
            confirmPasswordRules: [
                v => !!v || this.$vuetify.lang.t('$vuetify.passwordCompares')
            ],
            const_global,
            const_icons,
            locales: locations
        }
    },
    created() {
        this.checkPasswordHash()
    },
    computed: {
        rules() {
            return [
                {message: this.$vuetify.lang.t('$vuetify.passwordMinOneLowercase'), regex: /[a-z]+/},
                {message: this.$vuetify.lang.t('$vuetify.passwordMinOneUppercase'), regex: /[A-Z]+/},
                {message: this.$vuetify.lang.t('$vuetify.passwordMinOneNumber'), regex: /[0-9]+/},
                {message: this.$vuetify.lang.t('$vuetify.passwordMinOneSpecialChar'), regex: /[!@#$%^&*(),.?":{}|<>]+/},
                {message: this.$vuetify.lang.t('$vuetify.passwordMinEightChars'), regex: /.{8,}/}
            ]
        },

        notSamePasswords() {
            if (this.passwordsFilled) {
                return (this.input.password !== this.input.repeat_password)
            } else {
                return false
            }
        },
        passwordsFilled() {
            return (this.input.password !== '' && this.input.repeat_password !== '')
        },
        passwordValidation() {
            let errors = []
            let count = 0
            for (let i = 0; i < this.rules.length; i++) {

                let condition = this.rules[i]
                if (condition.regex.test(this.input.password) && this.rules[i].message === condition.message) {
                    condition.validField = 'ok'
                } else {
                    condition.validField = null
                }

                errors.push(condition)

                if (errors.length === 5) {
                    for (const errorPos in errors) {
                        if (errors[errorPos].validField === 'ok') {
                            ++count
                        }
                    }
                }

            }

            if (count >= 4) {
                this.requirementsOK(count)
            } else {
                this.requirementsOK(null)
            }

            return errors.length === 0 && this.input.password !== "" ? {valid: true, errors} : {valid: false, errors}

        }
    },
    methods: {
        goLogin() {
            redirect(LOGIN)
        },
        async checkPasswordHash() {
            this.errorMessage = null;

            let type = "new_password"
            if (this.activate) {
                type = "activate"
            }

            const response = await checkUserHash(this.$route.params.code, type)
            this.userInfo = response
            if (response !== 'error_data') {
                const currentLangIso = this.locales.find(loc => loc.code === this.$vuetify.lang.current).iso

                if (currentLangIso !== response.language) {
                    const targetLangIso = this.locales.find(loc => loc.iso === response.language).iso
                    await changeSiteLang(targetLangIso)
                }
            }
        },
        async submit() {
            if (this.$refs.form && this.input.password === this.input.repeat_password) {
                let userInfo = {}
                userInfo["username"] = this.userInfo.username
                userInfo["group_id"] = this.userInfo.group_id
                userInfo["new_password"] = this.input.password
                userInfo["from_remember"] = true

                const response = await changePassword(userInfo, false)
                if (response.data.error !== 0) {
                    showToast(true, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
                } else {
                    showToast(false, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
                    this.goLogin()
                }
            }
        },

        requirementsOK(count) {
            this.minRequirements = true
            this.requirements = count
        }
    }
}
</script>
