<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-view col-12 text-center">
    <v-row>
      <v-col class="cts-forbidden-box">
        <v-row dense class="ma-0 justify-center pb-4">
          <v-icon aria-hidden="false" large>{{const_icons.FORBIDDEN}}</v-icon>
        </v-row>
        <v-row dense class="ma-0 justify-center">
          <span>{{ $vuetify.lang.t('$vuetify.noPermissionMessage') }}</span>
        </v-row>
        <v-row dense class="ma-0 justify-center">
          <v-col md="2">
            <v-btn
                class="my-4 cts-button-login"
                @click="goLogin()"
            >
              {{ $vuetify.lang.t('$vuetify.exit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import const_icons from "@/constants/icons"
  import { LOGIN } from "@/constants/router/routes"
  import { redirect } from "@/util/router_utils"
  export default {
    name: "Forbidden",
    data: function () {
      return {
        const_icons
      }
    },
    methods: {
      goLogin(){
        redirect(LOGIN)
      },
    }
  }
</script>

<style scoped>

</style>
