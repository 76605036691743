<template>
  <v-row
    class="ma-0 pa-0 wrapper cts-login-topbar cts-grid-header text-center justify-center elevation-1"
    dense
  >
    <v-col
      cols="12"
      sm="3"
    >
      <a @click="clickLogo()">
        <img
          :alt="'Logo ' + theme"
          :src="`https://r.mailcomms.io/b2b/logos/${theme}_login.png`"
          class="cts-logo-login mx-auto d-block my-1"
        />
      </a>
    </v-col>
    <lang-selector
      v-if="action !== NEW_PASSWORD.NAME"
      :compact_mode="true"
      class="cts-lang--selector-login"
    />
  </v-row>
</template>

<script>
import LangSelector from "@/components/buttons/LangSelector.vue"
import { NEW_PASSWORD } from '@/constants/router/routes'

export default {
  name: 'TheHeaderLogin',

  props:{
    theme:String,
    action: String
  },

  components: {LangSelector},

  computed: {
    NEW_PASSWORD() {
      return NEW_PASSWORD
    }
  },

  methods: {
    clickLogo(){
      this.$emit('clickLogo',)
    }
  }
}
</script>

<style scoped>
  .wrapper{
    max-height: 4.5rem;
  }
</style>