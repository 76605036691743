<template>
  <v-container id="document-viewer" class="cts-container-pdf-viewer fluid grid-list-md text-xs-center pa-0 ma-0">
    <v-row id="document-viewer-html-content" class="cts-row-pdf-viewer pa-0 ma-0 justify-center">
      <v-col class="pa-0 ma-0 d-flex justify-center flex-column">
        <v-row v-if="iframeContent">
          <v-col>
            <iframe id="cts-preview-html-container" :srcdoc="iframeContent" class="iframe-preview"
                    title="html-viewer">
            </iframe>
          </v-col>
        </v-row>

        <v-row v-if="imageContent" justify="center">
          <img :alt="$vuetify.lang.t('$vuetify.resourcespreview')" :src="imageContent" class="pb-6"
               height="720">
        </v-row>

        <v-row v-if="loadingTask" id="document-viewer-pdf-content">
          <v-col class="ma-0 pa-0 d-flex justify-center" cols="12">
            <v-row class="ma-0 pa-0 d-flex justify-center">
              <v-col class="d-flex justify-center" cols="12">
                <v-row class="ma-0 pa-0 cts-pdf-manual-container d-flex justify-center" dense>
                  <v-col v-if="numPages" class="ma-0 pa-0 d-flex justify-center">
                    <v-carousel v-model="carousel" class="cts-carousel" hide-delimiters>
                      <v-carousel-item
                        v-for="(page, i) in numPages"
                        :id="i"
                        :key="page"
                        class="cts-carousel-item"
                      >
                        <v-sheet
                          color="white"
                          height="100%"
                          tile
                          width="100%"
                        >
                          <v-row
                            align="center"
                            class="fill-height"
                            justify="center"
                          >
                            <div class="cts-displayed-pdf">
                              <pdf
                                :src="loadingTask"
                                :page="i+1"
                                class="row pa-0 ma-0"
                                style="height: 100%"
                              >
                              </pdf>
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- button downdload -->
        <hr v-if='base64' class="html-separator">
        <v-row class="mt-7 mb-5 btn-download--document">
          <v-col class="pa-0" cols="12">
            <v-row>
              <v-col class="pa-0" cols="12">
                <v-btn
                  block
                  class="cts-button-standard mx-auto"
                  dark
                  @click="downloadManual()"
                >
                  <v-icon class="float-right" dense>download</v-icon>
                  <span>{{$vuetify.lang.t('$vuetify.download')}}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!--  -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {fileToBase64String, generatePdfDownload} from "@/util/utils"
import pdf from "vue-pdf";

export default {
  name: "documentsViewer",
  components: { pdf },
  props: {
    file: File,
    fileName: String,
    documentType: String,
    fileContent: String

  },

  data() {
    return {
      filePDF: null,
      fileToShow: null,
      numPages: null,
      loadingTask: null,
      overlay: false,
      carousel: 0,
      base64: null,
      iframeContent: null,
      imageContent: null
    }
  },

  async mounted() {
    if (this.file || this.fileContent) {
      this.base64 = this.file ? await fileToBase64String(this.file) : this.fileContent

      switch (this.documentType.toLowerCase()) {
        case "application/pdf":
        case "pdf":
          await this.processPdf()
          break

        case "text/html":
        case "html":
          this.processText()
          break

        case "image":
          this.processImage()
          break

        default:
          this.processText()
          break
      }

    }
  },

  methods: {
    loadPdf(base64String) {
      this.loadingTask = pdf.createLoadingTask(base64String);
      this.loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    },

    downloadManual() {
      const fileContent = this.base64.split(",")[1]
      generatePdfDownload(fileContent, this.fileName)
    },

    async processPdf() {
      this.loadPdf(this.base64)
    },

    processImage() {
      const fileContent = this.base64.split(",")[1]
      this.imageContent = `data:image/jpg;base64,${fileContent}`
    },

    processText() {
      const fileContent = this.base64.split(",")[1]

      this.iframeContent = decodeURIComponent(Array.prototype.map.call(atob(fileContent), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    }
  },

  watch: {
    file() {
      this.processPdf()
    }
  }
}
</script>

<style scoped>
.iframe-preview {
  width: 100%;
  height: 100%;
  border: none;
}

.html-separator {
  margin: 0 -20px !important;
  border-top: 1px solid #767676
}
</style>
