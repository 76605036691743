<template>
  <v-card v-if="mode != 'hidden'">
    <v-select
            :items="themes"
            label="Theme"
            dense
            v-model="selectTheme"
            @change="changeTheme"
    ></v-select>
  </v-card>
</template>

<script>

  import {mapGetters} from 'vuex'

  export default {
    name: 'SwichTheme',

    props: {
      mode: String,
      attrs: {
        type: Object,
        default: () => ({}),
      },
    },

    data: theme => ({
      initialDark: theme.$vuetify ? theme.$vuetify.theme : false,
      selectTheme: null,
      themes: ["light", "dark", "mapfre"]
    }),

    methods: {
      changeTheme() {
        let htmlElement = document.documentElement;
        htmlElement.setAttribute('theme', this.selectTheme);

        localStorage.setItem("theme", this.selectTheme);
        if( this.selectTheme != this.appearance.theme ) {
          this.$store.dispatch('appearance/changeTheme', this.selectTheme)
          location.reload()
        }
      }
    },
    computed:
        {
          ...mapGetters({
            appearance: 'appearance/getAppearance',
          })
        },
    created() {

      this.selectTheme = this.appearance.theme
      this.changeTheme()
    },
  }

</script>
