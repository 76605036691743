import router from "@/router"

export const redirect = (route, params, query) => {
  router.push({
    name: route.NAME,
    params: params,
    query: query
  }).catch((error) => {
    console.error("Error on routing", error)
  })
}
