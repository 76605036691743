<template>
  <div>
    <v-row class="cts-header hidden-md-and-down">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12" class="pa-0">
        <v-app-bar
          app
          height="70"
          clipped-left
          clipped-right
          class="cts-header-bar"
          flat
          dense
        >
          <ClientLogo class="col-xl-2 col-lg-2 col-md-3 col-sm-3 pa-0" />
          <MainMenu :compact="false" class="col-xl-8 col-lg-8 col-md-6 col-sm-6 pa-0 mb-2"/>
          <!-- Para seleccionar idioma en todo el website -->

          <!-- Para que detecte la subcuenta en header -->
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 pa-0">
            <lang-selector class="cts-lang--selector-header" :compact_mode="true"/>
            <UserSelector :authObject="authObject" style="float: right"/>
          </div>
          <!-- Para que detecte el tema en todo el website -->
          <SwichTheme mode="hidden"/>
        </v-app-bar>
      </v-col>
      <v-dialog
        class="cts-bgcolor-primary-alternative"
        v-model="loadingDialog"
        persistent
        width="300"
      >
        <v-card class="pa-2 pb-4 cts-loading-card">
          <v-card-text class="pa-0">
            <v-row dense class="ma-0 justify-center">
              <span class=" cts-color-darken">{{$vuetify.lang.t('$vuetify.loading')}}...</span>
            </v-row>
            <v-row dense class="ma-0 justify-center pt-2">
              <v-progress-circular
                      indeterminate
                      class=" cts-color-primary-alternative"
              ></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card class="mx-auto hidden-lg-and-up">
      <v-app-bar fixed class="cts-app-compact" style="z-index: 2">
        <lang-selector
          class="cts-lang--selector-header"
          :compact_mode="true"
        />
        <v-row dense class="ma-0 pa-0">
          <v-col cols="1">
            <v-app-bar-nav-icon @click="drawer = true" />
          </v-col>
          <v-col cols="10">
            <v-toolbar-title>
              <ClientLogo/>
            </v-toolbar-title>
          </v-col>
          <v-col cols="1">
          </v-col>
        </v-row>
      </v-app-bar>
      <!-- MOBILE VERSION -->
      <v-navigation-drawer
        id="mobile-navigation-drawer"
        v-model="drawer"
        fixed
        class="cts-navigation-drawer hidden-lg-and-up"
        temporary
      >
       <main-menu :authObject="authObject" :compact="true" />
        <template v-slot:append>
          <v-col class="pa-2">
            <v-row dense class="ma-0">
                <h5 class="cts-color-tertiary">{{ $vuetify.lang.t('$vuetify.selectedSubaccount') }}</h5>
                <subaccount-selector
                  v-if="hasSubaccount"
                  :subaccounts="authObject[const_global.GROUP][const_global.GROUP_SUBACCOUNTS]"
                  :subaccount_selected="authObject[const_global.ACTIVE_SUBACCOUNT]"
                />
            </v-row>

            <hr class="my-2">

            <v-row dense class="mb-2 mt-0 mx-0 justify-space-between">
              <v-btn
                v-if="checkPermission(const_permissions.SECTION_ACCOUNT)"
                outlined
                small
                :to="ACCOUNT.PATH"
                class="col-12 text-center cts-bgcolor-clear"
                >
                {{ $vuetify.lang.t('$vuetify.account') }}
                <v-icon aria-hidden="false" class="float-right" small color="var(--primary)">
                  {{ const_icons.USER }}
                </v-icon>
              </v-btn>
            </v-row>

            <v-row
              v-if="checkPermission(const_permissions.SECTION_ADMINISTRATION)"
              dense
              class="my-2 mt-0 mx-0 justify-space-between"
              >
              <v-btn outlined small :to="ADMINISTRATION.PATH" class="col-12 text-center cts-bgcolor-clear">
                {{ $vuetify.lang.t('$vuetify.administration') }}
                <v-icon
                  aria-hidden="false"
                  class="float-right"
                  small
                  color="var(--primary)"
                >
                  {{ const_icons.ADMINISTRATION }}
                </v-icon>
              </v-btn>
            </v-row>

            <v-row dense class="my-2 mx-0 justify-space-between">
              <v-btn
                id="mobile-navigation-drawer-logout"
                outlined
                small
                class="col-12 text-center cts-bgcolor-clear"
                @click="goLogout()"
              >
                {{ $vuetify.lang.t('$vuetify.logout') }}
                <v-icon
                  aria-hidden="false"
                  class="float-right"
                  small
                  color="var(--primary)"
                >
                  {{ const_icons.LOGOUT }}
                </v-icon>
              </v-btn>
            </v-row>

            <v-row v-if="authObject" dense class=" mx-0 justify-center">
              <span
                v-if="authObject[const_global.LAST_LOGIN_SUCCESS] !== ''"
                class="cts-color-darken font-weight-medium cts-font-size-4"
              >
                {{ dayjs.unix(authObject[const_global.LAST_LOGIN_SUCCESS]).format(const_global.DATE_TIME_FORMAT_A) }}
              </span>
              <span
                v-else
                class="cts-color-darken font-weight-medium cts-font-size-4"
              >
                {{ $vuetify.lang.t('$vuetify.noRecord') }}
              </span>
            </v-row>
          </v-col>
        </template>
      </v-navigation-drawer>

      <v-overlay :value="drawer"></v-overlay>
    </v-card>
  </div>
</template>

<script>
  import const_icons from "@/constants/icons"
  import MainMenu from "./navigations/MainMenu"
  import UserSelector from "./buttons/UserSelector"
  import SwichTheme from "./SwichTheme"
  import const_global from "@/constants/global"
  import {
    NAMESPACE as AUTHENTICATION_NAMESPACE,
    STATE as AUTHENTICATION_STATE,
    GETTERS as AUTHENTICATION_GETTERS,
    ACTIONS as AUTHENTICATION_ACTIONS
  } from "@/constants/vuex/authentication"
  import const_permissions from "@/constants/permissions"
  import SubaccountSelector from "./buttons/subaccountSelector"
  import { userLogout } from "@/services/userServices"
  import ClientLogo from "@/components/logos/ClientLogo"
  import LangSelector from "././buttons/LangSelector"
  import { checkPermission } from "@/util/store_utils"
  import { ACCOUNT, ADMINISTRATION, LOGIN } from "@/constants/router/routes"
  import { NAMESPACE as GLOBAL_NAMESPACE, STATE as GLOBAL_STATE } from "@/constants/vuex/global"
  import { redirect } from "@/util/router_utils"

  export default {
    name: "TheHeader",
    components: {
      ClientLogo,
      SubaccountSelector,
      SwichTheme,
      MainMenu,
      UserSelector,
      LangSelector
      // SwitchTheme
    },
    props: {
      source: String,
    },
    data() {
      return {
        checkPermission,
        const_global,
        drawer: false,
        notificationsBar: false,
        const_icons,
        const_permissions,
        authObject: null,
        learnurl: "certysign-learn",
        ACCOUNT,
        ADMINISTRATION
      }
    },
    computed: {
      loadingDialog() {
        return this.$store.state[GLOBAL_NAMESPACE][GLOBAL_STATE.LOADING_BOX]
      },

      hasSubaccount(){
        return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE] !== ""
      }

    },
    methods: {
      async goLogout() {
        await userLogout()
        await this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)

        redirect(LOGIN)
      },

    },

    created() {
      this.authObject = this.$store.getters[AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_GETTERS.G_LOGIN_DATA]
    },
  }
</script>
