<template>
  <v-container class="cts-login-container">
    <v-row class="justify-center">
      <LoginBox
        v-if="action == LOGIN.NAME"
        :theme="theme"
      />

      <NewPasswordBox
        v-if="action == NEW_PASSWORD.NAME || action == ACTIVATE_USER.NAME"
        :theme="theme"
        :activate="action == ACTIVATE_USER.NAME"
      />

      <RememberPasswordBox
        v-if="action == REMEMBER_PASSWORD.NAME"
        :theme="theme"
      />
    </v-row>
    <v-expand-transition>
      <v-card v-if="message" class="pa-5 cts-user-message-box">
        <span>{{message}}</span>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>

<script>

  import {mapGetters} from "vuex";
  import LoginBox from "../components/sections/user_management/LoginBox"
  import const_global from "@/constants/global"
  import RememberPasswordBox from "../components/sections/user_management/RememberPasswordBox"
  import NewPasswordBox from "../components/sections/user_management/NewPasswordBox"
  import { LOGIN, NEW_PASSWORD, ACTIVATE_USER, REMEMBER_PASSWORD } from "@/constants/router/routes"

  export default {
    name: 'UserManagement',
    props: {
      theme: String
    },
    components: {
      NewPasswordBox,
      RememberPasswordBox,
      LoginBox
    },
    data() {
      return {
        const_global,
        message: null,
        LOGIN,
        NEW_PASSWORD,
        ACTIVATE_USER,
        REMEMBER_PASSWORD
      }
    },
    computed:
        {
          ...mapGetters({
            appearance: 'appearance/getAppearance'
          }),
          action(){
            return this.$route.name
          }
        },
    mounted() {
      this.visible = true

      let self = this
      setTimeout(function () {
        self.message = null

      }, 5000)


    },
    methods: {}
  }
</script>

<style>
  html {
    overflow: hidden;
  }

</style>
