<template>
  <v-expand-transition>
    <v-dialog v-model="openDialog" max-width="800px">
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title v-if="isEditing" class="col-lg-12" :icon="const_icons.USERS"
                        :text="$vuetify.lang.t('$vuetify.editGroup')"/>
          <dialog-title v-else class="col-lg-12" :icon="const_icons.NEW_USER"
                        :text="$vuetify.lang.t('$vuetify.newGroup')"/>
        </v-card-title>
        <v-card-text class="pb-0">
          <form>

            <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
              <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.groupData')}}</h4>
            </div>
            <v-row class="mb-6" dense>
              <v-col cols="12" lg="4" md="4" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.name')}}</span>
                    <v-text-field required outlined
                                  :aria-label="$vuetify.lang.t('$vuetify.name')"
                                  :placeholder="$vuetify.lang.t('$vuetify.name')"
                                  autocomplete="off" v-model="newName"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="8" md="8" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.description')}}</span>
                    <v-text-field required outlined autocomplete="off"
                                  :aria-label="$vuetify.lang.t('$vuetify.groupDescription')"
                                  :placeholder="$vuetify.lang.t('$vuetify.groupDescription')"
                                  v-model="newDescription"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
              <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.frontalPolicy')}}</h4>
            </div>
            <v-row class="mb-6 px-2" dense>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.loginRetriesTime')}}</span>

                    <CtsSlider v-model="loginRetriesTime" :min-value="10" :max-value="60"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.loginRetriesQuantity')}}</span>

                    <CtsSlider v-model="loginRetriesQuantity" :min-value="3" :max-value="10"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.sessionExpiration')}}</span>

                    <CtsSlider v-model="sessionExpiration" :min-value="5" :max-value="60"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
              <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.passwordPolicy')}}</h4>
            </div>
            <v-row class="mb-6 px-2" dense>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.groupMinDaysToChange')}}</span>
                    <CtsSlider v-model="minDaysToChange" :min-value="7"
                               :max-value="90"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.passwordExpiration')}}</span>
                    <CtsSlider v-model="passwordExpiration" :min-value="minDaysToChange"
                               :max-value="120"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.passwordsQuantity')}}</span>
                    <CtsSlider v-model="passwordsQuantity" :min-value="1" :max-value="10"></CtsSlider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="ma-0 justify-space-between px-4 pb-4">
            <v-col cols="6" xl="6" lg="6" md="6" sm="6">
              <v-btn class="cts-button-tertiary float-left" small @click="openDialog = false" dark>
                {{$vuetify.lang.t('$vuetify.cancel')}}
                <v-icon aria-hidden="false" small class="ml-2 float-left">{{const_icons.CANCEL}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" xl="6" lg="6" md="6" sm="6">
              <v-btn class="cts-button-standard float-right" small @click="save" :disabled="!validForm">
                {{ $vuetify.lang.t('$vuetify.save') }}
                <v-icon aria-hidden="false" small class="ml-2 float-right">{{ const_icons.SAVE }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expand-transition>
</template>

<script>
  import DialogTitle from "../../../../structures/dialogTitle"
  import const_icons from "@/constants/icons"
  import { createGroup, getGroups } from "@/services/groupServices"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
  import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE, ACTIONS as ADMINISTRATION_ACTIONS } from "@/constants/vuex/administration"
  import CtsSlider from "../../../../structures/CtsSlider";
  import { showToast } from "@/util/utils"

  export default {
    name: "groupModal",
    components: { CtsSlider, DialogTitle },
    data() {
      return {
        isEditing: false,
        const_icons,
        newGroup: {},
        newName: '',
        newGroupAssignation: '',
        newDescription: '',
        minDaysToChange: 7,
        passwordsQuantity: 5,
        passwordExpiration: 90,
        loginRetriesQuantity: 3,
        loginRetriesTime: 10,
        sessionExpiration: 15,
        error: null,
        errorMessage: ''
      }
    },
    computed: {
      openDialog: {
        get() {
          return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_NEW_GROUP_DIALOG]
        },
        set(value) {
          this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_NEW_GROUP_DIALOG}`, value)
        }
      },

      validForm() {
        return this.newName.length > 0 && this.newDescription.length != 0
      }
    },
    created() {
      this.newGroup = {}
    },
    methods: {
      save() {
        if(this.validForm) {
          this.newGroup.name = this.newName
          this.newGroup.group_permissions = this.newGroupAssignation
          this.newGroup.description = this.newDescription
          this.newGroup.masteraccount = this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE]

          this.newGroup.mindaysPasswordChange = this.minDaysToChange
          this.newGroup.passwordsQuantity = this.passwordsQuantity
          this.newGroup.passwordExpiration = this.passwordExpiration
          this.newGroup.sessionExpiration = this.sessionExpiration
          this.newGroup.userIp = 0 // Oculto, Fijo siempre 0
          this.newGroup.loginRetriesQuantity = this.loginRetriesQuantity
          this.newGroup.loginRetriesTime = this.loginRetriesTime
          this.newGroup.masterAccountAdmin = 0 // Oculto, Fijo siempre 0

          createGroup(this.newGroup).then((response) => {
            let error = false
            if (response.data.error == 0) {
            this.open = false
            } else {
            error = true
            }
            getGroups()
            showToast(error, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
          })
          this.openDialog = false
        }

      }
    }
  }
</script>
