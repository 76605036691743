// Requests under /process

import Axios from "@/plugins/axios"
import store from "@/store"
import {PROCESS_ROUTES} from "@/constants/backOfficeRoutes"
import const_global from "@/constants/global"
import {ACTIONS as PROCESSES_ACTIONS, NAMESPACE as PROCESSES_NAMESPACE} from "@/constants/vuex/processes"
// import {serialize} from '@/plugins/objectToFormdata'

export const setProcessInfo = process_info => {
  store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_STATUS}`, false)
  return Axios.get(PROCESS_ROUTES.ROUTE_PROCESS_FIELDS.replace("$process_code$", process_info[const_global.PROCESS_CODE]))
}

export const launchTransaction = (transaction_info) => {
  // const processFormData = serialize(transaction_info)

  Axios.post(PROCESS_ROUTES.ROUTE_PROCESS_LAUNCH, transaction_info)
    .then((response) => {
      setTimeout(function () {
        store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_RESULT}`, response.data)
        if (response.data.error === 0 && response.data.extra && response.data.extra.find(t => t.instance_code.indexOf('INVALID') !== -1) === undefined) {
          store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_STATUS}`, const_global.TRANSACTION_OK)
        } else {
          store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_STATUS}`, const_global.TRANSACTION_ERROR)
        }


      }, 1000);
    })
}

export const getProcessBehaviorLayer = async (processCode) => {
  const res = await Axios.get(PROCESS_ROUTES.ROUTE_PROCESS_FIELDS.replace("$process_code$", processCode))
  return res.data.metadata.behaviorLayer ? JSON.parse(res.data.metadata.behaviorLayer) : null

}
