export const NAMESPACE = "vuex_processes"

export const STATE = {
    S_AVAILABLE_PROCESSES: "state_available_processes",
    S_AVAILABLE_TAGS: "state_available_tags",

    //NEW TRANSACTION
    S_CODE: "state_process_code",
    S_NAME: "state_process_name",
    S_DESCRIPTION: "state_process_description",
    S_IMAGE: "state_process_image",
    S_STEPS: "state_process_steps",
    S_FIELDS: "state_process_fields",
    S_METADATA: "state_process_metadata",
    S_RECIPIENTS: "state_process_recipients",
    S_TRANSACTION_STATUS: "state_transaction_status",
    S_TRANSACTION_RESULT: "state_transaction_result",
    S_DATA_PREVIEW: "state_data_preview",
    S_FILE_LOADING: "state_file_loading",
    S_COST_CENTER: "state_cost_center",
    S_PROCESS_TYPE: 'state_process_type',
    S_CHECKING_FIELD: "state_checking_field",
    S_PROCESS_NOTIFICATIONS: "state_process_notifications",


    S_CURRENT_TRANSACTION_PROCESS_CODE: "state_current_transaction_process_code",
    S_CURRENT_TRANSACTION_RECIPIENTS: "state_current_transaction_recipients",
    S_CURRENT_TRANSACTION_DATA: "state_current_transaction_data",
    S_CURRENT_RECIPIENTS_VALID: "state_current_recipients_valid",
    S_CURRENT_DATA_VALID: "state_current_data_valid",
    S_POSTAL_PROCESS_USER_CUSTOM_VALUES: "state_postal_process_user_custom_values",
    S_TRANSACTION_PENDING: "state_transaction_pending",
    S_TRANSACTION_STEP: "state_transaction_step",

    S_BATCH_MODE: "state_batch_mode"
}

export const ACTIONS = {
    A_SET_AVAILABLE_PROCESSES: "action_set_alert_available_processes",
    A_SET_PROCESS_TRANSACTION: "action_set_process_transaction",
    A_SET_PROCESS_RECIPIENTS: "action_set_process_recipients",
    A_SET_PROCESS_FIELD_VALUE: "action_set_process_fields_value",
    A_SET_PROCESS_METADATA_VALUE: "action_set_process_metadata_value",
    A_SET_TRANSACTION_STATUS: "action_set_transaction_status",
    A_SET_TRANSACTION_RESULT: "action_set_transaction_result",
    A_RESET_STATE: "action_reset_status",
    A_SET_DATA_PREVIEW: "action_set_data_preview",
    A_SET_FILE_LOADING: "action_set_file_loading",
    A_SET_COST_CENTER: "action_set_cost_center",
    A_SET_CHECKING_FIELD: "action_set_checking_field",
    A_SET_PROCESS_NOTIFICATIONS: "action_set_process_notifications",
    A_SET_PROCESS_TYPE: "action_set_process_type",


    A_CURRENT_TRANSACTION_PROCESS_CODE: "action_set_current_transaction_process_code",
    A_SET_CURRENT_TRANSACTION_RECIPIENTS: "action_set_current_transaction_recipients",
    A_SET_CURRENT_TRANSACTION_DATA: "action_set_current_transaction_data",
    A_SET_PROCESS_STEPS: "action_set_process_steps",
    A_SET_CURRENT_RECIPIENTS_VALID: "action_set_process_recipients_valid",
    A_SET_CURRENT_DATA_VALID: "action_set_process_data_valid",
    A_SET_POSTAL_PROCESS_USER_CUSTOM_VALUES: "action_set_postal_process_user_custom_values",

    A_SET_BATCH_MODE: "action_set_batch_mode",

    A_SET_TRANSACTION_PENDING: "action_set_transaction_pending",
    A_SET_TRANSACTION_STEP: "action_set_transaction_step",
}

export const MUTATIONS = {
    M_SET_AVAILABLE_PROCESSES: "mutation_set_available_processes",

    //NEW TRANSACTION
    M_SET_CODE: "mutation_process_code",
    M_SET_NAME: "mutation_process_name",
    M_SET_DESCRIPTION: "mutation_process_description",
    M_SET_IMAGE: "mutation_process_image",
    M_SET_STEPS: "mutation_process_steps",
    M_SET_FIELDS: "mutation_process_fields",
    M_SET_METADATA: "mutation_process_metadata",
    M_SET_RECIPIENTS: "mutation_process_recipients",
    M_SET_TRANSACTION_STATUS: "mutation_set_transaction_status",
    M_SET_TRANSACTION_RESULT: "mutation_set_transaction_result",
    M_SET_DATA_PREVIEW: "mutation_set_data_preview",
    M_SET_FILE_LOADING: "mutation_set_file_loading",
    M_SET_COST_CENTER: "mutation_set_cost_center",
    M_SET_PROCESS_TYPE: "mutation_set_process_type",
    M_SET_CHECKING_FIELD: "mutation_set_checking_field",
    M_SET_PROCESS_NOTIFICATIONS: "mutation_set_process_notifications",


    M_CURRENT_TRANSACTION_PROCESS_CODE: "mutation_set_current_transaction_process_code",
    M_SET_CURRENT_TRANSACTION_RECIPIENTS: "mutation_set_current_transaction_recipients",
    M_SET_CURRENT_TRANSACTION_DATA: "mutation_set_current_transaction_data",
    M_CURRENT_RECIPIENTS_VALID: "mutate_current_recipients_valid",
    M_CURRENT_DATA_VALID: "mutate_current_data_valid",
    M_POSTAL_PROCESS_USER_CUSTOM_VALUES: "mutate_postal_process_user_custom_values",

    M_BATCH_MODE: "mutate_batch_mode",
    M_SET_TRANSACTION_PENDING: "mutation_set_transaction_pending",
    M_SET_TRANSACTION_STEP: "mutation_set_transaction_step",

}

export const GETTERS = {
    G_AVAILABLE_PROCESSES: "get_available_processes",
    G_AVAILABLE_PROCESSES_BY_CATEGORIES: "get_available_processes_by_category",
    G_PROCESS_TRANSACTION: "get_process_transaction",
    G_PROCESS_NOTIFICATIONS: "get_process_notifications",

    G_CURRENT_TRANSACTION_RECIPIENTS: "get_current_transaction_recipients",
    G_CURRENT_TRANSACTION_DATA: "get_current_transaction_data",

    G_TRANSACTION_PENDING: "get_transaction_pending",
    G_TRANSACTION_STEP: "get_transaction_step",
}

export default {
    NAMESPACE,
    STATE,
    ACTIONS,
    MUTATIONS,
    GETTERS
}
