import { STATE, MUTATIONS, GETTERS, ACTIONS } from "@/constants/vuex/administration"

const state = {
  [STATE.S_GROUPS]: [],
  [STATE.S_GROUP_CODE_SELECTED]: null,
  [STATE.S_NEW_GROUP_DIALOG]: false,
  [STATE.S_GROUP_DETAILS]: {},
  [STATE.S_USERS]: null,
  [STATE.S_USER_DETAILS_DIALOG]: false,
}

const mutations = {
  [MUTATIONS.M_SET_GROUPS](state, groups) {
    state[STATE.S_GROUPS] = groups
  },
  [MUTATIONS.M_SET_GROUP_CODE_SELECTED](state, group_code) {
    state[STATE.S_GROUP_DETAILS] = null
    state[STATE.S_GROUP_CODE_SELECTED] = group_code
  },
  [MUTATIONS.M_SET_NEW_GROUP_DIALOG](state, value) {
    state[STATE.S_NEW_GROUP_DIALOG] = value
  },
  [MUTATIONS.M_SET_GROUP_DETAILS](state, value) {
    state[STATE.S_GROUP_DETAILS] = value
  },
  [MUTATIONS.M_SET_USERS](state, users) {
    state[STATE.S_USERS] = users
  },
  [MUTATIONS.M_SET_USER_DETAILS_DIALOG](state, users) {
    state[STATE.S_USER_DETAILS_DIALOG] = users
  }
}

const actions = {
  [ACTIONS.A_SET_GROUPS]({ commit }, groups) {
    commit(MUTATIONS.M_SET_GROUPS, groups)
  },
  [ACTIONS.A_SET_GROUP_CODE_SELECTED]({ commit }, group_code) {
    commit(MUTATIONS.M_SET_GROUP_CODE_SELECTED, group_code)
    if( !group_code ){
      commit(MUTATIONS.M_SET_GROUP_DETAILS, {})
    }
  },
  [ACTIONS.A_SET_NEW_GROUP_DIALOG]({ commit }, value) {
    commit(MUTATIONS.M_SET_NEW_GROUP_DIALOG, value)
  },
  [ACTIONS.A_SET_GROUP_DETAILS]({ commit }, value) {
    commit(MUTATIONS.M_SET_GROUP_DETAILS, value)
  },
  [ACTIONS.A_SET_USERS]({ commit }, value) {
    commit(MUTATIONS.M_SET_USERS, value)
  },
  [ACTIONS.A_SET_USER_DETAILS_DIALOG]({ commit }, value) {
    commit(MUTATIONS.M_SET_USER_DETAILS_DIALOG, value)
  }
}

const getters = {
  [GETTERS.G_GROUP_DETAILS](state){
    return state[STATE.S_GROUP_DETAILS]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
