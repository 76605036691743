export const NAMESPACE = "vuex_authentication"

export const STATE = {
  S_USER_LOGGED: "state_user_logged",

  S_USERNAME: "state_username",
  S_USER_ID: "state_user_id",
  S_NAME: "state_name",
  S_PERMISSIONS: "state_permissions",

  S_MASTER_ACCOUNT_CODE: "state_master_account_code",
  S_MASTER_ACCOUNT_ADMIN: "state_master_account_admin",

  S_GROUP_ID: "state_group_id",
  S_GROUP_NAME: "state_group_name",

  S_AVAILABLE_SUBACCOUNTS: "state_available_subaccounts",
  S_SUBACCOUNT_CODE: "state_subaccount_code",
  S_SUBACCOUNT_NAME: "state_subaccount_name",

  S_LANGUAGE: "state_language",
  S_LANGUAGE_CHECK_PENDING: "state_language_check_pending",
  S_AVAILABLE_COST_CENTERS: "state_available_cost_centers",

  S_REMEMBER_SEND: "state_remember_send",
  S_CHECKING_LOGIN: "state_checking_login",

  S_TOKEN_CODE: "state_token_code",
  S_TOKEN_EXPIRATION: "state_token_expiration",
  S_TOKEN_EXPIRATION_TIMESTAMP: "state_token_expiration_timestamp",
  S_EXPIRED_PASSWORD: "state_expired_password",
  S_LAST_SESSION_REFRESH: "state_last_session_refresh",

  S_USER_GUIDE: "state_user_guide",
  S_USER_TIMEZONE: "state_user_timezone",

  S_LAST_LOGIN_SUCCESS_TIMESTAMP: "state_last_login_success_timestamp",
  S_LAST_LOGIN_ERROR_TIMESTAMP: "state_last_login_error_timestamp",
  S_LOGIN_SESSION_POPUP: "state_login_session_popup",
}

export const MUTATIONS = {
  M_USER_LOGGED: "mutation_set_user_logged",

  M_USERNAME: "mutation_set_username",
  M_USER_ID: "mutate_user_id",
  M_NAME: "mutation_set_name",
  M_PERMISSIONS: "mutation_set_permissions",

  M_MASTER_ACCOUNT_CODE: "mutation_set_master_account_code",
  M_MASTER_ACCOUNT_ADMIN: "mutation_master_account_admin",

  M_GROUP_ID: "mutation_set_group_id",
  M_GROUP_NAME: "mutation_set_group_name",

  M_AVAILABLE_SUBACCOUNTS: "mutation_set_available_subaccounts",
  M_SUBACCOUNT_CODE: "mutation_set_subaccount_code",
  M_SUBACCOUNT_NAME: "mutation_set_subaccount_name",

  M_LANGUAGE: "mutation_set_language",
  M_LANGUAGE_CHECK_PENDING: "mutation_language_check_pending",
  M_AVAILABLE_COST_CENTERS: "mutation_available_cost_centers",

  M_REMEMBER_SEND: "mutation_set_remember_send",
  M_CHECKING_LOGIN: "mutation_set_checking_login",


  M_SET_TOKEN_CODE: "mutation_set_token_code",
  M_SET_TOKEN_EXPIRATION: "mutation_set_token_expiration",
  M_SET_EXPIRED_PASSWORD: "mutation_set_expired_password",
  M_SET_TOKEN_EXPIRATION_TIMESTAMP: "mutation_set_expiration_timestamp",

  M_SET_USER_MANUAL: "mutation_set_user_manual",
  M_SET_USER_GUIDE: "mutation_set_user_guide",
  M_SET_USER_TIMEZONE: "mutation_set_user_timezone",

  M_LAST_LOGIN_SUCCESS_TIMESTAMP: "mutation_set_last_login_success_timestamp",
  M_LAST_LOGIN_ERROR_TIMESTAMP: "mutation_set_last_login_error_timestamp",
  M_LOGIN_SESSION_POPUP: "mutation_login_session_popup",
}

export const ACTIONS = {
  A_SET_LOGIN_DATA: "action_set_login_data",
  A_SET_LOGOUT_DATA: "action_set_logout_data",
  A_CLEAR_SESSION_DATA: "action_clear_session_data",
  A_CHANGE_SUBACCOUNT: "action_change_subaccount",
  A_SET_AVAILABLE_COST_CENTERS: "action_set_available_cost_centers",

  A_REMEMBER_SEND: "action_set_remember_send",

  A_CHECKING_LOGIN: "action_set_checking_login",

  A_SET_EXPIRED_PASSWORD: "action_set_expired_password",
  A_SET_EXPIRATION_SESSION_TIMESTAMP: "action_set_expiration_session_timestamp",

  A_SET_LANGUAGE_CHECK_PENDING: "action_set_language_check_pending",
  A_SET_LANGUAGE: "action_set_language",
  A_SET_LOGIN_SESSION_POPUP: "action_set_login_session_popup",
  A_SET_TOKEN_EXPIRATION_TIMESTAMP: "action_set_token_expiration_timestamp"
}

export const GETTERS = {
  G_LOGIN_DATA: "getter_login_data",
  G_EXPIRATION_SESSION_TIMESTAMP: "getter_expiration_session_timestamp"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}
