export default [
  {
    code: 'es',
    name: 'Español',
    iso: 'spa'
  },
  {
    code: 'eu',
    name: 'Euskera',
    iso: 'eus'
  },
  {
    code: 'ca',
    name: 'Català',
    iso: 'cat'
  },
  {
    code: 'gl',
    name: 'Galego',
    iso: 'glg'
  },
  {
    code: 'en',
    name: 'English',
    iso: 'eng'
  },
  {
    code: 'pt',
    name: 'Português',
    iso: 'por'
  },
  {
    code: 'it',
    name: 'Italiano',
    iso: 'ita'
  },
  {
    code: 'nl',
    name: 'Nederlands',
    iso: 'nld'
  },
  {
    code: 'fr',
    name: 'Française',
    iso: 'fra'
  },
  {
    code: 'de',
    name: 'Deutsche',
    iso: 'deu'
  },

]
