<template>

    <v-col class="px-0 py-0 mx-auto" cols="10" lg="12" md="12" sm="12" xl="12">
        <v-img
          alt="Logo"
          class="shrink mx-auto"
          contain
          src="https://r.mailcomms.io/b2b/logos/light.png"
          style="cursor: pointer"
          width="125"
          @click="goRoot()"
        />
    </v-col>
</template>

<script>

import {mapGetters} from "vuex";
import {redirect} from "@/util/router_utils"
import {ROOT} from "@/constants/router/routes"

export default {
    name: "CertySignLogo",
    computed: {
        ...mapGetters({
            appearance: 'appearance/getAppearance'
        }),
    },
    data() {
        return {
            theme: ""
        }
    },
    created() {
        this.theme = this.appearance.theme
    },
    methods: {
        goRoot() {
            redirect(ROOT)
        }
    }
}
</script>

<style scoped>

</style>
