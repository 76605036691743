<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <v-container class="cts-main-view col-12">
    <v-scroll-y-transition>
      <v-col v-if="available_groups" cols="12" class="ma-0 pa-0">
        <v-row dense class="ma-0">
          <v-col cols="12" class="pa-0">
            <v-row dense class="ma-0 pa-0">
              <v-col cols="11" lg="10" offset-lg="1" class="mx-auto cts-view-header">
                <v-row dense class="cts-breadcrumbs ma-0 mt-1 pa-0">
                  <a @click.prevent="backToAdmin" :title="$vuetify.lang.t('$vuetify.administrationTitle')">{{
                    $vuetify.lang.t('$vuetify.administrationTitle')
                    }} </a>
                  <span class="cts-color-black_soft_text font-weight-regular pl-1">/ {{
                  $vuetify.lang.t('$vuetify.groupsTitle')
                }} </span>
                </v-row>
                <view-title
                  :icon="const_icons.PASSWORD"
                  :title="$vuetify.lang.t('$vuetify.groupsTitle')"
                  :subtitle="$vuetify.lang.t('$vuetify.groupsDescription')"
                  idName="groups"
                />
              </v-col>
            </v-row>
            <v-row dense class="ma-0 pa-0 justify-center">
              <v-col cols="11" lg="10" xl="10" md="11" class="white pa-0">
                <v-row dense class="cts-row-processes-filters py-4">
                  <v-col cols="12" class="pa-0">
                    <v-row dense class="ma-0 justify-space-between">
                      <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                        <v-btn v-if="checkPermission(const_permissions.GROUP_CREATE)" class="cts-button-standard"
                               small color="primary" @click="createGroup">
                          {{ $vuetify.lang.t('$vuetify.newGroup') }}
                          <v-icon size="17px !important" class="float-right">add</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="6" xl="3" lg="3" md="3" sm="4">
                        <v-text-field multiple clearable single-line outlined dense
                                      class="ma-0 cts-text-box"
                                      v-model="search"
                                      :placeholder="$vuetify.lang.t('$vuetify.search')"
                                      :aria-label="$vuetify.lang.t('$vuetify.search')"
                                      :append-icon="const_icons.SEARCH"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="justify-center">
              <v-col cols="11" lg="10" xl="10" md="11" class="pa-0">
                <v-data-table
                        dense
                        :headers="headers"
                        :items="available_groups"
                        @pagination="filterGroups"
                        :search="search"
                        class="elevation-0 col-12"
                        sort-by="name"
                        calculate-widths
                        :footer-props="{
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'keyboard_arrow_left',
                  nextIcon: 'keyboard_arrow_right',
                  itemsPerPageText: $vuetify.lang.t('$vuetify.groupsPerPage'),
                  pageText: $vuetify.lang.t('$vuetify.groupsFound')+ ' ' + filtered_groups + ' ' + $vuetify.lang.t('$vuetify.groups')
                }">
                  <template v-slot:item="{item}">
                    <tr class="hidden-xs-only">

                      <td class="text-start cts-group-name-column">
                        <v-tooltip v-if="item.name.length > 35" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="checkPermission(const_permissions.SECTION_GROUP)" class="cts-color-primary text-left cts-break-word"
                                   text small @click="openGroup(item.id)" v-bind="attrs" v-on="on"><span class="cts-break-word">{{ item.name }}</span>
                            </v-btn>
                            <h4 v-else class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</h4>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <v-row v-else dense class="ma-0 pa-0">
                          <v-col cols="12" class="ma-0 pa-0">
                            <v-btn v-if="checkPermission(const_permissions.SECTION_GROUP)" class="cts-color-primary text-left"
                                   text small @click="openGroup(item.id)">{{ item.name }}
                            </v-btn>
                            <h4 v-else class="cts-color-tertiary">{{ item.name }}</h4>
                          </v-col>
                        </v-row>
                      </td>
                      <td class="text-start cts-group-description-column">
                        <v-tooltip v-if="item.name.length > 35" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.description }}</h4>
                          </template>
                          <span>{{ item.description  }}</span>
                        </v-tooltip>
                        <h4 v-else class="cts-color-tertiary">{{ item.description }}</h4>
                      </td>
                      <td class="text-start">
                        <h4 v-if="item.master_account_admin == '1'" class="cts-color-tertiary text-center">
                          <v-icon>{{ const_icons.CHECK }}</v-icon>
                        </h4>
                      </td>
                    </tr>
                    <v-card class="mx-auto my-2 cts-card-transaction hidden-sm-and-up" max-width="95%" outlined
                            @click="openGroup(item.id)">
                      <v-list-item class="cts-list-item-card">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row dense class="ma-0 justify-space-between">
                              <v-col cols="8" class="hidden-sm-and-up pa-0 ma-0">
                                <h4 class="cts-font-size-5 cts-white-space pa-1">
                              <span class="font-weight-bold mr-1">
                              {{ item.name }}
                              </span>
                                  <span v-if="item.master_account_admin == '1'" class="text-lowercase font-italic">
                              ({{ $vuetify.lang.t('$vuetify.administrator') }})
                              </span>
                                </h4>
                                <h4 class="cts-font-size-5 cts-white-space pa-1"><span
                                        class="mr-2">{{ item.description }}</span></h4>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                      </v-list-item>
                    </v-card>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <group-modal v-if="openDialog"></group-modal>
      </v-col>
    </v-scroll-y-transition>
    <v-col v-if="!available_groups" cols="12" class="ma-0 pa-0">
      <loading-container/>
    </v-col>
  </v-container>
</template>

<script>

  import const_icons from "@/constants/icons"
  import ViewTitle from "../../structures/viewTitle"
  import GroupModal from "./groupsView/groupView/groupModal"
  import const_global from "@/constants/global"
  import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE, ACTIONS as ADMINISTRATION_ACTIONS} from "@/constants/vuex/administration"
  import { getGroups } from "@/services/groupServices"
  import const_permissions from "@/constants/permissions";
  import LoadingContainer from "../../structures/loadingContainer";
  import { redirect } from "@/util/router_utils"
  import { checkPermission } from "@/util/store_utils"
  import { ADMINISTRATION, GROUP_VIEW } from "@/constants/router/routes"
  import { mapActions } from "vuex"

  export default {
    name: "GroupsViewer",
    components: {LoadingContainer, GroupModal, ViewTitle},
    data() {
      return {
        const_icons,
        const_global,
        const_permissions,
        checkPermission,
        headers: [
          {
            text: this.$vuetify.lang.t('$vuetify.groupTitle'),
            align: 'start',
            value: 'name',
            width: '30%',
            class: "hidden-xs-only"
          },
          {
            text: this.$vuetify.lang.t('$vuetify.description'),
            align: 'start',
            value: 'description',
            width: '30%',
            class: "hidden-xs-only"
          },
          {
            text: this.$vuetify.lang.t('$vuetify.administrator'),
            align: 'center',
            value: 'master_account_admin',
            width: '10%', class: "hidden-xs-only"
          },
        ],
        search: '',
        contextMessage: false,
        error: null,
        filtered_groups: null,
        errorMessage: ''
      }
    },
    methods: {
      ...mapActions({
        actionSetGroupCodeSelected: `${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUP_CODE_SELECTED}`,
        actionSetNewGroupDialog: `${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_NEW_GROUP_DIALOG}`
      }),
      openGroup(code) {
        this.actionSetGroupCodeSelected(code)
        redirect(GROUP_VIEW)

      },
      backToAdmin() {
        redirect(ADMINISTRATION)
      },
      filterGroups(pagination) {
        this.filtered_groups = pagination.itemsLength
      },
      createGroup() {
        this.actionSetNewGroupDialog(true)
      }
    },
    computed: {
      openDialog() {
        return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_NEW_GROUP_DIALOG]
      },
      available_groups() {
        return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_GROUPS]
      }
    },
    created() {
      this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUPS}`, null)
      this.$store.dispatch(ADMINISTRATION_NAMESPACE + "/" + ADMINISTRATION_ACTIONS.A_SET_GROUP_CODE_SELECTED, null)
      getGroups().then((response) => {
        this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUPS}`, response.data)
      })
    },
  }
</script>

<style scoped>

</style>
