import { STATE, MUTATIONS, ACTIONS, GETTERS } from "@/constants/vuex/transactions"

const state = {
  [STATE.S_AVAILABLE_TRANSACTIONS]: [],
  [STATE.S_TRANSACTION]: null,
  [STATE.S_TRANSACTION_SELECTED]: null,
  [STATE.S_PROCESSES_FILTER]: [],
  [STATE.S_LINKED_GROUPS_FILTER]: [],
  [STATE.S_ACTIVE_FILTERS]: null,
}

const mutations = {
  [MUTATIONS.M_SET_AVAILABLE_TRANSACTIONS](state, transactions) {
    state[STATE.S_AVAILABLE_TRANSACTIONS] = transactions
  },
  [MUTATIONS.M_SET_TRANSACTION](state, transaction) {
    state[STATE.S_TRANSACTION] = transaction
  },
  [MUTATIONS.M_SET_TRANSACTION_SELECTED](state, transaction_id) {
    state[STATE.S_TRANSACTION_SELECTED] = transaction_id
  },
  [MUTATIONS.M_SET_PROCESSES_FILTER](state, process) {
    state[STATE.S_PROCESSES_FILTER] = process
  },
  [MUTATIONS.M_SET_LINKED_GROUPS_FILTER](state, group) {
    state[STATE.S_LINKED_GROUPS_FILTER] = group
  },
  [MUTATIONS.M_SET_ACTIVE_FILTERS](state, active_filters) {
    state[STATE.S_ACTIVE_FILTERS] = active_filters
  }
}

const actions = {
  [ACTIONS.A_SET_AVAILABLE_TRANSACTIONS]({ commit }, transactions) {
    commit(MUTATIONS.M_SET_AVAILABLE_TRANSACTIONS, transactions)
  },
  [ACTIONS.A_SET_TRANSACTION]({ commit }, transaction) {
    commit(MUTATIONS.M_SET_TRANSACTION, transaction)
  },
  [ACTIONS.A_SET_TRANSACTION_SELECTED]({ commit }, transaction_id) {
    commit(MUTATIONS.M_SET_TRANSACTION_SELECTED, transaction_id)
    if( !transaction_id ) {
      commit(MUTATIONS.M_SET_TRANSACTION, null)
    }
  },
  [ACTIONS.A_RESET_STATE]({ commit }) {
    commit(MUTATIONS.M_SET_TRANSACTION, null)
    commit(MUTATIONS.M_SET_AVAILABLE_TRANSACTIONS, [])
  },
  [ACTIONS.A_SET_FILTERS]({ commit }, payload) {
    commit(MUTATIONS.M_SET_PROCESSES_FILTER, payload["processes"])
    commit(MUTATIONS.M_SET_LINKED_GROUPS_FILTER, payload["linked_groups"])
  },
  [ACTIONS.A_SET_ACTIVE_FILTERS]({ commit }, active_filters) {
    commit(MUTATIONS.M_SET_ACTIVE_FILTERS, active_filters)
  }
}

const getters = {
  [GETTERS.G_AVAILABLE_TRANSACTIONS](state) {
    return state[STATE.S_AVAILABLE_TRANSACTIONS]
  },
  [GETTERS.G_TRANSACTION](state) {
    return state[STATE.S_TRANSACTION]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
