import { GETTERS as LOCATION_GETTERS, NAMESPACE as LOCATION_NAMESPACE } from '@/constants/vuex/location'
import store from '@/store'
import 'dayjs/locale/es'
import 'dayjs/locale/eu'
import 'dayjs/locale/ca'
import 'dayjs/locale/gl'
import 'dayjs/locale/en'
import 'dayjs/locale/pt'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/nl'


const isSameOrBeforePlugin = require('dayjs/plugin/isSameOrBefore')
const relativeTimePlugin = require('dayjs/plugin/relativeTime')

const dayjs = require("dayjs")
const langCode = store.getters[`${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`].code
dayjs.locale(langCode)

dayjs.extend(isSameOrBeforePlugin)
dayjs.extend(relativeTimePlugin)

export default dayjs
