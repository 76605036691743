<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon left size="19" class="mr-1">{{const_icons.USER}}</v-icon>
        {{$vuetify.lang.t('$vuetify.userData')}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-sm-4 pt-2">
      <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
        <h4 class="my-0 font-weight-medium">{{$vuetify.lang.t('$vuetify.userInfoTitle')}}</h4>
      </div>
      <v-row class="mb-6" dense>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.name')}}</span>
              <h4 class="cts-color-darken font-weight-medium">{{text.name}}</h4>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.user')}}</span>
              <h4 class="cts-color-darken font-weight-medium">{{text.username}}</h4>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.groupsAssignation')}}</span>
              <h4 class="cts-color-darken font-weight-medium">{{authObject.group.group_name}}</h4>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
      <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
        <h4 class="my-0 font-weight-medium">{{$vuetify.lang.t('$vuetify.loginInfo')}}</h4>
      </div>
      <v-row class="mb-6" dense>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.lastLoginSuccess')}}</span>
              <h4 class="cts-color-darken font-weight-medium">
                <span class="cts-color-darken font-weight-medium" v-if="authObject[constants.LAST_LOGIN_SUCCESS] !== ''">
                  {{ dayjs.unix(authObject[constants.LAST_LOGIN_SUCCESS]).format(constants.DATE_TIME_FORMAT_A) }}
                </span>
                <span class="cts-color-darken font-weight-medium" v-else>
                  {{$vuetify.lang.t('$vuetify.noRecord')}}
                </span>
              </h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.lastLoginError')}}</span>
              <h4>
                <span class="cts-color-darken font-weight-medium" v-if="authObject[constants.LAST_LOGIN_ERROR] !== ''">
                  {{ dayjs.unix(authObject[constants.LAST_LOGIN_ERROR]).format(constants.DATE_TIME_FORMAT_A) }}
                </span>
                <span class="cts-color-darken font-weight-medium" v-else>
                  {{$vuetify.lang.t('$vuetify.noRecord')}}
                </span>
              </h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="cts-content-section col-12 ma-0 pa-0 my-2" v-if="available_cost_centers && available_cost_centers.length > 0">
        <h4 class="mb-0 mt-2 font-weight-medium">{{$vuetify.lang.t('$vuetify.costCenters')}}</h4>
      </div>
      <v-row class="mb-6" dense v-if="available_cost_centers && available_cost_centers.length > 0">
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-row dense class="ma-0">
                <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.defaultCostCenter')}}</span>
              </v-row>
              <v-row dense class="ma-0">
                <span class="cts-form-labeled-input cts-color-darken font-weight-medium">{{default_cost_center}}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a v-on="on" v-if="available_cost_centers.length > 1 && checkPermission(const_permissions.ACCOUNT_CHANGE_COST_CENTER)"
                       @click="changeCostCenterDialog = true" class="cts-link ml-1 font-weight-medium">({{ $vuetify.lang.t('$vuetify.change') }})</a>
                  </template>
                  <span>{{ $vuetify.lang.t('$vuetify.changeDefaultCostCenter') }}</span>
                </v-tooltip>
              </v-row>

            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-row dense class="ma-0">
                <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.availableCostCenters')}}</span>
              </v-row>
              <v-row dense class="ma-0">

                  <span v-for="(acc ,index) in available_cost_centers" :key="index">
                  <span v-if="index !== 0">, </span>
                    <span class="cts-form-labeled-input cts-color-darken font-weight-medium"> {{acc}}</span>
                  </span>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="cts-content-section col-12 ma-0 pa-0 my-2">
        <h4 class="mb-0 mt-2 font-weight-medium">{{$vuetify.lang.t('$vuetify.subaccounts')}}</h4>
      </div>
      <v-row class="mb-6" dense>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.activeSubaccount')}}</span>
              <h4 class="cts-color-darken font-weight-medium">{{authObject.active_subaccount.sub_account_name}}</h4>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xl="3" lg="4" md="4" sm="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.subaccountsAssigned')}}</span>
              <h4 class="cts-color-darken font-weight-medium" v-for="(section ,index) in authObject.group.group_subaccounts"
                  :key="index">{{section.sub_account_name}}</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="cts-content-section col-12 ma-0 pa-0 my-2">
        <h4 class="mb-0 mt-2 font-weight-medium">{{$vuetify.lang.t('$vuetify.languageAndTimezone')}}</h4>
      </div>
      <v-row class="mb-6" dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <v-row class="ma-0 py-0">
            <v-col cols="12" md="3" xl="2" class="ma-0 py-0 pl-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.defaultLanguage')}}</span>
              <v-select
                id="userdata-select-language"
                single-line
                outlined
                dense
                class="ma-0 cts-select-box cts-box-filter-tooltip"
                :placeholder="$vuetify.lang.t('$vuetify.defaultLanguage')"
                :menu-props="selectsProps"
                :items="filteredLocations"
                item-text="name"
                item-value="iso"
                @change="checkSavePending"
                v-model="defaultLanguage"
              >
                <template v-slot:selection="{ item }">
                  <v-tooltip v-if="item.length > 35" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <span :id="`processes-input-select_categories_${item}`" v-else>{{ item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <!-- <v-col cols="12" md="5" xl="4" class="ma-0 py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.defaultTimeZone')}}</span>
              <time-zone-selector @timezone_changed="timezoneChanged"></time-zone-selector>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="changeCostCenterDialog" v-if="checkPermission(const_permissions.ACCOUNT_CHANGE_COST_CENTER)" max-width="600px" persistent>
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title icon="point_of_sale" :text="$vuetify.lang.t('$vuetify.selectedCostCenter')"/>
        </v-card-title>
        <v-card-text class="">
          <v-row dense class="ma-0 pt-4">
            <p class="cts-font-size-4 text-justify">{{$vuetify.lang.t('$vuetify.costCenterDialogTextAccount')}}</p>
          </v-row>
          <v-row dense class="ma-0">
            <v-col cols="8" lg="8" class="mt-auto pa-0">
              <v-row dense class="ma-0 ">
                <span class="cts-font-size-4">{{$vuetify.lang.t('$vuetify.userCECOCodeTitle')}}</span>
              </v-row>
              <v-autocomplete
                      :value="default_cost_center"
                      :items="available_cost_centers"
                      item-text="code"
                      item-value="code"
                      @change="new_cost_center = $event"
                      required
                      dense
                      outlined
                      hide-details
                      single-line
                      :placeholder="$vuetify.lang.t('$vuetify.selectCostCenter')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" lg="4" class="mt-auto pb-0">
              <v-btn
                id="section-user-detail-change_cost_center_btn"
                class="cts-button-field cts-button-standard-block"
                block
                small
                @click="changeDefaultCostCenter()"
                dark
              >
                <v-icon dense class="float-right">check</v-icon>
                <span>{{$vuetify.lang.t('$vuetify.accept')}}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

  import const_icons from '@/constants/icons'
  import constants from '@/constants/global'
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
  import { NAMESPACE as LOCATION_NAMESPACE } from "@/constants/vuex/location"
  import const_permissions from "@/constants/permissions";
  import DialogTitle from "../../structures/dialogTitle";
  import { changeDefaultCostCenterService } from "@/services/userServices"
  import { checkPermission } from "@/util/store_utils"
  import { showToast } from "@/util/utils"
  import locations from "@/constants/locations"
  import { mapGetters } from "vuex"
  import { NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS } from "@/constants/vuex/global"

  export default {
    name: "sectionUserDetail",
    components: {
      DialogTitle
    },
    props: {
      text: Object,
      authObject: Object
    },

    data() {
      return {
        constants,
        const_icons,
        checkPermission,
        const_permissions,
        default_cost_center: null,
        new_cost_center: null,
        available_cost_centers: null,
        changeCostCenterDialog: false,
        locations,
        selectsProps: {
          "closeOnClick": false,
          "closeOnContentClick": true,
          "openOnClick": false
        },
        defaultLanguage: this.$store.state[LOCATION_NAMESPACE].currentLocation.iso,
        new_timezone: null
      }

    },

    computed: {
    ...mapGetters({
      subdomainConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
    }),

    filteredLocations() {
      if (Array.isArray(this.subdomainConfig.available_languages)) {
        return this.locations.filter(x => this.subdomainConfig.available_languages.includes(x.code))
      }

      return []
    }
  },

    methods: {
      changeDefaultCostCenter(){
        if(!this.new_cost_center) {
          this.new_cost_center = this.default_cost_center
        }
        changeDefaultCostCenterService(this.new_cost_center).then((response) => {
          if (response.data.error !== 0) {
            showToast(true, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
          } else {
            this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_AVAILABLE_COST_CENTERS}`, response.data.extra.cost_centers)
            showToast(false ,this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
          }
        })

        this.default_cost_center = this.new_cost_center
        this.changeCostCenterDialog = false
      },
      timezoneChanged(newTimezone){
        this.new_timezone = newTimezone
        this.checkSavePending()
      },

      checkSavePending() {
        this.$emit("userDetailsChanged", {
          section: "userDetails",
          data: {
            lang: this.locations.find(l => l.iso === this.defaultLanguage),
            timezone: this.new_timezone
          }
        })

      }
    },
    created() {
      this.available_cost_centers = []
      let self = this
      let available_cost_centers = this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS]
          available_cost_centers.forEach(cc => {

        if( cc.is_default ) {
          this.default_cost_center = cc.code
        }
        self.available_cost_centers.push(cc.code)
      })
      // ServerBus.$emit("activateSaveButton", 'userDetails', false)
    }
  }
</script>
