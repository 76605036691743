import { showToast } from "@/util/utils"
import const_global from '@/constants/global'
import { ACTIONS, GETTERS, MUTATIONS, STATE } from '@/constants/vuex/authentication'
import { ACTIONS as PROCESSES_ACTIONS, NAMESPACE as PROCESSES_NAMESPACE } from '@/constants/vuex/processes'
import { ACTIONS as TRANSACTIONS_ACTIONS, NAMESPACE as TRANSACTIONS_NAMESPACE } from '@/constants/vuex/transactions'
import Vue from 'vue'

let expirationInterval = null

const state = {

  [STATE.S_USER_LOGGED]: false,
  [STATE.S_USERNAME]: '',
  [STATE.S_USER_ID]: null,
  [STATE.S_NAME]: '',
  [STATE.S_PERMISSIONS]: [],
  [STATE.S_AVAILABLE_COST_CENTERS]: [],

  [STATE.S_MASTER_ACCOUNT_CODE]: '',
  [STATE.S_MASTER_ACCOUNT_ADMIN]: false,

  [STATE.S_GROUP_ID]: '',
  [STATE.S_GROUP_NAME]: '',
  [STATE.S_USER_TIMEZONE]: null,

  [STATE.S_AVAILABLE_SUBACCOUNTS]: [],
  [STATE.S_SUBACCOUNT_CODE]: '',
  [STATE.S_SUBACCOUNT_NAME]: '',

  [STATE.S_LANGUAGE]: null,
  [STATE.S_LANGUAGE_CHECK_PENDING]: true,

  [STATE.S_REMEMBER_SEND]: false,
  [STATE.S_CHECKING_LOGIN]: false,

  [STATE.S_TOKEN_CODE]: null,
  [STATE.S_TOKEN_EXPIRATION]: 0,
  [STATE.S_TOKEN_EXPIRATION_TIMESTAMP]: null,

  [STATE.S_EXPIRED_PASSWORD]: false,
  [STATE.S_LAST_SESSION_REFRESH]: null,

  [STATE.S_USER_GUIDE]: null,

  [STATE.S_LAST_LOGIN_SUCCESS_TIMESTAMP]: null,
  [STATE.S_LAST_LOGIN_ERROR_TIMESTAMP]: null,

  [STATE.S_LOGIN_SESSION_POPUP]: false,

}

const mutations = {
  [MUTATIONS.M_USER_LOGGED](state, payload) {
    state[STATE.S_USER_LOGGED] = payload
  },

  [MUTATIONS.M_USERNAME](state, payload) {
    state[STATE.S_USERNAME] = payload
  },
  [MUTATIONS.M_USER_ID](state, payload) {
    state[STATE.S_USER_ID] = payload
  },
  [MUTATIONS.M_NAME](state, payload) {
    state[STATE.S_NAME] = payload
  },
  [MUTATIONS.M_PERMISSIONS](state, payload) {
    state[STATE.S_PERMISSIONS] = payload
  },
  [MUTATIONS.M_MASTER_ACCOUNT_CODE](state, payload) {
    state[STATE.S_MASTER_ACCOUNT_CODE] = payload
  },
  [MUTATIONS.M_MASTER_ACCOUNT_ADMIN](state, payload) {
    state[STATE.S_MASTER_ACCOUNT_ADMIN] = payload
  },
  [MUTATIONS.M_GROUP_ID](state, payload) {
    state[STATE.S_GROUP_ID] = payload
  },
  [MUTATIONS.M_GROUP_NAME](state, payload) {
    state[STATE.S_GROUP_NAME] = payload
  },
  [MUTATIONS.M_SET_USER_TIMEZONE](state, payload) {
    state[STATE.S_USER_TIMEZONE] = payload
  },
  [MUTATIONS.M_AVAILABLE_SUBACCOUNTS](state, payload) {
    state[STATE.S_AVAILABLE_SUBACCOUNTS] = payload
  },
  [MUTATIONS.M_SUBACCOUNT_CODE](state, payload) {
    state[STATE.S_SUBACCOUNT_CODE] = payload
  },
  [MUTATIONS.M_SUBACCOUNT_NAME](state, payload) {
    state[STATE.S_SUBACCOUNT_NAME] = payload
  },
  [MUTATIONS.M_LANGUAGE](state, payload) {
    state[STATE.S_LANGUAGE] = payload
  },
  [MUTATIONS.M_AVAILABLE_COST_CENTERS](state, payload) {
    state[STATE.S_AVAILABLE_COST_CENTERS] = payload
  },
  [MUTATIONS.M_REMEMBER_SEND](state, payload) {
    state[STATE.S_REMEMBER_SEND] = payload
  },
  [MUTATIONS.M_CHECKING_LOGIN](state, payload) {
    state[STATE.S_CHECKING_LOGIN] = payload
  },
  [MUTATIONS.M_SET_TOKEN_CODE](state, payload) {
    state[STATE.S_TOKEN_CODE] = payload
  },
  [MUTATIONS.M_SET_TOKEN_EXPIRATION](state, minutes) {
    state[STATE.S_TOKEN_EXPIRATION] = minutes
  },

  [MUTATIONS.M_SET_TOKEN_EXPIRATION_TIMESTAMP](state, value) {
    state[STATE.S_TOKEN_EXPIRATION_TIMESTAMP] = value
  },

  [MUTATIONS.M_SET_EXPIRED_PASSWORD](state, data) {
    state[STATE.S_EXPIRED_PASSWORD] = data
  },

  [MUTATIONS.M_LANGUAGE_CHECK_PENDING](state, payload) {
    state[STATE.S_LANGUAGE_CHECK_PENDING] = payload
  },
  [MUTATIONS.M_LAST_LOGIN_SUCCESS_TIMESTAMP](state, payload) {
    state[STATE.S_LAST_LOGIN_SUCCESS_TIMESTAMP] = payload
  },
  [MUTATIONS.M_LAST_LOGIN_ERROR_TIMESTAMP](state, payload) {
    state[STATE.S_LAST_LOGIN_ERROR_TIMESTAMP] = payload
  },
  [MUTATIONS.M_LOGIN_SESSION_POPUP](state, payload) {
    state[STATE.S_LOGIN_SESSION_POPUP] = payload
  },
}

const actions = {
  [ACTIONS.A_SET_LOGOUT_DATA]({ dispatch }, disable_alert) {
    dispatch(ACTIONS.A_CLEAR_SESSION_DATA)

    if (!disable_alert) {
      showToast(false, Vue.prototype.vuetify.framework.lang.t('$vuetify.sessionClosed'))
    }
  },
  [ACTIONS.A_CLEAR_SESSION_DATA]({ commit, dispatch }) {
    commit(MUTATIONS.M_USER_LOGGED, false)
    commit(MUTATIONS.M_USERNAME, '')
    commit(MUTATIONS.M_USER_ID, null)
    commit(MUTATIONS.M_NAME, '')

    commit(MUTATIONS.M_MASTER_ACCOUNT_CODE, '')
    commit(MUTATIONS.M_MASTER_ACCOUNT_ADMIN, false)
    commit(MUTATIONS.M_SET_USER_TIMEZONE, null)
    commit(MUTATIONS.M_GROUP_NAME, '')
    commit(MUTATIONS.M_GROUP_ID, '')
    commit(MUTATIONS.M_PERMISSIONS, [])
    commit(MUTATIONS.M_AVAILABLE_SUBACCOUNTS, [])
    commit(MUTATIONS.M_AVAILABLE_COST_CENTERS, [])

    commit(MUTATIONS.M_LAST_LOGIN_SUCCESS_TIMESTAMP, null)
    commit(MUTATIONS.M_LAST_LOGIN_ERROR_TIMESTAMP, null)

    commit(MUTATIONS.M_SUBACCOUNT_CODE, '')
    commit(MUTATIONS.M_SUBACCOUNT_NAME, '')
    window.sessionStorage.clear()
    window.localStorage.clear()

    dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_RESET_STATE}`, null, { root: true })
    dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_RESET_STATE}`, null, { root: true })
    dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_ACTIVE_FILTERS}`, null, { root: true })
  },
  [ACTIONS.A_SET_LOGIN_DATA]({ commit }, login_data) {
    commit(MUTATIONS.M_USER_LOGGED, true)
    commit(MUTATIONS.M_USERNAME, login_data[const_global.USERNAME])
    commit(MUTATIONS.M_USER_ID, login_data[const_global.ID])
    commit(MUTATIONS.M_NAME, login_data[const_global.NAME])
    commit(MUTATIONS.M_LANGUAGE, login_data[const_global.LANGUAGE])
    commit(MUTATIONS.M_LANGUAGE_CHECK_PENDING, true)
    commit(MUTATIONS.M_AVAILABLE_COST_CENTERS, login_data[const_global.COST_CENTERS])
    commit(MUTATIONS.M_SET_USER_TIMEZONE, login_data[const_global.USER_TIMEZONE])
    commit(MUTATIONS.M_LAST_LOGIN_SUCCESS_TIMESTAMP, login_data[const_global.LAST_LOGIN_SUCCESS])
    commit(MUTATIONS.M_LAST_LOGIN_ERROR_TIMESTAMP, login_data[const_global.LAST_LOGIN_ERROR])
    commit(MUTATIONS.M_LOGIN_SESSION_POPUP, true)

    let group = login_data[const_global.GROUP]
    commit(MUTATIONS.M_MASTER_ACCOUNT_CODE, group[const_global.GROUP_MASTER_ACCOUNT_CODE])

    //TODO: Cambiarlo en backoffice
    let admin = false
    if (group[const_global.GROUP_MASTER_ACCOUNT_ADMIN]) {
      admin = true
    }
    commit(MUTATIONS.M_MASTER_ACCOUNT_ADMIN, admin)
    commit(MUTATIONS.M_GROUP_NAME, group[const_global.NAME])
    commit(MUTATIONS.M_GROUP_ID, group[const_global.ID])
    commit(MUTATIONS.M_PERMISSIONS, group[const_global.PERMISSIONS])
    commit(MUTATIONS.M_AVAILABLE_SUBACCOUNTS, group[const_global.SUBACCOUNTS])

    commit(MUTATIONS.M_SET_TOKEN_CODE, login_data[const_global.TOKEN][const_global.CODE])
    commit(MUTATIONS.M_SET_TOKEN_EXPIRATION, parseInt(login_data[const_global.TOKEN][const_global.EXPIRATION]) * 60)

    if (group[const_global.SUBACCOUNTS].length !== 0) {
      commit(MUTATIONS.M_SUBACCOUNT_CODE, group[const_global.SUBACCOUNTS][0][const_global.SUB_ACCOUNT_CODE])
      commit(MUTATIONS.M_SUBACCOUNT_NAME, group[const_global.SUBACCOUNTS][0][const_global.SUB_ACCOUNT_NAME])
    }
  },
  [ACTIONS.A_CHANGE_SUBACCOUNT]({ commit, dispatch }, subaccount) {
    commit(MUTATIONS.M_SUBACCOUNT_NAME, subaccount[const_global.SUB_ACCOUNT_NAME])
    commit(MUTATIONS.M_SUBACCOUNT_CODE, subaccount[const_global.SUB_ACCOUNT_CODE])
    dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_AVAILABLE_TRANSACTIONS}`, [], { root: true })
  },

  [ACTIONS.A_REMEMBER_SEND]({ commit }, value) {
    commit(MUTATIONS.M_REMEMBER_SEND, value)
  },
  [ACTIONS.A_CHECKING_LOGIN]({ commit }, value) {
    commit(MUTATIONS.M_CHECKING_LOGIN, value)
  },

  [ACTIONS.A_SET_AVAILABLE_COST_CENTERS]({ commit }, value) {
    commit(MUTATIONS.M_AVAILABLE_COST_CENTERS, value)
  },

  [ACTIONS.A_SET_EXPIRED_PASSWORD]({ commit }, value) {
    commit(MUTATIONS.M_SET_EXPIRED_PASSWORD, value)
  },

  [ACTIONS.A_SET_LANGUAGE_CHECK_PENDING]({ commit }, value) {
    commit(MUTATIONS.M_LANGUAGE_CHECK_PENDING, value)
  },

  [ACTIONS.A_SET_LANGUAGE]({ commit }, value) {
    commit(MUTATIONS.M_LANGUAGE, value)
  },

  [ACTIONS.A_SET_LOGIN_SESSION_POPUP]({ commit }, value) {
    commit(MUTATIONS.M_LOGIN_SESSION_POPUP, value)
  },

  [ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP]({ commit }, value) {
    commit(MUTATIONS.M_SET_TOKEN_EXPIRATION_TIMESTAMP, value)
  },
}

const getters = {
  [GETTERS.G_LOGIN_DATA](state) {
    return {
      [const_global.USER_LOGGED]: state[STATE.S_USER_LOGGED],
      [const_global.USERNAME]: state[STATE.S_USERNAME],
      [const_global.LANGUAGE]: state[STATE.S_LANGUAGE],
      [const_global.NAME]: state[STATE.S_NAME],
      [const_global.ID]: state[STATE.S_USER_ID],
      [const_global.USER_TIMEZONE]: state[STATE.S_USER_TIMEZONE],
      [const_global.LAST_LOGIN_SUCCESS]: state[STATE.S_LAST_LOGIN_SUCCESS_TIMESTAMP],
      [const_global.LAST_LOGIN_ERROR]: state[STATE.S_LAST_LOGIN_ERROR_TIMESTAMP],
      [const_global.ACTIVE_SUBACCOUNT]: {
        [const_global.SUB_ACCOUNT_CODE]: state[STATE.S_SUBACCOUNT_CODE],
        [const_global.SUB_ACCOUNT_NAME]: state[STATE.S_SUBACCOUNT_NAME]
      },
      [const_global.GROUP]: {
        [const_global.GROUP_MASTER_ACCOUNT_ADMIN]: state[STATE.S_MASTER_ACCOUNT_ADMIN],
        [const_global.GROUP_MASTER_ACCOUNT_CODE]: state[STATE.S_MASTER_ACCOUNT_CODE],
        [const_global.GROUP_ID]: state[STATE.S_GROUP_ID],
        [const_global.GROUP_NAME]: state[STATE.S_GROUP_NAME],
        [const_global.PERMISSIONS]: state[STATE.S_PERMISSIONS],
        [const_global.GROUP_SUBACCOUNTS]: state[STATE.S_AVAILABLE_SUBACCOUNTS]
      }

    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  expirationInterval
}
