<template>
  <v-expand-transition>
    <v-row dense class="ma-0 pa-0" v-if="!disabled || permission.id == 20" >
      <v-col cols="11" xl="6" lg="6" md="8" sm="11" :class="'ma-0 px-2 py-0 ' + children_class" >
        <h5 class="ma-0 py-0">{{permission.description}}</h5>
      </v-col>
      <v-col cols="1" xl="1" lg="1" md="1" sm="1" class="ma-0 pa-0">
        <v-sheet class="pa-0 ma-0">
          <v-checkbox :readonly="!checkPermission(const_permissions.GROUP_EDIT_PERMISSIONS)" :disabled="disabled" class="cts-tabs-checkboxes pa-0 ma-0" v-model="assigned" @change="sendChange()" :aria-label="permission.description"
                      color="var(--primary)"></v-checkbox>
        </v-sheet>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script>
  import {ServerBus} from "../../main";
  import const_global from "@/constants/global"
  import { checkPermission } from "@/util/store_utils"
  import const_permissions from "@/constants/permissions"

  export default {
    name: "listWithCheck",
    props: {
      permission: Object,
      children_class: String
    },
    data(){
      return{
        checkPermission,
        const_permissions,
        disabled: false,
        assigned: false
      }
    },
    methods:{
      sendChange(){
        this.$emit("change")
        ServerBus.$emit(const_global.SERVER_BUS_EVENT_PERMISSION, {
          id: this.permission.id,
          assigned: this.assigned
        })
      }
    },
    created() {
      if( this.permission.id == 20 ) {
        this.disabled = true
      } else {
        this.disabled = this.permission.disabled
      }
      this.assigned = this.permission.assigned

      ServerBus.$on(const_global.SERVER_BUS_EVENT_PERMISSION_BACK, (perm) => {
        if (this.permission.id == perm.id) {
          this.assigned = perm.assigned
          this.disabled = perm.disabled
          this.sendChange()
        }
      })
    },
    beforeDestroy() {
      ServerBus.$off(const_global.SERVER_BUS_EVENT_PERMISSION_BACK)
    }
  }
</script>

<style scoped>

</style>
