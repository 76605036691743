<template>
  <vChart v-if="chartData['days']" class="cts-bar-chart"  ref="chart" :options="option" :autoresize="true"></vChart>
</template>

<script>
/* eslint-disable */
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legend'
  import 'echarts/lib/chart/bar'
  import vChart from 'vue-echarts'
  import dayjs from "dayjs";
  import {mapGetters} from "vuex";
import { NAMESPACE as LOCATION_NAMESPACE, GETTERS as LOCATION_GETTERS } from "@/constants/vuex/location"

  export default {
    name: "barChart",
    components: {vChart},
    props: {
      dates: Array,
      colors: Array,
      chartData: Object
    },

    computed: {
      ...mapGetters({
        currentLocation: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
      }),
    },

    data() {
      return {
        emphasisStyle: {
          itemStyle: {
            barBorderWidth: 1,
            shadowBlur: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(0,0,0,0.5)'
          }
        },
        option: {
          height:250,
          textStyle: {
            color: "#000000",
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 11,
          },
          color: this.colors,
          backgroundColor: 'transparent',
          animation: true,
          animationDuration: 500,
          legend: {
            show: false,
            type: 'plain',
            id: 'chartLegend',
            orient: "vertical",
            top: 0,
            right: 0,
            padding: 5,
            itemGap: 10,
            itemHeight: 4,
            selected: {
              selectedProcess: true
            },
            selectorLabel: {
              borderRadius: 0
            }
          },
          tooltip: {},
          xAxis: {
            data: [],
            axisLine: {onZero: true},
            splitLine: {show: false},
            splitArea: {show: false}
          },
          yAxis: {
            max: function (value) {
              return Math.ceil(value.max/5)*5+5;
            },
            inverse: false,
            splitArea: {show: false},
            splitLine: {show: false},
          },
          grid: {
            show: true,
            containLabel: true,
            backgroundColor: '#ffffff'
          },
          series: []
        },
      }
    },
    methods: {
      fillStatistics(result) {
        this.option.xAxis.data = []
        this.option.series.length = 0

        let arr_dates = []

        if (this.dates.length === 2) {
          const [a_date, b_date] = this.dates.map(x => dayjs(x).locale(this.currentLocation.code))

          let first_date = b_date.isAfter(a_date) ? a_date : b_date
          const second_date = b_date.isAfter(a_date) ? b_date : a_date

          while (first_date.isSameOrBefore(second_date)) {
            let day = first_date.format('D MMM')
            let day_to_find = first_date.format('YYYYMMDD')

            if (result.days && result.days[day_to_find]) {
              arr_dates[day] = result.days[day_to_find]
            } else {
              arr_dates[day] = {
                processes: {},
                total_count: 0
              }
            }
            first_date = first_date.add(1, 'd')
          }

        } else {
          arr_dates = result.days
        }
        Object.entries(arr_dates).forEach(([day_name, day_value]) => {
          this.option.xAxis.data.push(day_name)

          Object.entries(day_value.processes).forEach(([process_name, process_value]) => {

            if (!this.option.series.find(ds => ds.name === process_value.name)) {
              let days_values = []
              Object.values(arr_dates).forEach((day_value) => {
                if (day_value.processes[process_name]) {
                  days_values.push(day_value.processes[process_name].total_count)
                } else {
                  days_values.push(0)
                }
              })


              let node_data = {
                name: process_value.name,
                type: 'bar',
                barWidth: '40%',
                stack: 'one',
                emphasis: this.emphasisStyle,
                data: days_values
              }
              this.option.series.push(node_data)
            }
          })
        })
      }
    },

    created() {
      this.fillStatistics(this.chartData)
    }
  }
</script>

