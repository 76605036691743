export const NAMESPACE = "vuex_transactions"

export const STATE = {
  S_AVAILABLE_TRANSACTIONS: "state_available_transactions",
  S_TRANSACTION: "state_transaction",
  S_TRANSACTION_SELECTED: "state_transaction_selected",
  S_PROCESSES_FILTER: "state_processes_filter",
  S_LINKED_GROUPS_FILTER: "state_available_groups_filter",
  S_ACTIVE_FILTERS: "state_active_filters"
}

export const MUTATIONS = {
  M_SET_AVAILABLE_TRANSACTIONS: "mutation_available_transactions",
  M_SET_TRANSACTION: "mutation_set_transaction",
  M_SET_TRANSACTION_SELECTED: "mutation_set_transaction_selected",
  M_SET_PROCESSES_FILTER: "mutation_set_processes_filter",
  M_SET_LINKED_GROUPS_FILTER: "mutation_set_available_groups_filter",
  M_SET_ACTIVE_FILTERS: "mutation_set_active_filters"
}

export const ACTIONS = {
  A_SET_AVAILABLE_TRANSACTIONS: "action_set_available_transactions",
  A_SET_TRANSACTION: "action_set_transaction",
  A_SET_TRANSACTION_SELECTED: "action_set_transaction_selected",
  A_RESET_STATE: "action_reset_status",
  A_SET_FILTERS: "action_set_filters",
  A_SET_ACTIVE_FILTERS: "action_set_active_filters"
}

export const GETTERS = {
  G_AVAILABLE_TRANSACTIONS: "get_available_transactions",
  G_TRANSACTION: "get_transaction"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}