import {STATE, MUTATIONS, ACTIONS } from "@/constants/vuex/userAccount"

const state = {
  [STATE.S_CECO_DETAILS_DIALOG]: false
}

const mutations = {
  [MUTATIONS.M_SET_CECO_DETAILS_DIALOG](state, users) {
    state[STATE.S_CECO_DETAILS_DIALOG] = users
  }
}

const actions = {
  [ACTIONS.A_SET_CECO_DETAILS_DIALOG]({ commit }, value) {
    commit(MUTATIONS.M_SET_CECO_DETAILS_DIALOG, value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
