<template>
  <v-row dense class="ma-0 justify-center mt-6">
    <v-col cols="12" class="text-center pt-6">
      <h1>{{$vuetify.lang.t('$vuetify.loading')}}</h1>
      <v-progress-circular
              indeterminate
              color="var(--primary-alternative)"
              class="mt-4"
      ></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "loadingContainer"
  }
</script>

<style scoped>

</style>
