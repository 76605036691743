// Requests under /group

import Axios from "@/plugins/axios"
import store from "@/store"
import { GROUP_ROUTES }  from "@/constants/backOfficeRoutes"
import { serialize } from '@/plugins/objectToFormdata'
import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE } from "@/constants/vuex/administration"
import const_global from "@/constants/global"
import { filterPermissions, filterProcesses, filterSubaccounts } from "@/util/utils"

export const getAvailableProcesses = ()  => {
  let group_id = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID]
  let subaccount_id = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE]

  return Axios.get(GROUP_ROUTES.ROUTE_PROCESSES.replace("$group_id$", group_id).replace("$subaccount_id$", subaccount_id))
}

export const getGroups = () => {
  let master_account_code = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE]
  return Axios.get(GROUP_ROUTES.ROUTE_GROUPS.replace("$master_account_code$", master_account_code))
}

export const createGroup = group_info => {
  let groupData = {};
  groupData = serialize(group_info)
  return Axios.post(GROUP_ROUTES.ROUTE_CREATE_GROUP, groupData)
}

export const getGroupDetails = () => {
  let group_id = store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_GROUP_CODE_SELECTED]

  return Axios.get(GROUP_ROUTES.ROUTE_GET_GROUP_DETAILS.replace("$group_id$", group_id))
}

export const saveGroupDetails = group => {
  let group_to_send = {}
  Object.assign(group_to_send, group)

  group_to_send.processes = []

  group_to_send.permissions = filterPermissions(group_to_send.permissions)
  group_to_send.processes = filterProcesses(group_to_send.subaccounts)
  group_to_send.subaccounts = filterSubaccounts(group_to_send.subaccounts)

  const groupData = serialize(group_to_send)

  return Axios.post(GROUP_ROUTES.ROUTE_SAVE_GROUP.replace("$group_id$", group[const_global.GROUP_ID]), groupData)
}
