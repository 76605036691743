import {
  ACTIONS as PROCESSES_ACTIONS,
  GETTERS as PROCESSES_GETTERS,
  NAMESPACE as PROCESSES_NAMESPACE,
  STATE as PROCESSES_STATE
} from '@/constants/vuex/processes'
import const_global from "@/constants/global"
import fileValidations from "@/util/validations/fileValidations"

export default {
    props: {
        mapping: Object,
        processFields: Object,
        extra: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            step: "fill_fields",
            requiredProps: [],
            dataIsValid: false
        }
    },

    computed: {
        processData() {
            return this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_CURRENT_TRANSACTION_DATA}`]
        },

        recipientsAreValid() {
            return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CURRENT_RECIPIENTS_VALID]
        },

        allValid() {
            return this.dataIsValid && this.recipientsAreValid
        },

        currentTransactionRecipients() {
            const recipients = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_CURRENT_TRANSACTION_RECIPIENTS}`]
            if (!recipients) {
                return [{
                    [const_global.CONTACT_ID]: null,
                    [const_global.CONTACT_NAME]: null,
                    [const_global.CONTACT_SURNAME]: null,
                    [const_global.CONTACT_EMAIL]: null,
                    [const_global.CONTACT_COUNTRY_CODE]: null,
                    [const_global.CONTACT_PHONE]: null,
                }]
            }
            return recipients
        },

        emailRequired() {
            return this.requiredProps.findIndex(rp => rp === const_global.FIELD_EMAIL) !== -1
        },

        phoneRequired() {
            return this.requiredProps.findIndex(rp => rp === const_global.FIELD_MOBILE_PHONE) !== -1
        },

        processName() {
            return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_NAME]
        },
        processCode() {
            return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CODE]
        },

        processContactFieldsSuggested() {
            return [
                const_global.CONTACT_NAME,
                const_global.CONTACT_SURNAME,
                const_global.CONTACT_ID
            ]
        },
    },

    created() {
        this.requiredProps = Object.keys(this.processFields).filter(x => ('allow_empty' in this.processFields[x]['validations']))

        if (!this.processData) {
            const data = {}
            for (const key in this.processFields) {
                let fieldValue = null
                if (this.processFields[key]["type"] === "array_file") {
                    fieldValue = []
                } else if (this.processFields[key]["type"] === "list") {
                    fieldValue = this.processFields[key]["validations"]["allowed_values"][0]
                }

                data[key] = key === "id" ? "%%contact_id%%" : fieldValue
            }

            data['name'] = '%%contact_name%%'
            data['surname'] = '%%contact_surname%%'

            if (this.emailRequired) {
                data['email_address'] = '%%contact_email%%'
            }

            if (this.phoneRequired) {
                data['mobile_phone'] = '%%contact_phone%%'
                data['country_code'] = '%%contact_country_code%%'
            }

            this.setCurrentTransactionData(data)
            this.dataIsValid = this.validateAllFields()
        }
    },

    methods: {
        setCurrentTransactionData(value) {
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_TRANSACTION_DATA}`, value)
        },

        mergedPayload() {
            const data = Object.assign({}, this.processData)

            data["name"] = "%%contact_name%%"
            data["surname"] = "%%contact_surname%%"

            if (this.emailRequired) {
                data["email_address"] = "%%contact_email%%"
            }

            if (this.phoneRequired) {
                data["mobile_phone"] = "%%contact_phone%%"
                data["country_code"] = "%%contact_country_code%%"
            }

            if (Object.keys(this.processData).includes("id")) {
                data["id"] = "%%contact_id%%"
            }

            return data
        },

        validateAllFields() {
            const payload = this.mergedPayload()
            const results = []
            for (const key in this.processFields) {
                results.push(this.validateField(this.processFields[key], payload[key]))
            }

            return results.every(Boolean)
        },

        validateField(fieldDescription, fieldValue) {
            const validations = fieldDescription.validations

            if (!validations.allow_empty && !fieldValue) {
                return false
            }

            const extensions = validations.allowed_extensions && validations.allowed_extensions.length > 0 ? validations.allowed_extensions : ["pdf", "html"]
            const array_file_validation = (fileArray, validations) => {
                if (fileArray.some(x => !(x instanceof File))) {
                    return false
                }

                const notEmpty = validations.allow_empty || (!validations.allow_empty && fileArray.length > 0)
                const extensionValidation = fileArray.map(file => fileValidations.fileExtension(file, extensions))

                return notEmpty && extensionValidation.every(Boolean)
            }

            switch (fieldDescription.type) {
                case "array_file": {
                    return array_file_validation(fieldValue, validations)
                }

                case "file": {
                    if (fieldDescription.field_configuration?.allow_multiple_selection) {
                        return array_file_validation(fieldValue, validations)
                    }

                    const extensionValidation = fileValidations.fileExtension(fieldValue, extensions)

                    if (validations.allow_empty) {
                        if (fieldValue) {
                            return extensionValidation
                        }
                        return true

                    } else {
                        return extensionValidation
                    }

                }

                default: {
                    if (fieldValue) {
                        return !validations.max_length || fieldValue.length <= validations.max_length
                    }

                    return validations.allow_empty
                }

            }
        }
    },

    watch: {
        processData: {
            handler() {
                this.setCurrentTransactionData(this.processData)
                this.dataIsValid = this.validateAllFields()
            },

            deep: true
        }
    }
}