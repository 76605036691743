export default {
  badge: 'Plaque',
  close: 'Fermer',
  dataIterator: {
    noResultsText: 'Aucun élément ne coïncide avec la recherche',
    loadingText: 'Chargement en cours...'
  },
  dataTable: {
    itemsPerPageText: 'Rangées par page :',
    ariaLabel: {
      sortDescending: ' : Classement descendant. Cliquez pour quitter le classement.',
      sortAscending: ' : Classement ascendant. Cliquez pour classement descendant.',
      sortNone: ' : Non classé. Cliquez pour classement ascendant.',
      activateAscending: 'Activer ascendant',
      activateDescending: 'Activer descendant',
      activateNone: 'Désactiver tout'
    },
    sortBy: 'Classé par'
  },
  dataFooter: {
    itemsPerPageText: 'Éléments par page :',
    itemsPerPageAll: 'Tous',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: '{0}-{1} sur {2}'
  },
  datePicker: {
    itemsSelected: '{0} sélectionnés',
    prevMonthAriaLabel: 'mois précédent',
    nextMonthAriaLabel: 'mois suivant'
  },
  noDataText: 'Pas de données disponibles',
  carousel: {
    prev: 'Visuel précédent',
    next: 'Visuel suivant',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} de plus'
  },
  fileInput: {
    counter: '{0} fichiers',
    counterSize: '{0} fichiers ({1} au total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Accueil',
  templates: 'Flux',
  accounts: 'Comptes',
  processes: 'Procédures',
  transactions: 'Transactions',
  statistics: 'Statistiques',
  support: 'Support',
  contacts: 'Contacts',
  administration: 'Administration',
  search: 'Recherche',
  appearance: 'Styles',
  users: 'Identifiants',
  alastria: 'Alastria',
  billing: 'Facturation',
  account: 'Gérer mon compte',
  admin: 'Administration',
  demos: 'Démos',
  selectedSubaccount: 'Sous-compte sélectionné',
  withoutSubaccount: 'Sans sous-compte',
  groups: 'groupes',
  filterDate: 'Filtre de date',
  subaccount: 'Sous-compte',
  subaccounts: 'Sous-comptes',
  costCenters: 'Centres de coût',
  subaccountsAssigned: 'Sous-comptes assignés',
  defaultCostCenter: 'Centre de coût par défaut',
  selectedCostCenter: 'Centre de coût sélectionné',
  availableCostCenters: 'Centres de coût disponibles',
  changeDefaultCostCenter: 'Changer le centre de coût par défaut',
  costCenterDialogText: 'Si vous le souhaitez, vous pouvez changer le centre de coût sélectionné pour la transaction. Cela ne modifiera pas le centre de coût par défaut, il sera uniquement utilisé ponctuellement pour cette transaction.',
  costCenterDialogTextAccount: 'Si vous le souhaitez, vous pouvez changer le centre de coût par défaut. Par cette action, le centre de coût sélectionné sera le centre de coût prédéterminé',
  costCenterSelectedTitle: 'Centre de coût sélectionné',
  activeSubaccount: 'Sous-compte actif',
  user: 'Identifiant',
  userData: 'Données de l’utilisateur',
  userCECOS: 'Centres de coût',
  userCECO: 'Centre de coût',
  userCodeCECO: 'Code',
  userCECOPrimary: 'Par défaut',
  userCECOCodeTitle: 'Code de centre de coût',
  password: 'Mot de passe',
  passwordchange: 'Changer mot de passe',
  oldpassword: 'Mot de passe actuel',
  newpassword: 'Nouveau mot de passe',
  updatePassword: 'Mettre à jour mot de passe',
  repeatpassword: 'Répéter mot de passe',
  passwordrequirements: 'Le mot de passe doit contenir au moins 8 caractères et remplir au moins 3 des règles suivantes :',
  passwordMinOneLowercase: 'Minimum 1 lettre minuscule.',
  passwordMinOneUppercase: 'Minimum 1 lettre majuscule.',
  passwordMinOneNumber: 'Minimum 1 chiffre.',
  passwordMinOneSpecialChar: 'Minimum 1 caractère spécial.',
  passwordMinEightChars: 'Minimum 8 caractères.',
  passwordCompares: 'Comparaison de mot de passe requise.',
  passwordFieldError: 'Erreur dans le champ, consulter les règles.',
  passwordrequirementsMoreThanEightChars: 'Le mot de passe doit contenir au moins 8 caractères',
  notequalpasswords: 'Les mots de passe ne coïncident pas',
  samepasswordthanolder: 'Le nouveau mot de passe est identique à l’antérieur',
  emptyOldPassword: 'Vous n’avez pas saisi le mot de passe antérieur',
  role: 'Rôle',
  lastConnection: 'Dernière connexion',
  languageSelector: 'Sélectionnez une langue',
  logout: 'Fermer session',
  others: 'Autres',
  continue: 'Continuer',
  continueWSend: 'Continuer envoi',
  sendRecoveryForm: 'Envoyer demande',
  restartFilters: 'Nettoyer filtres',
  back: 'Retour',
  backToMethods: 'Méthodes de récupération',
  moreInfo: 'Plus d’informations',
  activateuser: 'Activer identifiant',
  exit: 'Quitter',
  platformVersion: 'Version de la plateforme',
  termsAndConditionsCompliance: 'Termes et conditions',
  privacyPoliciesCompliance: 'Politique de protection des données',
  cookiesPoliciesCompliance: 'Politique de cookies',
  cookiesPoliciesCertySign: 'Cookies dans CertySign',
  alertSizeFiles: 'Tous les fichiers doivent peser au maximum 8 Mb',
  login: 'Démarrer session',
  noSubaccountsMessage: 'Vous ne disposez pas de sous-comptes assignés',
  telephone: 'Téléphone',
  name: 'Prénom',
  lastName: 'Nom',
  email: 'E-mail',
  omit: 'Omettre',
  id: 'Référence client',
  clear: 'Effacer',
  add: 'Ajouter',
  recipients: 'Destinataires',
  shipmentData: 'Données de l’envoi',
  prevAndSend: 'Affichage et envoi',
  previewFileSize: 'Le fichier doit peser au maximum 15 Mb',
  rulesFilesSize: 'Tous les fichiers doivent peser au maximum 15 Mb',
  sectionDataFieldsDescription: 'Vous disposez en bas de l’écran des différents champs nécessaires pour l’envoi de la transaction aux destinataires. Les champs de contact seront remplis automatiquement depuis les données du destinataire',
  sectionDataFieldsNotAditionalFields: 'Cette procédure ne dispose pas de champs supplémentaires, finalisez l’envoi de votre transaction depuis Affichage.',
  sectionPreviewSubtitle: 'En bas de l’écran, vous pouvez naviguer entre les destinataires pour vérifier individuellement chaque envoi',
  manual_version: 'Affichage de la dernière version du guide de l’utilisateur.',
  view_login: 'Démarrer session',
  view_transactions: 'Transactions',
  view_transaction: 'Détails de transaction',
  view_process: 'Lancer procédure',
  view_processes: 'Procédures',
  view_statistics: 'Statistiques',
  view_support: 'Support et aide',
  view_administration: 'Administration',
  view_groups: 'Groupes',
  view_group: 'Détails de groupe',
  view_users: 'Identifiants',
  view_account: 'Mon compte',
  view_remember_password: 'Rappeler mot de passe',
  view_change_password: 'Changer mot de passe',
  view_activate_user: 'Activer identifiant',
  view_new_password: 'Nouveau mot de passe',
  view_contacts: 'Contacts',
  view_user: 'Détails de l’utilisateur',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Erreur',
  searchAgain: 'Nouvelle recherche',
  recipient: 'Destinataire',
  sender: 'Expéditeur',
  namesender: 'Nom de l’expéditeur',
  subject: 'Objet',
  emailbody: 'Texte de l’e-mail',
  resourcespreview: 'Affichage des ressources',
  attach: 'Joindre',
  actions: 'Actions',
  changePassword: 'Mettre à jour mot de passe',
  sessionClosed: 'Session correctement fermée',
  noPermissionMessage: 'Vous n’avez pas les autorisations pour accéder à cette section',
  download: 'Télécharger',
  viewDocument: 'Voir Document',
  accept: 'Accepter',
  reject: 'Refuser',
  change: 'Changer',
  addVariables: 'Ajouter variable',
  resources: 'Ressources',
  launchDataKo: 'Les données fournies ne sont pas valides pour effectuer l’envoi',
  launchDataOk: 'Les données sont correctes, vous pouvez effectuer l’envoi',
  addNewRecipient: 'Ajouter nouveau destinataire',
  transactionRecipients: 'Destinataires de la transaction',
  noRecipientsSelected: 'Aucun destinataire sélectionné',
  recipientsSelected: 'Ont été sélectionnés',
  recipientSelected: 'A été sélectionné',
  preview: 'Affichage',
  summary: 'Résumé',
  transaction_summary: 'Résumé de l’envoi',
  PDFpreview: 'Afficher PDF',
  previewing: 'Affichage de :',
  notAvailable: 'Non disponible',
  hide: 'Masquer',
  send: 'Envoyer',
  showFile: 'Voir fichier',
  error500Message: 'Une erreur est survenue. Si le problème persiste, vous pouvez contacter le service de support par le biais de',
  thisForm: 'ce formulaire',
  error401Message: 'La session a expiré',
  withoutData: 'Pas de données',
  notInformed: 'Non renseigné',
  loginTitleText: 'Démarrer session',
  changePasswordTitleText: 'Changement de mot de passe',
  activateUserTitleText: 'Activation d’identifiant',
  homeWelcomeMessageTitle: 'Bienvenue dans CertySign',
  homeWelcomeMessageSubtitle: 'Environnement de gestion de transactions et de flux d’envoi certifié',
  changePasswordUserTitleText: 'Établir nouveau mot de passe',
  activateUserSubtitleText: 'Pour activer l’identifiant, il faut obligatoirement saisir un nouveau mot de passe',
  changePasswordUserSubtitleText: 'Pour établir un nouveau mot de passe, remplissez les champs',
  activateUserTitleTextWithoutHash: 'URL non valide',
  activateUserSubtitleTextWithoutHash: 'L’URL que vous utilisez n’est pas valide. Si vous le souhaitez, vous pouvez demander une nouvelle URL en cliquant sur \u2018J’ai oublié mon mot de passe’ sur l’écran de login.',
  goToLogin: 'Démarrage de session',
  activeFilters: 'Filtres actifs',
  rememberPassword: 'Vous avez oublié votre mot de passe ?',
  rememberPasswordTitleText: 'Récupérer mot de passe',
  supportContact: 'Contact service de support',
  rememberPasswordSubTitleText: 'Sélectionnez une méthode pour récupérer votre mot de passe',
  rememberPasswordEmailResponse: 'Si l’identifiant indiqué existe, vous recevrez un e-mail avec les instructions pour changer de mot de passe',
  rememberPasswordWebResponse: 'Si les données saisies sont correctes, notre équipe de support vous contactera par e-mail et vous donnera les instructions pour changer de mot de passe',
  rememberPasswordPhoneResponse: 'Si les données saisies sont correctes, notre équipe de support vous contactera par téléphone et vous donnera les instructions pour changer de mot de passe',
  expiredPasswordResponse: 'Votre mot de passe a expiré, vous recevrez bientôt un e-mail avec les instructions pour en choisir un nouveau',
  rememberPasswordSupportEmail: 'E-mail de récupération',
  rememberPasswordSupportEmailSubtitle: 'Nous vous enverrons un e-mail qui vous permettra de récupérer votre mot de passe',
  rememberPasswordSupportEmailText: 'Veuillez remplir le formulaire avec l’adresse électronique que vous avez enregistrée dans CertySign',
  rememberPasswordSupportWeb: 'Support via web',
  rememberPasswordSupportWebSubtitle: 'Envoyer un e-mail à l’équipe de support technique de CertySign',
  rememberPasswordSupportWebText: 'Remplissez les données et l’équipe de support technique vous répondra dans les meilleurs délais.',
  emailSupportServiceHours: 'Horaires d’ouverture :',
  rememberPasswordSupportPhone: 'Support téléphonique',
  rememberPasswordSupportPhoneSubtitle: 'Appel téléphonique à l’équipe de support technique de CertySign',
  rememberPasswordSupportPhoneText: 'Remplissez les données et l’équipe de support technique vous appellera dans les meilleurs délais.',
  phoneSupportServiceHours: 'Horaires du service de support par téléphone :',
  rememberPasswordNotSupportBySchedule: 'Option désactivée car en dehors des horaires',
  rememberPasswordMondayToFriday: 'Du lundi au vendredi :',
  rememberPasswordSaturdaySundayAndOthers: 'Les samedis, dimanches et jours fériés :',
  rememberPasswordNotIncludedDays: '(hors jours fériés)',
  supportFinalSendMessage: 'Votre demande a été envoyée à notre service de support',
  goBackToMethods: 'Sélectionner une autre méthode',
  rememberPasswordContactName: 'Prénom et nom',
  goToC2C: 'Demander appel',
  messageDescription: 'Message',
  company: 'Entreprise',
  legalCheckboxText: 'J’accepte les Conditions d’utilisation de la page et la Politique de protection des données',
  selectHelps: 'Comment pouvons-nous vous aider ?',
  selectHelpsTooltip: 'Support et aide',
  helpManual: 'Voir manuel',
  helpGuide: 'Voir guide rapide',
  helpSupport: 'Contacter le service de support',
  helpSupportPhonePreffered: 'Contacter de préférence par téléphone',
  helpSupportPhoneSchedule: 'Horaires d’accueil téléphonique, les jours ouvrables',
  requiredEmail: 'E-mail requis',
  requiredPhone: 'Adresse électronique requise',
  requiredName: 'Prénom requis',
  requiredEmailFormat: 'Format d’e-mail incorrect',
  requiredPhoneFormat: 'Format de numéro de téléphone incorrect',
  requiredTextarea: 'Message requis',
  tag_signature: 'Signature électronique',
  tag_email: 'E-mail',
  tag_sms: 'SMS',
  tag_cert_email: 'Courrier électronique certifié',
  tag_landing: 'Page web',
  tag_biometric_signature: 'Signature biométrique',
  tag_postal: 'Postal',
  processesViewListTitle: 'Procédures',
  processesViewListDescription: 'Liste avec toutes les procédures disponibles dans CertySign',
  tags: 'Étiquettes',
  searchs: 'Chercher',
  processInfo: 'Informations sur la procédure',
  processesCategoriesFilter: 'Filtre par catégories',
  processesTagFilter: 'Filtre par étiquettes',
  processesTagOthers: 'autres',
  processesSearch: 'Chercher procédures',
  searchTransactions: 'Consulter transactions',
  searchTransaction: 'Consulter transaction',
  deleteFilters: 'Supprimer filtres',
  withoutResults: 'Pas de résultat',
  withoutProcessResults: 'Vous ne disposez pas de procédures pour leur lancement suivant vos autorisations ou suivant les critères de recherche établis',
  allProcesses: 'Toutes les procédures',
  emailNotValid: 'L’adresse électronique n’est pas valide',
  phoneNotValid: 'Le numéro de téléphone n’est pas valide',
  phoneAtLeastNineChars: 'Le numéro de téléphone doit contenir au moins 9 chiffres.',
  doesntExist: 'N’existe pas',
  dontExist: 'N’existent pas',
  requiredField: 'Champ obligatoire',
  errorField: 'Champ erroné',
  numberField: 'Champ numérique',
  fiveCharsField: 'Champ doit contenir 5 caractères',
  CPvalidField: 'CP ne correspond pas à la province',
  delete: 'Supprimer',
  save: 'Enregistrer',
  file: 'Fichier',
  loading: 'Chargement en cours',
  countrySelector: 'Sélectionnez un pays',
  provinceSelector: 'Sélectionnez une province',
  andNumberMore: 'et {count} de plus',
  selectFile: 'Sélectionnez un fichier',
  selectFileHtml: 'Sélectionnez un fichier html',
  selectFiles: 'Sélectionnez des fichiers',
  allTransactions: 'Toutes les transactions',
  transactionsPerPage: 'Transactions par page',
  transactionsSend: 'Ont été envoyées',
  transactionslower: 'transactions',
  transactionWithoutId: 'Assignation d’ID en attente',
  withoutCertificated: 'Certificat non disponible',
  filter: 'Filtrer',
  transactionsProcessFilter: 'Filtre par procédure',
  processDataPreviewText: 'Vous trouverez ci-dessous le résumé de la nouvelle transaction, avec le nombre d’envois nécessaires et le centre de coût associé',
  processDataPreviewTextWithoutCC: 'Vous trouverez ci-dessous le résumé de la nouvelle transaction, qui indique le nombre d’envois nécessaire',
  transactionsLinkedGroupsFilter: 'Filtre par groupe',
  transactionsDateFilter: 'Filtre par date',
  numberOfTransactions: 'Nombre d’envois',
  associatedCECO: 'Centre de coût associé',
  transactionsCategoryFilter: 'Filtre par catégorie',
  transactionsStatusFilter: 'Filtre par statut',
  transactionState: 'Statut de la transaction',
  transactionSummary: 'Résumé de la transaction',
  transactionGeneralData: 'Informations de la transaction',
  transactionData: 'Données de la transaction',
  files: 'Fichiers',
  transactionContact: 'Données du contact',
  transaction: 'Transaction',
  transactionEvents: 'Événements certifiables de la transaction',
  transactionWithoutEvents: 'Il n’y a pas d’événements enregistrés pour la transaction',
  transactionProgress: 'Progression de la transaction',
  transactionSubtitle: 'Informations sur le statut de la transaction',
  transactionDetails: 'Détails de la transaction',
  transactionEventInfo: 'Informations sur l’événement',
  eventMeaningPrepend: 'Que signifie l’événement',
  eventMeaningAppend: ' ?',
  eventMeaning: 'Que signifie cet événement ?',
  transactionLaunchedDate: 'Date de création',
  transactionLastEvent: 'Dernier événement reçu',
  transactionLastEventDate: 'Date dernier événement',
  transactionContactName: 'Prénom et nom',
  transactionContactMobilephone: 'Téléphone portable',
  transactionContactEmail: 'E-mail',
  transactionContactID: 'ID',
  advancedFilters: 'Filtres avancés',
  group: 'Groupe',
  date: 'Date',
  hideFilters: 'Masquer filtres',
  processName: 'Nom de la procédure',
  usernameTransactionDetail: 'Utilisateur expéditeur',
  recipientsNumber: 'Nombre de destinataires',
  invalidTransaction: 'Données non valides',
  dataTableShowRecipients: 'Affichage envoi <span class="font-weight-medium">:page:</span> de <span class="font-weight-medium">{total}</span>',
  transactionRedirectToProcesses: 'Aller à procédures',
  transactionRedirectToTransactions: 'Aller à transactions',
  transactionRedirectToNewSend: 'Nouvel envoi',
  transactionProcessed: 'Transaction traitée avec succès',
  transactionsProcessed: 'Transactions traitées avec succès',
  transactionError: 'Transaction traitée avec éléments non valides',
  transactionsError: 'Transactions traitées avec éléments non valides',
  transactionProcessing: 'Transaction en cours de traitement...',
  transactionLaunchedMessage: 'La transaction a été traitée avec succès, au fur et à mesure du traitement des envois, ils seront visibles dans la section de',
  transactionsLaunchedMessage: 'Les transactions ont été traitées avec succès, au fur et à mesure du traitement des envois, ils seront visibles dans la section de',
  transactionDownloadFile: 'Voir certificat',
  transactionProcessingMessage: 'Le système traite la transaction, veuillez patienter quelques instants',
  transactionsProcessingMessage: 'Le système traite les transactions, veuillez patienter quelques instants',
  transactionsErrorMessage: 'Pendant la procédure des transactions des différents destinataires, des données non valides ont été détectées dans une transaction au moins. Vous disposez ci-dessous de la liste complète des transactions de l’envoi',
  transactionErrorMessage: 'Pendant la procédure de la transaction, des données non valides ont été détectées',
  recoverProcessQuestion: 'Si vous commencez une nouvelle procédure, vous perdrez les avancées',
  recoverProcessQuestionAppend: 'Souhaitez-vous commencer une nouvelle procédure ?',
  statisticsTransactionsMultipleTitle: 'Transactions réalisées entre le <span class="font-weight-bold">{start_day} {start_month}</span> et le <span class="font-weight-bold">{end_day} {end_month}</span>',
  statisticsTransactionsOneTitle: 'Transactions réalisées le {start_day} {start_month}',
  statisticsTotalTransactionsTitle: 'Totaux par type de transaction',
  statisticsNoResults: 'Aucun résultat avec les filtres indiqués',
  statisticsProcessTitle: 'Procédure :',
  total: 'Total',
  totalTransactions: 'Transactions totales',
  totalTransactionsByProcesses: 'Détail des transactions par procédures',
  accountDescription: 'Paramètres de compte',
  accountTitle: 'Compte',
  contactsDescription: 'Liste et gestion de contacts',
  contactsTitle: 'Contacts',
  transactionsDescription: 'Liste et détails de transactions',
  transactionsTitle: 'Transactions',
  statisticsDescription: 'Analyse graphique de vos transactions',
  statisticsTitle: 'Statistiques',
  supportDescription: 'Dans cette section, vous trouverez de l’aide par le biais du support technique ou dans le manuel de l’utilisateur',
  supportManualDescription: 'Dans cette rubrique, vous pouvez consulter les documents liés à la manipulation de la plateforme',
  supportManualTitle: 'Consulter manuel et guide',
  supportManualTitleOnlyManual: 'Consulter manuel',
  supportManualTitleOnlyGuide: 'Consulter guide',
  administrationDescription: 'Administration de comptes de la plateforme',
  administrationTitle: 'Administration',
  userActive: 'Identifiant actif',
  userInactive: 'Identifiant désactivé',
  userStateTitle: 'Statut de l’identifiant',
  groupsTitle: 'Groupes d’autorisations',
  groupsDescription: 'Gestion des groupes du compte. Configuration des paramètres et autorisations de chaque groupe.',
  groupsAssignation: 'Groupe assigné',
  groupInheritFrom: 'Hériter autorisations de',
  groupDataTitle: 'Informations du groupe',
  groupUsersPermissionsTitle: 'Autorisations, utilisateurs et procédures',
  groupPermissionsTabsTitle: 'Autorisations',
  groupProcessesAndSubaccounts: 'Sous-comptes et procédures',
  groupLinked: 'Groupes rattachés',
  groupPermissionsOwnerText: 'Ce groupe est le propriétaire du compte, toutes les autorisations lui sont assignées',
  groupPermissionsOwnerSubaccountProcessesText: 'Ce groupe est le propriétaire du compte, tous les sous-comptes et toutes les procédures lui sont assignés',
  groupAssociatedProcesses: 'Procédures associées',
  groupAssociatedSubaccounts: 'Sous-compte associés',
  newGroup: 'Nouveau groupe',
  groupName: 'Nom du groupe',
  editGroup: 'Édition du groupe',
  groupDescription: 'Description',
  groupMinDaysToChange: 'Jours minimum de validité du mot de passe',
  groupTabUsersNameTitle: 'Prénom et nom',
  groupTabPermissionSectionTitle: 'Sections',
  groupTabPermissionGroupTitle: 'Groupes',
  groupTabPermissionUsersTitle: 'Identifiants',
  usersSearch: 'Chercher identifiants',
  subaccountWithoutProcesses: 'Sous-compte sans procédures associées',
  selectCostCenter: 'Sélectionnez un centre de coût',
  adminGroupInfoDescription: 'Dans cette section, vous pouvez éditer la description, les politiques de session et les mots de passe de la plateforme.',
  adminGroupInfoUsers: 'Dans cette section, vous pouvez gérer les identifiants associés à ce groupe.',
  adminGroupInfoPerms: 'Dans cette section, vous pouvez gérer les autorisations liées aux fonctions que l’utilisateur peut réaliser sur la plateforme.',
  adminGroupInfoProcesses: 'Dans cette section, vous pouvez gérer l’accès de l’utilisateur aux procédures et aux sous-comptes. Cet accès autorisera le lancement, l’affichage de transactions et de statistiques des procédures assignées.',
  adminGroupInfoLinked: 'Dans cette section, vous pouvez gérer la supervision sur d’autres groupes. Les groupes habilités partageront les informations sur les transactions lancées ainsi que les statistiques avec le groupe actuel.',
  passwordsQuantity: 'Nombre de mots de passe stockés',
  passwordExpiration: 'Jours avant l’expiration du mot de passe',
  loginRetriesQuantity: 'Nombre de nouvelles tentatives dans login',
  loginRetriesTime: 'Minutes avant de réessayer dans login',
  sessionExpiration: 'Minutes avant l’expiration de la session',
  processAlertExpirationQuestion: 'Souhaitez-vous continuer ?',
  processAlertExpirationPhrase: 'Pour des raisons de sécurité, la session est sur le point d’expirer',
  processAlertExpiration: 'Alerte d’expiration',
  continueSession: 'Continuer',
  usersTitle: 'Identifiants',
  activate: 'Activé',
  deactivate: 'Désactivé',
  usersPerPage: 'Identifiants par page',
  usersDescription: 'Gestion des utilisateurs du compte',
  userInfoTitle: 'Informations de l’utilisateur',
  userPermissionTitle: 'Type d’autorisation assigné',
  newUser: 'Nouvel utilisateur',
  editUser: 'Édition de l’utilisateur',
  groupTitle: 'Nom du groupe',
  administrator: 'Administrateur',
  groupLinkedHeader: 'Lié',
  firmaElectronica: 'Signature électronique',
  comunicacionCertificada: 'Communication certifiée',
  publicacionWeb: 'Publication web',
  groupVisibility: 'Visibilité de groupe',
  passwordPolicy: 'Politique de mots de passe',
  frontalPolicy: 'Politique de session',
  groupData: 'Données du groupe',
  groupsPerPage: 'Groupes par page',
  groupsFound: 'Ont été trouvés',
  cancel: 'Annuler',
  description: 'Description',
  subaccountChanged: 'Sous-compte changé',
  processContinueTitle: 'Vous avez une procédure d’envoi en attente',
  processContinueSubtitle: 'Souhaitez-vous récupérer la procédure ou en démarrer une nouvelle ?',
  newProcess: 'Nouvelle procédure',
  recover: 'Récupérer',
  newShipping: 'Nouvel envoi',
  toContinueFillFields: 'Pour continuer, remplissez correctement les champs',
  passwordRequired: 'Mot de passe requis',
  userRequired: 'Identifiant requis',
  fieldAlert: 'Le champ %%fields%% sera rempli automatiquement depuis les destinataires',
  fieldAlertPlural: 'Les champs %%fields%% seront remplis automatiquement depuis les destinataires',
  fieldNumberPhone: 'téléphone',
  fieldCountryCode: 'code pays',
  fieldEmail: 'e-mail',
  fieldName: 'prénom',
  fieldSurname: 'nom',
  and: 'et',
  certificate: 'Certificat',
  country: 'Pays',
  continueEditing: 'Éditer les données de l’envoi',
  second: 'seconde',
  seconds: 'secondes',
  nextRefreshAvailableAt: 'Prochaine mise à jour disponible dans',
  fastSearch: 'Recherche rapide',
  update: 'Mettre à jour',
  process: 'Procédure',
  transactionId: 'ID de la transaction',
  transactionClientId: 'Référence client',
  createdDate: 'Créée',
  updatedAt: 'Mise à jour',
  state: 'Statut',
  noGroupAssigned: 'Aucun groupe sélectionné',
  contact_country_code: 'Code pays',
  contact_email: 'E-mail',
  contact_id: 'Référence client',
  contact_name: 'Prénom',
  contact_phone: 'Téléphone',
  contact_surname: 'Nom',
  contact_complete_name: 'Nom complet',
  certificateValidDownload: 'Certificat correctement téléchargé',
  certificateFailDownload: 'Le certificat n’a pas pu être téléchargé',
  graphicBarValidDownload: 'Graphique correctement téléchargé',
  graphicBarFailDownload: 'Le graphique n’a pas pu être téléchargé',
  queued: 'La transaction est en attente d’être traitée.',
  new_communication: 'La transaction a été correctement générée.',
  orchestrator_new_communications_communication_received: 'La transaction a été correctement traitée.',
  commstracker_generate_url_generated: 'Le lien de l’URL courte a été généré.',
  commstracker_generate_url_click: 'Le lien de la transaction a été ouvert.',
  communication_send_sms_sent: 'Le SMS a été déposé dans le centre de service d’envoi du fournisseur. Ce dernier se chargera de l’envoyer à son destinataire final.',
  communication_send_sms_not_sent: 'Le SMS n’a pas pu être déposé dans le centre de service d’envoi du fournisseur.',
  communication_send_sms_delivered: 'Le SMS a été remis au destinataire.',
  communication_send_sms_not_delivered: 'Le SMS n’a pas pu être remis au destinataire.',
  generate_otp_code_otp_generated: 'Un mot de passe à usage unique (OTP) a été généré pour son utilisation.',
  generate_certificate_generated: 'Le certificat de finalisation de la transaction a été généré. Il mentionne le contenu de la communication et les preuves associées aux événements survenus pendant la procédure.',
  communication_send_email_delivered: 'Le message a été remis au fournisseur du service de messagerie électronique (ESP) du destinataire.',
  communication_send_email_complaint: 'Le destinataire du message a indiqué que le courrier électronique reçu est un courrier indésirable.',
  communication_send_email_queued: 'Le message électronique a été placé dans la file d’attente.',
  communication_send_email_deferred: 'Le message électronique a été placé dans la file d’attente et sera remis dès que possible.',
  communication_send_email_bounce: 'Le message électronique a été renvoyé.',
  communication_send_email_sent: 'Le message électronique a été déposé dans le centre de service d’envoi du fournisseur. Ce dernier se chargera de l’envoyer à son destinataire final.',
  communication_send_email_sender_not_allowed: 'Le message électronique n’a pas pu être envoyé car l’expéditeur n’est pas autorisé.',
  communication_send_email_open: 'Le message électronique a été ouvert par le destinataire.',
  communication_send_email_click: 'Le destinataire du message électronique a cliqué sur un lien.',
  communication_send_email_block: 'L’adresse électronique du destinataire figure sur la liste des exclusions, le message n’a pas été envoyé.',
  communication_send_email_unsubscribe: 'Le destinataire du message électronique souhaite se désinscrire de la liste de distribution.',
  communication_send_email_spam: 'Le message électronique a été identifié comme courrier indésirable.',
  communication_send_email_hard_blacklisted: 'L’adresse électronique du destinataire figure sur la liste des exclusions, le message n’a pas été envoyé.',
  communication_send_email_complaint_blacklisted: 'L’adresse électronique du destinataire figure sur la liste des plaintes, le message n’a pas été envoyé.',
  document_upload_s3_uploaded: 'Le certificat de finalisation de la transaction a été stocké de manière sécurisée. Il mentionne le contenu de la communication et les preuves associées aux événements survenus pendant la procédure.',
  communication_send_telegram_delivered: 'Le SMS a été remis au destinataire.',
  communication_send_telegram_not_delivered: 'Le SMS n’a pas pu être remis',
  web_framework_generated: 'L’interface graphique web a été générée pour que le destinataire puisse interagir.',
  web_framework_solicitud_otp: 'Un mot de passe à usage unique (OTP) a été demandé.',
  web_framework_signable_document_loaded: 'Un document à signer a été chargé sur l’interface web.',
  web_framework_otp_simple_signature_ok: 'La documentation a été signée avec le mot de passe à usage unique.',
  web_framework_commited: 'La procédure a été complétée sur l’interface web.',
  web_framework_document_signed: 'La documentation a été signée.',
  web_framework_form_based_identification: 'L’étape d’authentification a été complétée par le biais du formulaire.',
  web_framework_certificate_based_identification: 'Un accès avec certificat personnel a été réalisé de manière satisfaisante',
  web_framework_document_based_identification: 'Identification correcte sur le formulaire.',
  web_framework_certificate_based_identification_failed: 'La procédure d’identification par certificat a échoué.',
  web_framework_document_based_identification_failed: 'La procédure d’identification par document a échoué.',
  web_framework_form_based_identification_failed: 'La procédure d’authentification par formulaire a échoué.',
  web_framework_web_framework_expired: 'La transaction a expiré.',
  web_framework_documentation_downloaded: 'La documentation a été téléchargée par le destinataire.',
  web_framework_electronic_notificacion_rejected: 'La notification électronique a été refusée par le destinataire.',
  web_framework_gdpr_formulary_committed: 'Le formulaire de consentement a été rempli par le destinataire.',
  web_framework_sepa_formulary_committed: 'Le formulaire SEPA a été rempli par le destinataire.',
  web_framework_requested_otp_code: 'Un mot de passe à usage unique (OTP) a été demandé.',
  web_framework_entered_wrong_otp_code: 'Le mot de passe à usage unique (OTP) saisi par le destinataire est incorrect.',
  web_framework_entered_timedout_otp_code: 'Le mot de passe à usage unique a expiré.',
  web_framework_entered_correct_otp_code: 'Le mot de passe à usage unique (OTP) saisi par le destinataire est correct.',
  web_framework_electronic_notificacion_accepted: 'La notification électronique a été acceptée par le destinataire.',
  web_framework_signature_rejected: 'La demande de signature électronique a été refusée par le destinataire.',
  web_framework_request_otp_code_too_early: 'Le mot de passe à usage unique (OTP) a été demandé par le destinataire avant le délai stipulé pour pouvoir en créer un nouveau',
  web_framework_capture_data_commited_no_response: 'Tentative d’envoi du formulaire sans réponses.',
  web_framework_capture_data_commited: 'L’envoi de données a été correctement réalisé',
  web_framework_document_previewed: 'La documentation a été affichée par le destinataire.',
  web_framework_document_simply_signed: 'La documentation a été signée électroniquement.',
  document_upload_alastria_uploaded: 'Le hash du certificat de finalisation a été chargé dans le nœud de CertySign dans le réseau Blockchain d’Alastria.',
  publish_downloadable_file_generated: 'La documentation a été générée pour être publiée sur l’interface web.',
  publish_downloadable_file_open: 'La documentation a été ouverte pour être publiée sur l’interface web.',
  document_sign_signed: 'Le certificat a été signé',
  rpc_callback_published: 'Le retour de l’appel RPC a été publié',
  loop_add_added: 'La transaction a été ajoutée à la boucle des nouvelles tentatives.',
  loop_add_tick: 'Une tentative supplémentaire a été réalisée',
  loop_add_timeout: 'Le nombre limite de tentatives a été atteint',
  loop_cancel_canceled: 'La boucle a été annulée',
  identification_create_identification_generated: 'La procédure permettant à l’utilisateur de s’identifier dans la transaction a été créée.',
  identification_create_identification_ok: 'Identification correcte',
  test_load_processed: 'L’essai de chargement a été traité',
  instance_delete_deleted: 'L’instance correspondant à la transaction a été effacée.',
  unzip_file_extracted: 'Le fichier a été décompressé.',
  communicalia_consent_accepted: 'Le destinataire de la communication a accepté le consentement.',
  communicalia_consent_declined: 'Le destinataire de la communication a refusé le consentement.',
  communicalia_consent_no_data: 'Le destinataire de la communication n’a pas répondu.',
  robinson_list_accepted: 'Le destinataire ne figure pas sur la liste de Robinson',
  robinson_list_declined: 'Le destinataire figure sur la liste Robinson',
  robinson_list_no_data: 'Le destinataire figure sur la liste Robinson',
  publish_embeddable_file_generated: 'Le fichier intégré pour l’interface web a été généré.',
  document_upload_sftp_uploaded: 'Le chargement de la documentation par SFTP a été effectué.',
  file_delete_deleted: 'Le fichier a été effacé.',
  disable_framework_web_framework_web_disabled: 'L’interface web associée à la transaction a été désactivée.',
  generate_onespan_transaction_transaction_create: 'La transaction de signature électronique avec OneSpan a été créée',
  generate_onespan_transaction_transaction_create_error: 'Une erreur est survenue pendant la création de la transaction de signature électronique dans OneSpan.',
  generate_onespan_transaction_transaction_complete: 'La transaction de signature électronique dans OneSpan a été complétée.',
  generate_onespan_transaction_transaction_expire: 'La transaction de signature électronique dans OneSpan a expiré.',
  generate_onespan_transaction_transaction_decline: 'La transaction de signature électronique dans OneSpan a été rejetée.',
  generate_onespan_transaction_email_bounce: 'Le courrier électronique correspondant à la transaction de signature électronique dans OneSpan a été retourné.',
  generate_onespan_transaction_transaction_opt_out: 'Le destinataire a choisi de ne pas signer la transaction.',
  generate_onespan_transaction_transaction_delete: 'La transaction de signature électronique de OneSpan a été supprimée.',
  generate_onespan_transaction_transation_desactivate: 'La transaction de signature électronique de OneSpan a été désactivée.',
  generate_onespan_transaction_kba_failure: 'La transaction a été bloquée pour des raisons de sécurité de l’accès ou de la procédure.',
  generate_onespan_transaction_transaction_sent: 'La transaction de signature électronique de OneSpan a été envoyée.',
  generate_onespan_transaction_transaction_sent_error: 'L’envoi de la transaction de signature électronique de OneSpan a échoué.',
  generate_onespan_transaction_package_trash: 'La transaction a été déplacée dans la corbeille de OneSpan.',
  get_onespan_files_files_onespan_generated: 'Les fichiers nécessaires pour OneSpan ont été générés.',
  communication_send_postal_generated: 'L’envoi de la communication postale a été généré.',
  communication_send_postal_sent_to_factory: 'L’envoi a été chargé sur la plateforme communication postale.',
  communication_send_postal_printed: 'La documentation associée à l’envoi postal a été imprimée.',
  communication_send_postal_pre_admission_requested: 'L’envoi de la communication est en cours de pré-enregistrement télématique sur le site web de l’opérateur postal.',
  communication_send_postal_pre_admitted: 'L’envoi de la communication a été enregistré télématiquement par l’opérateur postal, avant son dépôt.',
  communication_send_postal_sent_to_logistic_operator: 'L’envoi de la communication est en cours de dépôt chez l’opérateur postal.',
  communication_send_postal_on_the_way_to_logistic_operator: 'L’envoi est en route vers le bureau de poste le plus proche du destinataire de la communication.',
  communication_send_postal_received_by_logistic_operator: 'L’envoi est au bureau de poste le plus proche du destinataire de la communication.',
  communication_send_postal_not_received_by_logistic_operator: 'L’envoi de la communication n’est pas arrivé au bureau de poste.',
  communication_send_postal_on_delivery: 'L’envoi de la communication postale est en route.',
  communication_send_postal_delivered_to_recipient: 'L’envoi de la communication postale a été remis au destinataire.',
  communication_send_postal_returned_to_postal_office: 'L’envoi est au bureau de poste et un avis de passage a été déposé dans la boîte aux lettres du destinataire de la communication',
  communication_send_postal_delivered_into_postal_office: 'L’envoi de la communication a été remis au destinataire au bureau de poste.',
  communication_send_postal_generic_delivered: 'L’envoi de la communication postale a été remis au destinataire.',
  communication_send_postal_returned: 'Il n’a pas été possible d’effectuer la remise de l’envoi et la communication postale a dû être renvoyée à l’expéditeur.',
  communication_send_postal_canceled: 'L’envoi de la communication postale a été annulé.',
  communication_send_postal_lost: 'L’envoi de la communication postale s’est perdu.',
  communication_send_postal_expired: 'L’envoi de la communication postale a été renvoyé à l’expéditeur car le destinataire ne l’a pas récupéré dans les 15 jours ouvrables suivant le dépôt de l’avis de passage dans sa boîte aux lettres.',
  communication_send_postal_rejected: 'L’envoi de la communication postale a été refusé par le destinataire.',
  communication_send_postal_stolen: 'L’envoi de la communication postale a été volé.',
  communication_send_postal_outside_sicer: 'L’envoi de la communication n’est pas enregistré chez l’opérateur postal.',
  communication_send_postal_bad_postal_address: 'L’adresse postale de l’envoi n’est pas bonne.',
  communication_send_postal_passed_away: 'Le destinataire de l’envoi de la communication postale est décédé.',
  communication_send_postal_other: 'Autres.',
  communication_send_postal_no_information: 'L’opérateur postal n’a pas d’informations concernant l’envoi de la communication.',
  communication_send_postal_received_pee: 'La preuve numérique de remise de l’envoi a été reçue.',
  communication_send_postal_certificate_returned: 'Le certificat retour avec la preuve de remise impossible de l’opérateur postal a été généré.',
  communication_send_postal_unknown: 'Le destinataire de l’envoi de la communication est inconnu à l’adresse postale indiquée.',
  zip_files_path_zipped: 'Un ou plusieurs documents ont été compressés',
  intromission_api_retries_muted: 'Les nouvelles tentatives de la transaction ont été désactivées',
  intromission_api_retries_unmuted: 'Les nouvelles tentatives de la transaction ont été activées',
  intromission_api_instance_canceled: 'La transaction a été annulée',
  antivirus_filtered: 'L’antivirus a filtré la transaction parce que des ressources potentiellement dangereuses ont été téléchargées.',
  //API messages
  user_operation_error_creating_user: 'Une erreur est survenue pendant la création de l’identifiant',
  user_operation_user_already_exists: 'L’identifiant existe déjà',
  user_operation_user_created: 'Identifiant créé avec succès',
  user_operation_user_updated: 'Identifiant mis à jour avec succès',
  user_operation_no_exists: 'L’identifiant n’existe pas',
  user_operation_password_changed: 'Le mot de passe a été mis à jour',
  user_operation_pass_incorrect: 'La combinaison identifiant et mot de passe est incorrecte',
  user_operation_password_expired: 'Le mot de passe a expiré',
  user_operation_logout: 'Session correctement fermée',
  user_operation_blocked_user: 'L’identifiant a été bloqué',
  user_operation_wrong_subdomain: 'L’identifiant ou le mot de passe n’est pas correct',
  user_operation_max_pass_tries: 'Identifiant bloqué, nombre de tentatives atteint',
  user_operation_deleted: 'Identifiant supprimé correctement',
  user_operation_error_deleted: 'Erreur pendant la suppression de l’identifiant',
  user_has_been_disabled: 'Identifiant désactivé',
  user_operation_error_user_not_found: 'Si l’adresse électronique indiquée existe, vous recevrez bientôt un e-mail avec les indications pour changer de mot de passe',
  user_operation_remember_password_email: 'Si l’adresse électronique indiquée existe, vous recevrez bientôt un e-mail avec les indications pour changer de mot de passe',
  email_sent_to_support: 'Demande envoyée correctement',
  user_operation_error_change_password_min_days: 'Il n’est pas possible de changer de mot de passe parce que le délai requis n’est pas écoulé',
  user_operation_error_passwords_mismatch: 'Les mots de passe ne coïncident pas',
  user_operation_error_password_has_been_used_before: 'Le mot de passe a déjà été utilisé, vous devez en choisir un autre',
  user_or_group_no_exists: 'Identifiant ou groupe inexistant',
  user_error_already_active: 'L’identifiant est déjà actif',
  activation_email_resend: 'L’e-mail d’activation a été renvoyé',
  group_operation_error_creating_group: 'Une erreur est survenue pendant la création du groupe',
  group_operation_group_already_exists: 'Le groupe existe déjà',
  group_operation_group_created: 'Groupe créé avec succès',
  group_operation_group_updated: 'Groupe :group_name: mis à jour avec succès',
  //Terms and conditions
  termsConditionsDPOTitle: 'Délégué à la protection des données',
  termsConditionsTitular: 'Titulaire',
  termsConditionsNIF: 'N.I.F.',
  termsConditionsDomicilioSocial: 'Siège social',
  termsConditionsDatosRegistrales: 'Données du registre',
  termsConditionsContacto: 'Contact',
  termsConditionsDPOEmailTitle: 'E-mail Customer Comms',
  termsConditionsDPOEmail: 'Adresse électronique du délégué à la protection des données',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (ci-après CCOMMS), en sa condition de titulaire et responsable de la présente application, conformément à l’article 10 de la loi 34/2002 du 11 juillet 2002 sur les services de la société de l’information et le commerce électronique, met à disposition des utilisateurs les informations suivantes à propos de cette application :',
  termsConditionsObjetoTitulo: 'OBJET',
  termsConditionsObjetoParrafo1: 'Les présentes Conditions générales d’utilisation (ci-après les \u2018CGU’) réglementent l’utilisation de l’application CertySign (ci-après \u2018l’application’) que CCOMMS met à disposition des utilisateurs qui y accèdent pour pouvoir effectuer la demande réalisée.',
  termsConditionsObjetoParrafo2: 'Les présentes CGU s’appliqueront donc à l’activité promotionnelle et d’informations effectuée par l’intermédiaire de l’application, comme la prestation des services proposés sur l’application, de telle sorte qu’elles devront être respectées à tout moment, que ce soit pour naviguer simplement sur l’application ou pour souscrire des services dans le cadre de l’application, même si ces dernières activités seront également soumises aux Conditions générales de souscription applicables, et aux Conditions particulières éventuelles.',
  termsConditionsUsuariosTitulo: 'UTILISATEURS',
  termsConditionsUsuariosParrafo1: 'La personne accédant à et/ou utilisant l’application acquiert la condition d’utilisateur et dès lors, accepte pleinement et sans aucune réserve les présentes CGU, ainsi que les Conditions particulières qui, le cas échéant, complètent, modifient ou remplacent les CGU concernant certains services et contenus de l’application, en s’obligeant à respecter les restrictions d’utilisation de cette page fixées par CCOMMS à chaque instant.',
  termsConditionsAccesoAPPTitulo: 'ACCÈS À L’APPLICATION',
  termsConditionsAccesoAPPParrafo1: 'L’accès et l’utilisation de l’application ont un caractère restreint ; pour pouvoir y accéder, l’utilisateur devra au préalable s’être inscrit.',
  termsConditionsUsoAPPTitulo: 'UTILISATION DE L’APPLICATION',
  termsConditionsUsoAPPParrafo1: 'L’utilisateur s’engage à utiliser l’application, ses services et contenus sans enfreindre la législation en vigueur, la bonne foi, les usages communément acceptés et l’ordre public. De même, il est interdit d’utiliser l’application à des fins illégales ou nuisibles contre CCOMMS ou tout autre tiers, ou, de n’importe quelle manière, de causer préjudice ou empêcher le fonctionnement normal de l’application.',
  termsConditionsResposabilidadTitulo: 'RESPONSABILITÉ DE L’UTILISATEUR POUR DOMMAGES ET INTÉRÊTS',
  termsConditionsResposabilidadParrafo1: 'L’utilisation de l’application se fera sous la responsabilité unique et exclusive de l’utilisateur. Cette responsabilité s’étendra à l’utilisation, par l’utilisateur ou n’importe quel tiers, de tout mot de passe ou similaire attribué pour accéder à l’application ou à l’un de ses services.',
  termsConditionsResposabilidadParrafo2: 'Sans préjudice de ce qui précède, CCOMMS se réserve le droit de refuser ou d’inactiver à tout moment et sans avis préalable l’accès à l’application ou à l’accès restreint, aux utilisateurs qui ne respectent pas ces CGU, les normes fixées par CCOMMS ou leurs collaborateurs ou qui pourraient perturber le bon fonctionnement, l’image, la crédibilité et/ou le prestige de CCOMMS ou ses collaborateurs.',
  termsConditionsResposabilidadParrafo3: 'En ce sens, CCOMMS se réserve la faculté d’annuler ou de restreindre l’accès à l’application à certains utilisateurs, en cas d’observation d’une conduite jugée par CCOMMS comme étant contraire à ces Conditions d’utilisation, à la loi, aux normes établies par CCOMMS ou ses collaborateurs ou susceptible de perturber le bon fonctionnement, l’image, la crédibilité et/ou le prestige de CCOMMS ou ses collaborateurs. De même, CCOMMS pourra exiger leur responsabilité aux utilisateurs pour les dommages et intérêts dérivés d’une mauvaise utilisation de l’application.',
  termsConditionsResposabilidadParrafo4: 'CCOMMS ne garantit pas la légalité, la fiabilité et l’utilité, ainsi que la véracité ou exactitude, des contenus transmis par les utilisateurs pendant l’utilisation de l’application.',
  termsConditionsResposabilidadParrafo5: 'CCOMMS ne contrôle pas l’utilisation faite par les utilisateurs de l’application, ni ne garantit qu’ils le font conformément aux présentes Conditions générales.',
  termsConditionsResposabilidadParrafo6: 'CCOMMS ne contrôle ni ne garantit l’absence de virus informatique dans les services fournis par des tiers par l’intermédiaire de l’application susceptibles de produire des altérations dans leur système informatique (software et hardware) ou sur les documents et fichiers électroniques stockés dans leur système informatique.',
  termsConditionsPropiedadIntelectualTitulo: 'PROPRIÉTÉ INTELLECTUELLE',
  termsConditionsPropiedadIntelectualParrafo1: 'Toute utilisation ou modification du matériel ou des contenus pour toute fin autre que celle autorisée dans les conditions générales sera considérée comme une violation des lois internationales du copyright qui protègent les droits d’auteur.',
  termsConditionsPropiedadIntelectualParrafo2: 'Concernant les contenus, sont interdits :',
  termsConditionsPropiedadIntelectualListaELemento1: 'Leur reproduction, copie, distribution, modification ou manipulation, de n’importe quelle manière et dans n’importe quel but, sans autorisation préalable écrite de leurs titulaires légitimes ou à moins d’y être autorisé par la loi.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Toute violation des droits de CCOMMS ou de leurs titulaires légitimes sur ces contenus.',
  termsConditionsPropiedadIntelectualListaELemento3: 'Leur utilisation pour toute sorte de fins commerciales ou publicitaires, autres que celles strictement autorisées.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantit que les contenus, y compris ceux de propriété intellectuelle, n’ont pas un caractère illégal et n’enfreignent pas la réglementation en vigueur. Par conséquent, les contenus de l’application n’auront pas un caractère xénophobe, pornographique, discriminatoire, raciste ou qui encourage la violence de quelque manière que ce soit.',
  termsConditionsPropiedadIntelectualParrafo4: 'De plus, CCOMMS prendra les mesures légales jugées opportunes par la compagnie pour empêcher tout type de conduite contraire à la loi ou immorale.',
  termsConditionsModificacionUnilateralTitulo: 'MODIFICATION UNILATÉRALE',
  termsConditionsModificacionUnilateralParrafo1: 'CCOMMS pourra modifier de manière unilatérale et sans préavis, à condition de le juger opportun, la structure et l’aspect de l’application, ainsi que modifier ou supprimer les services, contenus et conditions d’accès et/ou d’utilisation de l’application.',
  termsConditionsExclusionGarantiasTitulo: 'EXCLUSION DE GARANTIES ET RESPONSABILITÉ',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMS ne donne aucune garantie ni ne se porte responsable, en aucun cas, des dommages et intérêts de toute nature susceptibles d’entraîner :',
  termsConditionsExclusionGarantiasListaELemento1: 'Le manquement à la loi, à la morale et aux bonnes mœurs, ainsi qu’à l’ordre public, ou l’utilisation des produits et/ou services offerts de manière non consciencieuse ou incorrecte, ou à des fins ou effets illégaux ou contraires à ceux qui sont fixés dans les présentes CGU ou dans les conditions éventuellement applicables.',
  termsConditionsExclusionGarantiasListaELemento2: 'En achetant ou en souscrivant des services, des biens ou des produits pouvant être acquis ou souscrits à des tiers par le biais d’un accès à l’application de CCOMMS, particulièrement dans les cas où le processus d’achat ou de souscription se fait directement sur l’application du tiers et même si des distinctifs ou un \u2018frame’ avec les éléments graphiques de l’application (Branding) sont affichés.',
  termsConditionsExclusionGarantiasListaELemento3: 'Le manque de disponibilité, maintenance et fonctionnement efficace de l’application et/ou de ses services ou contenus.',
  termsConditionsExclusionGarantiasListaELemento4: 'Le manque d’utilité, adéquation ou validité de l’application et/ou de ses services ou contenus pour répondre à des besoins, activités ou résultats concrets ou attentes des utilisateurs.',
  termsConditionsExclusionGarantiasListaELemento5: 'L’existence de virus, programmes malicieux ou nuisibles dans les contenus.',
  termsConditionsExclusionGarantiasListaELemento6: 'La réception, l’obtention, le stockage, la diffusion ou la transmission des contenus par les utilisateurs.',
  termsConditionsExclusionGarantiasListaELemento7: 'L’illégalité ou le défaut de véracité, d’exactitude, de fiabilité, de pertinence, d’actualité et d’exhaustivité des contenus et informations transmis ou mis à disposition des utilisateurs, y compris les informations et services fournis par des tiers ou par les utilisateurs par l’intermédiaire de l’application.',
  termsConditionsExclusionGarantiasListaELemento8: 'Le non-respect par des tiers de leurs obligations ou engagements en lien avec les services fournis aux utilisateurs par le biais de l’application.',
  termsConditionsExclusionGarantiasListaELemento9: 'Des contenus, fichiers, informations, publicités, opinions, concepts et images qui ne dépendent pas de l’application et qui ne sont pas gérés par CCOMMS. L’entreprise ne se fait pas responsable non plus de la mauvaise utilisation qui serait faite des contenus de l’application, qui relèvera exclusivement de la responsabilité de la personne qui y accède ou qui les utilise.',
  termsConditionsExclusionGarantiasListaELemento10: 'De l’utilisation indue des mots de passe des utilisateurs pour accéder aux contenus du service de l’application qui les requièrent et des conséquences dérivées de toute nature de leur mauvaise utilisation par les utilisateurs, de leur perte ou de leur oubli, et de leur utilisation indue par des tiers non autorisés.',
  termsConditionsExclusionGarantiasListaELemento11: 'L’incapacité de tout utilisateur ou l’usurpation de la personnalité d’un tiers effectuée par l’utilisateur.',
  termsConditionsExclusionGarantiasListaELemento12: 'Des contenus fournis par les utilisateurs.',
  termsConditionsProteccionDatosTitulo: 'PROTECTION DES DONNÉES',
  termsConditionsProteccionDatosParrafo1: 'Vous pouvez consulter les informations détaillées sur la Protection des données sur notre page de',
  termsConditionsLeyJurisdiccionTitulo: 'LOI APPLICABLE ET JURIDICTION',
  termsConditionsLeyJurisdiccionParrafo1: 'Toutes les Conditions, générales et particulières, insérées dans la présente application, sont soumises à la réglementation espagnole. Tous les conflits, controverses ou situations dérivés des Conditions générales et particulières seront soumis aux cours et tribunaux de la ville de Madrid, les parties renonçant expressément à leur juridiction.',
  termsConditionsProteccionDatosTitle: 'Consulter des informations sur la protection des données',
  termsConditionsProteccionDatos: 'Protection des données',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Protection des données de Mailteck & Customer Communications Tecknalia',
  privacyPoliciesConditionsParrafo1: 'Conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (RGPD), ainsi qu’à la loi organique 3/2018 du 5 décembre, sur la protection des données à caractère personnel et la garantie des droits numériques (LOPD-GDD) et à toute autre réglementation en vigueur sur la protection des données, les utilisateurs de l’application qui ont consenti volontairement de manière libre, claire et sans équivoque au traitement des données à caractère personnel en acceptant la case de \u2018Politique de protection des données’ sont informés que leurs données seront traitées, suivant l’entreprise avec laquelle ils ont souscrit, soit par l’entreprise MAILTECK, S.A., soit par l’entreprise CUSTOMER COMMUNICATIONS TECKNALIA, S.L., lesquelles seront mentionnées ensemble comme le GROUPE MAILCOMMS.',
  privacyPoliciesConditionsResponsable1Title: 'Responsable 1',
  privacyPoliciesConditionsResponsable2Title: 'Responsable 2',
  privacyPoliciesConditionsFinalidadTitle: 'Finalité',
  privacyPoliciesConditionsLegitimacionTitle: 'Licéité',
  privacyPoliciesConditionsDestinatariosTitle: 'Destinataires',
  privacyPoliciesConditionsDerechosTitle: 'Droits',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Informations supplémentaires',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Politique de l’information 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Politique de l’information 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identité',
  privacyPoliciesConditionsNIFTitle: 'NIF',
  privacyPoliciesConditionsDireccionTitle: 'Adresse',
  privacyPoliciesConditionsTelefonoTitle: 'Téléphone',
  privacyPoliciesConditionsCorreoTitle: 'Adresse électronique',
  privacyPoliciesConditionsDPDTitle: 'Contact avec le DPD',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Délégué à la protection des données de Customer Comms',
  privacyPoliciesConditionsDPDMailteckTitle: 'Délégué à la protection des données de Mailteck',
  privacyPoliciesConditionsFinalidadIntro: 'Gérer et traiter les services souscrits',
  privacyPoliciesConditionsFinalidadParrafo1: 'Le GROUPE MAILCOMMS va traiter les données fournies, dans le cadre de l’exécution d’un contrat souscrit par vous ou par votre entreprise, dans le but de gérer et traiter les services accordés dans ce contrat, et pour apporter un support à l’application CertySign.',
  privacyPoliciesConditionsFinalidadParrafo2: 'Le GROUPE MAILCOMMS traitera uniquement les données fournies pour les finalités décrites plus haut et ne les traitera pas ultérieurement de manière incompatible avec ces fins. Les données à caractère personnel fournies seront conservées pendant la durée de la relation contractuelle.',
  privacyPoliciesConditionsLegitimacionIntro: 'Exécution du contrat',
  privacyPoliciesConditionsLegitimacionParrafo1: 'Les données ont été fournies par vous ou par votre entreprise par le biais des canaux mis en place pour pouvoir vous donner accès à l’application CertySign, en conséquence du contrat de prestation de service souscrit.',
  privacyPoliciesConditionsDestinatariosIntro: 'Elles ne sont pas cédées à des tiers',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Par obligation légale si une loi l’exige.',
  privacyPoliciesConditionsDerechosIntro: 'Vous pouvez exercer vos droits dans la forme et dans les délais prévus par la loi.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Confirmation générale :',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Quiconque a le droit d’obtenir confirmation que le GROUPE MAILCOMMS traite ses données à caractère personnel.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Droits ARCO :',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Les personnes intéressées ont le droit d’accéder à leurs données à caractère personnel, ainsi que de demander la rectification des données inexactes, ou le cas échéant, de demander leur suppression quand, entre autres raisons, les données ne sont plus nécessaires pour les fins pour lesquelles elles ont été collectées.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limitation du traitement :',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'Dans certaines circonstances, les intéressés pourront demander la limitation du traitement de leurs données, auquel cas nous les conserverons uniquement pour l’exercice ou la défense des réclamations.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Opposition au traitement :',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'Dans certaines circonstances et pour des raisons liées à leur situation particulière, les intéressés pourront s’opposer au traitement de leurs données. Le GROUPE MAILCOMMS cessera donc de traiter les données, sauf pour des raisons d’impératif légal ou pour l’exercice ou la défense d’éventuelles réclamations.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilité des données :',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'Dans certaines circonstances et pour des raisons liées à leur situation particulière, les intéressés pourront demander de recevoir les données à caractère personnel fournies sur le formulaire de contact, dans un format structuré, couramment utilisé et lisible par une machine ; ils pourront aussi transmettre ces données à un autre responsable du traitement, sans entraves.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Plainte auprès d’un organisme public :',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Si l’intéressé considère que ses droits à la protection des données à caractère personnel ont été bafoués, il pourra déposer plainte auprès de l’Agence espagnole de protection des données',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canal pour exercer ses droits :',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Les intéressés pourront exercer les droits que leur concède la réglementation face à et contre chacun des responsables, devant les directions habilitées comme DPO dans la rubrique \u2018Responsables’.',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Plus d’informations sur la protection des données.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'Les données à caractère personnel ont été obtenues sur les canaux mis en place par les entreprises du GROUPE MAILCOMMS pour pouvoir gérer les services souscrits par vous ou votre entreprise.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'Les catégories de données qui seront traitées seront les données fournies pour le développement de chaque service souscrit.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'Le GROUPE MAILCOMMS ne traite pas de données spécialement protégées.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Politique de sécurité et de confidentialité des informations fondée sur la norme ISO 27001 -> En savoir plus.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Politique de sécurité et de confidentialité des informations fondée sur la norme ISO 27001 -> En savoir plus.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANNEXE I : POLITIQUE DU SYSTÈME DE GESTION DE LA SÉCURITÉ ET DE LA CONFIDENTIALITÉ DES INFORMATIONS.',
  privacyPoliciesConditionsPoliticaInformacionP1: 'Cette Politique de sécurité et de confidentialité des informations reflète l’engagement de la Direction qui a doté l’organisation de ressources, de responsabilités et de procédures qui permettent de garantir le respect de la réglementation en vigueur, ainsi que l’intégrité, la confidentialité et la disponibilité des informations et des systèmes ; elle est cruciale pour la sécurité, la confidentialité et la continuité de notre organisation et pour celles de nos clients.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'Le Groupe Mailteck, conscient que la sécurité et la confidentialité des informations relatives à nos clients sont une ressource de grande valeur, a mis en place un Système de gestion de la sécurité et de la confidentialité des informations conformément aux conditions des normes ISO/IEC 27001:2013 et ISO/IEC 27701:2019 pour garantir les niveaux de sécurité requis, la continuité des services fournis et le respect de la réglementation dans le traitement des données à caractère personnel, ce qui réduit les risques dérivés de cette gestion.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms, conscient que la sécurité et la confidentialité des informations relatives à nos clients sont une ressource de grande valeur, a mis en place un Système de gestion de la sécurité et de la confidentialité des informations conformément aux conditions des normes ISO/IEC 27001:2013 et ISO/IEC 27701:2019 pour garantir les niveaux de sécurité requis, la continuité des services fournis et le respect de la réglementation dans le traitement des données à caractère personnel, ce qui réduit les risques dérivés de cette gestion.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'La Politique de sécurité et de confidentialité a pour objectif de fixer le cadre d’action nécessaire pour protéger les ressources d’information face aux menaces, internes ou externes, délibérées ou accidentelles, afin d’assurer le respect de la confidentialité, l’intégrité et la disponibilité des informations.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'L’efficacité et l’application du Système de gestion de la sécurité et de la confidentialité des informations relèvent de la responsabilité directe du Comité de la sécurité et de la confidentialité des informations, qui est responsable de l’approbation, de la diffusion et du respect de la présente Politique de sécurité et de confidentialité. Un Responsable du Système de gestion de la sécurité et de la confidentialité des informations a été nommé en son nom et représentation. Il possède l’autorité suffisante pour jouer un rôle actif dans le Système de gestion de la sécurité et de la confidentialité des informations, en supervisant son implantation, son développement et sa maintenance.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'De plus, pour respecter la réglementation en vigueur sur la protection des données, un Délégué à la protection des données (DPO) a été nommé dans le but de garantir la sécurité et la confidentialité des données à caractère personnel, notamment en tenant compte de l’activité de la compagnie.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'Le Comité de sécurité et de confidentialité des informations concevra et approuvera la méthodologie d’analyse des risques utilisée dans le Système de gestion de la sécurité et de la confidentialité des informations.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Toute personne dont l’activité peut, directement ou indirectement, être concernée par les conditions du Système de gestion de la sécurité et de la confidentialité des informations est tenue de respecter strictement la Politique de sécurité et de confidentialité.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'Le Groupe Mailteck va mettre en place toutes les mesures nécessaires pour respecter la réglementation applicable en matière de sécurité et de confidentialité en général et de sécurité informatique, relative à la politique informatique, à la sécurité des bâtiments et installations et au comportement des employés et personnes tierces associés au Groupe Mailteck dans l’utilisation des systèmes informatiques et le traitement des données à caractère personnel. Les mesures nécessaires pour garantir la sécurité et la confidentialité des informations grâce à l’application de normes, procédures et contrôles devront permettre d’assurer la confidentialité, l’intégrité, la disponibilité des informations, essentielles pour :',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Custommer Comms va mettre en place toutes les mesures nécessaires pour respecter la réglementation applicable en matière de sécurité et de confidentialité en général et de sécurité informatique, relative à la politique informatique, à la sécurité des bâtiments et installations et au comportement des employés et personnes tierces associés à Custommer Comms dans l’utilisation des systèmes informatiques et le traitement des données à caractère personnel. Les mesures nécessaires pour garantir la sécurité et la confidentialité des informations grâce à l’application de normes, procédures et contrôles devront permettre d’assurer la confidentialité, l’intégrité, la disponibilité des informations, essentielles pour :',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Respecter la législation, la réglementation et les normes en vigueur en matière de confidentialité et de systèmes d’informations et toutes les conditions contractuelles en matière de protection des données, ainsi que celles qu’il sera jugé pertinent de mener à bien dans l’objectif de parvenir à une amélioration continue de l’organisation.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Assurer la confidentialité des données gérées par le Groupe Mailteck.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Assurer la confidentialité des données gérées par Custommer Comms.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Assurer la disponibilité des systèmes d’information, dans les services offerts aux clients et dans la gestion interne.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Assurer la capacité de réponse en cas de situations d’urgence, en rétablissant le fonctionnement des services critiques dans les meilleurs délais.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Éviter les altérations non justifiées des informations.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Encourager la sensibilisation et la formation en sécurité et confidentialité des informations.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Impulser et contribuer à l’amélioration continue du système de gestion de la sécurité et de la confidentialité des informations.',
  cookiesPoliciesIntroText: 'Ce site web utilise des cookies pour améliorer l’expérience de navigation sur le site web. Vous pouvez changer ces paramètres à tout moment. Les cookies classés comme nécessaires sont stockés dans votre navigateur et sont essentiels pour le fonctionnement des fonctionnalités basiques du site web. Les cookies tiers seront stockés dans votre navigateur uniquement avec votre consentement. Ils nous aident à analyser et comprendre comment est utilisé ce site web. Vous pouvez choisir de ne pas recevoir ces cookies, toutefois l’exclusion volontaire de l’un de ces cookies peut entraîner l’indisponibilité de certaines fonctions. Pour plus d’informations sur la manière de supprimer les cookies, veuillez consulter la fonction aide de votre navigateur.',
  cookiesPoliciesNecessaryCookies: 'Cookies nécessaires',
  cookiesPoliciesNecessaryCookiesText: 'Ce type de cookies est absolument essentiel au bon fonctionnement du site web. Cette catégorie inclut uniquement des cookies qui garantissent des fonctionnalités de base et des caractéristiques de sécurité du site web, ils ne compilent ni ne stockent aucune information personnelle sur les utilisateurs du site web.',
  cookiesPoliciesUnnecessaryCookies: 'Cookies non nécessaires',
  cookiesPoliciesUnnecessaryCookiesText: 'Les cookies qui peuvent ne pas être particulièrement nécessaires au fonctionnement du site web et qui sont spécifiquement utilisés pour compiler des données personnelles de l’utilisateur par le biais d’analyse, de publicités et autres contenus intégrés sont appelés cookies non nécessaires. Il est obligatoire d’obtenir le consentement de l’utilisateur avant d’exécuter ces cookies sur le site web.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Cookies de Google Analytics',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Pour plus d’informations concernant ce type de cookies, vous pouvez consulter le document \u2018Utilisation des cookies de Google Analytics sur les sites web’.',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Maintenir des cookies actifs',
  cookiesNotificationBarText: 'Nous utilisons des cookies propres et tiers pour mesurer les visites, garantir la fonctionnalité du site web et analyser la navigation sur le site. Vous pouvez accepter les cookies ou configurer/refuser leur utilisation. Plus d’informations sur le propriétaire du site web, le responsable du traitement des cookies et ses données de contact dans notre',
  cookiesNotificationBarConfig: 'Configurer',
  cookiesPoliciesModalIntro: 'Introduction',
  cookiesPoliciesModalIntroP1: 'Ce site web utilise des cookies et/ou des technologies similaires qui stockent et récupèrent des informations quand vous naviguez pour améliorer et optimiser l’expérience utilisateur.',
  cookiesPoliciesModalIntroP2: 'En général, ces technologies peuvent servir à des finalités très diverses, par exemple reconnaître l’utilisateur, obtenir des informations sur ses habitudes de navigation, ou personnaliser l’affichage du contenu.',
  cookiesPoliciesModalIntroP3: 'Conformément au décret-loi royal 13/2012 et à son affectation dans l’article 22.2 de la loi sur les services de la société de l’information et le commerce électronique, vous êtes informé par la présente Politique des différents cookies utilisés par CUSTOMER COMMUNICATIONS TECKNALIA, S.L. dans les différents moyens ou canaux de communication, propres ou tiers, exemptés ou non du devoir d’informer, et des différents moyens permettant de les refuser, les autoriser ou les bloquer.',
  cookiesPoliciesModalIntroP4: 'Vous trouverez ci-dessous des informations détaillées sur : les \u2018cookies’, quelle typologie est utilisée sur ce site web, comment ils peuvent être désactivés sur un navigateur et comment bloquer spécifiquement l’installation de cookies tiers.',
  cookiesPoliciesModalResponsibility: 'Qui est le responsable de l’utilisation des cookies sur le présent site web ?',
  cookiesPoliciesModalResponsibilityP1: 'Le responsable de l’utilisation des cookies est CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (ci-après CCOMMS), titulaire du N.I.F. nº B-86414000 et dont le siège social est situé à l’adresse \u2018Leganés (Madrid), Avenida de la Recomba, 14’, dûment inscrite au Registre du commerce de Madrid au tome 29 801, folio 98, 8e section, feuille nº M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'Adresse électronique du délégué à la protection des données.',
  cookiesPoliciesModalWhatIsCookie: 'Qu’est-ce qu’un cookie ?',
  cookiesPoliciesModalWhatIsCookieP1: 'Un cookie est une donnée, un fichier ou un dispositif de stockage et de récupération des données (DARD), qu’un navigateur web stocke automatiquement sur l’appareil (smartphone, tablette ou smart TV) d’un utilisateur lorsqu’il accède à des pages web, comptes de réseaux sociaux ou quand il reçoit un courrier électronique.',
  cookiesPoliciesModalWhatIsCookieP2: 'Les cookies permettent de stocker et de récupérer automatiquement des données sur l’utilisation qui est faite des médias numériques cités et de l’équipement ou appareil depuis lequel on y accède.',
  cookiesPoliciesModalWhatIsCookieP3: 'Un cookie, ce sont des données qui sont stockées sur l’ordinateur de l’utilisateur, ce ne sont pas des logiciels, ni des fragments de code, ce sont de simples données. En principe, les cookies ne peuvent donc pas transmettre et exécuter un virus, ni installer un logiciel malveillant tel que des chevaux de Troie ou des logiciels espions.',
  cookiesPoliciesModalCookieTypes: 'Quels types de cookies sont utilisés sur le site web et dans quel but ?',
  cookiesPoliciesModalCookiesInstall: 'Quels cookies peuvent être installés sur le site de CertySign ?',
  cookiesPoliciesModalCookiesInstallP1: 'L’accès au site de CertySign implique l’installation des cookies, propres et/ou tiers, détaillés dans le tableau ci-dessous :',
  cookiesPoliciesModalBrowserConfigCookies: 'Comment configurer les cookies sur le navigateur ?',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Vous avez la possibilité de ne pas recevoir de cookies ou d’être informé de leur installation en paramétrant votre navigateur à cet effet. Tous les cookies, sauf ceux de Flash, peuvent être gérés sur votre navigateur, normalement dans la rubrique \u2018Options’ ou \u2018Préférences’.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Vous pouvez les désactiver tous, auquel cas votre navigateur n’autorisera aucune installation de cookies (ce qui bloquera certaines fonctions du site web), ou uniquement ceux qui viennent de certains sites, ce qui vous permettra de profiter des fonctionnalités avancées des sites qui respectent la confidentialité de vos données, et d’exclure ceux qui ne le font pas.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'Les liens suivants vous permettront d’accéder aux principales pages de support :',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'De même, l’utilisateur peut s’opposer à l’utilisation de certains cookies par l’intermédiaire des services suivants :',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloquer les cookies dans Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloquer les cookies dans Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloquer les cookies dans Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloquer les cookies dans Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'Que se passe-t-il si les cookies sont désactivés ?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Si vous refusez les cookies, vous pourrez continuer à naviguer sur notre site, toutefois l’utilisation de certains services pourra être limitée, ce qui pourrait rendre votre expérience moins satisfaisante.',
  cookiesPoliciesModalUnavailableCookiesP2: 'Le rejet, le blocage ou la non-autorisation des cookies décrits dans la présente Politique peut affecter une navigation correcte dans les contenus du site web.',
  cookiesPoliciesModalAcceptCookies: 'Acceptation de la Politique de cookies',
  cookiesPoliciesModalAcceptCookiesP1: 'En cliquant sur le bouton \u2018ACCEPTER COOKIES’, vous assumez que vous acceptez l’utilisation de cookies.',
  cookiesPoliciesModalAcceptCookiesP2: 'Cependant, à chaque ouverture de session, la Politique de cookies sera affichée en bas de la page pour que vous donniez votre consentement.',
  cookiesPoliciesModalUpgradeCookies: 'Notre Politique de cookies est-elle mise à jour ?',
  cookiesPoliciesModalUpgradeCookiesP1: 'Il est possible que les informations fournies dans la présente Politique de Cookies puissent être modifiées par des mises à jour ou des changements dans la politique de traitement des cookies ; de même, l’Agence espagnole de protection des données pourrait dicter des instructions et/ou recommandations de modification de cette Politique.',
  cookiesPoliciesModalUpgradeCookiesP2: 'C’est pourquoi nous vous recommandons de vérifier cette Politique chaque fois que vous accédez à notre site web dans le but d’être dûment informé sur la manière et la raison pour laquelle nous utilisons les cookies.',
  cookiesPoliciesModalRecipientCookies: 'Qui sont les destinataires des informations et comment l’utilisation des cookies affecte la protection des données à caractère personnel ?',
  cookiesPoliciesModalRecipientCookiesP1: 'Pour plus d’informations sur le traitement de vos données, vous pouvez consulter la',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Politique de protection des données',
  cookiesPoliciesModalContactInfoCookies: 'Informations de contact',
  cookiesPoliciesModalContactInfoCookiesP1: 'En cas de doute et pour toute explication et/ou suggestion concernant les cookies, nous vous prions de nous contacter par courrier électronique',
  cookiesPoliciesModalDatatableCookieType: 'Type de cookie',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Délai de traitement',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analytiques',
  cookiesPoliciesModalDatatableTypeSelf: 'Propres',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Chaque fois qu’un utilisateur visite un service, un outil d’un prestataire externe crée un cookie analytique sur l’ordinateur de l’utilisateur. Ce cookie, qui est généré uniquement lors de la visite, sera utile à CCOMMS lors de prochaines visites pour identifier le visiteur de manière anonyme.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permettre l’identification anonyme des utilisateurs navigateurs par l’intermédiaire du \u2018cookie’ (il identifie des navigateurs et appareils, non des personnes) et donc la comptabilisation approximative du nombre de visiteurs et sa tendance dans le temps. Identifier de manière anonyme les contenus les plus visités et les plus attrayants pour les utilisateurs. Savoir si l’utilisateur qui accède est nouveau ou s’il renouvelle sa visite. Sauf si l’utilisateur décide de s’inscrire chez CCOMMS, le \u2018cookie’ ne sera jamais associé à aucune donnée à caractère personnel permettant de l’identifier directement. Ces cookies seront uniquement utilisés à des fins statistiques qui aident à optimiser et améliorer l’expérience des utilisateurs sur le site.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistants',
  cookiesPoliciesModalDatatableTypeSelfD: 'Ce type de cookies permet d’élargir les informations affichées à chaque utilisateur anonyme dans les services de CCOMMS.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Entre autres, il stocke la durée ou la fréquence d’affichage des différentes sections, l’interaction avec les sections, ou les patrons de navigation et/ou comportement de l’utilisateur.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Persistants',
  eventInfo: 'Informations sur l’événement',
  manual: 'Manuel',
  guia: 'Guide',
  supportLoggedPhonePrefferedMessage: '(Support d’utilisateur déjà connecté - Préférence Téléphone)',
  supportLoggedNotPhoneMessage: 'Numéro de téléphone non tapé',
  supportLoggedUserMessage: '(Support d’utilisateur déjà connecté)',
  supportNotLoggedUserWebMessage: '(Login - Support via web) L’utilisateur demande de l’aide pour récupérer son mot de passe',
  supportNotLoggedUserNotAvailableMessage: 'Non disponible',
  supportNotLoggedUserC2CMessage: '(Login - Support via C2C) L’utilisateur demande de l’aide par téléphone pour récupérer son mot de passe',
  processA4NotSupported: 'Les documents doivent être au format A4, ne pas dépasser 389 pages et 15 Mb',
  cookiesGAMessage: 'Mesure interne avec Google Analytics. Ces cookies stockent un identifiant unique permettant de reconnaître l’utilisateur lors de futures visites sur le site web.',
  cookiesGAFGenericMessage: 'Mesure interne avec Google Analytics.',
  cookiesCookieMessage: 'Cookie',
  cookiesNecessaryCookieDescription: 'Ce cookie est nécessaire, il est utilisé pour établir la préférence de l’utilisateur concernant la politique de cookies.',
  documentHtmlRule: 'Type de fichier accepté - HTML',
  rulesFilesSizePostal: 'Le fichier doit peser au maximum 15 Mb',
  rulesFilesSizeEmail: 'Le fichier doit peser au maximum 12 MB',
  rulesFilesSizeMultiple: 'La taille maximale totale des fichiers est 12 MB',
  rulesFilesSizeHtml: 'Le fichier doit peser au maximum 1 Mb',
  rulesFilesExtensionHtml: 'Le fichier doit être au format HTML',
  group_cannot_be_empty: 'Le groupe :group_name: ne peut pas être vide',
  language: 'Langue',
  defaultLanguage: 'Langue par défaut',
  transactionDownloadPee: 'Voir preuve numérique de remise de l’envoi.',
  transactionTracking: 'Suivi d’envoi',
  documentOrder: 'Établir un classement de documents',
  yes: 'Oui',
  no: 'Non',
  notifications: 'Notifications',
  activateNotifications: 'Activer',
  deactivateNotifications: 'Désactiver',
  selectCategoryNotifications: 'Sélectionner action pour notifications',
  activateCategoryNotifications: 'Activer toutes',
  deactivateCategoryNotifications: 'Désactiver toutes',
  customCategoryNotifications: 'Personnaliser',
  enabledNotifications: 'Notifications activées',
  disabledNotifications: 'Notifications désactivées',
  customNotifications: 'Notifications personnalisées',
  selectNotifications: 'Sélectionner configuration de notifications',
  enabledNotificationsDescriptionText: 'Chaque événement réalisé donnera lieu à une notification. Vous pourrez choisir de recevoir un e-mail pour chacune d’elles, ou un rapport quotidien avec les notifications groupées.',
  disabledNotificationsDescriptionText: 'Vous ne recevrez aucune notification en lien avec les statuts des envois effectués.',
  customNotificationsDescriptionText: 'Vous pourrez sélectionner des procédures pour lesquelles vous souhaitez recevoir des notifications concernant les statuts, et d’autres pour lesquelles vous ne souhaitez pas en recevoir.',
  enabledNotificationsConfigurationText: 'Configuration',
  enabledNotificationsConfigurationRealTime: 'Notifications en temps réel',
  enabledNotificationsConfigurationGrouped: 'Notifications groupées',
  selectEnabledNotifications: 'Sélectionner un type de notifications',
  enabledNotificationsRealTimeDescriptionText: 'Vous recevez un e-mail instantané pour chaque événement effectué.',
  enabledNotificationsGroupedDescriptionText: 'Vous recevrez un e-mail pendant l’horaire sélectionné avec le rapport de tous les événements effectués la veille.',
  enabledNotificationsScheduleRangeDescriptionText: 'Notifier pendant l’horaire suivant.',
  enabledNotificationsScheduleDescriptionText: 'Notifier uniquement pendant l’horaire suivant.',
  chooseNotificationsRange: 'Choisissez comment et quand vous souhaitez recevoir les notifications.',
  enabledNotificationsCustomText: 'Personnalisation des notifications',
  realTimeNotificationsDescription: 'Les notifications sont envoyées en temps réel. Pour chaque événement effectué, un e-mail est envoyé avisant de son statut.',
  groupedNotificationsDescription: 'Les notifications sont envoyées avec un rapport contenant une liste de toutes les transactions et le dernier événement enregistré pour chacune d’elles, pendant l’horaire sélectionné.',
  groupedNotificationsReportTime: 'À quelle heure souhaitez-vous recevoir le rapport',
  languageAndTimezone: 'Langue',
  defaultTimeZone: 'Plage horaire par défaut',
  user_process_notifications_updated: 'Notifications à jour',
  changeSectionConfirmation: 'Changements en attente d’enregistrement',
  changeSectionConfirmationText: 'Vous avez effectué des changements qui n’ont pas encore été enregistrés. Si vous souhaitez les enregistrer, cliquez sur',
  discard: 'Refuser',
  cost_center_updated: 'Centre de coût mis à jour avec succès',
  loginInfo: 'Information de démarrage de session',
  lastLoginSuccess: 'Dernier démarrage de session',
  lastLoginError: 'Échec de la dernière tentative d’accès',
  noRecord: 'Sans registre',
  charLimitLength: 'Le champ doit contenir {limit} caractères ou moins',
  requiredLastName: 'Nom requis',
  optional: 'Facultatif',
  availableVariables: 'Variables disponibles',
  twoFactorText: 'L’identifiant requiert un double facteur d’authentification Pour démarrer une session, saisissez le code que vous trouverez sur votre application de gestion des accès multifacteur.',
  mfa_configuration_required: 'L’identifiant requiert un double facteur d’authentification Pour le configurer, lisez le code QR dans votre application de gestion des accès multifacteur. Ensuite, saisissez le code dans la case du bas',
  code: 'Code',
  codeRequired: 'Le code est requis pour démarrer une session',
  mfa_code_invalid: 'Le code n’est pas bon',
  communicationContent: 'Contenu de la communication',
  uploadFiles: 'Joindre des documents',
  modifyFiles: 'Modifier des documents',
  fileResume: '{nfiles} fichier(s) ({size})MB au total',
  moreThan1City: 'Le code postal correspond à plusieurs villes, consultez le menu déroulant ou éditez le champ à la main.',
  shipmentDataSectionDescription: 'Les contenus de la communication sont ajoutés dans cette section. Les documents doivent être au format PDF et de dimension A4',
  zipCodeFormat: 'Vous devez saisir un code postal valide',
  deleteRecipient: 'Supprimer ce destinataire',
  mobileAddRecipientDescription: 'Plusieurs destinataires ?',
  errorFilesTotalSizeExceeded: 'La taille totale des fichiers ne peut pas dépasser 15 MB',
  fileExtensionNotAccepted: 'L’extension du fichier n’est pas valide. (Les extensions acceptées sont= {acceptedExtensions})',
  nameAndSurnameLength: 'Le prénom et le nom ne peuvent pas dépasser 99 caractères',
  batchModeTitle: 'Envoi par lots',
  batchModeCSVStep: 'Destinataires',
  batchModeAttachmentsStep: 'Fichiers requis',
  batchModeDownloadSampleCSV: 'Télécharger le fichier d’exemple',
  batchModeExtraCSVStepIntroduction: 'Vous devez d’abord télécharger un fichier <strong>CSV</strong> ou <strong>XLS</strong> avec les données des envois. Vous pouvez télécharger un exemple de fichier comprenant les champs nécessaires ci-dessous.',
  batchModeExtraCSVStepIntroduction2: 'Une fois le fichier préparé, vous pouvez l’ajouter en cliquant dessus ou en le faisant glisser dans la zone prévue à cet effet. N’oubliez pas que le fichier <strong>ne peut pas contenir de lignes vides ou d’erreurs</strong> dans aucun des destinataires.',
  batchModeExtraCSVStepIntroduction3: 'Une fois la structure du fichier validée, le bouton <strong>Continuer</strong> sera activé et vous pourrez poursuivre le processus.',
  batchModeExtraFilesStepIntroduction: 'À cette étape, les ressources nécessaires pour effectuer l’envoi à chaque destinataire seront ajoutées.<br><br>Un total de <b>{count}</b> fichiers a été détecté. Vous pouvez les ajouter en cliquant dessus ou en les faisant glisser dans la zone prévue à cet effet.<br><br>N’oubliez pas que la somme des fichiers de chaque envoi ne peut pas dépasser <b>{fileSizeLimit} Mo</b> et que, de manière générale, l’envoi ne peut pas dépasser <b>{totalSizeLimit} Go</b>.',
  extraFilesTableHeaderFileName: 'Nom du fichier',
  extraFilesTableHeaderStatus: 'Statut',
  extraFilesTableStatusNotFound: 'Téléchargement en attente',
  extraFilesTableStatusProcessing: 'En cours de traitement',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Traitement terminé',
  extraFilesTableHeaderActions: 'Actions',
  batchModePreviewStep: 'Prévisualiser et envoyer',
  batchModeStep1RemoveFile: 'Supprimer le fichier',
  batchModeCSVTableTitle: 'Le fichier <strong>{filename}</strong> contient <strong>{nOfRecipients}</strong> destinataires.',
  batchModeCSVTableSubtitle: 'Veuillez trouver ci-dessous un aperçu des premiers destinataires du fichier, puis cliquez sur « Afficher plus » pour plus d’informations.',
  batchModeCSVTableSubtitleNoData: 'Le fichier sélectionné ne contient aucun destinataire.',
  batchModeNoDataTitle: 'Aucun fichier sélectionné. Une fois ajouté, vous aurez les premiers destinataires du fichier.',
  dropZoneText: 'Cliquez ou faites glisser vos fichiers ici',
  dropZoneTextSingle: 'Cliquez ou faites glisser votre fichier ici',
  batchModeDialogTitle: 'Ajouter des destinataires',
  batchModeDialogManualColTitle: 'Manuellement',
  batchModeDialogManualColDescription: 'Si vous souhaitez effectuer un ou plusieurs envois individuellement, veuillez choisir cette option.',
  batchModeDialogBatchColTitle: 'Envoi par lots',
  batchModeDialogBatchColDescription: 'Si vous souhaitez effectuer des envois facilement à l’aide d’un fichier csv ou xls avec plusieurs destinataires et documents, choisissez cette option.',
  batchModeDialogBatchBtnManual: 'Envoi individuel',
  batchModeDialogBatchBtnBatch: 'Envoi par lots',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> fichiers en attente d’ajout',
  batchModeResourceTableAllFilesAdded: 'Tous les fichiers nécessaires ont été ajoutés',
  // batchModeResourceTableStatus : 'Statut actuel= <b>$currentFiles$</b>/$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Cliquez pour voir plus de détails',
  batchModeResourceTableFilenameText: 'Nom du fichier',
  batchModeResourceTableFoundOnNRows: 'destinataires concernés',
  batchModeResourceTableErrorFilenameInvalid: 'Vous avez essayé d’ajouter un ou plusieurs des fichiers qui ne correspondent pas aux fichiers demandés.',
  batchModeResourceTableErrorFileSizeLimit: 'La taille du fichier est supérieure à {size}Mo',
  batchModeResourceTableErrorTotalFileSizeLimit: 'Le total de fichiers téléchargés ne peut pas dépasser la taille de {size}Go',
  batchModeResourceTableInfoFileReplaced: 'Le fichier {filename} a été remplacé.',
  seeMore: 'Afficher plus',
  seeLess: 'Afficher moins',
  pending: 'En attente',
  batchModePreviewText: 'Une fois le traitement effectué, vous pourrez visualiser les transactions dans la vue correspondante et vous recevrez un e-mail avec les informations de chaque envoi, ainsi que l’identifiant de transaction associé à chaque destinataire.',
  batchModePreviewText1: 'Si l’un des envois ne peut pas être effectué, l’e-mail précisera lesquels d’entre eux n’ont pas été traités en raison de l’erreur.',
  csvError: 'Le fichier n’a pas pu être traité',
  csvErrorDetailStructure: 'La structure du fichier est incorrecte.',
  csvErrorDetailIncorrectFields: 'Les champs ne correspondent pas aux données nécessaires pour le processus',
  csvErrorDetailIncorrectExtensions: 'Il existe des extensions de fichier non valides aux lignes suivantes :',
  batchModeStep2SpaceLeft: 'Espace utilisé',
  batchModeStep2PendingFiles: 'Fichiers en attente',
  launchDataKoBatchMode: 'Les données fournies sont invalides',
  launchDataKoBatchModeStep1: 'Aucun fichier sélectionné',
  launchDataOkBatchModeStep1: 'La structure du fichier est correcte, vous pouvez poursuivre le processus',
  launchDataKoBatchModeStep2: 'Ajoutez les fichiers nécessaires',
  launchDataOkBatchModeStep2: 'Les fichiers nécessaires ont été ajoutés, vous pouvez poursuivre le processus',
  launchDataOkBatchMode: 'Les données sont correctes, vous pouvez poursuivre le processus',
  batchModeProcessLaunchedStep1: 'Démarrage du processus d’enregistrement',
  batchModeProcessLaunchedStep2: 'Téléchargement des ressources nécessaires pour les envois',
  batchModeProcessLaunchedStep3: 'Téléchargement du fichier des destinataires',
  batchModeProcessLaunchedStep4: 'Processus d’enregistrement terminé',
  transactionsProcessedBatchMode: 'Envoi par lots enregistré avec succès',
  transactionProcessingBatchMode: 'Envoi par lots en cours de traitement...',
  transactionProcessingBatchModeError: 'Échec du traitement de l’envoi par lots',
  transactionProcessingBatchModeErrorDescription: 'Une erreur s’est produite lors du traitement par lots, veuillez contacter l’assistance.',
  csvErrorDialogDescription: 'N’oubliez pas que vous devez télécharger un fichier <b>CSV</b> ou <b>XLS</b> et qu’il <strong>ne peut pas contenir de lignes vides ou d’erreurs</strong> pour aucun des destinataires.',
  extraFileError: 'Impossible d’ajouter le fichier',
  extraFileErrorDialogDescription: 'N’oubliez pas que la limite de taille par transaction est de <b>{fileSizeLimit} Mo</b> et que la taille maximale de l’envoi du lot est de <b>{totalSizeLimit} Go</b>.',
  extraFileErrorTransactionSizeLimit: 'La taille maximale de l’envoi a été dépassée dans les enregistrements suivants= {rows}',
  htmlEditorInfoParagraph1: 'À cette étape, le contenu de l’e-mail qui sera envoyé à chacun des destinataires sera personnalisé.',
  htmlEditorInfoParagraph3: 'Vous pouvez sélectionner un mode d’édition :',
  htmlEditorInfoBullet1: '<span>Sélection de modèle</span> Sélectionnez un modèle et effectuez les ajustements nécessaires depuis l’éditeur.',
  htmlEditorInfoBullet2: '<span>HTML personnalisé</span>. Téléchargez un fichier HTML personnalisé et effectuez les ajustements nécessaires depuis l’éditeur.',
  htmlEditorButtonUploadFile: 'TÉLÉCHARGER UN FICHIER HTML',
  htmlEditorDragAndDrop: 'Cliquez ici ou faites glisser les pièces jointes vers l’e-mail.',
  batchModeExtraCSVStepIntroductionVariables: 'N’oubliez pas que les variables suivantes peuvent être utilisées dans les champs de transaction :',
  batchModeContactId: 'Référence du destinataire',
  batchModeContactName: 'Prénom du destinataire',
  batchModeContactSurname: 'Nom du destinataire',
  htmlEditorTemplateSelected: 'Modèle sélectionné',
  htmlSubjectError: 'Le objet a plus de <span>{subjectLimit}</span> caractères',
  htmlEditorInfoParagraph4: 'Nous vous rappelons que la taille maximale des fichiers est de <b>{totalSizeLimit} Mo</b>.',
  backToProcess: 'Retour aux processus',
  infoAndHelp: 'Informations et aide',
  modelEmailHtmlTemplateSelector: 'Sélectionner un modèle',
  modelEmailEmailBodyNotHtml: "Le fichier sélectionné n’est pas un fichier html",
  templateSelectorDefaultTemplate: 'Par défaut',
  templateSelectorCustomTemplate: 'Personnalisée',
  templateSelectorNoLogoTemplate: 'Sans logo',
  filesExcedingSizeLimit: 'Fichiers excédant la taille autorisée',
  emailAttachmentsSectionTitle: 'Fichiers joints à l’e-mail',
  emailContent: 'Contenu de l’e-mail',
  emailSubject: 'Objet de l’e-mail',
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  filePasswordProtectedOrDamage: " Fichier invalide. Vérifiez que le fichier n'est pas endommagé ou protégé par mot de passe et réessayez",
  backLostChangesHTMLText: "Si vous revenez, vous perdrez les modifications apportées au modèle d'email ainsi que les fichiers joints. <br> Êtes-vous sûr de vouloir <b>retourner</b> à l'étape précédente ?",
  backLostChangesHTMLConfirmBack: "Retourner à l'étape précédente",
  backLostChangesHTMLConfirmContinue: "Continuer avec l'envoi",
  processStepperDigitalSignatureConfigurationTitle: 'Configuration',
  processStepperDigitalSignatureConfigurationDescription: 'Personnalisation de la cérémonie de signature',
  processStepperDigitalSignatureSignersTitle: 'Signataires',
  processStepperDigitalSignatureSignersDescription: 'Configuration des signataires et expérience de signature',
  processStepperDigitalSignatureDocumentsTitle: 'Documents',
  processStepperDigitalSignatureDocumentsDescription: 'Sélection des documents et interaction des signataires',
  processStepperDigitalSignatureSummaryTitle: 'Confirmation et Soumission',
  processStepperDigitalSignatureSummaryDescription: 'Révision et confirmation de la cérémonie de signature',
  transactionName: 'Nom de la transaction',
  transactionInterveners: 'Intervenants de la transaction',
  more: 'plus',
  finished: 'Terminée',
  eventsInfo: 'Informations sur les événements',
  transactionInvalidAlert: 'Lors de la vérification des données d\'entrée de la transaction, des erreurs possibles ont été détectées qui empêcheraient son exécution correcte. La transaction a été bloquée préventivement. Vous devez lancer une nouvelle transaction avec les bonnes données d\'entrée.',
  transactionEntryData: 'Données d\'entrée de la transaction',
  goToSignature: 'Aller à la signature',
  intervenersNotDetected: 'Aucun intervenant détecté',
  transactionEventsRegistered: 'Événements enregistrés dans la transaction',
  aboutEvents: 'À propos des événements',
  notEvents: 'Aucun événement n\'a été enregistré dans la transaction.',
  notEventsRegistered: 'Aucun événement n\'a été enregistré dans la transaction. Si la transaction a été créée récemment, il est possible qu\'elle soit encore en cours de génération.',
  downloadCertificate: 'Télécharger le certificat',
  notValid: 'Non valide',
  transactionPending: 'En cours',
  refresh: 'Rafraîchir',
  creation: 'Création',
  transactionDigitalSignatureInfo: 'Informations sur la transaction de signature numérique',
  reminders: 'Rappels',
  input: {
    clear: 'Effacer'
  },
  intervenerStartCeremony: 'Pour commencer le tour de signature de cet intervenant, un nouvel onglet s\'ouvrira. N\'oubliez pas que si la transaction comporte un ordre de signature, les intervenants précédents doivent avoir complété les documents.',
  details: 'Détails',
  front_renderer_builder_transaction_opened: 'Transaction de signature visitée',
  front_renderer_builder_transaction_finished: 'La transaction de signature a été complétée par tous les intervenants',
  front_renderer_builder_intervener_signed: 'Un intervenant a complété la signature des documents associés',
  front_renderer_builder_transaction_created: 'La transaction de signature a été créée avec succès',
  front_renderer_builder_transaction_invalid: 'La transaction de signature contient des erreurs et n\'a pas pu être créée correctement',
  front_renderer_builder_intervener_finished: 'L\'intervenant a complété toutes les signatures',
  certificate_builder_generated: 'Le certificat de finalisation de la transaction a été généré, qui contient le contenu de la communication et les preuves associées aux événements survenus dans le processus.',
  transactionFilesParagraph: 'Liste des fichiers contenus dans la transaction',
  transactionDigitalProcessed: 'Transaction "{transactionName}" traitée avec succès',
  signatureType: 'Type de signature',
  faceToFace: 'En personne',
  remote: 'À distance',
  transactionWarning: 'Avec des erreurs',
  cancelTransaction: 'Annuler la transaction',
  identifyTransactionPlataformCommunications: 'Identifier la transaction sur la plateforme et dans les communications',
  placeholderTransactionName: 'Nom de la transaction',
  determineMethodAccessSigning: 'Déterminer la méthode d\'accès à la cérémonie de signature',
  transactionType: 'Type de transaction',
  descriptionRemoteSignature: '<p><b>La signature électronique à distance</b> permet aux signataires d\'accéder à la cérémonie de signature depuis n\'importe où et sur n\'importe quel appareil grâce aux canaux de communication disponibles.</p>',
  descriptionPresentialSignature: '<p><b>La signature en personne</b> permet aux signataires d\'accéder depuis l\'appareil utilisé pour lancer la transaction.</p>',
  setSignatureReminders: 'Définir des rappels pour accéder à la cérémonie de signature',
  transactionReminder: 'Rappels de la transaction',
  transactionRedirectToTransactionDetail: 'Voir la transaction',
  transaccion_cancelada: 'Transaction annulée',
  cancelled: 'Annulée',
  digital_certificate: 'Cert.',
  cert: 'Cert.',
  nif: 'ID/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Identification par document',
  ama: 'AMA Portugal',
  lastUpdate: 'Dernière mise à jour',
  cancelTransactionAdvertisement: 'L\'annulation de la transaction n\'est pas réversible. N\'oubliez pas que le processus d\'annulation peut prendre quelques minutes. Êtes-vous sûr de vouloir annuler la transaction ?',
  transaction_cancelled: 'Transaction annulée',
  transaction_not_cancelled: 'La transaction n\'a pas pu être annulée',
  signed: 'Signature complétée',
  addReminder: 'Ajouter un rappel',
  sendingNewReminder: 'Ajoutez un rappel, en établissant les jours qui doivent passer entre le premier envoi ou le rappel précédent',
  confirm: 'Confirmer',
  sinceCreation: 'depuis la création',
  sinceReminderNumber: 'depuis le rappel n°',
  reminderLimitReached: 'Vous avez atteint la limite de rappels',
  reminderLimitReachedZero: 'Aucun rappel ne peut être ajouté',
  customTextComplementSignatureAccessInformation: 'Texte informatif inclus dans les e-mails d\'accès à la signature',
  globalMessageInterveners: 'Message global aux intervenants',
  exampleInformSignatory: 'Entrez le contenu du message',
  days: 'jours',
  infoReminder: '<p>Les rappels de la cérémonie de signature sont des communications envoyées aux signataires après la création. La limite est déterminée par l\'expiration de la transaction ou, le cas échéant, par les limites du processus lui-même.</p>',
  infoReminderDays: '<p>Les jours indiqués dans le premier rappel seront comptés <b>à partir de la création de la transaction</b>, tandis que les jours indiqués dans les rappels suivants seront comptés à partir de l\'envoi du rappel précédent.</p>',
  determineDaysSigningProcessRemainActive: 'Définissez les jours pendant lesquels la cérémonie de signature restera active',
  transactionExpiration: 'Expiration de la transaction',
  orderOfSignature: 'Ordre de signature',
  signatoriesAdded: 'ajoutés à la transaction',
  infoTransactionWithoutSignatures: 'La transaction n\'a pas encore de signataires. Vous pouvez commencer à les ajouter à partir du bouton ci-dessous.',
  intervenersLimitReached: 'Vous avez atteint la limite de signataires',
  addSigner: 'Ajouter un signataire',
  signerEdition: 'Configuration du signataire',
  nameSurname: 'Nom et prénom / Raison sociale',
  placeholderSignerName: 'Jean Exemple Exemple',
  identificationType: 'Type d’identification',
  withoutIdentification: 'Sans identification',
  securitySignature: 'Méthode de confirmation',
  shippingChannel: 'Canal d\'envoi',
  completetionEmail: 'Email de finalisation',
  personalizedMessage: 'Message personnalisé de l\'email initial',
  numberDocument: 'N° Document',
  contactMethod: 'Méthode de contact',
  notIndicated: 'Non indiqué',
  identificationSignature: 'Identification/Signature',
  documents: 'Document/s',
  infoTransactionWithoutDocuments: 'La transaction n\'a pas encore de documents. Vous pouvez commencer à les ajouter depuis le bouton ci-dessous.',
  documentsLimitReached: 'Vous avez atteint la limite de documents',
  addDocument: 'Ajouter un document',
  requirementsAddDocument: 'Vous devez prendre en compte que les documents doivent respecter les exigences suivantes :',
  addDocumentError: 'Le document n\'a pas pu être ajouté',
  documentNameRepeated: 'Le nom du document que vous essayez d\'ajouter existe déjà dans la transaction.',
  documentDetail: 'Détails du document',
  documentActions: 'Actions',
  withoutActions: 'Sans actions',
  positionSignature: 'Positionner les signatures',
  deleteDocument: 'Supprimer le document',
  size: 'Taille',
  totalFileSizeExceeded: 'La taille totale des fichiers téléchargés ne peut pas dépasser {size&Unit}',
  minCharacters: 'Minimum {min} caractères',
  maxCharacters: 'Maximum {max} caractères',
  expirationDays: 'Doit être supérieur à 0 et inférieur à {max} jours',
  expirationMustBeLongerThanReminders: 'La durée d\'expiration doit être supérieure aux rappels',
  reminderRuleAvailableDaysInput: 'Doit être supérieur à 0 et inférieur à {max} jours',
  remoteDigitalSignature: 'Signature électronique à distance',
  presentialSignature: 'Signature en personne',
  none: 'Aucune',
  noneMasc: 'Aucun',
  certificateDigital: 'Certificat numérique',
  alertAbandonProcess: 'Si vous quittez le processus maintenant, vous perdrez les données saisies. Êtes-vous sûr de vouloir sortir ?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Signature graphométrique',
  signature: 'Signature',
  documentSignatures: 'Signatures du document',
  closeDocument: 'Fermer le document',
  selectSignersActions: 'Sélectionnez un signataire pour connaître les actions disponibles sur le document',
  sendSignedDocuments: 'Envoyer des documents signés',
  notSendSignedDocuments: 'Ne pas envoyer de documents signés',
  documentLimitSizeAlert: 'La taille du document ne peut pas dépasser 12 MB',
  documentFormatAlert: 'Les documents doivent être au format PDF et ne doivent pas être cryptés ou corrompus',
  documentLimitCountAlert: 'La limite de documents pour la transaction est de ',
  signersAdded: 'Signataire/s',
  sendTransaction: 'Envoyer la cérémonie de signature',
  sendTransactionInfo: 'En haut, comme un résumé, vous avez les données saisies lors de la configuration. Une fois révisées, vous pouvez procéder à l\'envoi en cliquant sur le bouton ci-dessous',
  transactionOkMessage: 'La transaction a été traitée avec succès ; dans quelques minutes, vous pourrez voir les événements liés à celle-ci dans la vue "Détails de la transaction". Vous pouvez y accéder en cliquant sur le bouton ci-dessous',
  transactionsOkMessage: 'Les transactions ont été traitées avec succès ; dans quelques minutes, elles seront visibles dans la vue "Transactions". Vous pouvez y accéder en cliquant sur le bouton ci-dessous',
  goToPresentialCeremony: 'Démarrer la cérémonie de signature en personne',
  interactionType: 'Type d’interaction',
  signDocuments: 'Signer des documents',
  acceptDocuments: 'Accepter des documents',
  requireCompleteReading: 'Lecture complète du document',
  availableActions: 'Actions disponibles',
  signerAcceptDocument: 'Le signataire doit accepter ce document',
  signaturesNotAvailable: 'Les signatures ne sont pas disponibles si le signataire a activé l\'"acceptation des documents"',
  selectSigners: 'Sélectionner des signataires',
  selectSignersDescription: 'Sélectionnez les signataires de la transaction qui doivent participer au document',
  action: 'action',
  singleClickSignatureInfoText: 'L\'acceptation du document a été activée, il sera donc signé d\'un seul clic.',
  requireScrollToBottomInfoText: 'La lecture complète du document est activée',
  page: 'Page',
  interveners: 'Intervenants',
  "face-to-face": 'En personne',
  expiration: 'Expiration',
  signatureStatus: 'État de signature',
  remaining: 'Restant/s',
  completed: 'Complété',
  contact: 'Contact',
  events: 'événement/s',
  notSpecified: 'Non spécifié',
  intervener: 'Intervenant',
  reference: 'Référence',
  expired: 'Expirée',
  documentViewerDialogSubtitle: 'Sélectionnez les signataires qui interagiront avec le document, ainsi que les actions à effectuer',
  front_renderer_builder_intervener_identification_success: 'L\'intervenant a été identifié avec succès dans la cérémonie de signature',
  front_renderer_builder_signature_otp_generated: 'Le code d\'accès à la transaction a été généré',
  front_renderer_builder_signature_otp_sent: 'Le code d\'accès à la transaction a été envoyé au dispositif de l\'intervenant',
  front_renderer_builder_transaction_expired: 'La transaction n\'est plus accessible car elle a expiré'


}