<template>
  <v-text-field
    :id="`${customid}`"
    v-model="internalValue"
    :aria-autocomplete="autocomplete"
    :autocomplete="autocomplete ? 'on' : 'off'"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :rules="computedRules"
    :type="type"
    :height="height"
    dense
    outlined
    v-on:focusout="$emit('focusout')"
    class="b2b-standard-text-input cts-color-black_soft_text"
  >
    <template v-slot:append>
      <slot name="menu" />
    </template>
  </v-text-field>
</template>

<script>
import vModelMixin from "@/mixins/v-model-mixin"
import const_global from "@/constants/global"

export default {
  name: "textInput",
  mixins: [ vModelMixin ],
  props: {
    customid: String,
    height: {
      default: 28,
      type: Number
    },
    maxLength: {
      default: 245,
      type: Number
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    autocomplete: Boolean,
    required: Boolean,
    requiredText: String,
    type: String,
    placeholder: String,
    rules: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    computedRules() {
      let rules = [...this.rules]

      if (this.type === 'email') {
        rules.push(v => !v || const_global.EMAIL_REGEX.test(v) || this.$vuetify.lang.t('$vuetify.requiredEmailFormat'))
      }

      if (this.type === 'international_phone') {
        rules.push(v => !v || const_global.PHONE_REGEX_WITHOUT_PREFIX.test(v) || this.$vuetify.lang.t('$vuetify.requiredPhoneFormat'))
      }

      if (this.type === 'phone') {
        rules.push(v => !v || const_global.PHONE_REGEX.test(v) || this.$vuetify.lang.t('$vuetify.requiredPhoneFormat'))
      }

      rules.push(v => (v || '').length <= this.maxLength || this.$vuetify.lang.t('$vuetify.charLimitLength').replace('{limit}', this.maxLength.toString()))

      if (this.required) {
        rules.push(v => v && !!v.trim() || this.requiredText)
      }
      return rules
    }
  }
}
</script>

<style lang="scss">
  .b2b-standard-text-input .v-text-field__details {
    display: inline-block !important;
    margin-bottom: 0 !important;
    padding-left: 4px !important;

    & .v-messages__message {
      color: var(--error) !important;
    }
  }

  .b2b-standard-text-input {
    input {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
</style>
