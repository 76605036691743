<template>
    <v-tabs
            v-if="checkMenu(false)"
            class="cts-tabs-menu-principal hidden-lg-and-down d-flex justify-center col-8"
            height="30"
    >
      <v-tab :id="`header-tab-${route.name}`"
              v-for="route in allRoutes"
              :key="route.title"
              :to="route.path"
              :tabindex="false"
      >
        {{ $vuetify.lang.t('$vuetify.' + route.name) }}
      </v-tab>
    </v-tabs>
  <div v-else-if="checkMenu(true)" class="cts-w-100 pt-16">
    <v-row dense class="ma-0 mt-4">
      <v-card-title class="cts-usermenu-name cts-w-100 px-2 pt-2 pb-2">
        <h3 class="col-lg-12 ma-0 pa-0">{{ name }}</h3>
        <small>{{ groupName }}</small>
      </v-card-title>
    </v-row>
    <v-row dense class="ma-0">
      <v-list  dense nav class="hidden-lg-and-up">
        <v-list-item-group>
          <v-list-item
                  v-for="route in allRoutes"
                  :key="route.title"
                  :to="route.path">
            <v-list-item-icon>
              <v-icon>{{const_icons[route.name.toUpperCase()]}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ $vuetify.lang.t('$vuetify.' + route.name) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>
  </div>
</template>

<script>
  import const_global from "@/constants/global"
  import const_icons from "@/constants/icons"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
  import { checkPermission } from "@/util/store_utils"

  export default {
    name: "MainMenu",
    components: {},
    props:{
      compact: Boolean
    },
    data() {
      return {
        routes: this.$router.options.routes,
        const_icons,
        const_global,
        AUTHENTICATION_STATE
      }
    },
    computed: {
      allRoutes() {
        const availableRoutes = []
        this.routes.forEach(element => {
          if (element.meta.navigable && this.checkPermission(element.name)) {
            availableRoutes.push(element)
          }
        })
        return availableRoutes
      },

      hasSubaccounts(){
        return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE] != ""
      },

      name() {
        return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_NAME]
      },

      groupName() {
        return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_NAME]
      }

    },
    methods: {
      checkMenu(compact){
        return this.hasSubaccounts && this.compact == compact
      },
      redirect(route) {
        this.$router.push(route)
      },
      checkPermission(name) {
        return checkPermission(const_global.SECTION + "_" + name)
      }
    }
  }
</script>

<style scoped>

</style>
