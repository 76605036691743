<template>
  <v-card flat :disabled="disabled"
          class="cts-h-100 cts-image-card pa-0 float-left d-block col-12"
          @click="executeItem(OPEN_ITEM, item.code)">
    <div class="d-flex flex-no-wrap justify-left">
      <v-avatar tile
                class="ma-1"
                size="90">
        <v-icon aria-hidden="false" class="cts-bgcolor-lighten material-icons-rounded" size="40" color="var(--primary) !important">{{iconCode}}</v-icon>
      </v-avatar>
        <div class="cts-process-card-content">
          <v-card-title class="cts-section-card-title pa-1 pb-0">
            {{item.name}}
          </v-card-title>
          <v-card-subtitle class="cts-section-card-subtitle pa-1 pr-3">
            {{item.description}}
          </v-card-subtitle>
        </div>
    </div>
  </v-card>
</template>

<script>

  import const_icons from "@/constants/icons"
  import { redirect } from "@/util/router_utils"
  import { GROUPS, USERS } from "@/constants/router/routes"

  const OPEN_ITEM = "items"

  export default {
    name: "itemsCard",
    props: {
      item: Object,
      disabled: Boolean
    },
    data() {
      return {
        OPEN_ITEM,
        const_icons,
        upperCode: '',
        iconCode: '',
        src: 'processes/images/' + this.$props.item.image + '.png',
      }
    },
    created() {
      switch (this.item.code) {
        case 'groups': this.iconCode = const_icons.GROUPS
          break
        case 'users': this.iconCode = const_icons.USERS
          break
        default: this.iconCode = ''
      }
    },
    methods: {
      executeItem(option,code) {
        if (option == OPEN_ITEM && code == 'groups') {
          redirect(GROUPS)
        }

        if (option == OPEN_ITEM && code == 'users') {
          redirect(USERS)
        }
      }
    }
  }
</script>

<style scoped>

</style>
