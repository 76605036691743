<template>
  <div v-if="list.length > 1">
    <draggable v-if="list.length > 1" v-model="list">
        <v-row v-for="(doc, _index) in list" :key="doc.fileName" dense class="ma-0 my-2" justify="space-between">
          <v-col class="pr-5">
            <v-row dense align="center">
              <v-col class="cts-button-tertiary-custom">
                <div class="text-center"> {{ _index + 1}} </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10">
            <v-row dense v-if="getExtensionFromName(doc) == 'pdf'" class="cts-button-tertiary-block pa-0">
              <v-btn class="cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word" small @click="handleOpenPDFpreview(doc)" block outlined>
                <v-row dense class="ma-0 pa-0">
                  <v-col cols="2" class="pr-7 text-center">
                    <v-icon small class="text-left">menu</v-icon>
                  </v-col>
                  <v-col cols="8" class="text-left">
                    <v-tooltip bottom v-if="doc.fileName.length > 28">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">
                          {{ doc.fileName.slice(0, 28) }}
                          <span v-if="doc.fileName.length > 28">...</span>
                        </span>
                      </template>
                      <p class="ma-0 pa-0">{{doc.fileName}}</p>
                    </v-tooltip>
                    <span v-else :id="`data_preview-detail_table-body-${index}-document_name_text`" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName }}</span>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-icon :id="`data_preview-detail_table-body-${index}-document_previsualization_icon`" class="float-right">
                      {{const_icons.PREVISUALIZATION}}
                    </v-icon>
                  </v-col>
                </v-row>
              </v-btn>
            </v-row>
            <v-row dense v-else>
              <v-btn class="cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word" small @click="openHTMLpreview(doc)" block outlined>
                <v-icon>menu</v-icon>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-row dense class="ma-0 pa-0">
                    <v-col cols="10" class="text-left">
                      <v-tooltip bottom v-if="doc.fileName.length > 26">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName }}</span>
                        </template>
                        <p class="ma-0 pa-0">{{doc.fileName}}</p>
                      </v-tooltip>
                      <span v-else class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName.toUpperCase() }}</span>
                    </v-col>
                    <v-col cols="2" class="text-right">
                      <v-icon class="float-right">
                        {{const_icons.PREVISUALIZATION}}
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
    </draggable>
    <div class="text-right cts-font-size-4">{{$vuetify.lang.t('$vuetify.documentOrder')}}</div>
  </div>
  <div v-else>
    <v-row v-for="doc in list" :key="doc.fileName" dense class="ma-0 my-2">
      <v-col cols="12">
        <v-row dense v-if="getExtensionFromName(doc) == 'pdf'" class="cts-button-tertiary-block pa-0">
          <v-btn class="cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word" small @click="handleOpenPDFpreview(doc)" block outlined>
            <v-row dense class="ma-0 pa-0">
              <v-col cols="10" class="text-left">
                <v-tooltip bottom v-if="doc.fileName.length > 28">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName }}</span>
                  </template>
                    <p class="ma-0 pa-0">{{doc.fileName}}</p>
                </v-tooltip>
                <span v-else :id="`data_preview-detail_table-body-${index}-document_name_text`" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName }}</span>
              </v-col>

              <v-col cols="2" class="text-right">
                <v-icon :id="`data_preview-detail_table-body-${index}-document_previsualization_icon`" class="float-right">
                  {{const_icons.PREVISUALIZATION}}
                </v-icon>
              </v-col>
            </v-row>
          </v-btn>
        </v-row>

        <v-row dense v-else>
          <v-btn class="cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word" small @click="openHTMLpreview(doc)" block outlined>
            <v-col cols="12" class="ma-0 pa-0">
              <v-row dense class="ma-0 pa-0">
                <v-col cols="10" class="text-left">
                  <v-tooltip bottom v-if="doc.fileName.length > 26">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName }}</span>
                    </template>
                    <p class="ma-0 pa-0">{{doc.fileName}}</p>
                  </v-tooltip>
                  <span v-else class="cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5">{{ doc.fileName.toUpperCase() }}</span>
                </v-col>
                <v-col cols="2" class="text-right">
                  <v-icon class="float-right">
                    {{const_icons.PREVISUALIZATION}}
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import const_icons from "@/constants/icons"
import { convertFileObjectToBackendObject } from "@/util/utils"

export default {
  name: "DraggableList",
  components: { draggable },
  props: {
    array: Array,
    index: Number
  },

  data() {
    return {
      const_icons,
      list: []
    }
  },

  async created() {
    if (this.array.some(x => typeof x.name == 'string')) {
      this.list = await convertFileObjectToBackendObject(this.array)
      this.list = Array.isArray(this.list) ? this.list : [this.list]
    }
  },

  methods: {
    getExtensionFromName(file) {
      return file.fileName.toLowerCase().split(".")[1]
    },

    emitChange() {
      this.$emit("emailAttachmentOrder", this.list)
    },

    handleOpenPDFpreview(doc) {
      this.$emit("openPDFPrev", doc)
    },

    openHTMLpreview(doc) {
      this.$emit("openHTMLpreview", doc)
    }
  },
  watch: {
    list: function (newVal) {
      this.$emit("emailAttachmentOrder", newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
  .cts-button-tertiary-custom {
    background-color: var(--lighten) !important;
    color: var(--darken) !important;
    border: 2px solid var(--lighten);
    box-shadow: none;
  }
</style>
