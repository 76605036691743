<template>
  <div>
    <v-card color="transparent" flat>
      <v-card-text class="pa-0 cts-color-darken cts-font-size-4">
        <h2 id="login-reset_password-support_method-text-description" class="cts-subtitle-recovery font-weight-regular cts-font-size-4 hidden-xs-only">
          {{ $vuetify.lang.t('$vuetify.rememberPasswordSupportWebText') }}
        </h2>
        <h2
          id="login-reset_password-support_method-text-servicehours"
          v-if="supportConfig.show_hours_email_support"
          class="cts-subtitle-recovery font-weight-regular cts-font-size-4 hidden-xs-only pt-2"
        >
          {{ $vuetify.lang.t('$vuetify.emailSupportServiceHours') }}
        </h2>
        <h3
          v-if="supportConfig.show_hours_email_support"
          id="login-reset_password-support_method-text-m_f_schedule"
          class="mt-2 cts-font-size-5 cts-subtitle-recovery"
        >
          <strong id="login-reset_password-support_method-text-m_f_schedule-title">{{ $vuetify.lang.t('$vuetify.rememberPasswordMondayToFriday') }} </strong> 8:00h - 22:00h
        </h3>
        <h3
          v-if="supportConfig.show_hours_email_support"
          id="login-reset_password-support_method-text-sat_sun_holidays_schedule"
          class="cts-font-size-5 cts-subtitle-recovery"
        >
          <strong id="login-reset_password-support_method-text-sat_sun_holidays_schedule-title">{{ $vuetify.lang.t('$vuetify.rememberPasswordSaturdaySundayAndOthers') }} </strong> 10:00h -
          22:00h
        </h3>
        <h3
          v-if="supportConfig.show_hours_telephone_support"
          id="support-text-schedule_phone_support"
          class="cts-font-size-5 mt-1 mb-3"
        >
          <strong>{{ $vuetify.lang.t('$vuetify.helpSupportPhoneSchedule') }}: </strong>
          {{ const_global.WORKING_DAY_START_TIME }}:00h -
          {{ const_global.WORKING_DAY_END_TIME }}:00h
        </h3>

        <v-form
          id="login-reset_password-support_method-form"
          ref="form"
          v-model="isValid"
          class="cts-form-login pt-1"
          :class="!supportConfig.show_hours_email_support ? 'pt-3': ''"
        >

          <v-text-field
            id="login-reset_password-support_method-form-input-username"
            outlined
            v-model="formData.username"
            name="username"
            autocomplete="on"
            :rules="nameRules"
            :prepend-inner-icon="const_icons.USER"
            :placeholder="$vuetify.lang.t('$vuetify.rememberPasswordContactName')"
            :aria-label="$vuetify.lang.t('$vuetify.rememberPasswordContactName')"
          />
          <v-text-field
            id="login-reset_password-support_method-form-input-email"
            outlined
            v-model="formData.email"
            name="email"
            autocomplete="on"
            :prepend-inner-icon="const_icons.ALTERNATIVEMAIL"
            :rules="emailRules"
            :type="'email'"
            :placeholder="$vuetify.lang.t('$vuetify.email')"
            :aria-label="$vuetify.lang.t('$vuetify.email')"
          />
          <v-text-field
            id="login-reset_password-support_method-form-input-phone"
            outlined
            v-model="formData.phone"
            name="phone"
            autocomplete="on"
            :rules="phoneRules"
            :prepend-inner-icon="const_icons.TELEPHONE"
            :placeholder="$vuetify.lang.t('$vuetify.telephone')"
            :aria-label="$vuetify.lang.t('$vuetify.telephone')"
          />

          <v-checkbox
            v-if="isPhoneSupportTime"
            class="my-0"
            v-model="phoneSupportPreference"
            :label="$vuetify.lang.t('$vuetify.helpSupportPhonePreffered')"
          />

          <v-row dense justify="center" class="mb-2">
            <vue-recaptcha
              v-if="supportConfig.show_captcha_support && isValid"
              :sitekey="apiKey"
              @verify="verifyCaptcha"
              @expired="captchaExpired"
            />

          </v-row>

          <v-expand-transition>
            <v-row v-if="errorMessage != null" dense class="ma-0 cts-login-error-message">
              <span id="login-reset_password-support_method-form-text-error_message" class="caption">{{ errorMessage }}</span>
            </v-row>
          </v-expand-transition>

          <v-btn
            v-if="supportConfig.show_captcha_support"
            id="login-reset_password-support_method-form-button-submit"
            :disabled="!(isValid && captchaIsCorrect)"
            :loading="loader"
            class="my-2 cts-button-login"
            @click="submitForm"
          >
            {{ $vuetify.lang.t('$vuetify.sendRecoveryForm') }}
          </v-btn>
          <v-btn v-else
            id="login-reset_password-support_method-form-button-submit"
            :disabled="!isValid"
            :loading="loader"
            class="my-2 cts-button-login"
            @click="submitForm"
          >
            {{ $vuetify.lang.t('$vuetify.sendRecoveryForm') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import { NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS } from "@/constants/vuex/global"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
  import const_icons from '@/constants/icons'
  import const_global from '@/constants/global'
  import { launchSupportMessage } from "@/services/supportServices"
  import { VueRecaptcha } from 'vue-recaptcha'
  import dayjs from "dayjs"

  export default {
    name: "SupportSection",
    components: { VueRecaptcha },

    props: {
      domain: String
    },

    data() {
      return {
        apiKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
        now: null,
        const_global,
        const_icons,
        isValid: false,
        errorMessage: null,
        loader: false,
        phoneSupportPreference: false,
        formData: {
          username: null,
          email: null,
          phone: null
        },

        emailRules: [
          v => const_global.EMAIL_REGEX.test(v) || this.$vuetify.lang.t('$vuetify.requiredEmail'),
        ],

        nameRules: [
          v => !!v || this.$vuetify.lang.t('$vuetify.requiredName')
        ],

        phoneRules: [
          v => !!v || this.$vuetify.lang.t('$vuetify.requiredPhone'),
          v => !v || const_global.PHONE_REGEX.test(v) || this.$vuetify.lang.t('$vuetify.requiredPhoneFormat')
        ],
        captchaIsCorrect: null

      }
    },

    methods: {
      async submitForm() {
        this.errorMessage = null
        if (this.isValid && this.captchaIsCorrect) {
          const payload = {
            name: this.formData.username,
            // email: method === 'web' ? this.formData.email : "No disponible",
            email: this.formData.email,
            company: this.domain,
            phone: this.formData.phone
          }

          await launchSupportMessage(payload, 'password', this.phoneSupportPreference)
          await this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, false)
          await this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_REMEMBER_SEND}`, true)
        }
      },


      verifyCaptcha(response) {
        this.captchaIsCorrect = response.length > 0
      },

      captchaExpired() {
        this.captchaIsCorrect = false
      }
    },

    created() {
      this.now = dayjs()
    },

    computed: {
      ...mapGetters({
        supportConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
      }),

      isPhoneSupportTime () {
        const isWorkingHours = this.now.get('hour') >= const_global.WORKING_DAY_START_TIME && this.now.get('hour') < const_global.WORKING_DAY_END_TIME

        return const_global.WORKING_WEEK_DAYS.includes(this.now.get("day")) && isWorkingHours
      },
    }
  }
</script>
