<template>
	<v-card flat class="cts-tab-item-content">
		<v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
			<h4><v-icon aria-hidden="false" left size="19" class="mr-1">{{icon}}</v-icon> {{title}}</h4>
		</v-card-title>
		<v-card-text class="cts-tab-content pa-0 px-sm-4">
          <p>{{$vuetify.lang.t('$vuetify.adminGroupInfoUsers')}}</p>
			<users-datatable :users="group.users" :group="group"></users-datatable>
		</v-card-text>
	</v-card>
</template>

<script>
  import UsersDatatable from "../../usersView/usersDatatable";

  export default {
    name: "adminUsersTable",
    components: { UsersDatatable },
    props: {
      icon: String,
      title: String,
      group: Object
    }
  }
</script>
