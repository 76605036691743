import Axios from "@/plugins/axios"
import { BATCH_UPLOAD }  from "@/constants/backOfficeRoutes"

export const getBatchId = async (processCode) => {
  const url = BATCH_UPLOAD.ROUTE_GET_BATCH_ID
    .replace("$process_code$", processCode)

  const res = await Axios.get(url)
  return res
}

export const uploadDescriptor = async (processCode, batchId, data) => {
  const url = BATCH_UPLOAD.ROUTE_UPLOAD_DESCRIPTOR
    .replace("$process_code$", processCode)
    .replace("$batch_id$", batchId)

  const res = await Axios.post(url, data)
  return res
}

export const closeProcess = async (processCode, batchId) => {
  const url = BATCH_UPLOAD.ROUTE_CLOSE_BATCH_UPLOAD
    .replace("$process_code$", processCode)
    .replace("$batch_id$", batchId)

  const res = await Axios.post(url)

  return res
}
