<template>
    <v-card class="pa-2 pa-sm-5 py-sm-4 cts-login-box cts-recovery-expand-form" width="390">
      <v-row dense class="ma-0 pa-0">
        <v-col cols="7" class="mt-0 mb-2 mx-0 pa-0 cts-login-box-title">
          <h1 id="login-text-reset_password-title" class="cts-font-size-2 font-weight-regular">{{$vuetify.lang.t('$vuetify.rememberPasswordTitleText')}}</h1>
        </v-col>
        <v-col cols="5" class="mt-0 mb-2 mx-0 pa-0 cts-login-box-title">
          <a id="login-link-reset_password-back_to_login" @click="goLogin()" v-if="!selectMethod" class="cts-color-primary-alternative cts-font-size-4 float-right py-0 text-right"><v-icon class="mr-1" color="var(--primary-alternative)" size="13" aria-hidden="false">{{const_icons.BACK}}</v-icon>{{ $vuetify.lang.t('$vuetify.goToLogin') }}</a>
          <a id="login-link-reset_password-selected_method-back" @click="goBack()" v-if="selectMethod && !rememberSend" class="cts-color-primary-alternative cts-font-size-4 float-right py-0 text-right"><v-icon class="mr-1" color="var(--primary-alternative)" size="13" aria-hidden="false">{{const_icons.BACK}}</v-icon>{{ $vuetify.lang.t('$vuetify.back') }}</a>
        </v-col>
      </v-row>
        <div v-if="!rememberSend">
            <div v-if="!selectMethod" class="cts-login-box-subtitle pa-0">
                <h2 id="login-text-reset_password-subtitle" class="font-weight-regular cts-font-size-4">{{$vuetify.lang.t('$vuetify.rememberPasswordSubTitleText')}}</h2>
            </div>
            <v-row v-if="!selectMethod" class="col-lg-12 ma-0 pa-0">
              <div class="col-12 ma-0 pa-0">
                <card-with-image
                  id="login-reset_password-email_method"
                  @click.native="chooseMethod('emailrecovery')"
                  mode="box-recovery"
                  :title="$vuetify.lang.t('$vuetify.rememberPasswordSupportEmail')"
                  :subtitle="$vuetify.lang.t('$vuetify.rememberPasswordSupportEmailSubtitle')"
                  :icon="const_icons.FORWARD_TO_INBOX"
                  dark
                  id-name="login-reset_password-method_email"
                />

                <card-with-image
                  v-if="supportConfig.show_email_support"
                  id="login-reset_password-web_support_method"
                  @click.native="chooseMethod('supportemail')"
                  mode="box-recovery"
                  :title="$vuetify.lang.t('$vuetify.helpSupport')"
                  :subtitle="$vuetify.lang.t('$vuetify.rememberPasswordSupportWebSubtitle')"
                  :icon="const_icons.CONTACT_MAIL"
                  dark
                  id-name="login-reset_password-method_web_support"
                />
              </div>
            </v-row>
            <v-row v-if="selectMethod" class="col-lg-12 ma-0 pa-0">
                <div class="col-12 ma-0 pa-0">
                    <div v-if="selectedMethod == 'emailrecovery'">
                        <v-card color="transparent" flat>
                            <v-card-text  class="pa-0 cts-color-darken cts-font-size-4">
                              <span id="login-text-reset_password-email_method-text-description">{{$vuetify.lang.t('$vuetify.rememberPasswordSupportEmailText')}}</span>
                                <v-form
                                  id="login-text-reset_password-email_method-form"
                                  ref="form"
                                  v-model="valid"
                                  class="cts-form-login"
                                >
                                  <v-text-field
                                    id="login-text-reset_password-email_method-form-input_username" outlined
                                    v-model="input.email"
                                    name="email"
                                    autocomplete="on"
                                    class="mt-4"
                                    :prepend-inner-icon="const_icons.USER"
                                    :rules="emailRules"
                                    :type="'email'"
                                    :placeholder="$vuetify.lang.t('$vuetify.user')"
                                    @keyup.enter="submit"
                                    :aria-label="$vuetify.lang.t('$vuetify.user')"
                                    aria-hidden="false"
                                  />

                                  <v-row v-if="supportConfig.show_captcha_support" justify="center" class="my-2">
                                    <vue-recaptcha
                                      v-if="valid"
                                      :sitekey="apiKey"
                                      @verify="verifyCaptcha"
                                      @expired="captchaExpired"
                                    />
                                  </v-row>

                                  <v-expand-transition>
                                    <v-row v-if="errorMessage != null" dense class="ma-0 cts-login-error-message">
                                      <span id="login-reset_password-email_method-form-text-error_message" class="caption">{{errorMessage}}</span>
                                    </v-row>
                                  </v-expand-transition>

                                  <v-btn
                                    v-if="supportConfig.show_captcha_support"
                                    id="login-text-reset_password-email_method-button-submit"
                                    :disabled="!(valid && captchaIsCorrect)"
                                    :loading="loader"
                                    class="my-2 cts-button-login"
                                    @click="submit"
                                  >
                                    {{ $vuetify.lang.t('$vuetify.sendRecoveryForm') }}
                                  </v-btn>

                                  <v-btn
                                    v-else
                                    id="login-text-reset_password-email_method-button-submit"
                                    :disabled="!valid"
                                    :loading="loader"
                                    class="my-2 cts-button-login"
                                    @click="submit"
                                  >
                                    {{ $vuetify.lang.t('$vuetify.sendRecoveryForm') }}
                                  </v-btn>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </div>
                    <SupportSection
                      v-if="selectedMethod == 'supportemail'"
                      :domain="$attrs.theme"
                    />
                    <div v-if="selectedMethod == 'supportphone'">
                      <v-card color="transparent" flat>
                        <v-card-text class="pa-0 cts-color-darken cts-font-size-4">
                          <h2
                            id="login-reset_password-phone_support_method-text-description"
                            class="cts-subtitle-recovery font-weight-regular cts-font-size-4 hidden-xs-only"
                          >
                            {{ $vuetify.lang.t('$vuetify.rememberPasswordSupportPhoneText') }}
                          </h2>
                          <h2
                            v-if="supportConfig.show_hours_telephone_support"
                            id="login-reset_password-phone_support_method-text-servicehours"
                            class="cts-subtitle-recovery font-weight-regular cts-font-size-4 hidden-xs-only"
                          >
                            {{ $vuetify.lang.t('$vuetify.phoneSupportServiceHours') }}
                          </h2>

                          <h3
                            v-if="supportConfig.show_hours_telephone_support"
                            id="login-reset_password-phone_support_method-text-m_f_schedule"
                            class="mt-2 mb-4 cts-font-size-5 cts-subtitle-recovery"
                          >
                            <strong id="login-reset_password-phone_support_method-text-m_f_schedule-title">{{ $vuetify.lang.t('$vuetify.rememberPasswordMondayToFriday') }} </strong> 8:00h - 17:00h. {{ $vuetify.lang.t('$vuetify.rememberPasswordNotIncludedDays') }}
                          </h3>
                          <v-form
                            id="login-reset_password-phone_support_method-form"
                            ref="form"
                            lazy-validation
                            v-model="valid"
                            class="cts-form-login"
                            :class="!supportConfig.show_hours_telephone_support ? 'pt-3': ''"
                          >
                            <v-text-field id="login-reset_password-phone_support_method-form-input-username" outlined
                              v-model="input.username"
                              name="username"
                              autocomplete="on"
                              :rules="nameRules"
                              :prepend-inner-icon="const_icons.USER"
                              :placeholder="$vuetify.lang.t('$vuetify.name')"
                              :aria-label="$vuetify.lang.t('$vuetify.name')"
                            />
                            <v-text-field id="login-reset_password-phone_support_method-form-input-phone" outlined
                              v-model="input.phone"
                              name="phone"
                              autocomplete="on"
                              :rules="phoneRules"
                              :prepend-inner-icon="const_icons.TELEPHONE"
                              :placeholder="$vuetify.lang.t('$vuetify.telephone')"
                              :aria-label="$vuetify.lang.t('$vuetify.telephone')"
                            />
                            <v-expand-transition>
                              <v-row v-if="errorMessage != null" dense class="ma-0 cts-login-error-message">
                                <span id="login-reset_password-phone_support_method-form-text-error_mesage" class="caption">{{errorMessage}}</span>
                              </v-row>
                            </v-expand-transition>
                            <v-btn id="login-reset_password-phone_support_method-form-button-submit" class="my-2 cts-button-login mx-0" @click="goSupport('phone')">
                                {{ $vuetify.lang.t('$vuetify.goToC2C') }}
                            </v-btn>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </div>
                </div>
            </v-row>
        </div>
        <div v-else>
          <div v-if="selectedMethod == 'emailrecovery'" class="cts-login-box-subtitle pb-4 col-3">
            <div class="cts-user-management-error-message">
              <span>{{ $vuetify.lang.t('$vuetify.rememberPasswordEmailResponse') }}</span>
            </div>
          </div>
          <div v-if="selectedMethod == 'supportemail'" class="cts-login-box-subtitle pb-4 col-3">
            <div class="cts-user-management-error-message">
              <span>{{ $vuetify.lang.t('$vuetify.rememberPasswordWebResponse') }}</span>
            </div>
          </div>
          <div v-if="selectedMethod == 'supportphone'" class="cts-login-box-subtitle pb-4 col-3">
            <div class="cts-user-management-error-message">
              <span>{{ $vuetify.lang.t('$vuetify.rememberPasswordPhoneResponse') }}</span>
            </div>
          </div>

          <v-btn
            class="my-4 cts-button-login"
            @click="goLogin()"
          >
            {{ $vuetify.lang.t('$vuetify.goToLogin') }}
          </v-btn>
        </div>
        <footer-view-terms :visible="details" :policies="policies"></footer-view-terms>
    </v-card>

</template>

<script>
    import const_icons from '@/constants/icons'
    import { redirect } from "@/util/router_utils"
    import CardWithImage from "../../structures/CardWithImage"
    import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
    import { NAMESPACE as LOCATION_NAMESPACE, STATE as LOCATION_STATE } from "@/constants/vuex/location"
    import FooterViewTerms from "../footer/footerViewTerms";
    import {ServerBus} from "../../../main";
    import { launchSupportMessage } from "@/services/supportServices"
    import { rememberPassword } from "@/services/userServices"
    import { LOGIN } from "@/constants/router/routes"
    import { mapGetters } from 'vuex'
    import { NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS } from "@/constants/vuex/global"
    import SupportSection from './SupportSection.vue'
    import { VueRecaptcha } from 'vue-recaptcha'

    export default {
      name: "RememberPasswordBox",
      components: {
        FooterViewTerms,
        CardWithImage,
        SupportSection,
        VueRecaptcha
      },
      data() {
        return {
          apiKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
          const_icons,
          valid: true,
          errorMessage: null,
          loader: false,
          selectMethod: false,
          selectedMethod: '',
          sent: false,
          legalCheckbox: false,
          policies: false,
          details: false,
          isSupportTime: false,
          input: {
            email: ""
          },
          emailRules: [
            v => !!v || this.$vuetify.lang.t('$vuetify.requiredEmail'),
            v => /.+@.+\..+/.test(v) || this.$vuetify.lang.t('$vuetify.requiredEmail')
          ],
          nameRules: [
            v => !!v || this.$vuetify.lang.t('$vuetify.requiredName')
          ],
          phoneRules: [
            v => !!v || this.$vuetify.lang.t('$vuetify.requiredPhone')
          ],
          checkRules: [
            v => !!v || ''
          ],
          captchaIsCorrect: false
        }
      },
      computed: {
        ...mapGetters({
          supportConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
        }),

        rememberSend() {
          return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_REMEMBER_SEND]
        },
        lang() {
          return this.$store.state[LOCATION_NAMESPACE][LOCATION_STATE.S_CURRENT_LOCATION]["code"].toUpperCase()
        }
      },
      created() {
        this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_REMEMBER_SEND}`, false)
        ServerBus.$on("closeModal", () => {
          this.details = false
          this.policies = false
        })
        this.getSupportTime()
      },
      methods: {
        goLogin() {
          redirect(LOGIN)
        },
        goBack() {
          this.selectedMethod = ''
          this.selectMethod = false
          this.errorMessage = null
          this.input = {}
        },
        submit() {
          let self = this
          self.errorMessage = null;
          self.loader = true

          if (self.$refs.form.validate()) {
            let data = {};
            data.email = self.input.email.toLowerCase();

            this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, true)
            rememberPassword(data).then(() => {
              this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, false)
              this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_REMEMBER_SEND}`, true)
            })

          } else {
            self.errorMessage = this.$vuetify.lang.t('$vuetify.toContinueFillFields')
            self.loader = false
          }

        },

        chooseMethod(method) {
          this.selectMethod = true
          this.selectedMethod = method
        },

        goSupport(method) {
          this.errorMessage = null;

          if (this.$refs.form.validate()) {
            this.loader = true
            let data = {};

            method === 'web' ? data.email = this.input.email : data.email = "No disponible"

            data.name = this.input.username;
            data.phone = this.input.phone;
            data.company = this.$attrs.theme.toUpperCase();

            launchSupportMessage(data, 'password', method !== 'web').then(() => {
              this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, false)
              this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_REMEMBER_SEND}`, true)
            })
            self.loader = false

          } else {
            self.errorMessage = this.$vuetify.lang.t('$vuetify.toContinueFillFields')
            self.loader = false
          }
        },
        getSupportTime() {
          const now = new Date();
          const day = now.getDay();
          const hour = now.getHours();

          // TODO: Crear festivos
          // const monthDay = now.getDate();
          // const month = now.getMonth() + 1;
          // const festivo = monthDay + '/' + month;
          // const festivos = ["1/1", "6/1", "1/5", "15/8", "12/10", "1/11", "6/12"];

          this.isSupportTime = (day >= 1 && day <= 5) && (hour >= 8 && hour < 17);
        },

        verifyCaptcha(response) {
          this.captchaIsCorrect = response.length > 0
        },

        captchaExpired() {
          this.captchaIsCorrect = false
        }
      }
    }
</script>

<style scoped>

</style>
