export const NAMESPACE = "vuex_user_account"

export const STATE = {
  S_CECO_DETAILS_DIALOG: "state_ceco_details_dialog"
}

export const MUTATIONS = {
  M_SET_CECO_DETAILS_DIALOG: "mutation_ceco_details_dialog"
}

export const ACTIONS = {
  A_SET_CECO_DETAILS_DIALOG: "action_ceco_details_dialog"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS
}
