const state = {
  backgroundImage: "/bg.jpg",
  loginMessage: "",
  theme: "light"
}


const mutations = {
  mutateTheme(state, theme) {
    state.theme = theme
  }

}

const getters = {
  getAppearance(state) {
    return state;
  }
}

const actions = {
  changeTheme({ commit }, theme) {
    commit("mutateTheme", theme)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
