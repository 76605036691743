export const NAMESPACE = "vuex_functionality"

export const STATE = {
  S_ALERT_MESSAGE_POPUP_OPEN: "state_alert_message_popup_open",
  S_ALERT_MESSAGE_POPUP_INFO: "state_alert_message_popup_info",
  S_ALERT_MESSAGE_POPUP_ERROR: "state_alert_message_popup_error",
  S_ALERT_MESSAGE_TERMS: "state_alert_message_terms",
  S_ALERT_SUPPORT_DIALOG_M: "state_alert_support_dialog_mobile",
  S_ALERT_SUPPORT_DIALOG_D: "state_alert_support_dialog_desktop",
  S_THEME: "state_theme"
}

export const MUTATIONS = {
  M_SET_ALERT_MESSAGE_POPUP_OPEN: "mutation_set_alert_message_popup_open",
  M_SET_ALERT_MESSAGE_POPUP_INFO: "mutation_set_alert_message_popup_info",
  M_SET_ALERT_MESSAGE_POPUP_ERROR: "mutation_set_alert_message_popup_error",
  M_SET_THEME: "mutation_set_theme",
  M_SET_ALERT_MESSAGE_TERMS: "mutation_set_alert_message_terms",
  M_SET_ALERT_SUPPORT_DIALOG_M: "mutation_set_alert_support_dialog_mobile",
  M_SET_ALERT_SUPPORT_DIALOG_D: "mutation_set_alert_support_dialog_desktop"
}

export const ACTIONS = {
  A_SET_ALERT_MESSAGE_POPUP: "action_set_alert_message_popup",
  A_SET_ALERT_MESSAGE_TERMS: "action_set_alert_message_terms",
  A_SET_ALERT_SUPPORT_DIALOG_M: "action_set_alert_support_dialog_M",
  A_SET_ALERT_SUPPORT_DIALOG_D: "action_set_alert_support_dialog_D"
}

export const GETTERS = {
  G_ALERT_MESSAGE_POPUP: "get_set_alert_message_popup"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}