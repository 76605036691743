// Requests under /user

import Axios from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import { USER_ROUTES } from '@/constants/backOfficeRoutes'
import { serialize } from '@/plugins/objectToFormdata'
import {
  MUTATIONS as AUTHENTICATION_MUTATIONS,
  NAMESPACE as AUTHENTICATION_NAMESPACE,
  STATE as AUTHENTICATION_STATE
} from '@/constants/vuex/authentication'
import const_global from '@/constants/global'
import { showToast } from "@/util/utils"

export const userLogin = (username, password, mfa = null) => {
  const loginData = serialize({ username: username, password: password, mfa: mfa })

  return Axios.post(USER_ROUTES.ROUTE_LOGIN, loginData)
}

export const userLogout = () => {
  return Axios.get(USER_ROUTES.ROUTE_LOGOUT)
}

export const rememberPassword = data => {
  const dataObject = serialize(data)

  return Axios.post(USER_ROUTES.ROUTE_REMEMBER_PASSWORD.replace('$email$', data.email), dataObject)
}

export const changePassword = (userInfo, fromUserManagement) => {
  store.commit(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_MUTATIONS.M_USERNAME}`, userInfo.username)
  const dataObject = serialize(userInfo)

  const route = fromUserManagement ? USER_ROUTES.ROUTE_CHANGE_PASSWORD : USER_ROUTES.ROUTE_CHANGE_PASSWORD_WITHOUT_TOKEN

  return Axios.post(route.replace('$email$', userInfo.username), dataObject)
}

export const changeDefaultCostCenterService = cost_center_code => {
  const dataObject = serialize({
    cost_center_id: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS].find(acc => acc.code == cost_center_code).cost_center_id
  })

  let username = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME]
  return Axios.post(USER_ROUTES.ROUTE_CHANGE_DEFAULT_COST_CENTER.replace('$email$', username), dataObject)
}

export const refreshSession = () => {
  return Axios.get(USER_ROUTES.ROUTE_REFRESH_SESSION)
}

export const checkUserHash = (code, type) => {
  return new Promise(resolve => {
    Axios.get(USER_ROUTES.ROUTE_CHECK_HASH.replace('$code$', code).replace('$type$', type))
      .then(response => {
        if (!response.data || response.data.error == 1) {
          resolve('error_data')
        } else {
          resolve(response.data)
        }
      })
  })
}

export const createUser = userInfo => {
  const userData = serialize(userInfo)
  return Axios.post(USER_ROUTES.ROUTE_USER_CREATE, userData)
}

export const updateUser = userInfo => {
  const userId = userInfo.user_id ? userInfo.user_id : userInfo.id
  const userData = serialize(userInfo)
  return Axios.post(USER_ROUTES.ROUTE_USER_UPDATE.replace('$user_id$', userId), userData)
}

export const saveUserLanguage = (new_lang) => {
  const default_cost_center = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS].find(ac => ac.is_default == 1)
  let default_cost_center_code = null
  if (default_cost_center != null) {
    default_cost_center_code = default_cost_center.code
  }
  const user_info = {
    active: 1,
    user_id: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_ID],
    user_status: 'active',
    cost_centers: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS],
    current_group: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID],
    default_cost_center: default_cost_center_code,
    group: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID],
    group_name: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_NAME],
    language: new_lang,
    timezone: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_TIMEZONE],
    name: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_NAME],
    username: store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME],
  }
  const userData = serialize(user_info)

  return Axios.post(USER_ROUTES.ROUTE_USER_UPDATE.replace('$user_id$', user_info.user_id), userData)
}

export const getUserNotificationsConfig = () => {
  return new Promise((resolve) => {
    Axios.get(USER_ROUTES.ROUTE_USER_DETAILS_NOTIFICATIONS).then((response) => {
      resolve(response.data)
    })
  })
}

export const saveUserNotificationsConfig = (config) => {
  if (config.mode == const_global.NOTIFICATIONS_INTERVAL_GROUPED) {
    config.beginDoNotDisturb = null
    config.endDoNotDisturb = null
  }

  if (config.mode == const_global.NOTIFICATIONS_INTERVAL_REAL_TIME) {
    config.groupedTimeExpression = null
  }

  const notificationsConfig = serialize(config)
  return new Promise((resolve) => {
    Axios.post(USER_ROUTES.ROUTE_USER_DETAILS_NOTIFICATIONS_UPDATE, notificationsConfig).then((response) => {
      showToast(response.data.error != 0, vuetify.framework.lang.t(`$vuetify.${response.data.message}`))
      resolve(response.data)
    })
  })
}

export const getUsers = () => {
  let master_account_code = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE]
  return Axios.get(USER_ROUTES.ROUTE_USERS.replace('$master_account_code$', master_account_code))
}