<template>
  <v-col v-if="internalFiles" class="pa-0">
    <v-row v-for="file in internalFiles" :key="file.key" class="mb-2" dense>
      <transaction-file :file="file" @downloadCertificate="handleClickDocument"></transaction-file>
    </v-row>
  </v-col>
  <v-col v-else>
    <span>{{$vuetify.lang.t('$vuetify.withoutFiles')}}</span>
  </v-col>
</template>

<script>
import transactionFile from "@/components/sections/transactions/transactionsView/TransactionFile.vue";

export default {
  name: "TransactionFilesList",
  components: { transactionFile },
  props: {
    files: [Array, null],
    certificateVisible: Boolean
  },
  data() {
    return {
      downloadLoading: false
    }
  },
  computed: {
    internalFiles() {
      let files = this.files
      if (this.certificateVisible && !files.find(file => file.key === 'certificate')) {
        files.push({
          key: 'certificate',
          type: 'pdf'
        })
      }
      return files
    }
  },
  methods: {
    async handleClickDocument(document) {
      if (document.key === 'certificate') {
        this.$emit('certificate')
      }
    }
  }

}
</script>