<template>
  <div>
    <v-row dense class="px-0">
      <v-col cols="12" lg="7" class="pr-8">
        <section-title
          :text="$vuetify.lang.t('$vuetify.batchModePreviewStep')"
          icon="visibility"
          idName="batch-mode"
        />

        <p class="cts-font-size-4 pl-8 pt-4" v-html="$vuetify.lang.t('$vuetify.batchModeCSVTableSubtitle')"></p>

        <RecipientPreviewExpandableTable
          :recipients="csvData"
          :rowsToShow="5"
          class="pl-8"
          />

        <!-- <v-divider class="my-4"></v-divider>

        <p class="cts-font-size-4">Aquí se muestra un resumen de los ficheros adjuntos</p>

        <ResourceTable
          :requiredFiles="requiredFiles"
          :extraFiles="extraFiles"
          :csvData="csvData"
        /> -->
      </v-col>

      <v-col cols="12" lg="5" class="pl-8">
        <SectionDataPreviewResume
          :nOfRecipients="csvData.length"
          :showLanguageSelector="false"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import { checkPermission } from "@/util/store_utils"
import const_permissions from "@/constants/permissions"
import SectionDataPreviewResume from "@/components/sections/processes/processView/sectionDataPreviewResume.vue"
import RecipientPreviewExpandableTable from "@/components/sections/processLaunch/models/batchMode/RecipientPreviewExpandableTable"
// import ResourceTable from "@/components/sections/processLaunch/models/batchMode/ResourceTable"

  export default {
    name: "BatchModeSectionPreview",
    components: {
      SectionTitle,
      SectionDataPreviewResume,
      RecipientPreviewExpandableTable,
      // ResourceTable
    },

    props: {
      csvFilename: String,
      csvData: Array,
      extraFiles: Array,
      requiredFiles: Array,
    },

    data() {
      return {
        checkPermission,
        const_permissions,
      }
    },

    computed: {

    }

  }
</script>

<style lang="scss" scoped>

</style>