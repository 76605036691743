<template>
  <v-card flat class="cts-tab-item-content" v-if="userNotificationsConfig != null">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon left size="19" class="mr-1">{{ const_icons.NOTIFICATIONS }}</v-icon>
        {{ $vuetify.lang.t('$vuetify.notifications') }}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-sm-4">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <h4 v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_ALL" class="cts-notifications-title">
            {{ $vuetify.lang.t('$vuetify.enabledNotifications') }}
          </h4>

          <h4 v-else-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_NONE" class="cts-notifications-title">
            {{ $vuetify.lang.t('$vuetify.disabledNotifications') }}
          </h4>

          <h4 v-else-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_CUSTOM" class="cts-notifications-title">
            {{ $vuetify.lang.t('$vuetify.customNotifications') }}
          </h4>

          <h4 v-else class="cts-notifications-title">
            {{ $vuetify.lang.t('$vuetify.selectNotifications') }}
          </h4>
        </v-col>
      </v-row>

      <!--   First section   -->
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="4" class="ma-0 pa-0">
          <v-select
            single-line
            outlined
            dense
            id="processes-input-select_notifications"
            class="ma-0 cts-select-box cts-box-filter-tooltip"
            v-model="userNotificationsConfig.enabled"
            :placeholder="$vuetify.lang.t('$vuetify.selectCategoryNotifications')"
            :menu-props="selectsProps"
            :items="notificationsConfig"
            item-text="name"
            item-value="code"
            :label="$vuetify.lang.t('$vuetify.selectCategoryNotifications')"
            :aria-label="$vuetify.lang.t('$vuetify.selectCategoryNotifications')"
            @change="checkSavePending"
          >
            <template v-slot:selection="{ item }">
              <v-tooltip v-if="item.length > 35" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
              <span :id="`processes-input-select_notifications_${item}`" v-else>{{ item.name }}</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" md="8" class="ma-0 py-0">
          <p v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_ALL" class="cts-notifications-paragraph">
            {{ $vuetify.lang.t('$vuetify.enabledNotificationsDescriptionText') }}
          </p>

          <p v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_NONE" class="cts-notifications-paragraph">
            {{ $vuetify.lang.t('$vuetify.disabledNotificationsDescriptionText') }}
          </p>

          <p v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_CUSTOM" class="cts-notifications-paragraph">
            {{ $vuetify.lang.t('$vuetify.customNotificationsDescriptionText') }}
          </p>
        </v-col>
      </v-row>
      <!--   END first section   -->

      <!--   Second section   -->
      <v-row class="ma-0 pa-0" v-if="userNotificationsConfig.enabled !== const_global.NOTIFICATIONS_NONE">
        <v-col class="ma-0 pa-0 cts-content-section" cols="12">
          <h4 class="mb-0 mt-4">{{ $vuetify.lang.t('$vuetify.enabledNotificationsConfigurationText') }}</h4>
        </v-col>

        <v-col class="ma-0 pa-0" cols="12">
          <h4 class="mb-0 mt-4" v-if="userNotificationsConfig.mode === const_global.NOTIFICATIONS_INTERVAL_REAL_TIME">
            {{ $vuetify.lang.t('$vuetify.realTimeNotificationsDescription') }}
          </h4>

          <h4 class="mb-0 mt-4" v-else>
            {{ $vuetify.lang.t('$vuetify.groupedNotificationsDescription') }}
          </h4>
        </v-col>

        <v-col class="ma-0 pa-0" cols="12">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <h4 class="mb-0 mt-2">{{ $vuetify.lang.t('$vuetify.chooseNotificationsRange') }}</h4>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="4" class="ma-0 pa-0">
              <v-select
                single-line
                outlined
                dense
                id="processes-input-select_notifications"
                class="ma-0 cts-select-box cts-box-filter-tooltip"
                :placeholder="$vuetify.lang.t('$vuetify.selectEnabledNotifications')"
                :menu-props="selectsProps"
                :items="notificationsType"
                item-text="name"
                item-value="code"
                @change="checkSavePending"
                v-model="userNotificationsConfig.mode"
                :label="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                :aria-label="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
              >
                <template v-slot:selection="{ item }">
                  <v-tooltip v-if="item.length > 35" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <span :id="`processes-input-select_notifications_${item}`" v-else>{{ item.name }}</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" md="8" class="ma-0 py-0">
              <p
                v-if="userNotificationsConfig.mode === const_global.NOTIFICATIONS_INTERVAL_REAL_TIME"
                class="cts-notifications-paragraph pt-2"
              >
                {{ $vuetify.lang.t('$vuetify.enabledNotificationsRealTimeDescriptionText') }}
              </p>

              <p
                v-if="userNotificationsConfig.mode === const_global.NOTIFICATIONS_INTERVAL_GROUPED"
                class="cts-notifications-paragraph"
              >
                {{ $vuetify.lang.t('$vuetify.enabledNotificationsGroupedDescriptionText') }}
              </p>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0 cts-notifications-schedule-row">
            <v-col
              v-if="userNotificationsConfig.mode === const_global.NOTIFICATIONS_INTERVAL_GROUPED"
              cols="12"
              md="4"
              class="ma-0 pa-0 mt-3"
            >
              <span class="cts-form-labeled-input">
                {{ $vuetify.lang.t('$vuetify.groupedNotificationsReportTime') }}
              </span>
              <hour-slider
                :val="userNotificationsConfig.groupedTimeExpression"
                @changeSlider="changeGroupedTimeExpression"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_CUSTOM"
          class="ma-0 pa-0 cts-content-section"
          cols="12"
        >
          <h4 class="mb-0 mt-4">
            {{ $vuetify.lang.t('$vuetify.enabledNotificationsCustomText') }}
          </h4>
        </v-col>

        <v-col
          v-if="userNotificationsConfig.enabled === const_global.NOTIFICATIONS_CUSTOM"
          class="ma-0 pa-0"
          cols="12"
        >
          <v-row dense class="pt-4 pb-0 justify-space-between px-2">
            <v-col cols="12" xl="6" lg="8" md="12" sm="12" class="pa-0">
              <v-row dense class="ma-0">
                <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="px-0 pr-lg-2">
                  <v-select
                    single-line
                    outlined
                    dense
                    class="ma-0 cts-select-box cts-box-filter-tooltip"
                    :placeholder="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                    :menu-props="selectsProps"
                    :items="categoryNames"
                    v-model="selectedCategory"
                    clearable
                    :aria-label="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                    @change="checkSavePending"
                  >
                    <template v-slot:selection="{ item }">
                      <v-tooltip v-if="item.length > 35" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item }}</span>
                        </template>
                        <span>{{ item }}</span>
                      </v-tooltip>
                      <span v-else>
                        {{ item }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="px-0">
                  <v-select
                    id="processes-input-select_tags"
                    multiple
                    clearable
                    single-line
                    outlined
                    dense
                    :class="'ma-0 cts-select-box cts-select-tags-'+lang.toLowerCase()"
                    :items="availableTags"
                    v-model="selectedTags"
                    :placeholder="$vuetify.lang.t('$vuetify.processesTagFilter')"
                    :menu-props="selectsProps"
                    :aria-label="$vuetify.lang.t('$vuetify.processesTagFilter')"
                  >
                    <template v-slot:item="{ item }">
                      <div class="v-list-item__content">
                        <div :id="`processes-input-select_tags_${item}`" class="v-list-item__title">
                          {{ ($vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase() }}
                        </div>
                      </div>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <div v-if="index < 2" class="ml-1 py-1">
                        <v-chip dark label class="py-1 cts-select-chip">
                          <span :id="`processes-select-tags_${item}`">
                            {{ ($vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase() }}
                          </span>
                        </v-chip>
                      </div>

                      <span v-if="index >= 2" class="grey--text caption">
                        (+{{ selectedTags.length - 2 }})
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="3" xl="3" md="12" sm="12" class="px-0">
              <v-row dense class="ma-0 justify-end">
                <v-text-field id="processes-input-text_search" multiple clearable single-line outlined dense
                  class="ma-0 cts-text-box"
                  v-model="searchText"
                  :placeholder="$vuetify.lang.t('$vuetify.processesSearch')"
                  :append-icon="const_icons.SEARCH"
                  :menu-props="selectsProps"
                  :label="$vuetify.lang.t('$vuetify.processesSearch')"
                  :aria-label="$vuetify.lang.t('$vuetify.processesSearch')"
                >
                </v-text-field>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="categories != null && categories.length !== 0">
            <v-col cols="12" class="py-0">
              <v-row dense
                v-show="anyResults()"
                v-for="(category , index) in categories"
                :key="index"
              >
                <v-slide-y-transition>
                  <v-col
                    v-if="searchText == null || category.processes.filter(process => process.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).length !== 0"
                    class="cts-process-expandable-category-container"
                  >
                    <v-row dense>
                      <v-col class="mx-4">
                        <v-row class="ma-0 cts-categories-title mb-4" style="border-bottom: solid var(--tertiary) 1px;">
                          <v-col cols="12" md="9" class="pa-0">
                            <h4
                              :id="`processes-category_title-${category.name.toLowerCase().replaceAll(' ', '_')}`"
                              class="ma-1 font-weight-regular"
                            >
                              {{ category.name }}
                            </h4>
                          </v-col>

                          <v-col cols="12" md="3" class="ma-0 pa-0 d-flex flex-row justify-end align-center">
                              <span v-if="category.active" class="pa-0">
                                {{ $vuetify.lang.t('$vuetify.deactivateCategoryNotifications') }}
                              </span>
                              <span v-else class="pa-0">
                                {{ $vuetify.lang.t('$vuetify.activateCategoryNotifications') }}
                              </span>
                              <switch-without-label
                                v-model="category.active"
                                class="ml-4"
                                @input="changeStatesAllProcessesByCategory(category)"
                              />
                          </v-col>
                        </v-row>

                        <v-row v-if="(typeof category.processes != 'undefined')" class="mx-auto pa-0" dense>
                          <v-fade-transition v-for="(process ,index) in category.processes" :key="index">
                            <v-col
                              cols="12"
                              class="py-1 cts-process-card"
                            >
                              <v-row dense class="ma-0 pa-0"
                                     v-if="searchText == null || process.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1">
                                <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="ma-0 pa-0">
                                  <v-expansion-panels accordion class="elevation-0">
                                    <process-expandable
                                      :tags="process.tags"
                                      :process="process"
                                      :from-processes="true"
                                      :active-all="category.active"
                                      :category="category"
                                      :isActive="userNotificationsConfig.processes.includes(process.code)"
                                      @toggleActive="changeProcessStatus(process)"/>
                                  </v-expansion-panels>
                                </v-col>
                              </v-row>

                            </v-col>
                          </v-fade-transition>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-slide-y-transition>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="searchText != null && searchText !== '' && !anyProcessFound"
            dense
            class="ma-0 justify-center pt-8"
          >
            <span class="font-weight-light grey--text">
             {{ $vuetify.lang.t('$vuetify.withoutProcessResults') }}
            </span>
          </v-row>
        </v-col>
      </v-row>
      <!--   END second section   -->
    </v-card-text>
  </v-card>
</template>

<script>
import const_global from "@/constants/global";
import const_icons from "@/constants/icons";
import const_permissions from "@/constants/permissions";
import { NAMESPACE as PROCESSES_NAMESPACE, ACTIONS as PROCESSES_ACTIONS, GETTERS as PROCESSES_GETTERS } from "@/constants/vuex/processes"
import { getAvailableProcesses } from "@/services/groupServices"
import ProcessExpandable from "./notifications/processExpandable"
import HourSlider from "../../structures/hourSlider"
import { getUserNotificationsConfig } from "@/services/userServices"
import { redirect } from "@/util/router_utils"
import { checkPermission } from "@/util/store_utils"
import { getObjectWithoutReactivity } from "@/util/utils"
import { ERROR_500 } from "@/constants/router/routes"
import SwitchWithoutLabel from "@/components/structures/step_launcher/switchWithoutLabel.vue";

export default {
  name: "sectionUserNotifications",
  components: {SwitchWithoutLabel, HourSlider, ProcessExpandable },
  data() {
    return {
      const_global,
      const_icons,
      searchText: null,
      searching: false,
      categoriesFilter: [],
      notificationsConfig: [
        {
          code: const_global.NOTIFICATIONS_ALL, name: this.$vuetify.lang.t('$vuetify.activateCategoryNotifications')
        },
        {
          code: const_global.NOTIFICATIONS_NONE, name: this.$vuetify.lang.t('$vuetify.deactivateCategoryNotifications')
        },
        {
          code: const_global.NOTIFICATIONS_CUSTOM, name: this.$vuetify.lang.t('$vuetify.customCategoryNotifications')
        }
      ],
      notificationsType: [
        {
          code: const_global.NOTIFICATIONS_INTERVAL_REAL_TIME,
          name: this.$vuetify.lang.t('$vuetify.enabledNotificationsConfigurationRealTime')
        },
        {
          code: const_global.NOTIFICATIONS_INTERVAL_GROUPED,
          name: this.$vuetify.lang.t('$vuetify.enabledNotificationsConfigurationGrouped')
        }
      ],
      selectedTags: [],
      selectedCategory: null,
      selectsProps: {
        'closeOnClick': false,
        'closeOnContentClick': true,
        'openOnClick': false
      },
      lang: undefined,
      userNotificationsConfig: {
        beginDoNotDisturb: null,
        endDoNotDisturb: null,
        processes: [],
        groupedTimeExpression: null,
        mode: null
      },
      doNotDisturb: true,
      selectedProcesses: []
    }
  },
  watch: {
    doNotDisturb (val) {
      if (this.userNotificationsConfig != null && val === false) {
        this.userNotificationsConfig.beginDoNotDisturb = null
        this.userNotificationsConfig.endDoNotDisturb = null
      } else {
        this.userNotificationsConfig.beginDoNotDisturb = "18"
        this.userNotificationsConfig.endDoNotDisturb = "9"
      }
    },
  },
  created() {
    getUserNotificationsConfig().then(config => {
      this.userNotificationsConfig = config

      this.doNotDisturb = config.beginDoNotDisturb != null && config.beginDoNotDisturb !== "0" && config.endDoNotDisturb != null && config.endDoNotDisturb !== "0"

      if (checkPermission(const_permissions.SECTION_PROCESS)) {
        this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, null)
        getAvailableProcesses().then((response) => {
          if (response.data && Array.isArray(response.data)) {
          this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, response.data)

          } else {
            redirect(ERROR_500)
          }
        })
      } else {

        this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, [])
      }
      this.lang = this.$store.state.location.currentLocation.code
    })

    this.$emit("activateSaveButton", 'userNotifications', false)
  },
  computed: {
    categoryObjects() {
      return getObjectWithoutReactivity(this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_AVAILABLE_PROCESSES_BY_CATEGORIES}`])
    },

    categoryNames() {
      if (this.categoryObjects) {
        return this.categoryObjects.map(x => x.name)
      }

      return []
    },

    availableTags() {
      if (this.categoryObjects) {
        return this.categoryObjects.reduce((acc, category) => {
          return [... new Set([...acc, ...category.processes.flatMap(x => x.tags)])]
        }, new Set())
      }

      return []
    },

    categories() {
      if (this.categoryObjects) {
        let categories = this.categoryObjects

        if (this.selectedCategory) {
          categories = this.categoryObjects.filter(cat => cat.name === this.selectedCategory)
        }

        if (this.selectedTags.length > 0) {
          const categoriesWithProcessesThatContainSelectedTags = this.getCategoriesWithProcessesThatContainSelectedTags(categories)
          categories = categoriesWithProcessesThatContainSelectedTags.map(category => this.getProcessesThatContainSelectedTags(category))
        }
        categories = this.getCategoriesHaveAllProcessesEnabled(categories)

        return categories
      }
      return []
    }
  },
  methods: {
    anyResults () {
      return this.categories?.length > 0
    },

    getCategoriesWithProcessesThatContainSelectedTags (categories) {
      return categories.filter(category => {
        if (category.processes) {
          return category.processes.some(process => {
            return this.selectedTags.some(tag => process.tags.includes(tag))
          })
        }
      })
    },

    getCategoriesHaveAllProcessesEnabled (categories) {
      for (const category of categories){
        this.checkAllProcessesInCategoryAreEnabled(category)? category['active'] = true : category['active'] = false
      }
      return categories
    },

    getProcessesThatContainSelectedTags (category) {
        const filteredProcesses = category.processes.filter(process => {
          return process.tags.some(tag => this.selectedTags.some(tagSelected => tag === tagSelected))
        })
        return {
          ...category, processes: filteredProcesses
        }
    },

    checkAllProcessesInCategoryAreEnabled (category) {
      const codesAllEnabledProcesses = this.userNotificationsConfig.processes
      return category.processes.every(process => codesAllEnabledProcesses.includes(process.code))
    },

    checkSavePending () {
      if (this.userNotificationsConfig.mode === const_global.NOTIFICATIONS_INTERVAL_GROUPED &&
        (
          typeof this.userNotificationsConfig.groupedTimeExpression != "string"
          || this.userNotificationsConfig.groupedTimeExpression === "" )
      ) {
        this.userNotificationsConfig.groupedTimeExpression = "08:30"

      }
      this.emitNotificationsChanged()
    },

    changeProcessStatus (process) {
      if (this.userNotificationsConfig.processes.includes(process.code)) {
        this.disableProcess(process.code)
      } else {
        this.activateProcess(process.code)
      }

      this.checkSavePending()
    },

    activateProcess (codeOfProcessToActivate) {
      this.userNotificationsConfig.processes.push(codeOfProcessToActivate)
    },

    disableProcess (codeOfProcessToDeactivate) {
      this.userNotificationsConfig.processes = this.userNotificationsConfig.processes.filter(
        process =>!codeOfProcessToDeactivate.includes(process)
      )
    },

    changeStatesAllProcessesByCategory (category) {
      if (category.active) {
        this.activateProcessesByCategory(category.processes)
      } else {
        this.disableProcessesByCategory(category.processes)
      }
      this.emitNotificationsChanged()
    },

    activateProcessesByCategory (processesOfActivatedCategory) {
      for(const process of processesOfActivatedCategory) {
        if (!this.userNotificationsConfig.processes.includes(process.code)) {
          this.userNotificationsConfig.processes.push(process.code)
        }
      }
    },

    disableProcessesByCategory (processesOfDisabledCategory) {
      const processCodesToDelete = processesOfDisabledCategory.map(process => process.code)
      if (this.userNotificationsConfig.processes) {
        this.userNotificationsConfig.processes = this.userNotificationsConfig.processes.filter(
          process => !processCodesToDelete.includes(process)
        )
      }
    },

    // changeDoNotDisturb (newRange) {
    //   this.userNotificationsConfig.beginDoNotDisturb = newRange[1]
    //   this.userNotificationsConfig.endDoNotDisturb = newRange[0]
    //   this.checkSavePending()
    // },
    changeGroupedTimeExpression (newHour) {
      this.userNotificationsConfig.groupedTimeExpression = newHour
      this.checkSavePending()
    },

    emitNotificationsChanged () {
      this.$emit('notificationsChanged', {
        section: 'userNotifications',
        data: this.userNotificationsConfig
      })
    }
  }
}
</script>
