<template>
  <v-row class="cts-context-menu mx-auto" v-if="mode !== 'hidden'">
    <v-select id="user-selector-submenu-subaccount-selector"
            :disabled="subaccount_disabled"
            v-model="active_subaccount"
            outlined
            class="cts-bgcolor-clear cts-select-box cts-select-subaccount my-2 col-lg-2 mx-auto"
            :items="subaccounts"
            @change="changeSubAccount()"
            dense
            item-text="sub_account_name"
            item-value="sub_account_code"
            return-object
            :placeholder="$vuetify.lang.t('$vuetify.activeSubaccount')"
            :aria-label="$vuetify.lang.t('$vuetify.activeSubaccount')"
    >
      <template v-slot:item="data">
        <template v-if="(typeof data.item !== 'object')">
          <v-list-item-content>
            {{ data.item }}
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.sub_account_name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>
  </v-row>
</template>

<script>
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
  import { NAMESPACE as PROCESSES_NAMESPACE, ACTIONS as PROCESSES_ACTIONS } from "@/constants/vuex/processes"
  import { getAvailableProcesses } from "@/services/groupServices"
  import { redirect } from "@/util/router_utils"
  import { showToast } from "@/util/utils"
  import { PROCESS_VIEW, TRANSACTION_VIEW, ADMINISTRATION, ROOT, ERROR_500, PROCESSES } from "@/constants/router/routes"
  import { NAMESPACE as TRANSACTIONS_NAMESPACE, ACTIONS as TRANSACTIONS_ACTIONS } from "@/constants/vuex/transactions"

  export default {
    name: "subaccountSelector",
    props: {
      mode: String,
      subaccounts: Array,
      subaccount_selected: Object,
      attrs: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return{
        active_subaccount: null,
        PROCESS_VIEW,
        TRANSACTION_VIEW,
        ADMINISTRATION,
        ROOT,
        ERROR_500,
        PROCESSES
      }
    },

    computed: {
      subaccount_disabled() {
        return this.$route.name === PROCESS_VIEW.NAME || this.$route.name === TRANSACTION_VIEW.NAME ||
          this.$route.name === ADMINISTRATION.NAME || this.subaccounts.length === 1
      }
    },

    created() {
      this.active_subaccount = this.$props.subaccount_selected
    },

    methods: {
      async setAvailableProcesses() {
        const availableProcessesResponse = await getAvailableProcesses()

        if (availableProcessesResponse.data && Array.isArray(availableProcessesResponse.data)) {
          await this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES}`, availableProcessesResponse.data)
        } else {
          redirect(ERROR_500)
        }
      },

      async changeSubAccount() {
        const transaction_date_filter_value = []
        const date = new Date()
        date.setDate(date.getDate() - 7);
        transaction_date_filter_value.push(date.getFullYear() + "-" + ((date.getMonth() + 1).toString().padStart(2, "0")) + "-" + date.getDate().toString().padStart(2, "0"))
        date.setDate(date.getDate() + 7);
        transaction_date_filter_value.push(date.getFullYear() + "-" + ((date.getMonth() + 1).toString().padStart(2, "0")) + "-" + date.getDate().toString().padStart(2, "0"))


        const store_filters = {
          linked_groups: null,
          process_code: null,
          creation_timestamp: transaction_date_filter_value,
        }

        await this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_ACTIVE_FILTERS}`, store_filters)

        await this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHANGE_SUBACCOUNT}`, this.active_subaccount)

        if (this.$router.currentRoute.name === PROCESSES.NAME) {
          redirect(ROOT)
          await this.setAvailableProcesses()

        } else if (this.$router.currentRoute.name === ROOT.NAME) {
          redirect(PROCESSES)
          await this.setAvailableProcesses()

        } else {
          redirect(PROCESSES)
        }

        showToast(false, this.$vuetify.lang.t('$vuetify.subaccountChanged'))
        this.$emit('closeUserMenu')
      },
    }
  }
</script>
