<template>
  <v-row class="intervener-transaction-card" dense justify="start">
    <v-col class="text-center pa-0" cols="2" md="1" style="
        border-right: 1px solid var(--card-border);">
      <v-list-item-avatar>
        <v-icon :color="getIntervenerColor(index)" size="42">person_outline</v-icon>
      </v-list-item-avatar>
    </v-col>
    <v-col class="d-none d-md-block" md="2">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ $vuetify.lang.t('$vuetify.reference') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{reference}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="5" md="3">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{$vuetify.lang.t('$vuetify.contact_complete_name')}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{name}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col :cols="signCeremony ? 3 : 6" class="d-none d-md-block">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{$vuetify.lang.t('$vuetify.contact')}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{contact}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col v-if="signCeremony" class="align-content-center" cols="5" md="3">
      <v-row class="pr-4" dense justify="end">
        <v-btn v-if="signCeremonyEnabled" color="var(--primary-alternative)"
               small
               style="color: var(--clear)"
               target="_blank"
               @click="openConfirmDialog">
          <v-icon class="mr-2" size="20">mdi-draw-pen</v-icon>
          <span>{{$vuetify.lang.t('$vuetify.goToSignature')}}</span>
        </v-btn>
        <span v-else class="cts-color-tertiary cts-font-size-4">
          <v-icon small>check</v-icon>
          {{$vuetify.lang.t('$vuetify.signed')}}
        </span>
      </v-row>
    </v-col>
    <v-dialog v-if="confirmDialog" v-model="confirmDialog" width="600">
      <v-card class="pa-8 pb-4">
        <v-card-text>
          <v-row class="mb-4" dense justify="center">
            <span class="cts-font-size-title font-weight-medium">{{intervener.personal_info.name}}</span>
          </v-row>
          <p class="cts-font-size-2">{{
              $vuetify.lang.t('$vuetify.intervenerStartCeremony')
            }}</p>
        </v-card-text>
        <v-card-actions>
          <v-row dense justify="center">
            <v-btn class="mr-2" color="var(--primary)" outlined @click="confirmDialog = false">
              {{$vuetify.lang.t('$vuetify.cancel')}}
            </v-btn>
            <v-btn color="var(--primary-alternative)" dark @click="openFrontRenderer()">
              {{$vuetify.lang.t('$vuetify.goToSignature')}}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {getIntervenerColor} from "@/plugins/helpers";

export default {
  name: "IntervenerCard",
  props: {
    intervener: Object,
    index: Number,
    type: String,
    transactionFinish: Boolean,
    signCeremony: Boolean,
    signCeremonyEnabled: Boolean,
  },
  data() {
    return {
      reference: "",
      name: "",
      contact: "",
      confirmDialog: false
    }
  },
  created() {


    if (this.type === 'intervener') {
      this.reference = this.$vuetify.lang.t('$vuetify.notSpecified')
      this.name = this.$vuetify.lang.t('$vuetify.intervener')
      this.contact = this.$vuetify.lang.t('$vuetify.notSpecified')

      if (this.intervener.personal_info) {
        this.name = this.intervener.personal_info.name
        if (this.intervener.personal_info.identification_document && this.intervener.personal_info.identification_document.document_code !== "") {
          this.reference = this.intervener.personal_info.identification_document.document_code
        }
      }

      if (this.intervener.contact_info) {

        if (this.intervener.contact_info.phone && this.intervener.contact_info.phone[0]) {
          this.contact = "+" + this.intervener.contact_info.phone[0].country_code + " " + this.intervener.contact_info.phone[0].phone_number
        }
        //this.intervener.contact_info.email[0] ? this.intervener.contact_info.email[0] : this.intervener.contact_info.phone[0]
        if (this.intervener.contact_info.email && this.intervener.contact_info.email[0] !== "") {
          this.contact = this.intervener.contact_info.email[0]
        }

      }

    } else {
      this.reference = this.intervener.contact_id
      this.name = this.intervener.contact_name + ' ' + this.intervener.contact_surname
      if (this.intervener.contact_email !== "") {
        this.contact = this.intervener.contact_email
      } else if (this.intervener.contact_phone !== "") {
        this.contact = this.intervener.contact_phone
      } else {
        this.contact = this.$vuetify.lang.t('$vuetify.notSpecified')
      }
    }
  },
  methods: {
    getIntervenerColor,
    openConfirmDialog() {
      this.confirmDialog = true
    },
    openFrontRenderer() {
      window.open(this.intervener.metadata['SIGNATURE_CEREMONY#email_link'], '_blank')
      this.confirmDialog = false
    }
  }
}
</script>

<style lang="scss">
.transaction-details-container {
  padding-left: 14px !important;

}

.transaction-parameters {
  border-left: 1px solid var(--card-border);

  span {
    font-size: 0.9rem;
  }
}

.intervener-transaction-card {
  border: solid 1px var(--card-border);
  min-height: 60px;
  align-content: space-evenly;
  border-radius: 4px;
  margin-bottom: 8px;

  .col {
    height: fit-content;
    margin: auto 0;
  }

  .v-btn {
  }

  .v-list-item__title, .v-list-item__subtitle {
    font-size: 0.9rem;
  }


  .icon {
    min-width: 50px !important;


  }

  .v-list-item__avatar {
    margin-right: 0 !important;
  }

  .v-list-item {

    padding: 0;

    .v-list-item__content {
      padding: 0;
      padding-left: 8px;
    }
  }

  .button-intervener-details {
    border: solid 1px var(--primary-alternative);
  }
}
</style>