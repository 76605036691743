import Vue from 'vue'
import Vuex from 'vuex'
import appearance from './modules/appearance'
import location from './modules/location'
import global from './modules/global'
import vuex_functionality from './modules/vuex_functionality'
import vuex_processes from "./modules/sections/vuex_processes"
import vuex_authentication from "./modules/vuex_authentication"
import vuex_transactions from "./modules/sections/vuex_transactions"
import vuex_administration from "./modules/sections/vuex_administration"
import vuex_user_account from "./modules/sections/vuex_user_account"
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls"

import { NAMESPACE as AUTHENTICATION_NAMESPACE } from "@/constants/vuex/authentication"
import { NAMESPACE as FUNCTIONALITY_NAMESPACE } from "@/constants/vuex/functionality"
import { NAMESPACE as ADMINISTRATION_NAMESPACE } from "@/constants/vuex/administration"
import { NAMESPACE as PROCESSES_NAMESPACE } from "@/constants/vuex/processes"
import { NAMESPACE as TRANSACTIONS_NAMESPACE } from "@/constants/vuex/transactions"
import { NAMESPACE as USER_ACCOUNT_NAMESPACE } from "@/constants/vuex/userAccount"

let ls = new SecureLS({
  isCompression: false,
  encodingType: 'rc4',
  encryptionSecret: "x[>K{8xzv@v0Um9=3{?Q:]O,&gBff,"});

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

const store =  new Vuex.Store({
  modules: {
    global: global,
    appearance: appearance,
    location: location,
    [ADMINISTRATION_NAMESPACE]: vuex_administration,
    [AUTHENTICATION_NAMESPACE]: vuex_authentication,
    [FUNCTIONALITY_NAMESPACE]: vuex_functionality,
    [PROCESSES_NAMESPACE]: vuex_processes,
    [TRANSACTIONS_NAMESPACE]: vuex_transactions,
    [USER_ACCOUNT_NAMESPACE]: vuex_user_account,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  // strict: debug,
})

export default store
