<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-scroll="http://www.w3.org/1999/xhtml">
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-scroll-y-transition>
      <v-row v-if="group.original" dense class="ma-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-row dense class="ma-0">
            <v-col cols="11" xl="10" lg="10" md="11" sm="11" class="col-lg-offset-1 mx-auto cts-view-header">
              <v-row dense class="cts-breadcrumbs mt-1">
                <a
                  @click.prevent="backToMenu('admin')" :title="$vuetify.lang.t('$vuetify.administrationTitle')"
                >
                  {{$vuetify.lang.t('$vuetify.administrationTitle') }} </a>
                <a
                  @click.prevent="backToMenu('groups')"
                  :title="$vuetify.lang.t('$vuetify.groupsTitle')"
                  class="pl-1"
                >
                  / {{ $vuetify.lang.t('$vuetify.groupsTitle') }}
                </a>
                <span class="cts-color-black_soft_text font-weight-regular pl-1">
                  / {{ group.original[const_global.GROUP_NAME] }}
                </span>
              </v-row>
              <v-row dense>
                <v-col cols="12" xl="10" lg="10" md="8" sm="8">
                  <view-title
                    :icon="const_icons.GROUPS"
                    :title="group.original[const_global.GROUP_NAME]"
                    :subtitle="group.copy[const_global.GROUP_DESCRIPTION]"
                    idName="group-detail"
                  />
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="4" sm="4" class="hidden-xs-only">
                  <v-btn 
                    v-if="checkPermission(const_permissions.GROUP_EDIT_PROCESSES) || checkPermission(const_permissions.GROUP_EDIT_PERMISSIONS) || checkPermission(const_permissions.GROUP_EDIT_INFO) || checkPermission(const_permissions.GROUP_EDIT_USERS)|| checkPermission(const_permissions.GROUP_EDIT_LINKED_GROUPS)"
                    class="cts-link cts-button-standard float-right mt-6"
                    :title="$vuetify.lang.t('$vuetify.save')"
                    @click.prevent="save"
                    :disabled="!this.hasChanges"
                  >
                    {{ $vuetify.lang.t('$vuetify.save') }}
                    <v-icon small>{{const_icons.SAVE}}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 justify-center cts-view-content mb-0">
            <v-col cols="11" lg="10">
              <v-row dense class="ma-0 pb-0">
                <v-col md="12" class="pa-0">
                  <div class="col-lg-12 mx-auto pa-0">
                    <v-card flat tile color="cts-permissions-box cts-bgcolor-lighten" min-height="100%">
                      <v-tabs class="hidden-xs-only" vertical>
                        <v-tab class="justify-start cts-bgcolor-transparent">
                          <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.groupDataTitle')}}</h4>
                          <v-icon right class="d-block float-right" small>{{const_icons.TAB_ARROW}}</v-icon>
                        </v-tab>
                        <v-tab class="justify-start cts-bgcolor-transparent">
                          <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.users')}}</h4>
                          <v-icon right class="d-block float-right" small>{{const_icons.TAB_ARROW}}</v-icon>
                        </v-tab>
                        <v-tab class="justify-start cts-bgcolor-transparent">
                          <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.groupPermissionsTabsTitle')}}</h4>
                          <v-icon right class="d-block float-right" small>{{const_icons.TAB_ARROW}}</v-icon>
                        </v-tab>
                        <v-tab class="justify-start cts-bgcolor-transparent">
                          <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.groupProcessesAndSubaccounts')}}</h4>
                          <v-icon right class="d-block float-right" small>{{const_icons.TAB_ARROW}}</v-icon>
                        </v-tab>
                        <v-tab class="justify-start cts-bgcolor-transparent">
                          <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.groupLinked')}}</h4>
                          <v-icon right class="d-block float-right" small>{{const_icons.TAB_ARROW}}</v-icon>
                        </v-tab>

                        <v-tab-item style="min-height: 39vh">
                          <admin-group-info
                            :icon="const_icons.GROUP_INFO"
                            :group="group.original"
                            :title="$vuetify.lang.t('$vuetify.groupDataTitle')"
                            @input="handleChangeEvent"
                          />
                        </v-tab-item>
                        <v-tab-item style="min-height: 39vh">
                          <admin-users-table
                            :icon="const_icons.CONTACTS"
                            :group="group.original"
                            :title="$vuetify.lang.t('$vuetify.usersTitle')"
                          />
                        </v-tab-item>
                        <v-tab-item style="min-height: 39vh">
                          <admin-permissions
                            :icon="const_icons.CHANGE_PASS"
                            :master-account-admin="group.original[const_global.GROUP_MASTER_ACCOUNT_ADMIN]"
                            :permissions="group.original[const_global.PERMISSIONS]"
                            :title="$vuetify.lang.t('$vuetify.groupPermissionsTabsTitle')"
                            @change="handleChangeEvent"
                          />
                        </v-tab-item>
                        <v-tab-item style="min-height: 39vh">
                          <admin-processes-and-subaccounts 
                            :icon="const_icons.PROCESSES"
                            :master-account-admin="group.original[const_global.GROUP_MASTER_ACCOUNT_ADMIN]"
                            :subaccounts="group.original['subaccounts']"
                            :title="$vuetify.lang.t('$vuetify.groupProcessesAndSubaccounts')"
                            @change="handleChangeEvent"
                          />
                        </v-tab-item>
                        <v-tab-item style="min-height: 39vh">
                          <admin-linked-groups
                            :icon="const_icons.LINKED"
                            v-if="available_groups"
                            :available_groups="available_groups"
                            :group="group.original"
                            :title="$vuetify.lang.t('$vuetify.groupLinked')"
                            @change="handleChangeEvent"
                          />
                        </v-tab-item>
                      </v-tabs>
                      <v-expansion-panels class="hidden-sm-and-up mt-4">
                        <v-expansion-panel class="cts-expand-permission mb-3">
                          <v-card class="cts-permissions-card" tile outlined height="100%">
                            <v-expansion-panel-header class="pa-1">
                              <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                <h4 class="d-flex flex">
                                  <v-icon left size="19" class="mr-1">
                                    {{ const_icons.GROUP_INFO }}
                                  </v-icon>
                                  {{ $vuetify.lang.t('$vuetify.groupDataTitle') }}
                                </h4>
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <admin-group-info
                                :icon="const_icons.GROUP_INFO"
                                :group="group.original"
                                :title="$vuetify.lang.t('$vuetify.groupDataTitle')"
                                @change="handleChangeEvent"
                              />
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                        <v-expansion-panel class="cts-expand-permission mb-3">
                          <v-card class="cts-permissions-card" tile outlined height="100%">
                            <v-expansion-panel-header class="pa-1">
                              <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                <h4 class="d-flex flex">
                                  <v-icon left size="19" class="mr-1">{{ const_icons.CONTACTS }}</v-icon>
                                  {{ $vuetify.lang.t('$vuetify.users') }}
                                </h4>
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <admin-users-table
                                :icon="const_icons.CONTACTS"
                                :group="group.original"
                                :title="$vuetify.lang.t('$vuetify.usersTitle')"
                              />
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                        <v-expansion-panel class="cts-expand-permission mb-3">
                          <v-card class="cts-permissions-card" tile outlined height="100%">
                            <v-expansion-panel-header class="pa-1">
                              <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                <h4 class="d-flex flex">
                                  <v-icon left size="19" class="mr-1">{{ const_icons.CHANGE_PASS }}</v-icon>
                                  {{ $vuetify.lang.t('$vuetify.groupPermissionsTabsTitle') }}
                                </h4>
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <admin-permissions
                                :icon="const_icons.CHANGE_PASS"
                                :master-account-admin="group.original[const_global.GROUP_MASTER_ACCOUNT_ADMIN]"
                                :permissions="group.original[const_global.PERMISSIONS]"
                                :title="$vuetify.lang.t('$vuetify.groupPermissionsTabsTitle')"
                                @change="handleChangeEvent"
                              />
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                        <v-expansion-panel class="cts-expand-permission mb-3">
                          <v-card class="cts-permissions-card" tile outlined height="100%">
                            <v-expansion-panel-header class="pa-1">
                              <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                <h4 class="d-flex flex">
                                  <v-icon left size="19" class="mr-1">{{ const_icons.PROCESSES }}</v-icon>
                                  {{ $vuetify.lang.t('$vuetify.groupProcessesAndSubaccounts') }}
                                </h4>
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <admin-processes-and-subaccounts
                                :icon="const_icons.PROCESSES"
                                :master-account-admin="group.original[const_global.GROUP_MASTER_ACCOUNT_ADMIN]"
                                :subaccounts="group.original['subaccounts']"
                                :title="$vuetify.lang.t('$vuetify.groupProcessesAndSubaccounts')"
                              />
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                        <v-expansion-panel class="cts-expand-permission mb-3">
                          <v-card class="cts-permissions-card" tile outlined height="100%">
                            <v-expansion-panel-header class="pa-1">
                              <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                <h4 class="d-flex flex">
                                  <v-icon left size="19" class="mr-1">{{ const_icons.LINKED }}</v-icon>
                                  {{ $vuetify.lang.t('$vuetify.groupLinked') }}
                                </h4>
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <admin-linked-groups
                                v-if="available_groups"
                                :icon="const_icons.LINKED"
                                :available_groups="available_groups"
                                :title="$vuetify.lang.t('$vuetify.groupLinked')"
                                :group="group.original"
                                @change="handleChangeEvent"
                              />
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="hidden-sm-and-up ma-0 py-3 cts-button-save-title justify-center cts-w-100">
            <v-col cols="6">
              <v-btn
                v-if="checkPermission(const_permissions.GROUP_EDIT)"
                class="cts-link cts-button-standard-block"
                block
                @click.prevent="save"
                :title="$vuetify.lang.t('$vuetify.save')"
              >
              {{ $vuetify.lang.t('$vuetify.save') }}
                <v-icon small class="float-right">{{const_icons.SAVE}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-scroll-y-transition>
    <v-row dense v-if="!group.original">
      <loading-container/>
    </v-row>
  </v-container>
</template>

<script>

import const_icons from "@/constants/icons"
import const_global from "@/constants/global"
import AdminGroupInfo from "./groupView/adminGroupInfo"
import AdminUsersTable from "./groupView/adminUsersTable"
import AdminPermissions from "./groupView/adminPermissions"
import ViewTitle from "../../../structures/viewTitle"
import AdminProcessesAndSubaccounts from "./groupView/adminProcessesAndSubaccounts"
import {
  ACTIONS as ADMINISTRATION_ACTIONS,
  GETTERS as ADMINISTRATION_GETTERS,
  NAMESPACE as ADMINISTRATION_NAMESPACE,
  STATE as ADMINISTRATION_STATE
} from "@/constants/vuex/administration"
import const_permissions from "@/constants/permissions";
import LoadingContainer from "../../../structures/loadingContainer";
import AdminLinkedGroups from "./groupView/adminLinkedGroups";
import {getGroupDetails, saveGroupDetails} from "@/services/groupServices"
import {redirect} from "@/util/router_utils"
import {checkPermission} from "@/util/store_utils"
import {getObjectWithoutReactivity, showToast} from "@/util/utils"
import {ADMINISTRATION, GROUPS} from "@/constants/router/routes"

export default {
    name: "GroupView",
    components: {
      AdminLinkedGroups,
      LoadingContainer,
      AdminProcessesAndSubaccounts,
      ViewTitle,
      AdminPermissions,
      AdminUsersTable,
      AdminGroupInfo
    },
    data() {
      return {
        checkPermission,
        const_icons,
        const_global,
        const_permissions,
        users: [],
        search: '',
        processes: [],
        subaccounts: [],
        available_groups: [],
        isEditing: {
          text: this.$vuetify.lang.t('$vuetify.save'),
          event: "groupUpdate",
          args: true
        },
        hasChanges: false,
        contextMessage: false,
        error: null,
        errorMessage: ''
      }
    },
    computed: {
      group() {
        let group = getObjectWithoutReactivity(this.$store.getters[`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_GETTERS.G_GROUP_DETAILS}`])
        if (group) {
          this.setAvailableGroups(group.linked_groups, group.id)
        }

        const copyGroup = {...group}

        return {
          original: group,
          copy: copyGroup
        }
      }
    },
    methods: {
      handleChangeEvent(value) {
        this.hasChanges = true
        if (value) this.group.original[value.updatedValueName] = value.updatedValue
      },
      setAvailableGroups(linked_groups, group_id) {
        let available_groups = this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_GROUPS]

        available_groups.forEach(group => {
          let linked = linked_groups.find(id => id == group.id) != undefined

          let groupProps = {
            id: group.id,
            name: group.name,
            description: group.description,
            master_account_admin: group.master_account_admin,
            linked_group: linked
          }

          if( group_id != groupProps.id ){
            this.available_groups.push(groupProps)
          }
        })
      },
      backToMenu(section) {
        if (section === 'admin') {
          redirect(ADMINISTRATION)
        }
        if (section === 'groups') {
          redirect(GROUPS)
        }
      },
      save() {
        this.group.original.linked_groups = []
        this.available_groups.forEach(ag => {
          if( ag.linked_group ) {
            this.group.original.linked_groups.push(ag.id)
          }
        })

        saveGroupDetails(this.group.original).then((response) => {
          let error = false
          if (response.data.error != 0) {
            error = true
          }

          showToast(error, this.$vuetify.lang.t(`$vuetify.${response.data.message}`).replace(':group_name:', response.data.extra.group_name))
          redirect(GROUPS)

        })
      }
    },
    created() {
      this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUP_DETAILS}`, null)
      getGroupDetails().then((response) => {
        this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUP_DETAILS}`, response.data)
      })
    }
  }
</script>
