<template>
  <div class="resource-table">
    <v-expansion-panels class="px-0" v-model="openedPanel">
      <v-expansion-panel
        class="extrafile-panel px-0"
        :disabled="requiredFiles.length === extraFiles.length"
      >
        <v-expansion-panel-header class="px-lg-2 py-lg-0">
          <v-list-item class="pa-0">
            <v-icon
              id="resource-table-status-icon"
              v-if="requiredFiles.length === extraFiles.length"
              large
              class="extrafile-panel-header-icon my-2 pa-1"
              color="green"
            >
              done
            </v-icon>
            <v-icon
              id="resource-table-status-icon"
              v-else-if="extraFiles.length > 0 && requiredFiles.length > extraFiles.length"
              large
              class="extrafile-panel-header-icon my-2 pa-1"
              color="var(--primary)"
            >
              hourglass_bottom
            </v-icon>
            <v-icon
              id="resource-table-status-icon"
              v-else
              large
              class="extrafile-panel-header-icon my-2 pa-1"
              color="var(--primary)"
            >
              hourglass_empty
            </v-icon>
            
            <v-list-item-content two-line class="pl-2 pl-lg-5 py-1 cts-font-size-4">
              <span id="resource-table-panel-allFilesAdded" v-if="requiredFiles.length === extraFiles.length">{{ $vuetify.lang.t('$vuetify.batchModeResourceTableAllFilesAdded') }}</span>
              <span id="resource-table-panel-allFilesPending" v-else v-html="$vuetify.lang.t('$vuetify.batchModeResourceTableNOfFilesFound').replace('{nOfRequiredFiles}', requiredFiles.length - extraFiles.length)"></span>
              <span id="resource-table-panel-expand-more" v-if="requiredFiles.length !== extraFiles.length">{{ $vuetify.lang.t('$vuetify.batchModeResourceTableExpandText') }}</span>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="extrafile-panel-content pa-0">
          <v-divider style="background-color: var(--primary)" class="mx-4"></v-divider>
          <v-list class="pt-0">
            <v-virtual-scroll
              height="200"
              item-height="48"
              :items="requiredFiles.filter(x => !extraFiles.map(x => x.name).includes(x))"
            >
              <template v-slot:default="{ item }">
                <v-list-item class="cts-font-size-4 extrafile-panel-content-row mx-4 pt-2 pb-lg-2 mb-lg-2 pb-lg-1">
                  <v-row style="height: 48px !important">
                    <v-avatar
                      v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
                      :size="32"
                      class="mr-2 mt-lg-4 extrafile-panel-header-icon"
                    >
                      <span
                        class="cts-font-size-5 font-weight-medium"
                        style="color: var(--primary); font-size: 10px !important;"
                      >
                        {{ item.split(".").length > 1 ? item.split(".")[1].toUpperCase() : ''}}
                      </span>
                    </v-avatar>
                    <v-list-item-content class="py-1 py-lg-1 px-0 pt-lg-5">
                      <span>
                        <strong>{{ item }}</strong>
                        &nbsp;
                        {{ csvData.map(x => Object.values(x).filter(x => x.includes(item)).length).reduce((acc, curr) => curr + acc) }}
                        {{ $vuetify.lang.t('$vuetify.batchModeResourceTableFoundOnNRows') }}
                      </span>
                      
                    </v-list-item-content>
                    <v-list-item-action class="ma-lg-0 mt-lg-3 py-lg-0">
                      <span>{{ $vuetify.lang.t('$vuetify.pending') }}</span>
                    </v-list-item-action>
                  </v-row>
                  
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

  </div>
</template>

<script>
import { randomColor } from "@/util/utils"
  export default {
    name: "ResourceTable",
    props: {
      requiredFiles: Array,
      extraFiles: Array,
      csvData: Array
    },

    data() {
      return {
        openedPanel: null,
        extensionColors: [
          { extension: "pdf", color: "primary-alternative" },
          { extension: "html", color: "primary" },
          { extension: "htm", color: "primary" },
        ]
      }
    },

    methods: {
      closeAllPanels () {
        this.openedPanel = null
      },

      generateColorsForExtensions(files) {
        const extensions = Array.from(new Set(files.map(x => x.split(".")[1])))
        const colors = []

        while (colors.length < extensions.length) {
          const color = randomColor()
          if(!colors.includes(color)) {
            colors.push(color)
          }
        }

        return extensions.map((x, index) => ({ extension: x, color: colors[index] }))
      }
    },

    watch: {
      extraFiles: {
        handler(newVal) {
          if (newVal.length === this.requiredFiles.length) {
            this.closeAllPanels()
          }
        },

        deep: true
      }
    }
  }
</script>

<style lang="scss">
  .resource-table {
    .v-expansion-panels {
      z-index: 0 !important;
    }
  }
  .extrafiles-column {
    .v-virtual-scroll__item {
      // padding: 2px !important;
    }
  }

  .extrafile-panel {
    border: 1px solid var(--card-border);
    border-radius: 4px !important;

    // .v-expansion-panel-header {
    //   padding: 0 !important;
    // }

    & .v-expansion-panel-content__wrap {
      padding: 4px !important;
    }

    &-content-row {
      border-bottom: 1px solid var(--card-border);
    }

    &::after {
      border: none !important;
    }

    &-header-icon {
      border: 1px solid var(--card-border);
      border-radius: 4px;
      background-color: var(--lighten);
    }
  }

</style>