<template>
    <v-container class="cts-main-container cts-main-view col-12 pa-0 pt-2">
        <v-row class="ma-0" dense>
            <v-col class="col-lg-offset-1 mx-auto cts-view-header" cols="10" lg="10" md="10" sm="10" xl="10">
                <v-row dense>
                    <v-col cols="12" lg="10" md="10" sm="10" xl="10">
                        <view-title
                          :icon="const_icons.ADMINISTRATION"
                          :subtitle="$vuetify.lang.t('$vuetify.administrationDescription')"
                          :title="$vuetify.lang.t('$vuetify.administrationTitle')"
                          idName="administration"
                        />
                    </v-col>
                </v-row>
                <!--    Aquí para cambiar el theme se llama a ese componente  -->

                <!--    <v-row dense class="ma-0 mt-2">-->
                <!--      <div class="col-10 offset-1">-->
                <!--        <div class="col-3">-->
                <!--          <SwichTheme></SwichTheme>-->
                <!--        </div>-->
                <!--      </div>-->
                <!--    </v-row>-->

            </v-col>
        </v-row>
        <v-row class="ma-0 mt-2" dense>
            <div class="col-10 offset-1">
                <v-fade-transition v-for="(section ,index) in sections" :key="index">
                    <v-col v-if="section.allowed" class="py-2 d-block float-left" cols="12" lg="4" md="6" sm="6">
                        <items-card :item="section"/>
                    </v-col>
                    <v-col v-else class="py-2 d-block float-left" cols="12" lg="4" md="6" sm="6">
                        <items-card :item="section" disabled="true"/>
                    </v-col>
                </v-fade-transition>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import const_icons from '@/constants/icons'
import ViewTitle from "../components/structures/viewTitle"
// import SwichTheme from "../components/SwichTheme"
import ItemsCard from "../components/sections/administration/administrationView/itemsCard"
import const_global from "@/constants/global"
import {checkPermission} from "@/util/store_utils"
import {GROUPS, USERS} from "@/constants/router/routes"

export default {
    name: "Contacts",
    components: {ItemsCard, ViewTitle},
    data: function () {
        return {
            const_icons,
            sections: [
                {
                    code: 'groups',
                    allowed: checkPermission(`${const_global.SECTION}_${GROUPS.NAME}`),
                    name: this.$vuetify.lang.t('$vuetify.groupsTitle'),
                    description: this.$vuetify.lang.t('$vuetify.groupsDescription')
                },
                {
                    code: 'users',
                    allowed: checkPermission(`${const_global.SECTION}_${USERS.NAME}`),
                    name: this.$vuetify.lang.t('$vuetify.usersTitle'),
                    description: this.$vuetify.lang.t('$vuetify.usersDescription')
                },
            ]
        }
    }
}
</script>
