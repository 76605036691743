<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-view col-12">
    <v-row dense
           class="ma-0">
      <v-col cols="12"
             class="pa-0">
        <div class="col-12 col-lg-10 col-lg-offset-1 mx-auto cts-view-header">
          <view-title 
            :icon="const_icons.CONTACTS"
            :title="$vuetify.lang.t('$vuetify.contactsTitle')"
            :subtitle="$vuetify.lang.t('$vuetify.contactsDescription')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import const_icons from '@/constants/icons';
  import ViewTitle from "../components/structures/viewTitle";

  export default {
    name: "Contacts",
    components: {ViewTitle},
    data: function () {
      return {
        const_icons
      }
    },
    methods: {},
    mounted() {

    },
    created() {

    },
    computed: {}
  }
</script>

<style scoped>

</style>
