<template>
  <div>
    <v-card v-if="mode=='compact'" class="cts-h-100" style="cursor: pointer;border-radius: 2px !important;">
      <v-img :id="`${this.idName}-img-logo`" :src="src" height="100px"></v-img>
      <v-card-title :id="`${this.idName}-text-title`" class="cts-section-card-title pa-2">{{title}}</v-card-title>
      <v-card-subtitle :id="`${this.idName}-text-subtitle`" class="cts-section-card-subtitle pa-2">
        {{subtitle}}
      </v-card-subtitle>
    </v-card>
    <v-card v-if="mode=='box-recovery'" class="cts-card-recovery-mode mt-4 mb-0" :disabled="icon === 'contact_phone' && !isSupportTime" style="cursor: pointer;border-radius: 2px !important;" :dark="dark">
      <v-row class="col-12 ma-0 pa-0">
        <v-col cols="2" sm="2" class="pa-1">
          <v-icon :id="`${this.idName}-img-logo-hidden_sm_and_up`" aria-hidden="false" class="pa-2 hidden-sm-and-up" dark size="30">{{icon}}</v-icon>
          <v-icon :id="`${this.idName}-img-logo-hidden_xs_only`" aria-hidden="false" class="pa-3 hidden-xs-only" dark size="40">{{icon}}</v-icon>
        </v-col>
        <v-col cols="10" sm="9" class="pa-1">
          <v-card-title :id="`${this.idName}-text-title`" class="cts-section-card-title mt-1 px-2 py-0 cts-color-lighten">{{title}}</v-card-title>
          <v-card-subtitle :id="`${this.idName}-text-subtitle}`" class="cts-section-card-subtitle px-2 py-1">{{subtitle}}</v-card-subtitle>
        </v-col>
        <v-col cols="1" sm="1" class="pa-1 hidden-xs-only">
          <v-icon :id="`${this.idName}-link-arrow`" aria-hidden="false" size="25" class="cts-arrow-icon ma-auto d-block">keyboard_arrow_right</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "CardWithImage",
    props: {
      title: String,
      subtitle: String,
      code: String,
      src: String,
      mode: String,
      tags: String,
      dark: Boolean,
      icon: String,
      isSupportTime: Boolean,
      idName: String
    },
    data() {
      return {}
    },
  }
</script>

<style scoped>

</style>
