<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-view col-12 text-center">
    <v-row>
      <v-col class="cts-forbidden-box">
        <v-row dense class="ma-0 justify-center pb-4">
          <v-icon aria-hidden="false" large>{{ const_icons.SUBACCOUNT }}</v-icon>
        </v-row>
        <v-row dense class="ma-0 justify-center">
          <span style="max-width: 300px">{{ $vuetify.lang.t('$vuetify.noSubaccountsMessage') }}</span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import const_icons from "@/constants/icons";

  export default {
    name: "WithoutSubaccounts",
    data: function () {
      return {
        const_icons
      }
    },
    methods: {

    },
    mounted() {

    },
    created() {

    },
    computed: {}
  }
</script>

<style scoped>

</style>
