export const NAMESPACE = "location"

export const STATE = {
  S_AVAILABLE_LOCATIONS: "availableLocations",
  S_CURRENT_LOCATION: "currentLocation",
  S_LOCALES: "locales"
}

export const MUTATIONS = {
  M_LOCATION: "mutateCurrentLocation",
  M_LOCALES: "mutateLocales",
  M_AVAILABLE_LOCATIONS: "mutateAvailableLocations"
}

export const ACTIONS = {
  A_SET_LOCATION: "setCurrentLocation",
  A_SET_AVAILABLE_LOCATIONS: "setAvailableLocations",
  A_UPDATE_LOCALES: "setLocales",
}

export const GETTERS = {
  G_CURRENT_LOCATION: "getCurrentLocation",
  G_LOCALES: "getLocales",
  G_AVAILABLE_LOCATIONS: "getAvailableLocations",

}

export default {
  NAMESPACE
}
