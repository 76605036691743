<template>
  <vChart v-if="loaded && chartData['days']" class="" ref="chart" :options="option" :autoresize="true"></vChart>
</template>

<script>
  import 'echarts/lib/chart/pie'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/legend'
  import vChart from 'vue-echarts'
  import dayjs from "dayjs";

  export default {
    name: "pieChart",
    components: {vChart},
    props: {
      dates: Array,
      colors: Array,
      resolution: Number,
      chartData: Object
    },
    data() {
      return {
        loaded: false,
        option: {
          height: 250,
          backgroundColor: 'transparent',
          animation: true,
          animationDuration: 500,
          tooltip: {
            trigger: 'item',
            position: ['','60%'],
            formatter: '{a} <br/><span class="cts-w-100">{b}: {c} ({d}%)</span>'
          },
          legend: {
            show: false,
            type: 'plain',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20
          },
          series: []
        },
      }
    },
    
    created() {
      this.fillStatistics(this.chartData) 
    },

    methods: {
      fillStatistics(result) {
        this.option.series.length = 0

        let arr_dates = []

        if (this.dates.length === 2) {
          const [a_date, b_date] = this.dates.map(x => dayjs(x))

          let first_date = b_date.isAfter(a_date) ? a_date : b_date
          const second_date = b_date.isAfter(a_date) ? b_date : a_date

          while (first_date.isSameOrBefore(second_date)) {
            let day = first_date.format('D MMM')
            let day_to_find = first_date.format('YYYYMMDD')

            if (result.days && result.days[day_to_find]) {
              arr_dates[day] = result.days[day_to_find]
            } else {
              arr_dates[day] = {
                processes: {},
                total_count: 0
              }
            }

            first_date = first_date.add(1, 'd')
          }

        } else {
          arr_dates = result.days
        }

        let node_data = {
          name: this.$vuetify.lang.t('$vuetify.statisticsProcessTitle'),
          type: 'pie',
          color: this.colors,
          radius: '55%',
          center: ['40%', '50%'],
          data: [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }

        Object.values(arr_dates).forEach(day_value => {
          Object.entries(day_value.processes).forEach(([process_name, process_value]) => {

            if (!node_data.data.find(v => v.name === process_value.name)) {
              let days_values = 0
              Object.values(arr_dates).forEach((day_value) => {
                if (day_value.processes[process_name]) {
                  days_values = days_values + day_value.processes[process_name].total_count
                }
              })


              node_data.data.push({
                name: process_value.name,
                value: days_values
              })

            }
          })
        })
        this.option.series.push(node_data)
        this.loaded = true
      }
    }
  }
</script>
