<template>
  <v-col class="pa-0" cols="12">
    <v-list-item class="pa-0 transaction-file-card px-2">
      <v-list-item-avatar>
        <v-img :src="documentImgSrc(file.type)" aspect-ratio="1/1" cover
               width="20"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title :title="getDocumentName(file.key)">{{getDocumentName(file.key)}}</v-list-item-title>
        <v-list-item-subtitle>{{file.type}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn :loading="downloadLoading" color="var(--primary-alternative)" icon outlined
               style="border-color: var(--primary-alternative)"
               @click="handleClickDocument(file)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-col>
</template>

<script>

import {getDocumentBase64} from "@/services/otherServices";
import {generateFileDownload} from "@/util/utils";

export default {
  name: "TransactionFilesList",

  props: {
    file: Object,
    certificateVisible: Boolean
  },

  data() {
    return {
      downloadLoading: false
    }
  },

  computed: {
    internalFiles() {
      let files = this.files
      if (this.certificateVisible && !files.find(file => file.key === 'certificate')) {
        files.push({
          key: 'certificate',
          type: 'pdf'
        })
      }
      return files
    }
  },

  methods: {
    getDocumentName(documentKey) {
      if (documentKey === 'certificate') {
        return this.$vuetify.lang.t('$vuetify.certificate')
      }
      return documentKey.substring(documentKey.lastIndexOf('/') + 1)
    },
    documentImgSrc(documentType) {
      if (documentType.toLowerCase() === 'pdf') {
        return require('@/assets/images/icons/pdf.png')
      }

      if (documentType.toLowerCase() === 'html') {
        return require('@/assets/images/icons/html.png')
      }

      return require('@/assets/images/icons/other-docs.png')
      // return documentType.toLowerCase() === 'pdf' ? require('@/assets/images/icons/pdf.png') : require('@/assets/images/icons/html.png')
    },

    async handleClickDocument(document) {
      if (document.key === 'certificate') {
        this.downloadLoading = true
        this.$emit('downloadCertificate',document)
        setTimeout(() => {
          this.downloadLoading = false
        }, 1000)
        return
      }

      this.downloadLoading = true

      let fileObject

      fileObject = await getDocumentBase64(document.key)
      fileObject = fileObject.data


      generateFileDownload(fileObject.file_content, fileObject.file_name, document.type)

      this.downloadLoading = false
    }

  }

}
</script>

<style lang="scss">
.transaction-file-card {
  border: solid 1px var(--card-border);
  border-radius: 4px;

}
</style>