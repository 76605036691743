<template>
  <v-expand-transition class="cts-modal-terms">
    <v-dialog v-model="dialog" max-width="900px" scrollable @click:outside="closeDialog()">
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2 cts-dialog-terms-header">
          <dialog-title
            v-if="policiesBox"
            event="closeModalTerms"
            icon="import_contacts"
            :text="$vuetify.lang.t('$vuetify.privacyPoliciesCompliance')"
            :close="true"
            @closeModalTerms="closeDialog"
          />

          <dialog-title
            v-if="termsBox"
            event="closeModalTerms"
            icon="import_contacts"
            :text="$vuetify.lang.t('$vuetify.termsAndConditionsCompliance')"
            :close="true"
            @closeModalTerms="closeDialog"
          />

          <dialog-title
            v-if="cookiesBox"
            event="closeModalTerms"
            icon="import_contacts"
            :text="$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')"
            :close="true"
            @closeModalTerms="closeDialog"
          />
          <dialog-title
            v-if="cookiesPolicyBox"
            event="closeModalTerms"
            icon="import_contacts"
            :text="$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')"
            :close="true"
            @closeModalTerms="closeDialog"
          />
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row dense class="ma-0">
            <v-col v-if="termsBox" cols="12" xl="12" lg="12" md="12" sm="12">
              {{ $vuetify.lang.t('$vuetify.termsConditions1') }}<br><br>
              <strong>{{ $vuetify.lang.t('$vuetify.termsConditionsTitular') }}:</strong> Customer Communications Tecknalia, S.L.<br>
              <strong>{{ $vuetify.lang.t('$vuetify.termsConditionsNIF') }}:</strong> B-86414000<br>
              <strong>{{ $vuetify.lang.t('$vuetify.termsConditionsDomicilioSocial') }}:</strong> Avenida de la Recomba,
              14.28914 Leganés (Madrid)<br>
              <strong>{{ $vuetify.lang.t('$vuetify.termsConditionsDatosRegistrales') }}:</strong> Registro Mercantil de
              Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.<br>
              <strong>{{ $vuetify.lang.t('$vuetify.telephone') }}:</strong> <a v-if="screenWidth < 480" href="tel:91 689 56 65" title="Email Mailteck" class="cts-color-tertiary"> 91 689 56 65 </a><span class="pa-0 ma-0" v-else>91 689 56 65</span><br>
              <strong>{{ $vuetify.lang.t('$vuetify.email') }}:</strong> <a href="mailto:info@customercomms.com"
                                                                           class="cts-color-tertiary"
                                                                           title="Email Customer Comms"><u>info@customercomms.com</u></a><br>
              <strong>{{ $vuetify.lang.t('$vuetify.termsConditionsDPOEmail') }}:</strong> <a
                href="mailto:dpo@customercomms.com" class="cts-color-tertiary"
                title="Delegado de Protección de Datos"><u>dpo@customercomms.com</u></a><br><br>
              <strong>I.- {{ $vuetify.lang.t('$vuetify.termsConditionsObjetoTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsObjetoParrafo1') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsObjetoParrafo2') }}<br><br>
              <strong>II.- {{ $vuetify.lang.t('$vuetify.termsConditionsUsuariosTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsUsuariosParrafo1') }}<br><br>
              <strong>III.- {{ $vuetify.lang.t('$vuetify.termsConditionsAccesoAPPTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsAccesoAPPParrafo1') }}<br><br>
              <strong>IV.- {{ $vuetify.lang.t('$vuetify.termsConditionsUsoAPPTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsUsoAPPParrafo1') }}<br><br>
              <strong>V.- {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo1') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo2') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo3') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo4') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo5') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsResposabilidadParrafo6') }}<br><br>
              <strong>VI.- {{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualParrafo1') }} <br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualParrafo2') }}<br><br>
              <ul>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualListaELemento1') }}
                </li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualListaELemento2') }}
                </li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualListaELemento3') }}
                </li>
              </ul>
              <br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualParrafo3') }}<br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsPropiedadIntelectualParrafo4') }}<br><br>
              <strong>VII.-
                {{ $vuetify.lang.t('$vuetify.termsConditionsModificacionUnilateralTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsModificacionUnilateralParrafo1') }}<br><br>
              <strong>VIII.- {{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasParrafo1') }}<br><br>
              <ul>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento1') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento2') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento3') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento4') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento5') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento6') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento7') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento8') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento9') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento10') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento11') }}</li>
                <li>{{ $vuetify.lang.t('$vuetify.termsConditionsExclusionGarantiasListaELemento12') }}</li>
              </ul>
              <br><br>
              <strong>IX.- {{ $vuetify.lang.t('$vuetify.termsConditionsProteccionDatosTitulo') }}</strong><br><br>
              {{ $vuetify.lang.t('$vuetify.termsConditionsProteccionDatosParrafo1') }} <a
                class="cts-color-tertiary underline"
                @click="goPrivacyPolicy()"
                :title="$vuetify.lang.t('$vuetify.termsConditionsProteccionDatosTitle')"><u>{{$vuetify.lang.t('$vuetify.termsConditionsProteccionDatos')}}</u></a>.<br><br>
              <strong>X.- {{$vuetify.lang.t('$vuetify.termsConditionsLeyJurisdiccionTitulo')}}</strong><br><br>
              {{$vuetify.lang.t('$vuetify.termsConditionsLeyJurisdiccionParrafo1')}}<br><br>
            </v-col>

            <v-col v-if="policiesBox" cols="12" xl="12" lg="12" md="12" sm="12">
              <h3>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsTitle') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsParrafo1') }}</p>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsResponsable1Title') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>Mailteck, S.A. </span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsIdentidadTitle') }}: </strong>
                    Mailteck, S.A.<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsNIFTitle') }}: </strong>
                    A-31501901<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDireccionTitle') }}: </strong> Avenida
                    de la Recomba nº 12-14 - 28914 Leganés (Madrid)<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsTelefonoTitle') }}: </strong><a v-if="screenWidth < 480" href="tel:91 304 49 41" title="Email Mailteck" class="cts-color-tertiary"> 91 304 49 41 </a><span class="pa-0 ma-0" v-else>91 304 49 41</span><br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsCorreoTitle') }}: </strong>
                     <a href="mailto:info@mailteck.com" title="Email Mailteck" class="cts-color-tertiary">info@mailteck.com</a><br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDPDTitle') }}: </strong> <a href="mailto:dpo@mailteck.com" title="Delegado de Protección de Datos de Mailteck" class="cts-color-tertiary">dpo@mailteck.com</a>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsResponsable2Title') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>Customer Communications Tecknalia, S.L. </span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsIdentidadTitle') }}:</strong>
                    Customer Communications Tecknalia, S.L.<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsNIFTitle') }}:</strong>
                    B-86414000<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDireccionTitle') }}:</strong> Avenida
                    de la Recomba nº 12-14 - 28914 Leganés (Madrid)<br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsTelefonoTitle') }}:</strong> <a v-if="screenWidth < 480" href="tel:91 689 56 65" title="Email Mailteck" class="cts-color-tertiary"> 91 689 56 65 </a><span class="pa-0 ma-0" v-else>91 689 56 65</span><br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsCorreoTitle') }}:</strong> <a href="mailto:info@customercomms.com" class="cts-color-tertiary" title="Email Customer Comms">info@customercomms.com</a><br>
                    <strong> {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDPDTitle') }}:</strong> <a href="mailto:dpo@customercomms.com" class="cts-color-tertiary" title="Delegado de Protección de Datos de Customer Comms">dpo@customercomms.com</a>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadTitle') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadIntro') }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadParrafo1') }}<br><br>
                    {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadParrafo2') }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsLegitimacionTitle') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsLegitimacionIntro') }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsLegitimacionParrafo1') }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDestinatariosTitle') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDestinatariosIntro') }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDestinatariosParrafo1') }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosTitle') }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosIntro') }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem1ListaTitulo')
                          }}</strong>&nbsp;{{
                          $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem1ListaDescripcion')
                        }}
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem2ListaTitulo')
                          }}</strong>
                        {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem2ListaDescripcion') }}
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem3ListaTitulo')
                          }}</strong>&nbsp;{{
                          $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem3ListaDescripcion')
                        }}
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem4ListaTitulo')
                          }}</strong>
                        {{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem4ListaDescripcion') }}
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem5ListaTitulo')
                          }}</strong>&nbsp;{{
                          $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem5ListaDescripcion')
                        }}
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem6ListaTitulo')
                          }}</strong>&nbsp;{{
                          $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem6ListaDescripcion')
                        }}
                        (<a href="http://www.aepd.es" target="_blank" rel="noopener" title="AEPD" class="cts-color-tertiary">www.aepd.es</a>).
                      </li>
                      <li>
                        <strong>{{
                            $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem7ListaTitulo')
                          }}</strong>&nbsp;{{
                          $vuetify.lang.t('$vuetify.privacyPoliciesConditionsDerechosElem7ListaDescripcion')
                        }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong>{{
                              $vuetify.lang.t('$vuetify.privacyPoliciesConditionsInformacionAdicionalTitle')
                            }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span>{{
                                  $vuetify.lang.t('$vuetify.privacyPoliciesConditionsInformacionAdicionalIntro')
                                }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsInformacionAdicionalLista1') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsInformacionAdicionalLista2') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsInformacionAdicionalLista3') }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong style="line-height: 1.2em;">{{
                              $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacion1Title')
                            }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span style="line-height: 1.2em;">{{
                                  $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionIntro')
                                }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h3>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionTituloH') }}</h3>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP1') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP2') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP3') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP4') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP5') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP6') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP7') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP8') }}</p>
                    <ul>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista1') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista2') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista3') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista4') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista5') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista6') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista7') }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="3">
                          <strong style="line-height: 1.2em;">{{
                              $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacion2Title')
                            }}</strong>
                        </v-col>
                        <v-col cols="9" class="text--secondary">
                          <v-fade-transition leave-absolute>
                            <span key="0">
                              <span style="line-height: 1.2em;">{{
                                  $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacio2nIntro')
                                }}</span>
                            </span>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h3>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionTituloH') }}</h3>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP1') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionCCTP2') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP3') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP4') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP5') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP6') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionP7') }}</p>
                    <p>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionCCTP8') }}</p>
                    <ul>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista1') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionCCTLista2') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista3') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista4') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista5') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista6') }}</li>
                      <li>{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsPoliticaInformacionLista7') }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col v-if="cookiesPolicyBox" cols="12" xl="12" lg="12" md="12" sm="12">
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalIntro') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalIntroP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalIntroP2') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalIntroP3') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalIntroP4') }}</p>

              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalResponsibility') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalResponsibilityP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalResponsibilityP2') }} <a
                  href="mailto:dpo@customercomms.com" class="cts-color-tertiary underline">dpo@customercomms.com</a></p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalWhatIsCookie') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalWhatIsCookieP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalWhatIsCookieP2') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalWhatIsCookieP3') }}</p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalCookieTypes') }}</h3><br>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalCookiesInstall') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalCookiesInstallP1') }}</p>

              <div class="hidden-md-and-down">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="tableCookiesData"
                  class="elevation-0 col-12 px-0 px-md-2"
                  calculate-widths
                  sort-desc
                  hide-default-footer
                >
                </v-data-table>
                <br>
              </div>

              <div class="hidden-lg-and-up">
                <v-expansion-panels accordion class="elevation-0">
                  <v-expansion-panel class="cts-data-info-panel" style="border-color: var(--lighten)" v-for="cookie in tableCookiesData" :key="cookie.description">
                    <v-expansion-panel-header>{{ cookie.cookieType }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row dense>
                        <v-col cols="12" class="px-0">
                          <h4 class="font-weight-bold">{{ $vuetify.lang.t('$vuetify.description') }}</h4>
                        </v-col>
                        <p>{{cookie.description}}</p>
                      </v-row>

                      <v-row dense>
                        <h4 class="font-weight-bold">{{ $vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadTitle') }}</h4>
                        <p>{{cookie.purpose}}</p>
                      </v-row>

                      <v-row dense>
                        <v-col cols="12" class="px-0">
                          <h4 class="font-weight-bold">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTreatmentTerm') }}</h4>
                        </v-col>

                        <p>{{cookie.treatmentTerm}}</p>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br>
              </div>

              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookies') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesP2') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesP3') }}</p>
              <ul>
                <li>
                  <a
                    class="cts-color-tertiary underline"
                    :href="vendorLinks.chrome"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI1') }}
                  </a>
                </li>
                <li>
                  <a
                    v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'"
                    class="cts-color-tertiary underline"
                    :href="vendorLinks.apple.es"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI2') }}
                  </a>
                  <a
                    v-else
                    class="cts-color-tertiary underline"
                    :href="vendorLinks.apple.intl"
                    target="_blank"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI2') }}
                  </a>
                </li>
                <li>
                  <a
                    v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'"
                    class="cts-color-tertiary underline"
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
                    target="_blank"
                    rel="noopener"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI3') }}
                  </a>
                  <a
                    v-else
                    class="cts-color-tertiary underline"
                    href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox#w_block-cookies-for-a-single-website"
                    target="_blank"
                    rel="noopener"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI3') }}
                  </a>
                </li>
                <li>
                  <a
                    v-if="lang === 'es' || lang === 'ca' || lang === 'gl' || lang === 'eu'"
                    class="cts-color-tertiary underline"
                    href="https://support.microsoft.com/es-es/microsoft-edge"
                    target="_blank"
                    rel="noopener"
                  >
                    {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI4') }}
                  </a>
                  <a
                    v-else
                    class="cts-color-tertiary underline"
                    href="https://support.microsoft.com/en-US/microsoft-edge"
                    target="_blank"
                    rel="noopener"
                  >
                  {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI4') }}
                  </a>
                </li>
              </ul>
              <br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesP4') }}</p>
              <ul>
                <li><a class="cts-color-tertiary underline" href="https://www.criteo.com/privacy/disable-criteo-services-on-internet-browsers/">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI5') }}</a></li>
                <li><a class="cts-color-tertiary underline" href="https://youronlinechoices.eu/">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI6') }}</a></li>
                <li><a class="cts-color-tertiary underline" href="https://optout.networkadvertising.org/?c=1">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalBrowserConfigCookiesLI7') }}</a></li>
              </ul>
              <br>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUnavailableCookies') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUnavailableCookiesP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUnavailableCookiesP2') }}</p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalAcceptCookies') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalAcceptCookiesP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalAcceptCookiesP2') }}</p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUpgradeCookies') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUpgradeCookiesP1') }}</p>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalUpgradeCookiesP2') }}</p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalRecipientCookies') }}</h3>
              <br>
              <p>
                {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalRecipientCookiesP1') }}
                <a style="text-decoration: underline" @click="goPrivacyPolicy()">
                  {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalRecipientCookiesLinkCCOMMS')}}
                </a>
              </p>
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalContactInfoCookies') }}</h3><br>
              <p>
                {{ $vuetify.lang.t('$vuetify.cookiesPoliciesModalContactInfoCookiesP1') }}&nbsp;
                <a class="cts-color-tertiary underline" href="mailto:marketing@customercomms.com">
                  marketing@customercomms.com
                </a>
              </p>
            </v-col>
            <v-col v-if="cookiesBox" cols="12" xl="12" lg="12" md="12" sm="12" class="pb-8">
              <h3>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesCertySign') }}</h3><br>
              <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesIntroText') }}</p>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <strong>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesNecessaryCookies') }}</strong>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesNecessaryCookiesText') }}</p>
                    <v-data-table
                        dense
                        :headers="necessaryCookiesHeaders"
                        :items="necessaryCookiesData"
                        class="elevation-0 col-12 px-0 px-md-2"
                        sort-by="cookie"
                        calculate-widths
                        hide-default-footer
                    >
                      <template v-slot:item="{item}">
                        <tr>
                          <td class="text-start">
                            <h4 class="cts-color-tertiary pb-6">{{ item.cookie }}</h4>
                          </td>
                          <td class="text-start">
                            <h4 class="cts-color-tertiary pb-6">{{ item.description }}</h4>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <strong>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesUnnecessaryCookies') }}</strong>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="4" class="pa-0">
                        <v-checkbox class="cts-tabs-checkboxes cts-modal-checkbox pa-0 my-0 float-left" v-model="unnecessaryCookies" color="var(--primary)" aria-label="checkbox-active-user"></v-checkbox>
                        <h3 class="mt-0 cts-color-primary pa-0 cts-padding-small float-left" style="line-height: 25px">
                          {{ $vuetify.lang.t('$vuetify.cookiesPoliciesGoogleAnalyticsCheckboxText') }}</h3>
                      </v-col>
                    </v-row>
                    <p class="my-4">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesUnnecessaryCookiesText') }}</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <strong>{{ $vuetify.lang.t('$vuetify.cookiesPoliciesGoogleAnalyticsCookies') }}</strong>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="4" class="pa-0">
                        <v-checkbox class="cts-tabs-checkboxes cts-modal-checkbox pa-0 my-0 float-left"
                                    v-model="googleAnalyticsCookies" color="var(--primary)"
                                    aria-label="checkbox-active-user"></v-checkbox>
                        <h3 class="mt-0 cts-color-primary pa-0 cts-padding-small float-left" style="line-height: 25px">
                          {{ $vuetify.lang.t('$vuetify.cookiesPoliciesGoogleAnalyticsCheckboxText') }}</h3>
                      </v-col>
                    </v-row>
                    <p class="my-4">{{ $vuetify.lang.t('$vuetify.cookiesPoliciesGoogleAnalyticsCookiesText') }}</p>
                    <v-data-table
                        dense
                        :headers="GACookiesHeaders"
                        :items="GACookiesData"
                        class="elevation-0 col-12 px-0 px-md-2"
                        sort-by="cookie"
                        calculate-widths
                        hide-default-footer
                    >
                      <template v-slot:item="{item}">
                        <tr>
                          <td class="text-start">
                            <h4 class="cts-color-tertiary pb-6">{{ item.cookie }}</h4>
                          </td>
                          <td class="text-start">
                            <h4 class="cts-color-tertiary pb-6">{{ item.description }}</h4>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <v-col cols="12">
                  <v-btn class="cts-button-standard my-2 float-right" small
                         @click="saveCookies(unnecessaryCookies,googleAnalyticsCookies)">
                    {{ $vuetify.lang.t('$vuetify.save') }}
                    <v-icon aria-hidden="false" small class="ml-2">{{ const_icons.SAVE }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-expand-transition>
</template>

<script>
import DialogTitle from "../../structures/dialogTitle"
import { NAMESPACE as FUNCTIONALITY_NAMESPACE, STATE as FUNCTIONALITY_STATE, ACTIONS as FUNCTIONALITY_ACTIONS } from "@/constants/vuex/functionality"
import { NAMESPACE as LOCATION_NAMESPACE } from "@/constants/vuex/location"
import const_icons from "@/constants/icons";

export default {
  name: "footerViewTerms",
  components: {DialogTitle},
  props: {
    policies: Boolean,
    terms: Boolean,
    cookies: Boolean,
    cookiesPolicy: Boolean,
    close: Boolean
  },
  data() {
    return {
      const_icons,
      unnecessaryCookies: true,
      googleAnalyticsCookies: true,
      necessaryCookiesData: [
        {
          cookie: 'CScookiesPolicyAccepted',
          description: this.$vuetify.lang.t('$vuetify.cookiesNecessaryCookieDescription')
        }
      ],
      necessaryCookiesHeaders: [
        {text: this.$vuetify.lang.t('$vuetify.cookiesCookieMessage'), align: 'start', value: 'cookie', width: '25%', sortable: false},
        {text: this.$vuetify.lang.t('$vuetify.description'), value: 'description', align: 'start', width: '75%', sortable: false}
      ],
      unnecessaryCookiesData: [],
      unnecessaryCookiesHeaders: [
        {text: this.$vuetify.lang.t('$vuetify.cookiesCookieMessage'), align: 'start', value: 'cookie', width: '25%', sortable: false},
        {text: this.$vuetify.lang.t('$vuetify.description'), value: 'description', align: 'start', width: '75%', sortable: false}
      ],
      GACookiesData: [
        {
          cookie: '_ga',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAMessage')
        },
        {
          cookie: '_ga_C8Z6WV9GMM',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAFGenericMessage')
        },
        {
          cookie: '_ga_BT9F4YXY10',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAFGenericMessage')
        },
        {
          cookie: '_ga_EJW1FTLN0K',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAFGenericMessage')
        },
        {
          cookie: '_ga_LPMNR1YBN2',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAFGenericMessage')
        },
        {
          cookie: '_ga_BQHZZLN57Y',
          description: this.$vuetify.lang.t('$vuetify.cookiesGAFGenericMessage')
        }
      ],
      GACookiesHeaders: [
        {text: this.$vuetify.lang.t('$vuetify.cookiesCookieMessage'), align: 'start', value: 'cookie', width: '25%', sortable: false},
        {text: this.$vuetify.lang.t('$vuetify.description'), value: 'description', align: 'start', width: '75%', sortable: false}
      ],
      headers: [
        {
          id: 'cookieType',
          text: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableCookieType'),
          value: 'cookieType',
          cols: '1'
        },
        {
          id: 'description',
          text: this.$vuetify.lang.t('$vuetify.description'),
          value: 'description',
          cols: '3'
        },
        {
          id: 'purpose',
          text: this.$vuetify.lang.t('$vuetify.privacyPoliciesConditionsFinalidadTitle'),
          value: 'purpose',
          cols: '3'
        },
        {
          id: 'treatmentTerm',
          text: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTreatmentTerm'),
          value: 'treatmentTerm',
          cols: '1'
        },
      ],
      tableCookiesData: [
        {
          cookieType: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeAnalytics'),
          description: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeAnalyticsD'),
          purpose: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeAnalyticsF'),
          treatmentTerm: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeAnalyticsT'),
        },
        {
          cookieType: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeSelf'),
          description: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeSelfD'),
          purpose: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeSelfF'),
          treatmentTerm: this.$vuetify.lang.t('$vuetify.cookiesPoliciesModalDatatableTypeSelfT'),
        },
      ],
      policiesBox: false,
      cookiesBox: false,
      cookiesPolicyBox: false,
      termsBox: false,
      screenWidth: 0
    }
  },
  computed: {
    vendorLinks() {
      return  {
        chrome: `https://support.google.com/chrome/answer/95647?hl=${this.lang}`,
        apple: {
          es: `https://support.apple.com/${this.lang}-es/guide/safari/sfri11471/mac`,
          intl: `https://support.apple.com/${this.lang}-${this.lang}/guide/safari/sfri11471/mac`
        }
      }
    },

    lang: {
      get() {
        return this.$store.state[LOCATION_NAMESPACE]["currentLocation"]["code"]
      },
    },

    dialog: {
      get() {
        return this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_MESSAGE_TERMS]
      },
      set(value) {
        this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + FUNCTIONALITY_ACTIONS.A_SET_ALERT_MESSAGE_TERMS, value)

      }
    }
  },
  created() {
    this.screenWidth = screen.width
    this.policiesBox = this.policies
    this.cookiesBox = this.cookies
    this.cookiesPolicyBox = this.cookiesPolicy
    this.termsBox = this.terms
    setTimeout(()=>{
      let fakeModal = document.documentElement.getElementsByClassName('cts-dialog-terms-header')
      for (let i = 0; i <= fakeModal.length;i++) {
        if (fakeModal[i] != undefined && fakeModal[i].children.length === 0){
          fakeModal[i].style.display = "none";
        }
      }
    },1)
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },

    saveCookies(unnecessaryCookies, googleAnalyticsCookies) {
      this.unnecessaryCookies = [unnecessaryCookies, googleAnalyticsCookies]
      this.dialog = false
      this.$emit("saveUnnecessary", (this.unnecessaryCookies))
    },

    goPrivacyPolicy() {
      this.cookiesPolicyBox = false
      this.termsBox = false

      setTimeout(() => this.policiesBox = true, 1);
    },
  }
}
</script>

<style scoped>

</style>
