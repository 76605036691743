<template>
  <v-expand-transition>
    <v-dialog v-model="open" max-width="600px" scrollable @click:outside="closeModal()">
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title :text="$vuetify.lang.t('$vuetify.eventsInfo')" icon="import_contacts"/>
        </v-card-title>
        <v-card-text class="py-8">
          <v-list>
            <v-list-item v-for="event in eventsFiltered" :key="event.id" three-line>
              <v-list-item-avatar>
                <v-icon color="var(--tertiary)" large>{{getEventIcon(event.key)}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content style="align-items: start">
                <v-list-item-title>
                  {{event.event | capitalize}}
                </v-list-item-title>
                <v-list-item-subtitle style="-webkit-line-clamp:10">
                  {{$vuetify.lang.t('$vuetify.' + const_events[formatEventKey(event.key)].name)}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="cts-button-standard my-2" color="var(--primary-alternative)" dark
                 small @click="closeModal()">
            {{$vuetify.lang.t('$vuetify.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expand-transition>
</template>

<script>
import DialogTitle from "../../structures/dialogTitle";
import {ServerBus} from "@/main";
import const_events from "@/constants/events";
import {capitalize} from "@/util/utils";
import const_icons from "@/constants/icons";

export default {
  name: "TransactionEventsDetails",
  components: {DialogTitle},
  props: {
    events: Array
  },
  data() {
    return {
      open: true,
      const_events
    }
  },
  computed: {
    eventsFiltered() {
      /* not repeat events*/
      return this.events.filter(event => const_events[this.formatEventKey(event.key)]).sort((a, b) => {
        return const_events[this.formatEventKey(a.key)].timestamp - const_events[this.formatEventKey(b.key)].timestamp
      }).filter((event, index, self) =>
          index === self.findIndex((t) => (
            t.key === event.key
          ))
      )
    }

  },
  methods: {

    getEventIcon(eventKey) {
      if (typeof const_events[this.formatEventKey(eventKey)] != "undefined" && const_events[this.formatEventKey(eventKey)].icon !== "") {
        return const_events[this.formatEventKey(eventKey)].icon
      }

      return const_icons.EVENT_DEFAULT

    },
    closeModal() {
      this.open = false
      this.$emit('close')
      ServerBus.$emit("close", true)
    },
    formatEventKey(key) {
      return key.replace(".", "_").toUpperCase()
    },
  },
  filters: {
    capitalize
  }
}
</script>
