<template>
  <v-row dense class="ma-0 justify-space-between">
    <span v-text="val_checker" class="pr-2 cts-font-size-5"></span>
    <v-slider
            :disabled="disable"
            v-model="val_checker"
            color="var(--primary)"
            track-color="grey"
            height="2"
            :aria-label="val_checker"
            ticks="always"
            tick-size="0"
            :min="minValue"
            :max="maxValue"
            @change="updateValue"
    ></v-slider>
  </v-row>
</template>

<script>
  export default {
    name: "CtsSlider",
    props:{
      value: Number,
      minValue: Number,
      maxValue: Number,
      disable: Boolean
    },
    data(){
      return {
        val: null
      }
    },
    created() {
      this.val = this.$props.value
    },


    computed:{
      val_checker:{
        get(){
          return this.val
        },
        set(val){
          if( val < this.minValue ) {
            val = this.minValue
          }
          this.val = val
          this.$emit('input', val)
        }
      }
    },
    methods:{
      updateValue(value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style scoped>

</style>
