import { nullOrEmpty } from "@/util/utils"

const PROVINCES_DB = require("@/assets/raw/provinces.json")

export default class ZipCodeDB {
  static __importProvinceFile(provinceCode) {
    return require(`@/assets/raw/postal_codes-provinces/${provinceCode}.json`)
  }

  static getProvinceAndCityName(zipCode) {
    let codes
    try {
      codes = this.__importProvinceFile(parseInt(zipCode.toString().substr(0, 2)))  
    } catch (e) {
      return null
    }
    
    const zipCodeInfo = codes.filter(x => x["COD_POSTAL"] === parseInt(zipCode))

    if (nullOrEmpty(zipCodeInfo)) {
      return null
    }

    const provinceName = PROVINCES_DB.filter(x => parseInt(x["CODIGO"]) === zipCodeInfo[0]["COD_PROVINCIA"])[0]["LITERAL"]

    return zipCodeInfo.map(x => ({ province: provinceName, city: x["LocalidadMunicipio_INE"] }))

    
  }

}