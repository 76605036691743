export const NAMESPACE = "global"

export const STATE = {
  LOADING_BOX: "loading_box",
  VIEWS_DATA: "views_data",
  PROCESSES: "processes",
  TRANSACTIONS: "transactions",
  ADMIN: "administration",
  GROUPS: "groups",
  USERS: "users",
  CODE: "code",
  CONTENT: "content",
  SECTION: "section",
  VALUE: "value",
  EDIT_USER_DIALOG: "edit_user_dialog",
  USER_DETAILS_DIALOG: "user_details_dialog",
  SUBDOMAIN_CONFIG: "subdomain_config"
}

export const MUTATIONS = {
  CHANGE_STATUS_LOADING_BOX: "change_status_loading_box",
  SET_VIEW_DATA_CODE: "set_view_data_code_mutation",
  SET_VIEW_DATA_CONTENT: "set_view_data_content_mutation",
  SET_VIEW_DATA_PROPERTY: "set_view_data_property_mutation",
  SET_VIEW_DATA_CONTENT_PROPERTY: "set_view_data_content_property_mutation",
  UPDATE_PROCESS_FIELD_VALUE: "update_process_field_value_mutation",
  MUTATE_SUBDOMAIN_CONFIG: "mutate_subdomain_config"
}

export const ACTIONS = {
  SET_LOADING_BOX: "set_loading_box",
  SET_VIEW_DATA_CODE: "set_view_data_code_action",
  SET_VIEW_DATA_CONTENT: "set_view_data_content_action",
  SET_VIEW_DATA_PROPERTY: "set_view_data_property_action",
  SET_VIEW_DATA_CONTENT_PROPERTY: "set_view_data_content_property_action",
  UPDATE_PROCESS_FIELD_VALUE: "update_process_field_value_action",
  SET_SUBDOMAIN_CONFIG: "set_subdomain_config"
}

export const GETTERS = {
  GET_STATUS_LOADING_BOX: "get_status_loading_box",
  GET_VIEW_DATA_CODE: "get_view_data_code",
  SET_VIEW_DATA_CONTENT: "get_view_data_content",
  GET_VIEW_DATA_CONTENT_PROPERTY: "get_view_data_content_property",
  GET_SUBDOMAIN_CONFIG: "get_subdomain_config"

}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}