<template>
  <div>
    <v-col class="pa-0">
      <RecipientsSection
        v-if="step === 'fill_fields' && currentTransactionRecipients"
        :custom-validations="['postalRecipientLength']"
        :email-required="emailRequired"
        :phone-required="phoneRequired"
      />

      <ModelPostalDataSection
        class="mb-8"
        v-if="step === 'fill_fields'"
        :additionalFields="additionalFields"
        :mapping="mapping"
        :process-fields="removeDuplicatedFields"
        :processData="processData"
        @triggerValidateFields="handleTriggerValidateFields"
      />

      <SectionDataPreview
        v-else-if="step === 'preview'"
        :processFields="processFields"
        :process-payload="processData"
        :process-recipients="currentTransactionRecipients"
        :processData="processData"
        :class="{'': $vuetify.breakpoint.smAndDown, 'mb-9 pb-3': $vuetify.breakpoint.mdAndUp}"

      />

      <SectionTransactionLaunched
        v-else
      />
    </v-col>

    <v-col cols="12">
      <ProcessStepControlBar
        v-if="step !== 'launch'"
        class="mb-2"
        :infoObject="controlBar.info"
        :continueButtonObject="controlBar.continue"
        :backButtonObject="controlBar.back"
        @back="handleBackButton"
        @next="handleContinueButton"
      />
    </v-col>

  </div>
</template>

<script>
/* eslint-disable */
import RecipientsSection from '@/components/sections/processLaunch/recipients/RecipientsSection'
import const_global from '@/constants/global'
import const_icons from '@/constants/icons'
import SectionDataPreview from '@/components/sections/processes/processView/sectionDataPreview'
import SectionTransactionLaunched from '@/components/sections/processes/processView/sectionTransactionLaunched'
import { NAMESPACE as PROCESSES_NAMESPACE, GETTERS as PROCESSES_GETTERS } from '@/constants/vuex/processes'
import { ServerBus } from '@/main'
import ProcessStepControlBar from "@/components/sections/processLaunch/models/commonComponents/ProcessStepControlBar"
import ModelPostalDataSection from '@/components/sections/processLaunch/models/postal/ModelPostalDataSection'
import processDataMixin from "@/mixins/processData-mixin"

export default {
  name: 'ModelPostal',
  mixins: [ processDataMixin ],
  props: {
    mapping: Object
  },

  components: {
    RecipientsSection,
    SectionDataPreview,
    ProcessStepControlBar,
    ModelPostalDataSection,
    SectionTransactionLaunched
  },

  data() {
    return {
      const_icons,
      step: 'fill_fields',
      processContactFields: [
        const_global.FIELD_NAME,
        const_global.FIELD_SURNAME,
        const_global.FIELD_CONTACT_ID,
        const_global.FIELD_MOBILE_PHONE,
        const_global.FIELD_EMAIL,
        const_global.FIELD_COUNTRY_CODE
      ],
      recipientsValid: false,

      controlBar: {
        info: { text: this.$vuetify.lang.t('$vuetify.launchDataKo'), icon: "error", color: "red" },
        back: { text: this.$vuetify.lang.t('$vuetify.back'), icon: const_icons.BACK, disabled: false, visible: false },
        continue: { text: this.$vuetify.lang.t('$vuetify.continue'), icon: const_icons.NEXT, disabled: true }
      },
    }
  },
  computed: {
    currentTransactionRecipients() {
      const recipients = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_CURRENT_TRANSACTION_RECIPIENTS}`]
      if (!recipients) {
        return [{
          [const_global.CONTACT_ID]: null,
          [const_global.CONTACT_NAME]: null,
          [const_global.CONTACT_SURNAME]: null,
          [const_global.CONTACT_EMAIL]: null,
          [const_global.CONTACT_COUNTRY_CODE]: null,
          [const_global.CONTACT_PHONE]: null,
        }]
      }
      return recipients
    },

    allValid() {
      return this.recipientsAreValid && this.dataIsValid
    },

    additionalFields() {
      let additionalDF = false
      Object.entries(this.processFields).forEach((key) => {
        if (!this.processContactFields.includes(key[0])) {
          additionalDF = true
        }
      })

      return additionalDF
    },

    removeDuplicatedFields() {
      const newObject = JSON.parse(JSON.stringify(this.processFields))
      Object.keys(newObject).forEach(k => {
        if (this.processContactFields.includes(k)) {
          delete newObject[k]
        }
      })
      return newObject
    }

    },

  methods: {
    handleBackButton() {
      this.changeStep('fill_fields')
      for (const key in this.processFields) {
        if (["file", "array_file"].includes(this.processFields[key]["type"])) {
          this.processData[key] = []
        }
      }

    },

    handleContinueButton() {
      this.step === "fill_fields" ? this.changeStep('preview') : this.changeStep('launch')
    },

    handleTriggerValidateFields() {
      this.dataIsValid = this.validateAllFields()
    },

    changeStep(stepName) {
      this.step = stepName

      if (stepName === 'launch') {
        ServerBus.$emit('launchTransaction')
      }
    },

  },

  watch: {
    allValid(newValue) {
      this.controlBar.info.text = newValue ? this.$vuetify.lang.t('$vuetify.launchDataOk') : this.$vuetify.lang.t('$vuetify.launchDataKo')
      this.controlBar.info.icon = newValue ? "check_circle" : "error"
      this.controlBar.info.color = newValue ? "green" : "red"
      this.controlBar.continue.disabled =  !newValue
    },

    step(newValue) {
      this.controlBar.back.visible = newValue === "preview"
      this.controlBar.continue.text = newValue === "preview" ? this.$vuetify.lang.t('$vuetify.send') : this.$vuetify.lang.t('$vuetify.continue')

    }
  }
}
</script>

