<template>
  <v-col
    v-if="recipients"
    class="pa-0"
  >
    <section-title
      :text="`${$vuetify.lang.t('$vuetify.recipients')} (${recipients.length})`"
      icon="people"
      idName="recipients"
    />

    <RecipientRow
      v-for="(recipient, index) in recipients"
      :id="`recipient-${index + 1}`"
      :key="index"
      v-model="recipients[index]"
      :custom-validations="customValidations"
      :email-required="emailRequired"
      :index="index"
      :phone-required="phoneRequired"
      class="hidden-xs-only"
      @deleteRecipient="deleteRecipient(index)"
      @recipientValid="validateAndSet"
    />

    <v-tabs
      v-if="recipients.length > 1"
      v-model="tab"
      centered
      class="b2b-mobile-recipients hidden-sm-and-up"
    >
      <v-tab
        v-for="(recipient, index) in recipients"
        :key="index"
        class="hidden-sm-and-up"
      >
        {{ $vuetify.lang.t('$vuetify.recipient') }} {{ index + 1 }}

      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(recipient, index) in recipients"
          :key="index"
          class="pa-0 ma-0 pt-2"
        >
          <RecipientRow
            :id="`recipient-${index + 1}`"
            v-model="recipients[index]"
            :custom-validations="customValidations"
            :email-required="emailRequired"
            :index="index"
            :mobile="true"
            :phone-required="phoneRequired"
            @deleteRecipient="deleteRecipient(index)"
          />
          <div class="text-center pb-2">
            <a
              id="recipient-row-remove"
              class="b2b-link"
              @click="deleteRecipient(index)"
            >
              <v-icon
                class="pr-1"
                color="primary"
                small
              >clear
              </v-icon>
              <span>{{ $vuetify.lang.t('$vuetify.deleteRecipient') }}</span>
            </a>
          </div>
        </v-tab-item>
      </v-tabs-items>

    </v-tabs>

    <RecipientRow
      v-else
      :id="`recipient-0`"
      v-model="recipients[0]"
      :custom-validations="customValidations"
      :email-required="emailRequired"
      :index="0"
      :mobile="true"
      :phone-required="phoneRequired"
      class="hidden-sm-and-up"
      @deleteRecipient="deleteRecipient(0)"
    />

    <div class="px-2 text-center pb-3 hidden-sm-and-up">
      <span
        v-if="recipients.length < 2"
        class="cts-font-size-4"
      >{{ $vuetify.lang.t('$vuetify.mobileAddRecipientDescription') }}</span>
      <br v-if="recipients.length < 2">

      <a
        class="b2b-link"
        @click="addNewRecipient()"
      >
        <v-icon
          class="pr-1"
          color="primary"
          small
        >add
        </v-icon>
        <span>{{ $vuetify.lang.t('$vuetify.addNewRecipient') }}</span>
      </a>
    </div>

    <v-row
      class="ma-0 px-1"
      dense
      justify="end"
    >
  
      <a
        id="recipient-row-add"
        class="b2b-link mr-6 pr-6 hidden-xs-only"
        @click="addNewRecipient()"
      >
        <v-icon color="primary" small>add</v-icon>
        <span>{{ $vuetify.lang.t('$vuetify.addNewRecipient') }}</span>
      </a>
    </v-row>

  </v-col>
</template>

<script>
import const_global from '@/constants/global'
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import RecipientRow from '@/components/sections/processLaunch/recipients/RecipientRow'
import {
  NAMESPACE as PROCESSES_NAMESPACE,
  ACTIONS as PROCESSES_ACTIONS,
  GETTERS as PROCESSES_GETTERS
} from '@/constants/vuex/processes'

export default {
  name: 'RecipientsSection',
  components: {
    SectionTitle,
    RecipientRow
  },

  props: {
    emailRequired: Boolean,
    phoneRequired: Boolean,
    customValidations: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    currentTransactionRecipients() {
      return this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_CURRENT_TRANSACTION_RECIPIENTS}`]
    }
  },

  data() {
    return {
      recipients: [],
      dataModel: {},
      tab: null
    }
  },

  methods: {
    setCurrentTransactionRecipients(value) {
      this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_TRANSACTION_RECIPIENTS}`, value)
    },

    addNewRecipient() {
      this.recipients.push({ [const_global.CONTACT_COUNTRY_CODE]: 34 })
      this.tab++
    },

    deleteRecipient(index) {
      if (this.recipients.length > 1) {
        this.recipients.splice(index, 1)
      }
    },

    validateAndSet(recipients) {
      if (recipients) {
        const allValid = recipients.every(x => x.__valid === true)

        this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_RECIPIENTS_VALID}`, allValid)
        this.setCurrentTransactionRecipients(recipients)

      }
    }
  },

  created() {
    if (this.currentTransactionRecipients) {
      this.recipients = this.currentTransactionRecipients

    } else {
      this.recipients = [{
        [const_global.CONTACT_ID]: null,
        [const_global.CONTACT_NAME]: null,
        [const_global.CONTACT_SURNAME]: null,
        [const_global.CONTACT_EMAIL]: null,
        [const_global.CONTACT_COUNTRY_CODE]: null,
        [const_global.CONTACT_PHONE]: null,
      }]
    }
  },

  watch: {
    recipients: {
      handler(newRecipients) {
        this.validateAndSet(newRecipients)

      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.b2b-mobile-recipient-control {
  &__add {
    background-color: var(--primary-alternative) !important;
  }

  &__remove {
    color: var(--primary-alternative) !important;
    border-color: var(--primary-alternative) !important;
  }
}

.b2b-mobile-recipients .v-slide-group {
  .v-slide-group__prev {
    display: none !important;
  }

  padding: 0 5px;
  max-width: 400px !important;
}

</style>