<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon aria-hidden="false" left size="19" class="mr-1">{{icon}}</v-icon>
        {{title}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-4">
      <p>{{$vuetify.lang.t('$vuetify.adminGroupInfoDescription')}}</p>
      <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
        <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.groupData')}}</h4>
      </div>
      <v-row class="mb-6" dense>
        <v-col cols="12" lg="4"  md="4" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.name')}}</span>
              <v-text-field
                :disabled="!checkPermission(const_permissions.GROUP_EDIT)"
                required
                outlined
                readonly
                autocomplete="off"
                v-model="groupUpdatedValues[const_global.GROUP_NAME]"
                :aria-label="$vuetify.lang.t('$vuetify.name')"
                @input="handleInputEvent(const_global.GROUP_NAME)"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="8" md="8" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.description')}}</span>
              <v-text-field
                :disabled="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                required
                outlined
                autocomplete="off"
                v-model="groupUpdatedValues[const_global.GROUP_DESCRIPTION]"
                :aria-label="$vuetify.lang.t('$vuetify.description')"
                @input="handleInputEvent(const_global.GROUP_DESCRIPTION)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
        <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.frontalPolicy')}}</h4>
      </div>
      <v-row class="mb-6 px-2" dense>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.loginRetriesTime')}}</span>

              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_LOGIN_RETRIES_TIME]"
                :min-value="1"
                :max-value="60"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_LOGIN_RETRIES_TIME)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.loginRetriesQuantity')}}</span>

              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_LOGIN_RETRIES_QUANTITY]"
                :min-value="3"
                :max-value="10"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_LOGIN_RETRIES_QUANTITY)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.sessionExpiration')}}</span>

              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_SESSION_EXPIRATION]"
                :min-value="5"
                :max-value="60"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_SESSION_EXPIRATION)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="cts-content-section col-12 ma-0 pa-0 mb-2">
        <h4 class="mb-0 mt-2">{{$vuetify.lang.t('$vuetify.passwordPolicy')}}</h4>
      </div>
      <v-row class="mb-6 px-2" dense>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input ">{{$vuetify.lang.t('$vuetify.groupMinDaysToChange')}}</span>
              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_MINDAYS_PASSWORD_CHANGE]"
                :min-value="7"
                :max-value="60"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_MINDAYS_PASSWORD_CHANGE)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.passwordExpiration')}}</span>
              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_PASSWORD_EXPIRATION]"
                :min-value="groupUpdatedValues[const_global.GROUP_MINDAYS_PASSWORD_CHANGE]"
                :max-value="120"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_PASSWORD_EXPIRATION)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="6" class="cts-field-medium-block">
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.passwordsQuantity')}}</span>
              <CtsSlider
                v-model="groupUpdatedValues[const_global.GROUP_PASSWORDS_QUANTITY]"
                :min-value="1"
                :max-value="10"
                :disable="!checkPermission(const_permissions.SECTION_GROUP) || !checkPermission(const_permissions.GROUP_EDIT_INFO)"
                @input="handleInputEvent(const_global.GROUP_PASSWORDS_QUANTITY)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import const_global from "@/constants/global"
  import { checkPermission } from "@/util/store_utils"
  import const_permissions from "@/constants/permissions"
  import CtsSlider from "@/components/structures/CtsSlider"

  export default {
    name: "adminGroupInfo",
    components: { CtsSlider },
    props: {
      icon: String,
      title: String,
      group: Object,
    },
    data() {
      return {
        const_global,
        checkPermission,
        const_permissions,
        groupUpdatedValues : { ...this.group },
      }
    },
    methods: {
      handleInputEvent(updatedValueName) {
        const updatedValue = this.groupUpdatedValues[updatedValueName]
        if (updatedValue) this.$emit('input',{ updatedValueName, updatedValue })
      }
    },
  }
</script>
