<template>
  <v-col cols="12" class="pa-0">
    <v-row dense class="ma-0 pa-0 cts-row-processes-filters py-4">
      <v-col cols="6" xl="3" lg="3" md="3" sm="3" class="">
        <v-btn
          v-if="permissionNewUser()"
          class="cts-button-standard"
          small
          color="primary"
          @click="showUserDetails(null)"
        >
          {{ $vuetify.lang.t('$vuetify.newUser') }}
          <v-icon aria-hidden="false" size="17px !important" class="float-right">{{ const_icons.NEW_USER }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" xl="4" lg="4" md="4" sm="4" class="py-0 px-4 pr-0 pr-sm-4 offset-sm-5">
        <v-row dense class="ma-0 justify-end">
          <div class="px-0 col-lg-12">
            <v-text-field
              id="administration-users-searchBox"
              multiple
              clearable
              single-line
              outlined
              dense
              class="ma-0 cts-text-box"
              v-model="search"
              :placeholder="$vuetify.lang.t('$vuetify.usersSearch')"
              :append-icon="const_icons.SEARCH"
              :aria-label="$vuetify.lang.t('$vuetify.search')"
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="index < 2" class="mr-1">
                  <v-chip x-small label class="py-1">
                    <span>{{ item }}</span>
                  </v-chip>
                </div>
                <span v-if="index >= 2" class="grey--text caption">
                  (+{{ selectedTags.length - 2 }} {{ $vuetify.lang.t('$vuetify.others') }})
                </span>
              </template>
            </v-text-field>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="usersHeaders"
      :items="users"
      ref="users_table"
      :search="search"
      :options.sync="options"
      @pagination="filterUsers"
      class="elevation-0 col-12 px-0 px-md-2"
      sort-by="name"
      calculate-widths
      :footer-props="{
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right',
        itemsPerPageText: $vuetify.lang.t('$vuetify.usersPerPage'),
        pageText: `${$vuetify.lang.t('$vuetify.groupsFound')} ${filtered_users} ${$vuetify.lang.t('$vuetify.usersTitle')}`
      }"
    >
      <template v-slot:item="{item}">
        <tr class="hidden-xs-only">
          <td class="text-start">
            <h4 class="cts-color-tertiary">{{ item.name }}</h4>
          </td>
          <td class="text-start">
            <v-tooltip v-if="item.username.length > 25" bottom>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.username }}</h4>
              </template>
              <span>{{ item.username }}</span>
            </v-tooltip>
            <v-row v-else dense class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <h4 class="cts-color-tertiary">{{ item.username }}</h4>
              </v-col>
            </v-row>
          </td>
          <td class="text-start">
            <h4 v-if="item.active == '1'" class="cts-color-tertiary text-center">
              <v-icon>{{ const_icons.CHECK }}</v-icon>
            </h4>
          </td>
          <td v-if="item.group_name" class="text-start cts-group-name-column">
            <v-tooltip v-if="item.group_name.length > 25" bottom>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.group_name }}</h4>
              </template>
              <span>{{ item.group_name }}</span>
            </v-tooltip>
            <v-row v-else dense class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <h4 class="cts-color-tertiary">{{ item.group_name }}</h4>
              </v-col>
            </v-row>
          </td>
          <td class="text-start">
            <v-btn 
              v-if="permissionEditUser()"
              class="elevation-0 cts-bgcolor-primary cts-btn-datatable ml-3"
              fab
              x-small
              dark
              @click="showUserDetails(item)"
            >
              <v-icon aria-hidden="false" size="20">{{ const_icons.EDIT }}</v-icon>
            </v-btn>
          </td>
        </tr>
        <v-card
          v-if="permissionEditUser()"
          class="mx-auto my-2 cts-card-transaction hidden-sm-and-up"
          outlined
          @click="showUserDetails(item)"
        >
          <v-list-item class="cts-list-item-card pr-5 pl-1">
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <v-col cols="12" class="pa-0">
                  <v-icon
                    aria-hidden="false"
                    v-if="item.active == 0"
                    small
                    class="font-weight-bold red--text"
                    style="position: absolute;right: 5px;top: 5px"
                  >
                    person_outline
                  </v-icon>
                  <v-row dense class="ma-0 justify-space-between">
                    <v-col cols="12" class="hidden-sm-and-up pa-0 ma-0">
                      <h4 class="cts-font-size-5 cts-white-space pa-1">
                        <span class="font-weight-bold">{{item.name}}</span>
                      </h4>
                      <h4 class="cts-font-size-5 cts-white-space pa-1">
                        <span class="">{{ item.username }}</span>
                      </h4>
                    </v-col>
                  </v-row>
                </v-col>
              </v-list-item-title>
              <v-list-item-subtitle>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
    </v-data-table>
    <UserDetailsDialog
      v-if="userDialog"
      :user="userSelected"
      :group-mode="groupMode"
      :group="group"
    />
  </v-col>
</template>

<script>
import const_icons from "@/constants/icons"
import UserDetailsDialog from "./userDetailsDialog"
import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE, ACTIONS as ADMINISTRATION_ACTIONS } from "@/constants/vuex/administration"
import { checkPermission } from "@/util/store_utils"
import const_permissions from "@/constants/permissions"
import { mapActions } from "vuex"

export default {
  name: "usersDatatable",
  components: { UserDetailsDialog },
  props: {
    icon: String,
    title: String,
    users: Array,
    group: Object
  },

  computed: {
    userDialog: {
      get() {
        return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_USER_DETAILS_DIALOG]
      },
      set(value) {
        this.actionSetUserDetailsDialog(value)
      }
    }
  },

  data() {
    return {
      const_icons,
      search: '',
      userSelected: {},
      groupMode: true,
      options: {},
      filtered_users: null,
      usersHeaders: [
        { text: this.$vuetify.lang.t('$vuetify.groupTabUsersNameTitle'), align: 'start', value: 'name', width: '25%' },
        { text: this.$vuetify.lang.t('$vuetify.user'), value: 'username', align: 'start', width: '25%' },
        { text: this.$vuetify.lang.t('$vuetify.userActive'), align: 'center', value: 'active', width: '20%' }
      ],
    }
  },

  created() {
    this.userDialog = false
    if (Object.entries(this.group) == 0) {
      this.groupMode = false
      this.usersHeaders.push(
        { text: this.$vuetify.lang.t('$vuetify.groupName'), align: 'start', value: 'group_name', width: '20%' },
      )
    }
    this.usersHeaders.push(
      { text: '', value: 'actions', width: '5%' }
    )
  },
  methods: {
    ...mapActions({
      actionSetUserDetailsDialog: `${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_USER_DETAILS_DIALOG}`
    }),

    filterUsers(pagination) {
      this.filtered_users = pagination.itemsLength
    },

    permissionNewUser() {
      return this.groupMode ? checkPermission(const_permissions.GROUP_EDIT_USERS) : checkPermission(const_permissions.USER_CREATE)
    },

    permissionEditUser() {
      let valid = false
      if (this.groupMode) {
        valid = checkPermission(const_permissions.GROUP_EDIT_USERS)
      } else {
        valid = checkPermission(const_permissions.USER_EDIT)
      }
      return valid
    },

    showUserDetails(item) {
      this.userSelected = item
      this.actionSetUserDetailsDialog(true)
    }
  },
  watch: {
    search() {
      this.options.page = 1
    }
  }
}
</script>
