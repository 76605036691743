<template>
  <v-dialog
    v-model="visible"
    max-width="600px"
    content-class="cts-process-recovery-modal"
    @click:outside="visible = false; $emit('click:outside')"
  >
    <v-card class="col-lg-12 pt-0 mx-auto d-block" tile flat>
      <v-card-title class="ma-0 pa-0 mb-6">
        <dialog-title
          event="closeDialog"
          icon="info"
          :text="$vuetify.lang.t('$vuetify.batchModeDialogTitle')"
          :close="true"
          @closeDialog="visible = false"
        />
      </v-card-title>
      <v-card-text class="cts-dialog-contextual pa-0">
        <v-row dense>
          <v-col class="text-center px-3">
            <span class="cts-view-title text-uppercase font-weight-medium">{{ $vuetify.lang.t('$vuetify.batchModeDialogManualColTitle') }}</span>
            <p class="cts-font-size-4 pt-4">
              {{ $vuetify.lang.t('$vuetify.batchModeDialogManualColDescription') }}
            </p>
          </v-col>

          <v-col class="text-center px-3">
            <span class="cts-view-title text-uppercase font-weight-medium">{{ $vuetify.lang.t('$vuetify.batchModeDialogBatchColTitle') }}</span>
            <p class="cts-font-size-4 pt-4">
              {{ $vuetify.lang.t('$vuetify.batchModeDialogBatchColDescription') }}
            </p>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions class="cts-dialog-actions mx-auto mt-4">
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" lg="6" class="pr-lg-3">
              <v-btn
                class="cts-button-standard-block"
                block
                small
                color="primary"
                @click="$emit('batchMode', false)"
              >
                {{$vuetify.lang.t('$vuetify.batchModeDialogBatchBtnManual')}}
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6" class="pl-lg-3">
              <v-btn
                class="cts-button-standard-block"
                block
                small
                color="primary"
                @click="$emit('batchMode', true)"
              >
                {{$vuetify.lang.t('$vuetify.batchModeDialogBatchBtnBatch')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogTitle from '@/components/structures/dialogTitle'

  export default {
    name : "SelectBatchModeDialog",
    components: { DialogTitle },

    data() {
      return {
        visible: true
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>