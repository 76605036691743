<template>
    <v-container class="cts-main-container cts-main-view col-12 pa-0 pb-sm-14 pt-2">
        <v-scroll-y-transition>
            <div v-if="authObject">
                <v-row class="ma-0" dense>
                    <v-col class="col-lg-offset-1 mx-auto cts-view-header" cols="12" lg="10" md="10" sm="10" xl="10">
                        <v-row dense>
                            <v-col cols="12" lg="10" md="8" sm="8" xl="10">
                                <view-title
                                  :icon="const_icons.ACCOUNT"
                                  :subtitle="$vuetify.lang.t('$vuetify.accountDescription')"
                                  :title="this.authentication.name + ' - ' + this.authentication.username"
                                  idName="account"
                                />
                            </v-col>
                            <v-col class="hidden-xs-only" cols="12" lg="2" md="4" sm="4" xl="2">
                                <v-btn
                                  :disabled="!savePending"
                                  :title="$vuetify.lang.t('$vuetify.save')"
                                  class="cts-link cts-button-standard float-right mt-6"
                                  @click.prevent="handleSaveBtn"
                                >
                                    {{$vuetify.lang.t('$vuetify.save')}}
                                    <v-icon small>{{const_icons.SAVE}}</v-icon>
                                </v-btn>
                            </v-col>

                            <v-btn
                              :disabled="!savePending"
                              :title="$vuetify.lang.t('$vuetify.save')"
                              block
                              class="cts-link cts-button-standard my-2 mx-4 hidden-sm-and-up"
                              @click.prevent="handleSaveBtn"
                            >
                                {{$vuetify.lang.t('$vuetify.save')}}
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="pa-0 justify-center cts-view-content mb-0" dense>
                    <v-col cols="11" sm="10">
                        <v-row class="ma-0 pb-0" dense>
                            <v-col class="pa-0" md="12">
                                <div class="col-lg-12 mx-auto pa-0">
                                    <v-card color="cts-permissions-box cts-bgcolor-lighten" flat min-height="100%" tile>
                                        <v-tabs v-model="tab" class="hidden-xs-only" vertical @change="onTabChange">
                                            <v-tab ref="details"
                                                   class="justify-start cts-bgcolor-transparent"
                                                   href="#details"
                                                   @click.prevent="changeActiveSection('details')"
                                            >
                                                <h4 class="d-flex flex">{{$vuetify.lang.t('$vuetify.userData')}}</h4>
                                                <v-icon class="d-block float-right" right small>{{
                                                        const_icons.TAB_ARROW
                                                    }}
                                                </v-icon>
                                            </v-tab>

                                            <v-tab
                                              v-if="checkPermission(const_permissions.ACCOUNT_CHANGE_PASSWORD)"
                                              class="justify-start cts-bgcolor-transparent"
                                              href="#passwordchange"
                                              @click.prevent="changeActiveSection('passwordchange')"
                                            >
                                                <h4 class="d-flex flex">{{
                                                        $vuetify.lang.t('$vuetify.passwordchange')
                                                    }}</h4>
                                                <v-icon class="d-block float-right" right small>{{
                                                        const_icons.TAB_ARROW
                                                    }}
                                                </v-icon>
                                            </v-tab>

                                            <v-tab
                                              v-if="checkPermission(const_permissions.ACCOUNT_EDIT_NOTIFICATIONS)"
                                              class="justify-start cts-bgcolor-transparent"
                                              href="#notifications"
                                              @click.prevent="changeActiveSection('notifications')"
                                            >
                                                <h4 class="d-flex flex">{{
                                                        $vuetify.lang.t('$vuetify.notifications')
                                                    }}</h4>
                                                <v-icon class="d-block float-right" right small>{{
                                                        const_icons.TAB_ARROW
                                                    }}
                                                </v-icon>
                                            </v-tab>

                                            <v-tabs-items v-model="account_active_section">
                                                <v-tab-item style="min-height: 39vh" value="details">
                                                    <section-user-detail
                                                      v-if="account_active_section == 'details'"
                                                      :auth-object="authObject"
                                                      :text="this.authentication"
                                                      @userDetailsChanged="checkSavePending"
                                                    >
                                                    </section-user-detail>
                                                </v-tab-item>

                                                <v-tab-item
                                                  v-if="checkPermission(const_permissions.ACCOUNT_CHANGE_PASSWORD)"
                                                  style="min-height: 39vh"
                                                  value="passwordchange"
                                                >
                                                    <section-user-pass-change
                                                      v-if="account_active_section == 'passwordchange'"
                                                      :text="this.authentication"
                                                      @activateSaveButton="handleUserPassChange"
                                                    >
                                                    </section-user-pass-change>
                                                </v-tab-item>

                                                <v-tab-item
                                                  style="min-height: 39vh"
                                                  value="notifications"
                                                >
                                                    <section-user-notifications
                                                      v-if="account_active_section == 'notifications'"
                                                      @notificationsChanged="checkSavePending"
                                                    >
                                                    </section-user-notifications>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-tabs>

                                        <v-expansion-panels class="hidden-sm-and-up mt-4">
                                            <v-expansion-panel class="cts-expand-permission mb-3">
                                                <v-card class="cts-permissions-card" height="100%" outlined tile>
                                                    <v-expansion-panel-header class="pa-1"
                                                                              @click.stop="account_active_section = 'details'">
                                                        <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                                            <h4 class="d-flex flex">
                                                                <v-icon class="mr-1" left size="19">{{
                                                                        const_icons.USER
                                                                    }}
                                                                </v-icon>
                                                                {{$vuetify.lang.t('$vuetify.userData')}}
                                                            </h4>
                                                        </v-card-title>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <section-user-detail
                                                          v-if="account_active_section == 'details'"
                                                          :auth-object="authObject"
                                                          :text="this.authentication"
                                                          @userDetailsChanged="checkSavePending"
                                                        >
                                                        </section-user-detail>
                                                    </v-expansion-panel-content>
                                                </v-card>
                                            </v-expansion-panel>
                                            <v-expansion-panel class="cts-expand-permission mb-3">
                                                <v-card class="cts-permissions-card" height="100%" outlined tile>
                                                    <v-expansion-panel-header class="pa-1"
                                                                              @click.stop="account_active_section = 'passwordchange'">
                                                        <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                                            <h4 class="d-flex flex">
                                                                <v-icon class="mr-1" left size="19">
                                                                    {{const_icons.CHANGE_PASS}}
                                                                </v-icon>
                                                                {{$vuetify.lang.t('$vuetify.passwordchange')}}
                                                            </h4>
                                                        </v-card-title>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <section-user-pass-change
                                                          v-if="account_active_section == 'passwordchange'"
                                                          :text="this.authentication"
                                                          @activateSaveButton="handleUserPassChange"
                                                        >
                                                        </section-user-pass-change>
                                                    </v-expansion-panel-content>
                                                </v-card>
                                            </v-expansion-panel>
                                            <v-expansion-panel class="cts-expand-permission mb-3">
                                                <v-card class="cts-permissions-card" height="100%" outlined tile>
                                                    <v-expansion-panel-header class="pa-1"
                                                                              @click="account_active_section = 'notifications'">
                                                        <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2">
                                                            <h4 class="d-flex flex">
                                                                <v-icon class="mr-1" left size="19">
                                                                    {{const_icons.NOTIFICATIONS}}
                                                                </v-icon>
                                                                {{$vuetify.lang.t('$vuetify.notifications')}}
                                                            </h4>
                                                        </v-card-title>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <section-user-notifications
                                                          v-if="account_active_section == 'notifications'"
                                                          @notificationsChanged="checkSavePending"
                                                        />
                                                    </v-expansion-panel-content>
                                                </v-card>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-scroll-y-transition>

        <NotificationsConfirmDialog v-if="showDialog" @dialogConfirmation="handleDialogValue"
                                    @discardChanges="handleDiscard"/>

        <div v-if="!authObject">
            <loading-container/>
        </div>
    </v-container>
</template>

<script>
import const_icons from '@/constants/icons';
import ViewTitle from "../components/structures/viewTitle";
import SectionUserPassChange from "../components/sections/account/sectionUserPassChange"
import {
    NAMESPACE as AUTHENTICATION_NAMESPACE,
    STATE as AUTHENTICATION_STATE,
    GETTERS as AUTHENTICATION_GETTERS,
    ACTIONS as AUTHENTICATION_ACTIONS
} from "@/constants/vuex/authentication"
import const_permissions from "@/constants/permissions"
import SectionUserDetail from "../components/sections/account/sectionUserDetail";
import LoadingContainer from "../components/structures/loadingContainer";
import SectionUserNotifications from "@/components/sections/account/sectionUserNotifications"
import NotificationsConfirmDialog from "@/components/sections/account/notifications/notificationsConfirmDialog"
import {changePassword, saveUserLanguage, saveUserNotificationsConfig} from "@/services/userServices"
import {redirect} from "@/util/router_utils"
import {checkPermission, changeSiteLang} from "@/util/store_utils"
import {LOGIN, ACCOUNT} from "@/constants/router/routes"
import {showToast} from "@/util/utils"

export default {
    name: "Account",
    components: {
        LoadingContainer,
        SectionUserDetail,
        SectionUserPassChange,
        ViewTitle,
        SectionUserNotifications,
        NotificationsConfirmDialog
    },
    data: function () {
        return {
            account_active_section: this.$route.params.section,
            authObject: null,
            authentication: {
                name: null,
                username: null
            },
            cecoSelected: null,
            changeRoute: "",
            checkPermission,
            const_icons,
            const_permissions,
            dataSavePending: null,
            nextSection: null,
            passwordChangeData: {},
            previousSection: "details",
            savePending: false,
            showDialog: false,
            tab: this.$route.params.section,
            userinfo: {}
        }
    },
    created() {
        let self = this

        setInterval(function () {
            self.authentication.name = self.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_NAME]
            self.authentication.username = self.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME]
            self.authObject = self.$store.getters[AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_GETTERS.G_LOGIN_DATA]
        }, 200)
    },
    beforeRouteLeave(to, from, next) {
        if (this.savePending) {
            this.changeRoute = to.path
            this.showDialog = true

            return next(false)
        } else {
            return next()
        }
    },
    watch: {
        account_details() {
            this.account_active_section = "details"
        }
    },
    methods: {
        handleUserPassChange(userData) {
            if (userData) {
                Object.assign(this.passwordChangeData, userData.data)
                this.checkSavePending(userData)
            } else {
                this.savePending = false
            }
        },
        handleDiscard() {
            this.savePending = false
            this.dataSavePending = null
            this.showDialog = false

            if (this.changeRoute) {
                this.$router.push(this.changeRoute)
            } else {
                redirect(ACCOUNT, {section: this.nextSection})
            }
        },
        handleDialogValue(confirm) {
            if (confirm) {
                this.save()
                this.savePending = false
                this.dataSavePending = null

                if (this.changeRoute) {
                    this.$router.push(this.changeRoute)
                } else {
                    redirect(ACCOUNT, {section: this.nextSection})
                }
            }

            this.showDialog = false
            document.dispatchEvent(new CustomEvent("confirm", {detail: confirm}))
        },
        handleSaveBtn() {
            this.save()
            this.savePending = false
        },
        onTabChange() {
            if (this.savePending) {
                this.$nextTick(() => {
                    document.addEventListener("confirm", (val) => {
                        if (val) {
                            this.tab = this.account_active_section
                        } else {
                            this.account_active_section = this.tab
                        }
                    })
                })
            }
        },
        changeActiveSection(section) {
            if (this.savePending) {
                this.showDialog = true
                this.nextSection = section
            } else {
                redirect(ACCOUNT, {section})
            }
        },
        checkSavePending(data) {
            this.savePending = true
            this.dataSavePending = data
        },
        save() {
            let lang

            let userPassData = {}
            switch (this.dataSavePending.section) {
                case 'userNotifications':
                    saveUserNotificationsConfig(this.dataSavePending.data)
                    break;
                case 'userPassChanged':
                    userPassData = this.passwordChangeData
                    changePassword(userPassData, false).then((response) => {
                        if (response.data.error != 0) {
                            showToast(true, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
                        } else {
                            showToast(false, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
                            if (this.passwordChangeData.from_remember) {
                                redirect(LOGIN)
                            } else {
                                this.$store.dispatch(AUTHENTICATION_NAMESPACE + "/" + AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA, true)
                                redirect(LOGIN)
                            }
                        }
                    })
                    break;
                case 'userDetails':
                    lang = this.dataSavePending.data.lang
                    saveUserLanguage(lang.iso).then(response => {
                        if (response.data.error == 0) {
                            this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LANGUAGE}`, lang.iso)
                            changeSiteLang(lang.iso)

                            window.location.reload(true)
                        }
                    })
                    break;
            }
        }
    }
}
</script>
