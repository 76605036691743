<template>
  <a href="https://www.w3.org/WAI/WCAG2AA-Conformance" title="Explanation of WCAG 2 Level AA conformance" target="_blank">
    <v-img
            alt="Level AA conformance, W3C WAI Web Content Accessibility Guidelines 2.1"
            class="shrink"
            contain
            src="https://r.mailcomms.io/b2b/logos/wcag2.png"
            width="75"
            height="20"
    />
  </a>
</template>

<script>
  export default {
    name: "AAComponent"
  }
</script>

<style scoped>

</style>
