<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon aria-hidden="false" left size="19" class="mr-1">{{icon}}</v-icon>
        {{title}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-4">
      <p>{{$vuetify.lang.t('$vuetify.adminGroupInfoProcesses')}}</p>
      <v-row id="processesContainer" class="pa-0">
        <v-col cols="12" id="permissionsContainer" class="pa-0">
          <div class="cts-maincontainer-scrollbox py-0">
            <v-row dense class="mx-0 mb-8 mt-0 pa-0  px-3">
              <div v-if="masterAccountAdmin" class="font-italic">
                {{$vuetify.lang.t('$vuetify.groupPermissionsOwnerText')}}
              </div>
              <div v-else class="cts-w-100 px-2 ">
                <v-row dense class="ma-0 mb-8" v-for="(subaccount) in formattedSubaccounts"
                       v-bind:key="subaccount.sub_account_code">
                  <v-col cols="12" class="cts-content-section pb-0">
                    <v-row dense class="ma-0">
                      <v-col cols="11" xl="6" lg="6" md="6" sm="6">
                        <h4>{{subaccount.sub_account_name}}</h4>
                      </v-col>
                      <v-col cols="1" xl="6" lg="6" md="6" sm="6" class="px-0">
                        <v-checkbox
                          :readonly="!checkPermission(const_permissions.GROUP_EDIT_PROCESSES)"
                          :disabled="subaccount.processes == null || subaccount.processes.length == 0" @
                          class="cts-tabs-checkboxes pa-0 ma-0"
                          v-model="subaccount.assigned"
                          @change="changeSubaccount($event, subaccount[const_global.SUB_ACCOUNT_CODE])"
                          aria-label="checkbox-subaccount"
                          color="var(--primary)"
                          @click="$emit('change')"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="subaccount.processes" cols="12" class="ma-0 pa-0 mt-2">
                    <v-sheet class="pa-0 ma-0" v-for="(process) in subaccount[const_global.PROCESSES]"
                             v-bind:key="process.code">
                      <process-assigned-row
                        :subaccount-assigned="subaccount.assigned"
                        :process="process"
                        @changeProcessAssigned="changeProcessAssigned($event,process)"
                        @click="$emit('change')"
                      />
                    </v-sheet>
                  </v-col>
                  <v-col v-else cols="12" class="ma-0 pa-0 mt-2">
                    <h4 class="pl-1">{{$vuetify.lang.t('$vuetify.subaccountWithoutProcesses')}}</h4>
                  </v-col>
                </v-row>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import ProcessAssignedRow from "./processAssignedRow"
  import const_global from "@/constants/global"
  import {ServerBus} from "../../../../../main"
  import { checkPermission } from "@/util/store_utils"
  import const_permissions from "@/constants/permissions"

  export default {
    name: "adminProcessesAndSubaccounts",
    components: {ProcessAssignedRow},
    props: {
      icon: String,
      title: String,
      subaccounts: Array,
      masterAccountAdmin: Number
    },
    data() {
      return {
        checkPermission,
        const_permissions,
        const_global
      }
    },
    computed: {
      formattedSubaccounts(){
        this.subaccounts.forEach(sa => {
          let processes = sa[const_global.PROCESSES]
          if(processes) {
            processes.forEach(process => {
              process.disabled = !sa.assigned
            })
          }
        })
        return this.subaccounts
      }
    },
    methods: {
      changeSubaccount(assigned, sub_account_code) {
        ServerBus.$emit(const_global.SERVER_BUS_EVENT_PERMISSION_SUBACCOUNT, {
          assigned: assigned,
          [const_global.SUB_ACCOUNT_CODE]: sub_account_code
        })
      },
      changeProcessAssigned(value,process){
        process.assigned = value
      }
    },
  }
</script>

<style scoped>

</style>
