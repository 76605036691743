<template>
  <div id="cts-nt" class="ma-0">
    <span v-if="number" class="cts-nt-number pr-2 mr-3">{{ number }}</span>
    <span class="cts-nt-title">{{ text }}</span>
    <span class="cts-nt-required ml-2" v-if="required">*</span>
  </div>
</template>

<script>
export default {
  name: "SectionSubtitle",
  props: {
    number: {
      type: [Number, String],
      required: false
    },
    text: {
      type: String,
      required: true
    },
    required: Boolean
  }
}
</script>

<style scoped>
  #cts-nt {
    font-weight: bold;
    font-size: 13px;

  }
  .cts-nt-number {
    border-right: 2px solid var(--primary-alternative);
    font-size: 18px;
    width: 24px;
  }
  .cts-nt-title {
    margin: auto;
    font-size: 15px !important;
  }
  .cts-nt-required {
    color: var(--primary-alternative)
  }
</style>
