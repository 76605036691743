import Axios from "@/plugins/axios"
import { STATISTICS_ROUTES }  from "@/constants/backOfficeRoutes"
import { serialize } from '@/plugins/objectToFormdata'

export const getStatistics = filters => {
  let filters_data = {};
  filters_data = serialize({"filters": filters})

  return Axios.post(STATISTICS_ROUTES.ROUTE_STATISTICS, filters_data)
}

export const getStatisticsSearchFilters = () => {
  return Axios.get(STATISTICS_ROUTES.ROUTE_STATISTICS_FILTERS)
}
