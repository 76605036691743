import { ACTIONS, MUTATIONS, STATE, GETTERS } from "@/constants/vuex/functionality"

const state = {
  [STATE.S_ALERT_MESSAGE_POPUP_OPEN]: false,
  [STATE.S_ALERT_MESSAGE_POPUP_ERROR]: false,
  [STATE.S_ALERT_MESSAGE_POPUP_INFO]: null,
  [STATE.S_THEME]: null,
  [STATE.S_ALERT_MESSAGE_TERMS]: false,
  [STATE.S_ALERT_SUPPORT_DIALOG_M]: false,
  [STATE.S_ALERT_SUPPORT_DIALOG_D]: false
}

const mutations = {
  [MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN](state, payload) {
    state[STATE.S_ALERT_MESSAGE_POPUP_OPEN] = payload
  },
  [MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_ERROR](state, payload) {
    state[STATE.S_ALERT_MESSAGE_POPUP_ERROR] = payload
  },
  [MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_INFO](state, payload) {
    state[STATE.S_ALERT_MESSAGE_POPUP_INFO] = payload
  },
  [MUTATIONS.M_SET_THEME](state, theme) {
    state[STATE.S_THEME] = theme
  },
  [MUTATIONS.M_SET_ALERT_MESSAGE_TERMS](state, val) {
    state[STATE.S_ALERT_MESSAGE_TERMS] = val
  },
  [MUTATIONS.M_SET_ALERT_SUPPORT_DIALOG_M](state, val) {
    state[STATE.S_ALERT_SUPPORT_DIALOG_M] = val
  },
  [MUTATIONS.M_SET_ALERT_SUPPORT_DIALOG_D](state, val) {
    state[STATE.S_ALERT_SUPPORT_DIALOG_D] = val
  }
}


const actions = {
  [ACTIONS.A_SET_ALERT_MESSAGE_POPUP]({ commit, state }, payload) {

    if (state[STATE.S_ALERT_MESSAGE_POPUP_OPEN] && payload != null){
      setTimeout (() => {

        commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN, false)

        if (payload != null) {
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN, payload[STATE.S_ALERT_MESSAGE_POPUP_OPEN])
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_ERROR, payload[STATE.S_ALERT_MESSAGE_POPUP_ERROR])
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_INFO, payload[STATE.S_ALERT_MESSAGE_POPUP_INFO])
        } else {
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN, false)
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_ERROR, false)
          commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_INFO, null)
        }
      },1000)

    } else if (!state[STATE.S_ALERT_MESSAGE_POPUP_OPEN] && payload != null) {

      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN, payload[STATE.S_ALERT_MESSAGE_POPUP_OPEN])
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_ERROR, payload[STATE.S_ALERT_MESSAGE_POPUP_ERROR])
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_INFO, payload[STATE.S_ALERT_MESSAGE_POPUP_INFO])

    }else {
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_OPEN, false)
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_ERROR, false)
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_POPUP_INFO, null)

    }


  },
  [ACTIONS.A_SET_ALERT_MESSAGE_TERMS]({ commit }, payload) {
      commit(MUTATIONS.M_SET_ALERT_MESSAGE_TERMS, payload)
  },
  [ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_M]({ commit }, payload) {
      commit(MUTATIONS.M_SET_ALERT_SUPPORT_DIALOG_M, payload)
  },
  [ACTIONS.A_SET_ALERT_SUPPORT_DIALOG_D]({ commit }, payload) {
      commit(MUTATIONS.M_SET_ALERT_SUPPORT_DIALOG_D, payload)
  }

}

const getters = {
  [GETTERS.G_ALERT_MESSAGE_POPUP](state) {
    return {
      [STATE.S_ALERT_MESSAGE_POPUP_OPEN]: state[STATE.S_ALERT_MESSAGE_POPUP_OPEN],
      [STATE.S_ALERT_MESSAGE_POPUP_ERROR]: state[STATE.S_ALERT_MESSAGE_POPUP_ERROR],
      [STATE.S_ALERT_MESSAGE_POPUP_INFO]: state[STATE.S_ALERT_MESSAGE_POPUP_INFO],
    }
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
