<template>
  <v-container class="pa-0">
    <v-select
      :id="`${customid}`"
      v-model="value"
      :placeholder="fieldName"
      color="primary"
      dense
      outlined
      :items="items"
      @change="$emit('input', value)"
      :height="height"
    />
  </v-container>
</template>

<script>
  export default {
    name: "ListDataField",
    props: {
      customid: String,
      fieldName: String,
      items: Array,
      height: {
        default: 28,
        type: Number
      }
    },

    data() {
      return {
        value: this.items[0]
      }
    }
  }
</script>
