<template>
    <v-col class="pa-0">
        <RecipientsSection
          v-if="step === 'fill_fields' && currentTransactionRecipients"
          :custom-validations="['postalRecipientLength']"
          :email-required="emailRequired"
          :phone-required="phoneRequired"
        />

        <v-row v-if="step === 'fill_fields'" class="mt-4" dense>
            <!-- LEFT COL -->
            <v-col
              class="pr-md-6"
              cols="8"
            >
                <section-title
                  id="process-email-model-shipment-data_title"
                  :text="$vuetify.lang.t('$vuetify.shipmentData')"
                  icon="outgoing_mail"
                  idName="data-fields"
                />
                <div>
                    <section-subtitle
                      id="process-email-model-email_subject_title"
                      :number="1"
                      :text="$vuetify.lang.t('$vuetify.emailSubject')"
                      class="pt-2"
                    />

                    <v-row class="px-6" dense>
                        <text-data-field
                          v-model="processData.email_subject"
                          :max-length="100"
                          :suggested-fields="processContactFieldsSuggested"
                          :suggested-values="contactFieldsSuggestedLengths"
                          field-name="subject"
                          idName="process-email-model-email_subject_input"
                          style="position: relative;"
                        />
                    </v-row>
                </div>

                <div>
                    <section-subtitle
                      id="process-email-model-email_body_title"
                      :number="2"
                      :text="$vuetify.lang.t('$vuetify.emailContent')"
                      class="py-2"
                    />

                    <v-row class="mt-1 px-6 pb-2" dense justify="space-between">
                        <v-col class="text-left" cols="12">
              <span id="process-email-model-template_select_title" class="cts-font-size-4 cts-color-black_soft_text">
                {{$vuetify.lang.t('$vuetify.modelEmailHtmlTemplateSelector')}}
              </span>
                        </v-col>

                        <v-col cols="6">
                            <v-select
                              id="process-email-model-template_select"
                              v-model="selectedTemplateOption"
                              :disabled="templateSelectorDisabled"
                              :items="templateSelectorItems"
                              dense
                              height="28"
                              outlined
                            />
                        </v-col>

                        <v-col class="pr-0 d-flex justify-end" cols="6">
                            <input
                              id="process-email-model-email_body_input"
                              ref="uploader"
                              accept="text/html"
                              class="d-none"
                              type="file"
                              @change="onHtmlFileInputChange"
                            />

                            <standard-button
                              id="process-email-model-email_body_input_btn"
                              :icon="customTemplateLoaded ? 'clear' : 'file_upload'"
                              :text="customTemplateLoaded ? htmlFileName : $vuetify.lang.t('$vuetify.htmlEditorButtonUploadFile')"
                              class="float-right"
                              @click="handleUploadFileClick"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="px-6" dense>
                        <v-col class="text-center">
                            <v-progress-circular
                              v-if="htmlEditorLoading"
                              id="pro"
                              class="mx-2 mt-1 mt-4"
                              color="var(--primary-alternative)"
                              indeterminate
                              size="64"
                            />
                        </v-col>

                        <HTMLEditor
                          v-if="defaultTemplate"
                          v-model="emailBodyFile"
                          :initial-value="defaultTemplate"
                          outputFormat="text"
                          @input="handleHtmlEditorInput"
                          @onRender="editorOnRendered"
                        />
                    </v-row>
                </div>

                <div v-if="mapping.attachments" class="mt-6">
                    <section-subtitle
                      id="process-email-model-email_attachments_title"
                      :number="3"
                      :text="$vuetify.lang.t('$vuetify.emailAttachmentsSectionTitle')"
                      class="py-2"
                    />

                    <v-row class="mt-1 px-6" dense>
                        <v-row
                          v-if="emailAttachments && emailAttachments.length > 0"
                          class="pa-0 pl-6 pr-1 mb-2"
                          dense
                        >
                            <v-col
                              v-for="(file, index) in emailAttachments"
                              id="process-email-model-email_attachments_file"
                              :key="index"
                              class="pa-0 mb-2 pl-1 pr-1 my-2" cols="6"
                              dense
                              xl="4"
                            >
                                <v-tooltip
                                  :id="`process-email-model-email_attachment_${index}__tooltip`"
                                  dark
                                  top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-row
                                          class="cts-button-tertiary-block uploaded-file-container"
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                            <v-col align-self="center" class="text-left pa-0 ml-1" cols="1">
                                                <v-icon
                                                  id="process-email-model-email_attachments_file_clear"
                                                  color="var(--error)"
                                                  @click="handleFileRemoveClick(index)"
                                                >
                                                    clear
                                                </v-icon>
                                            </v-col>

                                            <v-col
                                              id="process-email-model-email_attachments_file_name"
                                              align-self="center"
                                              class="uploaded-file-container-filename d-inline-block text-truncate font-weight-medium cts-font-size-5"
                                              @click="handleFilePreviewClick(file)"
                                            >
                                                {{file.name}}
                                            </v-col>

                                            <v-col class="text-right" cols="2">
                                                <v-icon
                                                  id="process-email-model-email_attachments_file_view"
                                                  @click="handleFilePreviewClick(file)"
                                                >
                                                    remove_red_eye
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </template>

                                    <span>{{file.name}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>

                        <div class="process-email-model-dropzone mt-3" style="width: 100%">
                            <DropZone
                              v-model="emailAttachments"
                              :asyncMode="true"
                              :icon-size="24"
                              :is-loading="loading"
                              :multiple="true"
                              acceptedTypes="application/pdf"
                              height="60"
                              @filesWithError="handleFailedEmailAttachments"
                            >
                                <template v-slot:customText>
                                    <v-row align-content="center" justify="center" style="height: 100%">
                                        <v-col class="text-center">
                                            <v-icon class="mr-2" color="var(--primary-alternative)">file_upload</v-icon>
                                            <span id="dropzone-label">
                      {{$vuetify.lang.t('$vuetify.htmlEditorDragAndDrop')}}
                    </span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </DropZone>
                        </div>
                    </v-row>
                </div>
            </v-col>

            <!-- FILE PREVIEW DIALOG -->
            <v-dialog
              v-if="preview"
              id="process-email-view_file_dialog"
              v-model="preview"
              max-width="1200"
            >
                <v-card class="pa-2" tile>
                    <v-card-title class="col-lg-12 pa-0">
                        <dialog-title
                          :close="true"
                          :icon="const_icons.WATCH_FILE"
                          :text="$vuetify.lang.t('$vuetify.previewing') + previewFileSelection.name"
                          event="closePDFpreview"
                          @close="preview=false"
                        />
                    </v-card-title>

                    <v-card-text
                      id="process-email-view_file_dialog-content"
                      class="pb-0 mt-4"
                    >
                        <documentsViewer
                          :documentType="previewFileSelection.type"
                          :file="previewFileSelection"
                          :fileName="previewFileSelection.name"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!-- END FILE PREVIEW DIALOG -->
            <!-- END LEFT COL -->

            <v-col class="process-email-info_and_help pl-md-4" cols="4">
                <section-title
                  id="process-email-model-info_and_help"
                  :text="$vuetify.lang.t('$vuetify.infoAndHelp')"
                  icon="info"
                  idName="data-fields"
                />

                <v-row id="process-email-info_and_help-description" class="pa-2">
                    <p
                      class="cts-font-size-4 pl-2 mb-4 text-justify"
                      v-html="$vuetify.lang.t('$vuetify.htmlEditorInfoParagraph1')"
                    />

                    <p
                      class="cts-font-size-4 mb-0 pl-2 text-justify"
                      v-html="$vuetify.lang.t('$vuetify.htmlEditorInfoParagraph3')"
                    />

                    <ul class="mt-2 mb-4">
                        <li class="mb-2" v-html="$vuetify.lang.t('$vuetify.htmlEditorInfoBullet1')"></li>
                        <li v-html="$vuetify.lang.t('$vuetify.htmlEditorInfoBullet2')"></li>
                    </ul>

                    <p
                      v-if="emailBodyError"
                      class="cts-font-size-4 mb-0 pl-2 text-justify pt-3"
                      style="color: var(--error)"
                      v-html="emailBodyError"
                    />

                    <p
                      v-if="fileAttachmentsErrorString"
                      class="cts-font-size-4 mb-0 pl-2 text-justify pt-3"
                      style="color: var(--error)"
                      v-html="fileAttachmentsErrorString"
                    />
                    <p
                      v-if="generalError"
                      class="cts-font-size-4 mb-0 pl-2 text-justify pt-3"
                      style="color: var(--error)"
                      v-html="generalError"
                    />
                </v-row>
            </v-col>
        </v-row>

        <SectionDataPreview
          v-else-if="step === 'preview'"
          :process-fields="processFields"
          :process-payload="processData"
          :process-recipients="currentTransactionRecipients"
          :processData="processData"
        />

        <SectionTransactionLaunched
          v-else
        />

        <ProcessStepControlBar
          v-if="step !== 'launch'"
          :backButtonObject="controlBar.back"
          :continueButtonObject="controlBar.continue"
          :infoObject="controlBar.info"
          class="process-step-control-bar"
          @back="handleBackButton"
          @next="handleContinueButton"
        />

        <ProcessGeneralErrorDialog
          v-if="errorDialog.visible"
          :dialogTitle="errorDialog.dialogTitle"
          :errorDetail="errorDialog.errorDetail"
          :errorName="errorDialog.error"
          @close="errorDialog.visible = false"
          @click:outside="errorDialog.visible = false"
        />
        <ConfirmDialog ref="confirm_action"/>
    </v-col>
</template>

<script>
import RecipientsSection from "@/components/sections/processLaunch/recipients/RecipientsSection"
import customProcessMixin from "@/mixins/processData-mixin"
import SectionTitle from "@/components/sections/processes/processView/sectionTitle"
import SectionSubtitle from "@/components/sections/processes/processView/sectionSubtitle"
import TextDataField from "@/components/sections/processLaunch/models/commonComponents/dataFields/textDataField"
import HTMLEditor from "@/components/structures/HTMLEditor"
import StandardButton from "@/components/buttons/standardButton"
import {readFileContentAsyncAsText, convertBytesToMegabytes} from "@/util/utils"
import const_icons from "@/constants/icons"
import DropZone from "@/components/structures/DropZone"
import ProcessStepControlBar from "@/components/sections/processLaunch/models/commonComponents/ProcessStepControlBar"
import const_global from "@/constants/global"
import DialogTitle from "@/components/structures/dialogTitle"
import DocumentsViewer from "@/components/documentsViewer"
import {ServerBus} from "@/main"
import SectionDataPreview from "@/components/sections/processes/processView/sectionDataPreview"
import SectionTransactionLaunched from "@/components/sections/processes/processView/sectionTransactionLaunched"
import ProcessGeneralErrorDialog from "@/components/structures/ProcessGeneralErrorDialog"
import Axios from "axios";
import {STATIC_RESOURCES_ROUTES} from "@/constants/backOfficeRoutes";
import {refreshSession} from "@/services/userServices";
import ConfirmDialog from "@/components/structures/ConfirmDialog.vue";
// import Mime from "@/plugins/mime"

export default {
    name: "ProcessEmailModel",
    mixins: [customProcessMixin],
    components: {
        ConfirmDialog,
        RecipientsSection,
        ProcessStepControlBar,
        SectionTitle,
        SectionSubtitle,
        TextDataField,
        HTMLEditor,
        StandardButton,
        DropZone,
        DialogTitle,
        DocumentsViewer,
        SectionDataPreview,
        SectionTransactionLaunched,
        ProcessGeneralErrorDialog
    },
    data() {
        return {
            convertBytesToMegabytes,
            const_global,
            const_icons,
            defaultTemplate: null,
            emailBodyFile: null,
            templateSelectorDisabled: false,
            loading: false,
            htmlEditorLoading: true,
            emailAttachments: [],
            emailBodyError: null,
            emailAttachmentsError: null,
            htmlContentChange: false,
            emailBodyFileSize: 0,
            generalError: null,
            defaultProcessTemplate: this.extra.templates != null ? this.extra.templates[0] : this.$vuetify.lang.t('$vuetify.templateSelectorDefaultTemplate'),
            templateSelectorItems: this.extra.templates != null ? this.extra.templates : [
                this.$vuetify.lang.t('$vuetify.templateSelectorDefaultTemplate'),
                this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate'),
                this.$vuetify.lang.t('$vuetify.templateSelectorNoLogoTemplate'),
            ],
            customProcessTemplates: this.extra.templates != null,
            selectedTemplateOption: this.extra.templates != null ? this.extra.templates[0] : this.$vuetify.lang.t('$vuetify.templateSelectorDefaultTemplate'),
            controlBar: {
                info: {text: this.$vuetify.lang.t('$vuetify.launchDataKo'), icon: "error", color: "red"},
                back: {
                    text: this.$vuetify.lang.t('$vuetify.back'),
                    icon: const_icons.BACK,
                    disabled: false,
                    visible: false
                },
                continue: {text: this.$vuetify.lang.t('$vuetify.continue'), icon: const_icons.NEXT, disabled: true}
            },
            preview: false,
            previewFileSelection: null,
            htmlFileName: null,
            errorDialog: {
                visible: false,
                dialogTitle: null,
                error: null,
                errorDetail: null
            }
        }
    },
    async created() {

        await this.loadInitialTemplate()

    },
    computed: {

        fileAttachmentsErrorString() {
            return this.emailAttachmentsError ? this.emailAttachmentsError : null
        },

        contactFieldsSuggestedLengths() {
            let lengths = {}

            this.processContactFieldsSuggested.forEach(field => {
                lengths[field] = ""

                this.currentTransactionRecipients.forEach(recipient => {
                    lengths[field] = field in recipient && recipient[field] && recipient[field].length > lengths[field].length ? recipient[field] : lengths[field]
                })
            })

            return lengths
        },

        totalFileSize() {
            const bodyBase64Size = (this.emailBodyFileSize * const_global.SIZE_FACTOR_BASE64_FILE) + this.emailBodyFileSize

            const attachmentsTotalSize = this.emailAttachments.reduce((acc, cur) => acc + cur.size, 0)
            const attachmentsTotalSizeBase64 = (attachmentsTotalSize * const_global.SIZE_FACTOR_BASE64_FILE) + attachmentsTotalSize

            return bodyBase64Size + attachmentsTotalSizeBase64
        },
        customTemplateLoaded() {
            return this.selectedTemplateOption == this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate') && this.htmlFileName && this.emailBodyFile && !this.emailBodyError

        }
    },

    methods: {
        async loadInitialTemplate() {
            if (!this.customProcessTemplates) {
                this.defaultTemplate = const_global.EMAIL_DEFAULT_TEMPLATE.replace("%%logoURL%%", `https://r.mailcomms.io/b2b/logos/${this.$store.state["appearance"]["theme"]}_login.png`)
            } else {
                this.defaultTemplate = await this.loadProcessTemplate(this.selectedTemplateOption)

                if (this.templateSelectorItems[this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')] == null) {
                    this.templateSelectorItems.push(this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate'))
                }
                this.resetEditor()
            }
            return true
        },
        editorOnRendered() {
            this.htmlEditorLoading = false
        },

        handleHtmlEditorInput() {
            this.htmlContentChange = true
        },

        handleFileRemoveClick(index) {
            this.emailAttachments = this.emailAttachments.splice(index, 1)
        },

        handleFilePreviewClick(file) {
            this.previewFileSelection = file
            this.preview = true
        },

        async loadProcessTemplate(templateName) {

            const response = await Axios.get(STATIC_RESOURCES_ROUTES.ROUTE_TEMPLATES.replace("$processCode$", this.processCode).replace("$templateName$", templateName));
            return response.data;

        },
        async handleBackButton() {
            if (
              await this.$refs.confirm_action.open(
                null,
                this.$vuetify.lang.t('$vuetify.backLostChangesHTMLText'),
                {
                    width: "800px",
                    confirmText: this.$vuetify.lang.t('$vuetify.backLostChangesHTMLConfirmBack'),
                    cancelText: this.$vuetify.lang.t('$vuetify.backLostChangesHTMLConfirmContinue')
                }
              )
            ) {

                this.changeStep('fill_fields')
                this.emailBodyFile = ""
                this.emailAttachments = []
                this.processData["email_body"] = null
                this.processData["email_attachments"] = []
                this.selectedTemplateOption = this.defaultProcessTemplate
                await this.loadInitialTemplate()
                this.validateAllFields()
            }
        },

        handleContinueButton() {
            this.step === "fill_fields" ? this.changeStep('preview') : this.changeStep('launch')
        },

        async handleUploadFileClick() {
            refreshSession()
            if (this.htmlFileName && this.selectedTemplateOption === this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')) {
                this.resetEditor()
                this.htmlFileName = null
            } else {
                this.emailBodyFile = ""
                if (this.selectedTemplateOption === this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')) {
                    await this.checkSelectedTemplateOption()
                } else {
                    this.selectedTemplateOption = this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')
                }
            }

        },

        async onHtmlFileInputChange(event) {
            this.emailBodyError = null
            this.htmlEditorLoading = true
            const file = event.target.files[0]

            // const validation = file != null && Mime.getExtension(file.type) === "html"
            const validation = file != null && file.name.split(".")[1].toLowerCase() === "html"
            if (!validation) {
                this.emailBodyError = this.$vuetify.lang.t('$vuetify.modelEmailEmailBodyNotHtml')
                this.errorDialog.error = this.$vuetify.lang.t('$vuetify.modelEmailEmailBodyNotHtml')

            } else {
                this.emailBodyFile = await readFileContentAsyncAsText(file)
                this.htmlFileName = file.name
                if (this.customProcessTemplates) {

                    if (this.templateSelectorItems[this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')] == null) {
                        this.templateSelectorItems.push(this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate'))
                    }

                } else {

                    if (!this.templateSelectorItems.includes(file.name) && this.selectedTemplateOption !== this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')) {
                        this.selectedTemplateOption = this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')
                    }
                }
            }
            this.htmlEditorLoading = false
        },

        resetEditor() {
            this.emailBodyFile = this.defaultTemplate
            this.htmlFileName = null
            this.emailBodyError = null
            this.$refs.uploader.value = null
            this.selectedTemplateOption = this.defaultProcessTemplate

        },

        changeStep(stepName) {
            this.step = stepName

            if (stepName === 'launch') {
                ServerBus.$emit('launchTransaction')
            }
        },

        handleFailedEmailAttachments(files) {
            this.emailAttachmentsError = null
            this.errorDialog.dialogTitle = this.$vuetify.lang.t('$vuetify.extraFileError')
            this.errorDialog.error = this.$vuetify.lang.t('$vuetify.htmlEditorInfoParagraph4')
              .replace('{totalSizeLimit}', convertBytesToMegabytes(const_global.BYTES_LIMIT_10MB))

            const fileString = files.map(x => x.name).join(", ")

            this.errorDialog.errorDetail = fileString
            this.emailAttachmentsError = `${this.$vuetify.lang.t('$vuetify.filesExcedingSizeLimit')}: ${fileString}`
            this.errorDialog.visible = true
        },
        async checkSelectedTemplateOption() {
            refreshSession()
            this.htmlFileName = ""
            if (this.customProcessTemplates) {
                if (this.selectedTemplateOption === this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')) {
                    this.$refs.uploader.click()
                } else {
                    const content = await this.loadProcessTemplate(this.selectedTemplateOption)
                    const blob = new Blob([content], {type: 'text/html'}); // Tipo MIME para HTML
                    const file = new File([blob], this.selectedTemplateOption);
                    const event = {
                        target: {
                            files: [file]
                        }
                    }
                    await this.onHtmlFileInputChange(event)
                }
            } else {
                if (this.selectedTemplateOption === this.$vuetify.lang.t('$vuetify.templateSelectorCustomTemplate')) {
                    this.$refs.uploader.click()
                } else if (this.selectedTemplateOption === this.$vuetify.lang.t('$vuetify.templateSelectorNoLogoTemplate')) {
                    this.emailBodyFile = ""
                } else {
                    this.resetEditor()
                }
            }
        }
    },

    watch: {
        emailBodyError() {
            if (this.emailBodyError) {
                this.errorDialog.dialogTitle = this.$vuetify.lang.t('$vuetify.extraFileError')
                this.errorDialog.visible = true
            }
        },

        step() {
            this.controlBar.back.visible = this.step === "preview"
            this.controlBar.continue.text = this.step === "preview" ? this.$vuetify.lang.t('$vuetify.send') : this.$vuetify.lang.t('$vuetify.continue')
        },

        emailBodyFile() {
            if (this.emailBodyFile !== "") {
                const filename = this.htmlFileName ? this.htmlFileName : `HTML_Body_${this.processName}.html`
                const file = new File([this.emailBodyFile], filename, {type: "text/html"})
                this.emailBodyFileSize = file.size
                this.processData.email_body = file
            } else {
                this.processData.email_body = null
            }

            this.dataIsValid = this.validateAllFields()
        },

        emailAttachments() {
            if (this.emailAttachmentsError) {
                this.emailAttachmentsError = null
            }

            this.processData.email_attachments = this.emailAttachments
            this.dataIsValid = this.validateAllFields()

            if (!this.dataIsValid) {
                const allowed_extensions = this.processFields.email_attachments.validations?.allowed_extensions

                const wrongExtensions = this.emailAttachments.some(file => {
                    const fileExtension = file.name.split(".")[1].toLowerCase() || ""
                    return !allowed_extensions.includes(fileExtension)
                })

                if (wrongExtensions) {
                    this.emailAttachmentsError = this.$vuetify.lang.t('$vuetify.multipleFileExtensionError')
                      .replace("{acceptedExtensions}", allowed_extensions.join(","))
                }


            }

        },
        async selectedTemplateOption() {
            this.checkSelectedTemplateOption()
        },

        allValid() {
            if (this.allValid && this.totalFileSize < const_global.BYTES_LIMIT_10MB) {
                this.controlBar.info = {
                    icon: const_icons.CHECK_CIRCLE,
                    text: this.$vuetify.lang.t("$vuetify.launchDataOk"),
                    color: "green"
                }
                this.controlBar.continue.disabled = false
            } else {
                this.controlBar.info = {
                    icon: const_icons.ERROR,
                    text: this.$vuetify.lang.t("$vuetify.launchDataKo"),
                    color: "red"
                }
                this.controlBar.continue.disabled = true
            }
        },

        totalFileSize() {
            if (this.totalFileSize >= const_global.BYTES_LIMIT_10MB) {
                this.dataIsValid = false
                this.errorDialog.dialogTitle = this.$vuetify.lang.t('$vuetify.extraFileError')
                this.errorDialog.error = this.$vuetify.lang.t('$vuetify.htmlEditorInfoParagraph4')
                  .replace('{totalSizeLimit}', convertBytesToMegabytes(const_global.BYTES_LIMIT_10MB))

                this.errorDialog.visible = true
                this.generalError = this.$vuetify.lang.t('$vuetify.htmlEditorInfoParagraph4')
                  .replace('{totalSizeLimit}', convertBytesToMegabytes(const_global.BYTES_LIMIT_10MB))

                this.controlBar.info = {
                    icon: const_icons.ERROR,
                    text: this.$vuetify.lang.t("$vuetify.launchDataKo"),
                    color: "red"
                }
                this.controlBar.continue.disabled = true

            } else {
                this.generalError = null
                if (this.allValid) {
                    this.controlBar.info = {
                        icon: const_icons.CHECK_CIRCLE,
                        text: this.$vuetify.lang.t("$vuetify.launchDataOk"),
                        color: "green"
                    }
                    this.controlBar.continue.disabled = false
                }
            }
        }
    }
}
</script>

<style lang="scss">
.process-email-model-dropzone .dropzone {
    border: 2px dashed var(--primary-alternative) !important;
    background-color: transparent !important;
    color: var(--primary-alternative) !important;
    position: relative !important;
}

.process-email-model-dropzone .dropzone::before {
    content: "" !important;
    display: inline-block !important;
    position: absolute !important;
    background-color: var(--primary-alternative) !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0.1 !important;
}

#process-email-info_and_help-description {
    font-size: 13px !important;
}

.process-email-info_and_help {
    li > span {
        font-weight: bold !important;
    }

}

.uploaded-file-container {
    &-filename {
        margin-top: 2px !important;
    }

    width: 280px !important;
    max-width: 280px !important;
}

.process-step-control-bar {
    margin-top: 48px !important;
}


</style>