<template>
  <v-app
    v-if="theme"
  >
    <v-row
      v-if="isLoading"
      class="loading ma-0 pa-0"
    >
      <loading-container/>
    </v-row>

    <v-row
      v-else
      class="ma-0 pa-0 d-flex flex-column"
    >
      <title>{{theme | capitalize}} - CertySign</title>

      <v-col
        v-if="!$route.meta.requiresAuth"
        :class="'cts-login-background-color' && $route.path === '/rememberPassword' ? 'cts-grid-recovery' : ''"
        class="ma-0 pa-0 d-flex justify-center flex-column wrapper"
      >

        <the-header-login :action="action" :theme="theme"></the-header-login>

        <v-row class="ma-0 pa-0 justify-center" dense>
          <v-col
            class="cts-row-login-box"
            lg="4"
            sm="6"
          >
            <Login :theme="theme"/>
          </v-col>
        </v-row>

      </v-col>

      <v-col
        v-else
        class=" ma-0 pa-0"
      >
        <TheHeader @language:change="handleLanguageChange"/>
        <v-main class="cts-main-container">
          <router-view
            :key="$route.fullPath"
            style="height: fit-content"
          />
        </v-main>
      </v-col>

      <v-col class="ma-0 pa-0 footer">
        <TheFooter :theme="theme"/>
      </v-col>

      <v-btn
        id="backToTopID"
        class="hidden-md-and-up cts-color-primary cts-btn-to-top elevation-0"
        fab
        style="visibility: hidden"
        @click.native="scrollToTop"
      >
        <v-icon
          aria-hidden="false"
          class="elevation-4"
        >
          arrow_circle_up
        </v-icon>
      </v-btn>

      <alert-message-popup/>

    </v-row>

    <AlertExpirationDialog
      v-if="isSessionAboutToExpire"
      @closedExpirationModal="closedExpirationModal"
    />
  </v-app>
</template>

<script>
import Login from './views/UserManagement'
import TheHeader from './components/TheHeader.vue'
import {mapGetters} from 'vuex'
import {redirect} from '@/util/router_utils'
import AlertMessagePopup from './components/structures/alertMessagePopup'
import TheFooter from '@/components/TheFooter.vue'
import {
  NAMESPACE as AUTHENTICATION_NAMESPACE,
  STATE as AUTHENTICATION_STATE,
  ACTIONS as AUTHENTICATION_ACTIONS
} from '@/constants/vuex/authentication'
import {NAMESPACE as GLOBAL_NAMESPACE, ACTIONS as GLOBAL_ACTIONS} from '@/constants/vuex/global'
import loadingContainer from './components/structures/loadingContainer'
import pkg from '../package.json'
import {ROOT, NEW_PASSWORD, LOGIN} from '@/constants/router/routes'
import {getSubdomainConfig} from '@/services/staticResourcesServices'
import {capitalize, createInlineWorker} from '@/util/utils'
import locations from '@/constants/locations'
import AlertExpirationDialog from '@/components/structures/AlertExpirationDialog.vue'
import {showToast} from "@/util/utils"
import {userLogout} from "@/services/userServices"
import {ServerBus} from "@/main"
import TheHeaderLogin from "@/components/TheHeaderLogin.vue";

export default {
  name: 'App',
  components: {
    TheHeaderLogin,
    loadingContainer,
    TheFooter,
    AlertMessagePopup,
    Login,
    TheHeader,
    AlertExpirationDialog
  },
  data: () => ({
    theme: null,
    isLoading: false,
    NEW_PASSWORD,
    isSessionAboutToExpire: false,
    sessionExpirationTimeout: null,
    remindIsActive: true,
    worker: null,
    continueSession: false
  }),
  computed: {
    ...mapGetters({
      appearance: 'appearance/getAppearance',
    }),

    action() {
      return this.$route.name
    },

    isLogged() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_LOGGED]
    },

    sessionDuration() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION]
    },

    expirationTimestamp() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION_TIMESTAMP]
    }
  },
  created() {
    if (this.isLogged) {
      ServerBus.$on("resetSessionTimestamp", () => {
        if (this.worker) {
          this.worker.terminate()
          this.worker.onmessage = null

          const expirationTimestamp = Date.now() + (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION] * 1000)
          this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP}`, expirationTimestamp)
          this.initTimer()
        }
      })

      ServerBus.$on("endExpirationTimestamp", () => {
        if (this.worker) {
          this.worker.terminate()
          this.worker.onmessage = null
        }
      })

      if (!this.expirationTimestamp && !this.worker) {
        const expirationTimestamp = Date.now() + (this.sessionDuration * 1000)
        this.setExpirationTimestamp(expirationTimestamp)
      }

      this.initTimer()
    }

    let host = window.location.host.split('.')[0].toLowerCase()
    let htmlElement = document.documentElement
    this.$store.dispatch('appearance/changeTheme', host)
    localStorage.setItem('theme', host)
    htmlElement.setAttribute('theme', host)
    this.theme = host

    let newLink = document.createElement('link')
    newLink.rel = 'stylesheet'
    newLink.type = 'text/css'
    newLink.href = `https://r.mailcomms.io/b2b/${host}.css`

    document.getElementsByTagName('head')[0].appendChild(newLink)

    this.fetchSubdomainConfig()
  },
  mounted() {
    window.onscroll = function () {
      if (window.pageYOffset >= 150) {
        document.getElementById('backToTopID').style.visibility = 'visible'
      } else {
        document.getElementById('backToTopID').style.visibility = 'hidden'
      }
    }

    let version = pkg.version

    if (!localStorage.getItem('app_version')) {
      localStorage.setItem('app_version', version)
    }

    if (localStorage.getItem('app_version') !== version) {
      localStorage.removeItem('app_version')
      localStorage.removeItem('vuex')
      localStorage.removeItem('_secure__ls__metadata')
      localStorage.removeItem('theme')
      window.location.reload(true)
    }
  },
  methods: {
    setExpirationTimestamp(value) {
      this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP}`, value)
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    goRoot() {
      redirect(ROOT)
    },

    handleLanguageChange(userDetails) {
      if (!userDetails) {
        this.isLoading = true

        setTimeout(() => {
          this.isLoading = false
        }, 800)
      }
    },

    async fetchSubdomainConfig() {
      const subdomain = window.location.host.split('.')[0].toLowerCase()
      const defaultConfig = {
        show_email_support: true,
        show_telephone_support: true,
        show_hours_email_support: true,
        show_hours_telephone_support: true,
        show_captcha_support: true,
        pdf_manual: true,
        pdf_guide: true,
        available_languages: locations.map(x => x.code)
      }

      const subdomainConfigResponse = await getSubdomainConfig(subdomain)
      if (subdomainConfigResponse && subdomainConfigResponse.data) {
        this.$store.dispatch(`${GLOBAL_NAMESPACE}/${GLOBAL_ACTIONS.SET_SUBDOMAIN_CONFIG}`, subdomainConfigResponse.data)

      } else {
        this.$store.dispatch(`${GLOBAL_NAMESPACE}/${GLOBAL_ACTIONS.SET_SUBDOMAIN_CONFIG}`, defaultConfig)
      }

    },
    initTimer() {
      const now = Date.now()

      if (this.expirationTimestamp < now) {
        this.closeSession()
      }

      const workerFn = (milisecondsTillExpiration) => {
        const milisecsToShowDialog = milisecondsTillExpiration - 60000

        setTimeout(() => {
          postMessage("showDialog")
        }, milisecsToShowDialog - 10000)

        setTimeout(() => {
          postMessage("closeSession")
        }, milisecondsTillExpiration - 10000)
      }

      const milisecondsTillExpiration = this.expirationTimestamp - now
      this.worker = createInlineWorker(workerFn, [milisecondsTillExpiration])
      this.worker.onmessage = (event) => {
        if (event.data === "showDialog" && this.isLogged) {
          this.continueSession = false
          this.isSessionAboutToExpire = true
        }

        if (event.data === "closeSession" && !this.continueSession) {
          this.setExpirationTimestamp(null)
          this.closeSession()
          this.closedExpirationModal()
        }
      }
    },

    async closeSession() {
      if (this.worker) {
        await userLogout()
        this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)

        showToast(true, 401)
        redirect(LOGIN)
      }
    },

    clearTimer() {
      this.worker = null
    },

    closedExpirationModal(message) {
      this.isSessionAboutToExpire = false
      this.clearTimer()

      if (message === "continue") {
        this.continueSession = true
        const expirationTimestamp = Date.now() + (this.sessionDuration * 1000)
        this.setExpirationTimestamp(expirationTimestamp)
        this.initTimer()
      }
    }
  },

  watch: {
    isLogged(newValue) {
      if (!newValue) {
        this.clearTimer()
        this.continueSession = false
      }
    }
  },

  filters: {
    capitalize
  },
}
</script>
<style scoped>
.wrapper {
  height: 100vh;
}

.footer {
  max-height: 2.5rem;
}
</style>