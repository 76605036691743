<template>
  <v-dialog
      max-width="600px"
      id="expiration-dialog"
      v-model="display"
      persistent
  >
    <v-card
      class="col-lg-12 mx-auto d-block pt-0"
      tile
      flat
    >
      <v-card-title class="ma-0 pa-0 mb-6">
        <dialog-title
          class="col-12"
          :icon="const_icons.ALERT"
          :text="$vuetify.lang.t('$vuetify.processAlertExpiration')"/>
      </v-card-title>
      <v-card-text class="cts-dialog-contextual pa-0">
        <p class="cts-color-tertiary px-5">
          {{$vuetify.lang.t('$vuetify.processAlertExpirationPhrase')}}.&nbsp;
          <span class="font-weight-bold">
            {{$vuetify.lang.t('$vuetify.processAlertExpirationQuestion')}}
          </span>
        </p>
      </v-card-text>
        <v-card-actions class="cts-dialog-actions mx-auto">
          <v-row class="ma-0 pa-0" dense>
            <v-col
                cols="12"
                sm="6"
                class="text-center">
              <v-btn
                class="cts-button-standard"
                small
                color="primary"
                @click="continueSession()"
              >
                {{ $vuetify.lang.t('$vuetify.continueSession') }}
              </v-btn>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="text-center">
              <v-btn
                  class="cts-button-secondary"
                  small
                  color="primary"
                  @click="expireSession(false)"
              >
                {{ $vuetify.lang.t('$vuetify.logout') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogTitle from "./dialogTitle"
  import const_icons from "@/constants/icons"
  import { refreshSession, userLogout } from "@/services/userServices"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"
  import { redirect } from '@/util/router_utils'
  import { LOGIN } from '@/constants/router/routes'
  import { showToast } from "@/util/utils"

  export default {
    name: "AlertExpirationDialog",

    data() {
      return {
        display: true,
        const_icons
      }
    },

    components: { DialogTitle },

    methods:{
      async expireSession(showExpiredMessage) {
        this.$emit("closedExpirationModal", "expire")
        await userLogout()

        this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)

        if (showExpiredMessage) {
          showToast(true, 401)
        }

        redirect(LOGIN)
      },

      continueSession() {
        refreshSession()
        this.$emit("closedExpirationModal", "continue")
      },
    }
  }
</script>