import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from '@/constants/vuex/authentication'
import { ACTIONS as LOCATION_ACTIONS, NAMESPACE as LOCATION_NAMESPACE } from '@/constants/vuex/location'
import VUEX_CONSTANTS from '@/constants/vuex'
import locations from "@/constants/locations"
import const_permissions from '@/constants/permissions'
import store from '@/store'
import Vue from 'vue'

export const checkPermission = (permission) => {
  //TODO: Aunque sea admin maestro de owners tiene que mandar permiso developer?
  if (store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_ADMIN] && permission !== const_permissions.CERTYSIGN_DEVELOPER ||
    permission === const_permissions.SECTION_WITHOUT_SUBACCOUNTS || permission === const_permissions.SECTION_ERROR_500) {
    return true

  }

  let store_obj = store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_PERMISSIONS]
  return store_obj.includes(permission)
}

export const changeStateOfLoadingBox = (payload) => {
  return store.dispatch(`${VUEX_CONSTANTS.GLOBAL.NAMESPACE}/${VUEX_CONSTANTS.GLOBAL.ACTIONS.SET_LOADING_BOX}`, payload)
}

export const changeSiteLang = (languageIsoCode) => {
  const location = locations.find(l => l.iso === languageIsoCode)
  store.dispatch(`${LOCATION_NAMESPACE}/${LOCATION_ACTIONS.A_SET_LOCATION}`, location)
  store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LANGUAGE] = languageIsoCode
  Vue.prototype.vuetify.framework.lang.current = location.code
}
