<template>
	<v-container class="cts-main-view col-12">
		<v-row dense class="ma-0">
			<div class="col-12 col-lg-10 col-lg-offset-1 mx-auto cts-view-header">
				<div class="cts-breadcrumbs">
					<a @click.prevent="backToMenu('admin')" :title="$vuetify.lang.t('$vuetify.administrationTitle')">{{
						$vuetify.lang.t('$vuetify.administrationTitle') }} </a>
					<a @click.prevent="backToMenu('users')" :title="$vuetify.lang.t('$vuetify.usersTitle')">/ {{
						$vuetify.lang.t('$vuetify.usersTitle') }} </a>
					<span class="cts-color-black_soft_text font-weight-regular">/ {{ user.name }} </span>
				</div>
				<view-title
          :icon="const_icons.USER"
          :title="user.name"
          :subtitle="user.username"
          idName="user-detail"
        />
			</div>
		</v-row>
		<v-row class="col-10 pa-0 mx-auto">
			<v-col md="12" class="px-4">
				<v-row dense class="ma-0 pb-0">
					<v-col md="12" class="pa-0">
						<section-title icon="assignment" :text="$vuetify.lang.t('$vuetify.userInfoTitle')"></section-title>
						<v-row dense class="col-11 mx-auto cts-transaction-state">
							<v-col class="col-12 col-lg-6 col-xl-6">
								<span class="cts-transaction-state-title">{{$vuetify.lang.t('$vuetify.userPermissionTitle')}}:</span>
								<span class="cts-transaction-state-value">{{user.groupPermission}} </span>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
  import const_icons from "@/constants/icons";
  import ViewTitle from "../../../structures/viewTitle"
  import { redirect } from "@/util/router_utils"
  import SectionTitle from "../../processes/processView/sectionTitle"
  import { ADMINISTRATION, USERS } from "@/constants/router/routes"
  import { NAMESPACE as GLOBAL_NAMESPACE } from "@/constants/vuex/global"

  export default {
    name: "userView",
    components: {SectionTitle, ViewTitle},
    data: function () {
      return {
        const_icons,
        user: null
      }
    },
    methods: {
      backToMenu(section) {
        if (section === 'admin') {
          redirect(ADMINISTRATION)
        }
        if (section === 'users') {
          redirect(USERS)
        }
      },
      getUserData(userInfo){
        this.user = userInfo
      }
    },
    created() {
      let user = this.$store.state[GLOBAL_NAMESPACE]["views_data"]["users"]["code"]
      this.getUserData(user)
    }
  }
</script>

<style scoped>

</style>
