const theme = window.location.host.split('.')[0].toLowerCase()

export default {
    api_key: "crtrsz3q46u4zaz8arirvqgx5bmaf0gs7n1iyz6fmbw8s9l6",
    init_config: {
        menubar: false,
        plugins: [
            "lists",
            "autolink",
            "image",
            "fullscreen",
            "noneditable",
        ],
        toolbar: `
      undo redo |
      bold italic underline |
      fontfamily fontsize |
      forecolor backcolor |
      alignleft aligncenter alignright alignfull |
      numlist bullist outdent indent |
      image |
      fullscreen
    `,
        height: "480",
        width: "100%",
        file_picker_types: "image",

        elementpath: false,
        branding: false,
        statusbar: false,
        skin_url: `https://r.mailcomms.io/b2b/tiny/skins/${theme}`,
        object_resizing: "mceNonEditable",
        forced_root_blocks: false,
        setup: (editor) => {
            editor.on('init', () => {
                window.dispatchEvent(new Event("editorRendered"))
            })
        }
    }
}