import Axios from "@/plugins/axios"
import store from "@/store"
import { SUPPORT_ROUTES }  from "@/constants/backOfficeRoutes"
import { serialize } from '@/plugins/objectToFormdata'
import { NAMESPACE as AUTHENTICATION_NAMESPACE, ACTIONS as AUTHENTICATION_ACTIONS } from "@/constants/vuex/authentication"

export const launchSupportMessage = (data, type, preferred_phone = false) => {
  store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_CHECKING_LOGIN}`, true)

  let url
  if (type == 'help') {
    url = SUPPORT_ROUTES.ROUTE_SUPPORT_HELP
  } else {
    url = preferred_phone ? SUPPORT_ROUTES.ROUTE_SUPPORT_RECOVER_PASSWORD_PHONE : SUPPORT_ROUTES.ROUTE_SUPPORT_RECOVER_PASSWORD_EMAIL
  }

  const dataObject = serialize(data)
  return Axios.post(url, dataObject)
}
