export const NAMESPACE = "authentication"

export const STATE = {
  USER_LOGGED: "user_logged",
  USER: "user",
  USERNAME: "username",
  NAME: "name",
  PERMISSIONS: "permissions",
  GROUP: "group",
  GROUP_ID: "id",
  GROUP_NAME: "name",
  USER_MANAGEMENT_MESSAGE: "user_management_message",
  SUB_ACCOUNT: "sub_account",
  SUB_ACCOUNTS: "sub_accounts",
  SUB_ACCOUNT_CODE: "sub_account_code",
  SUB_ACCOUNT_SELECTED: "sub_account_selected",
  SUB_ACCOUNT_NAME: "sub_account_name",
  MASTER_ACCOUNT_CODE: "master_account_code",
  MASTER_ACCOUNT_ADMIN: "master_account_admin",
  LANGUAGE: "language"
}

export const MUTATIONS = {
  SET_LOGIN_MUTATION: "set_login_mutation",
  SET_LOGOUT_MUTATION: "set_logout_mutation",
  SET_USER_MANAGEMENT_MESSAGE: "set_user_management_message_mutation",
  SET_SUB_ACCOUNT: "set_sub_account_mutation",
  CHANGE_SUB_ACCOUNT: "change_sub_account_mutation"
}

export const ACTIONS = {
  SET_LOGIN_ACTION: "set_login_action",
  SET_LOGOUT_ACTION: "set_logout_action",
  SET_USER_MANAGEMENT_MESSAGE: "set_user_management_message_action",
  SET_SUB_ACCOUNT: "set_sub_account_action",
  CHANGE_SUB_ACCOUNT: "change_sub_account_action"
}

export const GETTERS = {
  IS_LOGGED: "get_is_logged",
  GET_AUTH: "get_authentication"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}