export function getIntervenerColor(index) {
  const colors = [
    '#3bb717',
    '#1c6a94',
    '#cc8235',
    '#a850dc',
    '#d26359',
    '#d2b659',

  ]
  return colors[index % colors.length]
}

export function formatFileNameForBack(fileName) {
  const replacements = {
    'Á': 'A', 'À': 'A', 'Â': 'A', 'Ä': 'A', 'á': 'a', 'à': 'a', 'ä': 'a', 'â': 'a', 'ª': 'a',
    'É': 'E', 'È': 'E', 'Ê': 'E', 'Ë': 'E', 'é': 'e', 'è': 'e', 'ë': 'e', 'ê': 'e',
    'Í': 'I', 'Ì': 'I', 'Ï': 'I', 'Î': 'I', 'í': 'i', 'ì': 'i', 'ï': 'i', 'î': 'i',
    'Ó': 'O', 'Ò': 'O', 'Ö': 'O', 'Ô': 'O', 'ó': 'o', 'ò': 'o', 'ö': 'o', 'ô': 'o',
    'Ú': 'U', 'Ù': 'U', 'Û': 'U', 'Ü': 'U', 'ú': 'u', 'ù': 'u', 'ü': 'u', 'û': 'u',
    'Ñ': 'N', 'ñ': 'n', 'Ç': 'C', 'ç': 'c'
  };

  fileName = fileName.replace(/[ÁÀÂÄáàäâªÉÈÊËéèëêÍÌÏÎíìïîÓÒÖÔóòöôÚÙÛÜúùüûÑñÇç]/g, match => replacements[match]);
  fileName = fileName.replace(/[^a-zA-Z0-9. \-_]+/g, "");
  fileName = fileName.replace(/["',]/g, "").replace(/\.\./g, ".");

  return fileName;
}