<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       :width="width"
       :height="height"
       viewBox="0 0 55 55"
       preserveAspectRatio="none">
    <desc>{{iconName}}</desc>
    <g :fill="iconColor">
      <component v-bind:is="svgIcon"></component>
    </g>
  </svg>
</template>

<script>

export default {

  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 30
    },
    height: {
      type: [Number, String],
      default: 30
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    src: String
  },
  computed: {
    svgIcon(){
      let svg = import('./svgIcons/' + this.src + '.vue')
        return () => svg
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
