<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon aria-hidden="false" left size="19" class="mr-1">{{icon}}</v-icon>
        {{title}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-4">
      <p>{{$vuetify.lang.t('$vuetify.adminGroupInfoPerms')}}</p>
      <div id="permissionsContainer" class="col-lg-12 pa-0">
        <div class="cts-maincontainer-scrollboxpy-0">
          <div class="mx-0 mb-8 mt-0 pa-0">
            <div v-if="masterAccountAdmin"  class="font-italic">
              {{$vuetify.lang.t('$vuetify.groupPermissionsOwnerText')}}
            </div>
            <div v-else class=" px-2 ">
              <div v-for="(gp,section) in groupedPermissions" v-bind:key="gp.id">
                <div class="cts-content-section col-12 ma-0 pa-0">
                  <h4 class="mb-0 mt-2">{{section}}</h4>
                </div>
                <div class="col-12 ma-0 pa-0 mt-2">
                  <v-sheet class="pa-0 ma-0 " v-for="(level_1) in gp" v-bind:key="level_1.id">
                    <list-with-check
                      :permission="level_1"
                      @change="$emit('change')"
                    />
                    <v-sheet
                      class="pa-0 ma-0"
                      v-for="(level_2) in level_1.childrens"
                      v-bind:key="level_2.id"
                    >
                      <list-with-check
                        :permission="level_2"
                        children_class="pl-4"
                        @change="$emit('change')"
                      />
                      <v-sheet
                        class="pa-0 ma-0"
                        v-for="(level_3) in level_2.childrens"
                        v-bind:key="level_3.id"
                      >
                        <list-with-check
                          :permission="level_3"
                          children_class="pl-8"
                          @change="$emit('change')"
                        />
                        <v-sheet
                          class="pa-0 ma-0"
                          v-for="(level_4) in level_3.childrens"
                          v-bind:key="level_4.id"
                        >
                          <list-with-check
                            :permission="level_4"
                            children_class="pl-10"
                            @change="$emit('change')"
                          />
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                  </v-sheet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import ListWithCheck from "@/components/structures/listWithCheck"
  import const_permissions from "@/constants/permissions"
  import {ServerBus} from "@/main";
  import const_global from "@/constants/global";

  export default {
    name: "adminPermissions",
    components: { ListWithCheck },
    props: {
      icon: String,
      title: String,
      permissions: Array,
      masterAccountAdmin: Number
    },

    computed: {
      groupedPermissions() {
        let permissions_by_category = {}
        let self = this
        this.permissions.filter(p => p.depends == 0).forEach(function callback(permission) {
          let category = permission.category

          if (permission.category != const_permissions.CATEGORY_HIDDEN_NAME) {
            if (typeof permissions_by_category[category] == "undefined") {
              permissions_by_category[category] = []
            }
            permission.disabled = false

            permission["childrens"] = self.permissions.filter(p => p.depends == permission.id)
            permission["childrens"].forEach(pc => {

              pc.disabled = !permission.assigned
              pc["childrens"] = self.permissions.filter(p => p.depends == pc.id)
              pc["childrens"].forEach(p => {

                p.disabled = !pc.assigned

                p["childrens"] = self.permissions.filter(o => o.depends == p.id)
                p["childrens"].forEach(l => {
                  l.disabled = !p.assigned
                })
              })
            })
            let depends = self.permissions.find(p => p.id == permission.depends)
            if (depends && !depends.assigned) {
              permission.disabled = true
            }
            permissions_by_category[category].push(permission)
          }
        })
        return permissions_by_category
      }
    },
    created() {
      ServerBus.$on(const_global.SERVER_BUS_EVENT_PERMISSION, (perm) => {
        let permission = this.permissions.find(p => p.id == perm.id)
        permission.assigned = perm.assigned

        if (permission.childrens) {
          if (!permission.assigned) {
            permission.childrens.forEach(perm => {
              ServerBus.$emit(const_global.SERVER_BUS_EVENT_PERMISSION_BACK, {
                id: perm.id,
                assigned: false,
                disabled: true
              })
            })
          } else {

            permission.childrens.forEach(perm => {
              ServerBus.$emit(const_global.SERVER_BUS_EVENT_PERMISSION_BACK, {
                id: perm.id,
                assigned: false,
                disabled: false
              })
            })
          }

        }
      })
    },
    beforeDestroy() {
      ServerBus.$off(const_global.SERVER_BUS_EVENT_PERMISSION)
    }
  }
</script>

