<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    content-class="cts-process-recovery-modal"
  >
    <v-card class="col-lg-12 mx-auto d-block" tile flat>
      <v-card-text class="cts-dialog-contextual pa-0">
        <v-card-title class="ma-0 pa-0 mb-6">
          <dialog-title
            class="col-12"
            :icon="const_icons.ALERT"
            :text="$vuetify.lang.t('$vuetify.changeSectionConfirmation')"
            :close="true"
            @close="handleButtonClick(false)"
          />
        </v-card-title>
        <v-row dense class="ma-0 px-3">
          <v-col cols="12">
            <span class="mr-4">
              {{$vuetify.lang.t('$vuetify.changeSectionConfirmationText')}}
              <span class="font-weight-bold">{{$vuetify.lang.t('$vuetify.save') }}</span>.
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="cts-dialog-actions mx-auto mt-4">
        <v-row dense class="ma-0 pa-0">
          <v-col cols="12" sm="6">
            <v-btn
              id="notifications-dialog-confirm_close"
              class="cts-button-secondary-block"
              block
              small
              color="primary"
              @click="handleDiscard"
            >
              {{ $vuetify.lang.t('$vuetify.discard') }}
              <v-icon small class="float-left">{{ const_icons.CLOSE }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              id="notifications-dialog-confirm_save"
              class="cts-button-standard-block"
              block
              small
              color="primary"
              @click="handleButtonClick(true)"
            >
              {{ $vuetify.lang.t('$vuetify.save') }}
              <v-icon small class="float-right">{{ const_icons.SAVE }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import const_icons from "@/constants/icons";
import DialogTitle from "../../../structures/dialogTitle";

export default {
  name: "notificationsConfirmDialog",
  components: { DialogTitle },

  data() {
    return {
      const_icons,
      dialog: true,
    }
  },
  methods: {
    handleButtonClick(value) {
      this.$emit("dialogConfirmation", value)
      this.dialog = !this.dialog
    },

    handleDiscard() {
      this.$emit("discardChanges")
    }
  }
}
</script>
