import GLOBAL from "./global";
import PROCESSES from "./processes";
import USERMANAGEMENT from "./userManagement";
import AUTHENTICATION from "./authentication";

export default {
  GLOBAL,
  PROCESSES,
  USERMANAGEMENT,
  AUTHENTICATION
}
