export default {
  props: {
    value: {}
  },
  data () {
    return {
      internalValue: this.value
    }
  },  
  watch: {
    internalValue: {
      handler(newValue) {
        this.$emit('input', newValue)
      },

      deep: true    
    },

    value: {
      handler(newValue) {
        this.internalValue = newValue
      },

      deep: true
    }
  }
}