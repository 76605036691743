<template>
  <v-row dense class="ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-footer
          dark
          padless
          class="cts-bgcolor-user-menu-background px-2 px-lg-8"
          tile
      >
        <v-row dense class="ma-0 pa-0 justify-lg-space-between">
          <v-col cols="12" xl="2" lg="2" md="12" sm="12" class="cts-logo-footer">
            <CertySignLogo id="footer-link-certysign_logo"/>
          </v-col>
          <v-col cols="12" xl="8" lg="8" md="12" sm="12" class="py-0 py-sm-1">
            <div class="cts-col-footer-terms text-center hidden-md-and-down mt-sm-1">
              <span class=" caption">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span id="footer-text-copyright" v-on="on">
                      Copyright CertySign - {{ new Date().getFullYear() }} |
                    </span>
                  </template>
                  <span>{{$vuetify.lang.t('$vuetify.platformVersion')}}: {{version}}</span>
                </v-tooltip>
                <a id="footer-link-terms_and_conditions"
                  @click="openTerms()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.termsAndConditionsCompliance')}}
                </a> | 
                <a id="footer-link-privacy_policies"
                  @click="openPolicies()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.privacyPoliciesCompliance')}}
                </a> | 
                <a
                  id="footer-link-cookies_policies"
                  @click="openCookiesPolicy()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')}}
                </a>
              </span>
            </div>

            <!-- MOBILE -->
            <div class="cts-col-footer-terms text-center hidden-lg-and-up cts-w-100 mt-0">
              <span id="footer-text-copyright" class="caption">
                Copyright CertySign - {{ new Date().getFullYear() }} <br>
                <a id="footer-link-terms_and_conditions"
                  @click="openTerms()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.termsAndConditionsCompliance')}}
                </a> | 
                <a id="footer-link-privacy_policies"
                  @click="openPolicies()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.privacyPoliciesCompliance')}}
                </a> | 
                <a id="footer-link-cookies_policies"
                  @click="openCookiesPolicy()"
                  class="text-decoration-none cts-bgcolor-transparent pa-0 font-weight-bold"
                >
                  {{$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')}}
                </a>
              </span>
            </div>
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="12" sm="12" class="text-center">
            <v-row dense class="ma-0 justify-center pb-2">
              <AAComponent id="footer-link-w3level" class="pt-1"></AAComponent>
            </v-row>
          </v-col>
          <footer-view-terms
            v-if="details"
            :policies="policies"
            :terms="terms"
            :cookies="cookies"
            :cookiesPolicy="cookiesPolicy"
            @saveUnnecessary="handleSaveUnnecesarry"
          />
        </v-row>
      </v-footer>
      <v-snackbar
          class="ma-0 cts-snack-cookies-bar hidden-md-and-down"
          content-class="cts-content-cookier-bar"
          timeout="-1"
          bottom
          tile
          color="var(--verylowtransparent)"
          v-model="cookiesBar"
      >
        {{ $vuetify.lang.t('$vuetify.cookiesNotificationBarText') }} <a style="text-decoration: underline" @click="openCookiesPolicy()">{{$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')}}</a>

        <template v-slot:action="{ attrs }">

          <v-btn
            color="var(--lighten)"
            class="ma-2"
            text
            small
            v-bind="attrs"
            @click="openCookies()"
          >
            {{$vuetify.lang.t('$vuetify.cookiesNotificationBarConfig')}}
          </v-btn>

          <v-btn class="cts-button-secondary-small ma-2 float-right" v-bind="attrs" small @click="saveCookies('ko')">
            {{$vuetify.lang.t('$vuetify.reject')}}
          </v-btn>

          <v-btn class="cts-button-standard-small ma-2 float-right" v-bind="attrs" small @click="saveCookies('ok')">
            {{$vuetify.lang.t('$vuetify.accept')}}
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
          class="mx-0 cts-snack-cookies-bar-mobile hidden-lg-and-up"
          timeout="-1"
          bottom
          tile
          color="var(--verylowtransparent)"
          v-model="cookiesBar"
      >
        <template v-slot:action="{ attrs }">
          <v-row dense class="ma-0 px-5 py-3">
            <v-col cols="12">
              {{ $vuetify.lang.t('$vuetify.cookiesNotificationBarText') }} <a style="text-decoration: underline" @click="openCookiesPolicy()">{{$vuetify.lang.t('$vuetify.cookiesPoliciesCompliance')}}</a>
            </v-col>
            <v-col cols="12">
              <v-row dense class="ma-0 pa-0">
                <v-col cols="12">
                  <v-btn
                    color="var(--lighten)"
                    class="mx-auto d-block"
                    text
                    small
                    v-bind="attrs"
                    @click="openCookies()"
                  >
                    {{$vuetify.lang.t('$vuetify.cookiesNotificationBarConfig')}}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <v-col cols="6">
                  <v-btn class="cts-button-secondary-small ma-2 float-left" v-bind="attrs" small @click="saveCookies('ko')">
                    {{$vuetify.lang.t('$vuetify.reject')}}
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn class="cts-button-standard-small ma-2 float-right" v-bind="attrs" small @click="saveCookies('ok')">
                    {{$vuetify.lang.t('$vuetify.accept')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>

  import AAComponent from "@/components/logos/AAComponent";
  import CertySignLogo from "@/components/logos/CertySignLogo";
  import { NAMESPACE as FUNCTIONALITY_NAMESPACE, STATE as FUNCTIONALITY_STATE, ACTIONS as FUNCTIONALITY_ACTIONS } from "@/constants/vuex/functionality"
  import FooterViewTerms from "./sections/footer/footerViewTerms";
  import {version} from './../../package.json';

  export default {
    name: "TheFooter",
    components: {FooterViewTerms, CertySignLogo, AAComponent},
    props: {
      theme: String
    },
    data() {
      return {
        version,
        policies: false,
        terms: false,
        cookies: false,
        cookiesBar: true,
        cookiesPolicy: false,
        unnecesaryCookies: true,
        googleCookies: true
      }
    },
    computed: {
      details:{
        get(){
          return this.$store.state[FUNCTIONALITY_NAMESPACE][FUNCTIONALITY_STATE.S_ALERT_MESSAGE_TERMS]
        },
        set(value) {
          this.$store.dispatch(`${FUNCTIONALITY_NAMESPACE}/${FUNCTIONALITY_ACTIONS.A_SET_ALERT_MESSAGE_TERMS}`, value)
        }
      }
    },
    created() {
      this.details = false
      const isCookie = this.getCookie('CScookiesPolicyAccepted')
      this.cookiesBar = !isCookie
    },
    methods: {
      openTerms() {
        this.terms = true
        this.policies = false
        this.cookies = false
        this.details = true
        this.cookiesPolicy = false
      },
      openPolicies() {
        this.terms = false
        this.policies = true
        this.cookies = false
        this.details = true
        this.cookiesPolicy = false
      },
      openCookies() {
        this.terms = false
        this.policies = false
        this.cookies = true
        this.details = true
        this.cookiesPolicy = false
      },
      openCookiesPolicy() {
        this.terms = false
        this.policies = false
        this.cookies = false
        this.details = true
        this.cookiesPolicy = true
      },
      setCookie(name, value, days) {
          let expires = "";
          if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "") + expires + "; path=/";
      },
      getCookie(name) {
        if (name === 'CScookiesPolicyAccepted') {
          let nameEQ = name + "=";
          let ca = document.cookie.split(";");
          for (const element of ca) {
            let c = element;
            while (c.charAt(0) === " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      },
      delAnalyticsCookies() {
        const ca = document.cookie.split(";")
        for (const element of ca) {
          const c = element;
          const cd = c.split("=")
          const cookieToDelete = cd[0].trim()

          if (cookieToDelete.includes("_ga")) {
            document.cookie = `${cookieToDelete}=; Path=/; Domain=.certy-sign.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
          }
        }
      },
      saveCookies(status) {
        if(status && status === 'ok' && this.unnecesaryCookies && this.googleCookies)  {
          this.setCookie("CScookiesPolicyAccepted", "true", 30)
        } else {
          this.setCookie("CScookiesPolicyAccepted", "false", 30)
          this.delAnalyticsCookies()
        }
        this.cookiesBar = false
      },

      handleSaveUnnecesarry(status) {
        this.unnecesaryCookies = status[0]
        this.googleCookies = status[1]
      }

    },
  }
</script>
