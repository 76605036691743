<template>
    <v-row :id="`processes-expandable-row-${process.name.toLowerCase().replace(/\s/g, '_')}`" :class="isActive ? 'activated' : 'deactivated'"
           class="ma-0 pa-0 cts-process-expandable" dense>
        <v-col :id="`processes-expandable-col-${process.name.toLowerCase().replace(/\s/g, '_')}`" cols="12">
            <v-expansion-panel
              :id="`processes-expandable-content-${process.name.toLowerCase().replace(/\s/g, '_')}`"
              class="cts-data-info-panel"
              @click="panelProcessClick"
            >
                <v-expansion-panel-header
                  :id="`processes-expandable-content-header-${process.name.toLowerCase().replace(/\s/g, '_')}`"
                  class="pa-0 cts-process-expandable-header"
                  disable-icon-rotate
                >
                    <template v-slot:actions>
                        <v-icon class="icon" left style="display: none;">{{const_icons.EXPANDARROW}}</v-icon>
                    </template>
                    <v-row class="header ma-0 pa-0" dense>
                        <v-col class="ma-0 pa-0" cols="10">
                            <v-avatar
                              :id="`processes-expandable-${process.name.toLowerCase().replace(/\s/g, '_')}-process_icon`"
                              class="cts-process-expandable-icon ma-0 float-left"
                              size="35"
                              tile
                            >
                                <icon-base
                                  :icon-color="isActive ? 'var(--primary-alternative)': 'var(--tertiary)'"
                                  :icon-name="process.name"
                                  :src="svgIconName"
                                  height="35"
                                  width="35"
                                />
                            </v-avatar>
                            <span class="cts-process-expandable-title ml-4">{{process.name}}</span>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.smAndDown ? 1 : 2" class="ma-0 pa-0 d-flex flex-row justify-end align-center">
                          <span v-if="isActive">{{$vuetify.lang.t('$vuetify.deactivateNotifications')}}</span>
                          <span v-else>{{$vuetify.lang.t('$vuetify.activateNotifications')}}</span>
                          <switch-without-label
                            v-model="internalIsActive"
                            class="ml-4"
                            @input="toogleActive"
                          ></switch-without-label>
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
            </v-expansion-panel>
        </v-col>
    </v-row>
</template>

<script>
import const_permissions from "@/constants/permissions";
import const_global from "@/constants/global";
import const_icons from "@/constants/icons";
import vuex_processes from "@/store/modules/sections/vuex_processes";
import IconBase from "../../../../assets/images/IconBase";
import SwitchWithoutLabel from "@/components/structures/step_launcher/switchWithoutLabel.vue";

export default {
    name: "processExpandable",
    components: {SwitchWithoutLabel, IconBase},
    props: {
        process: Object,
        tags: Array,
        groupAdministration: Boolean,
        fromProcesses: Boolean,
        activeAll: Boolean,
        category: Object,
        isActive: Boolean
    },
    data() {
        return {
            const_permissions,
            const_global,
            const_icons,
            vuex_processes,
            recipients: [],
            continue_process_dialog: false,
            processStates: null
        }
    },

    computed: {
        svgIconName() {
            return this.process.image ? this.process.image : 'default'
        },
        internalIsActive: {
            get() {
                return this.isActive
            },
            set(value) {
                this.$emit('update:isActive', value)
            }
        }
    },
    methods: {
        toogleActive() {
            this.$emit("toggleActive")
        },
        panelProcessClick() {
            this.processStates = ['Transacción admitida', 'Impreso', 'Generado envío postal', 'Depositado en operador postal [CORREOS]', 'Enviado a través de PostaMail [RECEPCIÓN]', 'Enviado por operador postal [CORREOS]']
        }
    },
}
</script>