<template>		
	<v-row
    dense
    style="border-top: 1px solid var(--card-border);"
    class="d-flex"
    :class="$vuetify.breakpoint.smAndDown? 'flex-column' : 'flex-row'">
		<v-col
      class="d-flex"
      :class="$vuetify.breakpoint.smAndDown? 'justify-center' : 'justify-start'">
        <v-btn
          v-if="backButtonObject.visible"
          id="processview-button-back"
          class="cts-button-secondary float-left mt-2 mb-lg-0 mb-4"
          small
          :disabled="backButtonObject.disabled"
          @click="$emit('back')"
        >
          {{ backButtonObject.text }}
          <v-icon small class="float-left">{{ backButtonObject.icon }}</v-icon>
        </v-btn>
		</v-col>
		<v-col
      class="pa-0 d-flex justify-end"
      :class="$vuetify.breakpoint.smAndDown? 'flex-column' : 'flex-row'">
        <v-col
          md="12" lg="10" xl="10"
          class="text-right"
          :class="{'text-center': $vuetify.breakpoint.smAndDown}">
          <v-icon
            id="processlaunch-launch-status-icon"
            small
            class="pr-2 hidden-sm-and-down"
            :color="infoObject.color"
          >
            {{ infoObject.icon }}
          </v-icon>

          <span
            :id="`processlaunch-launch-status-text-${infoObject.color === 'red' ? 'ko' : 'ok'}`"
            class="font-weight-medium cts-font-size-5"
            :class="{'red--text': $vuetify.breakpoint.smAndDown && infoObject.color === 'red'}"
          >
            {{ infoObject.text }}
          </span>
        </v-col>
        <v-col class="d-flex justify-center">
          <standard-button
            :id-external="`processlaunch-button-${continueButtonObject.text.toLowerCase()}`"
            :icon="$vuetify.breakpoint.smAndDown ? '' : continueButtonObject.icon"
            :text="continueButtonObject.text"
            :disabled="continueButtonObject.disabled"
            @click="$emit('next')"
          />
        </v-col>
    </v-col>
	</v-row>
</template>

<script>
	import const_icons from "@/constants/icons"
	import StandardButton from '@/components/buttons/standardButton'

	export default {
		name: "ProcessStepControlBar",
		components: { StandardButton },

		props: {
			infoObject: Object,
			continueButtonObject: Object,
			backButtonObject: Object,
		},

		data() {
			return {
				const_icons
			}
		}
	}
</script>
