<template>
    <v-list-item class="transaction-details-card">

        <v-list-item-avatar v-if="icon">
            <v-icon
              :color="iconColor"
              size="46">
                {{icon}}
            </v-icon>
        </v-list-item-avatar>
        <v-list-item-content :style="icon ? '':'border-left: solid 6px var(--card-border);'" class="py-1">
            <v-list-item-title class="font-weight-medium mb-2">
                {{title}}
            </v-list-item-title>
            <v-list-item-subtitle v-if="subtitle">
                {{subtitle}}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="extra">
                {{extra}}
            </v-list-item-subtitle>
            <slot name="content"></slot>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "DetailCard",
    props: {
        icon: String,
        title: String,
        subtitle: String,
        extra: String,
        iconColor: {
            type: String,
            default: "var(--primary)"
        }
    }
}
</script>

<style lang="scss">
.transaction-details-card {
    height: 70px;

    .v-list-item__avatar {
        margin-right: 0 !important;
    }

    .v-list-item__content {
        padding-left: 12px;
        align-self: flex-start;

    }

    span {
        font-size: 0.9rem;
    }
}
</style>