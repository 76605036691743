<template>
  <v-row dense class="ma-0 cts-transaction-event-row pa-2 mb-2 d-flex justify-space-between">
    <v-col cols="10" sm="10" md="11" lg="8" class="d-flex flex-row">

      <v-col cols="3" sm="2" md="1" lg="1" align-self="center" class="pa-0 pl-2 mr-4">
          <span class="font-weight-medium">
            {{ dayjs.unix(event.event_at).format(const_global.DATE_TIME_FORMAT_C) }}
          </span>
      </v-col>

      <v-col cols="9" sm="11" md="11" lg="11" class="pa-0 d-flex align-center">

          <v-icon
            v-if='typeof const_events[formatEventKey(event.key)] != "undefined" && const_events[formatEventKey(event.key)].icon !== ""'
            class="mr-6"
            size="18"
          >
            {{ const_events[formatEventKey(event.key)].icon }}
          </v-icon>
          <v-icon v-else class="mr-6" small>
            {{const_icons.EVENT_DEFAULT}}
          </v-icon>


        <v-col sm="11" md="" lg="11" class="pa-0">
          <v-row>
            <span class="font-weight-bold">{{ event.event | capitalize }}</span>
          </v-row>
          <v-row class="pr-2 hidden-xs-only">
            <span>{{ event.subtitle.toUpperCase() }}</span>
          </v-row>
        </v-col>

      </v-col>

    </v-col>


    <v-col class="d-flex align-center justify-end" >

      <v-col
        cols="1"
        class="pa-0 d-flex justify-center"
      >
        <v-tooltip
          v-if="typeof const_events[formatEventKey(event.key)] != 'undefined' && $vuetify.lang.t('$vuetify.' + const_events[formatEventKey(event.key)].name) !== '' && $vuetify.lang.t('$vuetify.' + const_events[formatEventKey(event.key)].name).indexOf('$vuetify') === -1"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              icon
              class="cts-color-darken mr-1"
              @click.stop.prevent="changeDialogState(true)"
              v-on="on"
            >
              <span class="cts-span-info">?</span>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.eventMeaning') }}</span>
        </v-tooltip>
      </v-col>

    </v-col>

    <v-dialog
      v-if="(typeof const_events[formatEventKey(event.key)] != 'undefined')"
      v-model="helpDialog"
      max-width="600px"
      content-class=""
    >
      <v-card class="col-lg-12 mx-auto d-block" tile flat>
        <v-card-text class="cts-dialog-contextual pa-0">
          <v-card-title class="ma-0 pa-0">
            <dialog-title
              :close="true"
              event="closeEventRowDialog"
              class="col-12"
              :icon="const_icons.HELP"
              :text="$vuetify.lang.t('$vuetify.eventMeaning')"
              @close="changeDialogState(false)"
            />
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0">
            <h4 class="cts-color-darken cts-font-size-3 col-lg-12 font-weight-bold text-center my-3">
              {{ this.event.event | capitalize }}
            </h4>
            <h4 class="cts-color-darken col-lg-12 my-3 cts-font-size-4">
              {{ $vuetify.lang.t('$vuetify.' + const_events[formatEventKey(event.key)].name) }}
            </h4>
          </v-card-subtitle>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
  import const_global from "@/constants/global"
  import const_icons from "@/constants/icons";
  import const_events from "@/constants/events";
  import DialogTitle from "../../structures/dialogTitle";
  import {ServerBus} from "@/main";
  import { capitalize } from "@/util/utils"

  export default {
    name: "transactionEventRow",
    components: {DialogTitle},
    data() {
      return {
        const_global,
        const_events,
        const_icons,
        helpDialog: false
      }
    },
    props:{
      event: Object
    },
    filters: {
      capitalize
    },
    created() {
      ServerBus.$on("closeEventRowDialog", () => {
        this.helpDialog = false
      })
    },
    beforeDestroy() {
      ServerBus.$off('closeEventRowDialog')
    },
    methods: {
      formatEventKey(key){
        return key.replace(".","_").toUpperCase()
      },
      changeDialogState(state){
        this.helpDialog = state
      }
    }
  }
</script>
