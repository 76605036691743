import Axios from "axios"
import { STATIC_RESOURCES_ROUTES }  from "@/constants/backOfficeRoutes"

export const fetchLocations = () => {
  return Axios.get(STATIC_RESOURCES_ROUTES.ROUTE_LOCATIONS)
}

export const getLocaleFile = code => {
  return Axios.get(STATIC_RESOURCES_ROUTES.ROUTE_LOCALE_FILE.replace("$code$", code))
}

export const getCountryCodes = () => {
  return Axios.get(STATIC_RESOURCES_ROUTES.ROUTE_COUNTRY_CODES)
}

export const getSubdomainConfig = subdomain => {
  return Axios.get(STATIC_RESOURCES_ROUTES.ROUTE_SUBDOMAIN_CONFIG.replace("$subdomain$", subdomain))
}