<template>
  <v-row dense class="ma-0 justify-space-between">
    <v-slider v-model="value"
              :label=fullTime
              @change="changeSlider"
              height="22"
              :max='86399'
              :min='0'
              hide-details
              step="1800"
              ticks="always"
              tick-size="0"
              track-color="grey"
              :class="'cts-hour-slider'"
              :color="'var(--primary-alternative)'"
              :thumb-label="false">
    </v-slider>
  </v-row>
</template>

<script>
export default {
  name: "hourSlider",
  props: {
    val: String
  },
  data() {
    return {
      value: 0,
    }
  },
  computed: {
    fullTime() {
      return this.formatData(this.value)
    }
  },
  mounted() {
    if (this.val) {
      this.value = (this.val.split(":")[0] * 3600) + (this.val.split(":")[1] * 60)
    }
  },
  methods: {
    formatData(data) {
      let hours = 0
      let minutes = 0
      hours = Math.floor(data / 3600);
      hours = (hours < 10) ? '0' + hours : hours;
      minutes = Math.floor((data / 60) % 60);
      minutes = (minutes < 10) ? '0' + minutes : minutes;

      return hours + ":" + minutes
    },
    changeSlider(val){
      this.$emit("changeSlider", this.formatData(val))
    }
  }
}
</script>

<style scoped>

</style>
