<template>
    <v-container class="pa-0 ma-0">
        <v-row v-if="currentStep < 3" class="pl-1" dense>
            <section-title
              :icon="currentSectionTitle.icon"
              :text="currentSectionTitle.title"
              idName="batch-mode"
            />
        </v-row>
        <!-- STEP 1 -->
        <div v-if="currentStep === 1" class="pl-lg-6 pr-lg-8">
            <v-row dense>
                <v-col class="pr-lg-8" cols="12" lg="4">
                    <p
                      class="cts-font-size-4 mb-0 pl-0 pt-2"
                      v-html="$vuetify.lang.t('$vuetify.batchModeExtraCSVStepIntroduction')"
                    >
                    </p>

                    <v-row class="pt-6" dense justify="center">
                        <standard-button
                          id="standard-process-batch_mode-download_sample_file"
                          :text="$vuetify.lang.t('$vuetify.batchModeDownloadSampleCSV')"
                          class="hidden-xs-only"
                          icon="download"
                          @click="downloadSampleFile"
                        />

                    </v-row>

                    <p class="cts-font-size-4 mb-0 pl-0 pt-lg-7">
                        {{$vuetify.lang.t('$vuetify.batchModeExtraCSVStepIntroductionVariables')}}
                    </p>

                    <ul class="cts-font-size-4 pt-lg-2 pl-lg-4" style="list-style: none;">
                        <li v-for="v in variables" :key="v.key">
                            <b>{{v.key}}</b>: {{v.text}}
                        </li>
                    </ul>

                    <p
                      class="cts-font-size-4 mb-0 pl-0 pt-lg-7"
                      v-html="$vuetify.lang.t('$vuetify.batchModeExtraCSVStepIntroduction2')"
                    >
                    </p>

                    <p
                      class="cts-font-size-4 mb-0 pl-0 pt-4"
                      v-html="$vuetify.lang.t('$vuetify.batchModeExtraCSVStepIntroduction3')"
                    >
                    </p>
                </v-col>

                <v-col class="pl-lg-8" cols="12" lg="8">
                    <v-row dense justify="space-between">
                        <v-col v-if="csvData" class="cts-font-size-4">
                            <v-icon v-if="csvData.length > 0" class="pr-2" color="green" small>check_circle</v-icon>
                            <v-icon v-else class="pr-2" color="red" small>error</v-icon>
                            <span
                              v-html="$vuetify.lang.t('$vuetify.batchModeCSVTableTitle').replace('{filename}', csvFile.name).replace('{nOfRecipients}', csvData.length)"></span>
                            <a style="color: var(--primary-alternative)" @click="removeCSV">
                                <strong>&nbsp;({{$vuetify.lang.t('$vuetify.batchModeStep1RemoveFile')}})</strong>
                            </a>
                            <p v-if="csvData.length > 0" class="pl-1 mt-5">
                                {{$vuetify.lang.t('$vuetify.batchModeCSVTableSubtitle')}}
                            </p>
                            <p v-else class="pl-1 mt-5">
                                {{$vuetify.lang.t('$vuetify.batchModeCSVTableSubtitleNoData')}}
                            </p>
                        </v-col>
                        <v-col v-else class="cts-font-size-4">
                            <span v-html="$vuetify.lang.t('$vuetify.batchModeNoDataTitle')"></span>
                        </v-col>
                    </v-row>


                    <DropZone
                      v-if="!csvData"
                      v-model="csvFile"
                      :multiple="false"
                      :text="`${$vuetify.lang.t('$vuetify.dropZoneTextSingle')} CSV o XLS`"
                      :text-cols="12"
                      acceptedTypes=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      class="mt-2"
                      height="400"
                    />

                    <p
                      v-if="csvError"
                      class="pt-2 cts-font-size-4 text-right"
                      style="color: var(--error)"
                    >
                        <b>{{$vuetify.lang.t('$vuetify.csvError')}}:</b>&nbsp;
                        <span
                          v-if="csvErrorDetail && csvErrorDetail.code === 'TooManyFields'">{{$vuetify.lang.t('$vuetify.csvErrorDetailStructure')}}</span>
                        <span
                          v-else-if="csvErrorDetail && csvErrorDetail.code === 'TooFewFields'">{{$vuetify.lang.t('$vuetify.csvErrorDetailStructure')}}</span>
                        <span v-else>{{csvErrorDetail}}</span>
                    </p>

                    <RecipientPreviewExpandableTable
                      :recipients="csvData"
                      :rowsToShow="5"
                    />

                </v-col>
            </v-row>
        </div>
        <!-- END STEP 1 -->

        <!-- STEP 2 -->
        <div v-show="currentStep == 2 && requiredFiles.size > 0" class="pl-lg-6 pr-lg-8 pt-2">
            <v-row class="ma-0 px-0" dense>
                <v-col class="pr-lg-8" cols="12" lg="4">
                    <p
                      class="cts-font-size-4 mb-0 pl-0"
                      v-html="$vuetify.lang.t('$vuetify.batchModeExtraFilesStepIntroduction')
              .replace('{fileSizeLimit}', Math.trunc(convertBytesToMegabytes(this.fileSizeLimitBytes.file)))
              .replace('{totalSizeLimit}', convertBytesToGigabytes(this.fileSizeLimitBytes.total))
              .replace('{count}', requiredFiles.size)
            "
                    >
                    </p>
                    <br>
                    <p class="cts-font-size-4" style="color: var(--primary)">
                        <span class="font-weight-bold">{{
                                $vuetify.lang.t('$vuetify.batchModeStep2PendingFiles')
                            }}:&nbsp;</span>
                        <span>{{requiredFiles.size - extraFiles.length}}</span>
                    </p>

                    <p class="cts-font-size-4" style="color: var(--primary)">
                        <span class="font-weight-bold">{{$vuetify.lang.t('$vuetify.batchModeStep2SpaceLeft')}}:</span>
                        <span v-if="convertBytesToMegabytes(currentFileSizeBytes) > 200">
              {{convertBytesToGigabytes(currentFileSizeBytes)}} GB
            </span>
                        <span v-else>
              {{currentFileSizeBytes === 0 ? 0 : convertBytesToMegabytes(currentFileSizeBytes)}} MB
            </span>
                        / <span class="font-weight-bold">{{convertBytesToGigabytes(fileSizeLimitBytes.total)}} GB</span>
                    </p>
                </v-col>

                <v-col class="py-0 px-0 mt-lg-0 mt-4" cols="12" lg="8">
                    <div class="extrafiles-column">
                        <ResourceTable
                          :csvData="csvData"
                          :extraFiles="extraFilesArraySecure"
                          :requiredFiles="Array.from(requiredFiles)"
                        />

                        <div class="cts-font-size-4 text-right mt-4" style="color: var(--success)">
                            <span v-if="fileTableInfo">{{fileTableInfo}}</span>
                        </div>

                        <file-upload
                          ref="upload"
                          v-model="extraFiles"
                          :accept="acceptedTypes"
                          :data="{ batch_id: batchId}"
                          :drop="currentStep == 2"
                          :headers="fileUploadComponentParameters.headers"
                          :multiple="true"
                          :name="fileUploadComponentParameters.name"
                          :post-action="fileUploadComponentParameters.postAction"
                          class=""
                          style="width: 100%; cursor: pointer !important;"
                          @input-filter="inputFilter"
                        >
                            <div
                              :style="`height: ${resourcesPanelOpen ? 200 : 300}px; transition: height 0.3s linear 0.3s;`"
                              class="table-dropzone">
                                <v-row align-content="center" justify="center" style="height: 100%">
                                    <v-icon color="var(--primary)" size="96">file_upload</v-icon>
                                    <v-col class="text-center" cols="12">
                                        <span>{{$vuetify.lang.t('$vuetify.dropZoneText')}}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </file-upload>

                        <div class="cts-font-size-4 text-right" style="color: var(--error)">
                            <span>{{fileTableError}}</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- END STEP 2 -->

        <!-- STEP 3 -->
        <BatchModeSectionPreview
          v-if="currentStep == 3"
          :csvData="csvData"
          :csvFilename="csvFile.name"
          :extraFiles="extraFiles"
          :requiredFiles="Array.from(requiredFiles)"
        />
        <!-- END STEP 3 -->

        <!-- STEP 4 -->
        <div v-if="currentStep == 4" class="ml-8">
            <BatchModeLaunchedSection
              :error="processingError"
              :extraFiles="extraFiles"
              :uploadingFiles="uploadingFiles"
              @endProcess="endProcess"
            />
        </div>
        <!-- END STEP 4 -->

        <!-- CONTROL BAR -->

        <ProcessStepControlBar
          v-if="currentStep < 4"
          :backButtonObject="controlBar.back"
          :continueButtonObject="controlBar.continue"
          :infoObject="controlBar.info"
          class="mt-6"
          @back="handleControlBarBack"
          @next="handleControlBarNext"
        />

        <!-- CONTROL BAR -->

        <BatchModeErrorDialog
          v-if="errorDialog.visible"
          :dialogTitle="errorDialog.dialogTitle"
          :errorDetail="errorDialog.errorDetail"
          :errorName="errorDialog.error"
          @close="errorDialog.visible = false"
          @click:outside="errorDialog.visible = false"
        />
    </v-container>
</template>

<script>
import StandardButton from '@/components/buttons/standardButton'
import Papa from "papaparse"
import {
    NAMESPACE as PROCESSES_NAMESPACE,
    STATE as PROCESSES_STATE
} from "@/constants/vuex/processes"
import const_global from '@/constants/global'
import FileUpload from 'vue-upload-component'
import Mime from "@/plugins/mime"
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import DropZone from '@/components/structures/DropZone'
import BatchModeSectionPreview from "./BatchModeSectionPreview"
import RecipientPreviewExpandableTable
    from "@/components/sections/processLaunch/models/batchMode/RecipientPreviewExpandableTable"
import BatchModeLaunchedSection from "@/components/sections/processLaunch/models/batchMode/BatchModeLaunchedSection"
import BatchModeErrorDialog from "@/components/sections/processLaunch/models/batchMode/BatchModeErrorDialog"
import ResourceTable from "@/components/sections/processLaunch/models/batchMode/ResourceTable"
import {convertExcelFileToCSV, convertBytesToMegabytes, convertBytesToGigabytes, getFileEncoding} from "@/util/utils"
import {getBatchId, uploadDescriptor, closeProcess} from "@/services/batchModeServices"
import {NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE} from "@/constants/vuex/authentication"
import {BATCH_UPLOAD} from "@/constants/backOfficeRoutes"
import const_icons from "@/constants/icons"
import ProcessStepControlBar from "@/components/sections/processLaunch/models/commonComponents/ProcessStepControlBar"
import RecipientFileParser from "@/util/RecipientFileParser"

export default {
    name: "BatchMode",
    props: {
        processData: Object
    },
    components: {
        StandardButton,
        FileUpload,
        SectionTitle,
        DropZone,
        BatchModeSectionPreview,
        RecipientPreviewExpandableTable,
        ResourceTable,
        ProcessStepControlBar,
        BatchModeLaunchedSection,
        BatchModeErrorDialog
    },

    data() {
        return {
            convertBytesToMegabytes,
            convertBytesToGigabytes,
            const_icons,
            resourcesPanelOpen: false,
            files: [],
            process_fields: this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_FIELDS],
            recipient_fields: [
                const_global.CONTACT_ID,
                const_global.CONTACT_NAME,
                const_global.CONTACT_SURNAME,
            ],
            optional_recipient_fields: [
                const_global.CONTACT_EMAIL,
                const_global.CONTACT_COUNTRY_CODE,
                const_global.CONTACT_PHONE
            ],
            sampleFile: null,
            csvFile: null,
            headers: null,
            csvData: null,
            csvError: false,
            csvErrorDetail: null,
            requiredFiles: [],
            extraFiles: [],
            allowedExtensions: ["html", "pdf"],
            fileSizeLimitBytes: {
                file: 10485760, // 10MB
                total: 1000000000, // 1073741824 = 1GB 1000000000
                transaction: 10485760 // 10MB
            },

            fileTableError: null,
            fileTableInfo: null,

            fileUploadTableHeaders: [
                {text: "#", value: "index", name: "index"},
                {text: this.$vuetify.lang.t('$vuetify.extraFilesTableHeaderFileName'), value: "name", name: "name"},
                {text: this.$vuetify.lang.t('$vuetify.extraFilesTableHeaderStatus'), value: "status", name: "status"},
            ],

            fileUploadComponentParameters: {
                drop: true,
                name: "resource_file",
                postAction: BATCH_UPLOAD.ROUTE_UPLOAD_RESOURCE.replace("$process_code$", this.processData.process_code),
                headers: {
                    Lang: this.$store.state.location.currentLocation.iso,
                    GroupId: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID],
                    Username: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME],
                    SubaccountCode: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_SUBACCOUNT_CODE],
                    Token: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_CODE],
                    Subdomain: window.location.host.split('.')[0].toLowerCase()
                }
            },

            behaviorLayer: JSON.parse(this.processData.process_metadata.behaviorLayer),

            controlBar: {
                info: {text: this.$vuetify.lang.t('$vuetify.launchDataKoBatchModeStep1'), icon: "error", color: "red"},
                back: {
                    text: this.$vuetify.lang.t('$vuetify.back'),
                    icon: const_icons.BACK,
                    disabled: false,
                    visible: false
                },
                continue: {text: this.$vuetify.lang.t('$vuetify.continue'), icon: const_icons.NEXT, disabled: true}
            },

            currentStep: 1,
            batchId: null,
            csvErrorDialog: false,
            errorDialog: {
                visible: false,
                dialogTitle: null,
                error: null,
                errorDetail: null
            },
            processingError: false,
            rowFileSizeMap: [],
            currentFileSizeBytes: 0,

            variables: [
                {key: "%%contact_id%%", text: this.$vuetify.lang.t("$vuetify.batchModeContactId")},
                {key: "%%contact_name%%", text: this.$vuetify.lang.t("$vuetify.batchModeContactName")},
                {key: "%%contact_surname%%", text: this.$vuetify.lang.t("$vuetify.batchModeContactSurname")},
            ]
        }
    },

    computed: {
        uploadingFiles() {
            if (this.extraFiles.length == 0) {
                return null
            }

            return this.extraFiles.some(x => x.active || !x.success)
        },

        currentSectionTitle() {
            let result = {title: null, icon: null}
            switch (this.currentStep) {
                case 1:
                    result.title = this.$vuetify.lang.t('$vuetify.batchModeCSVStep')
                    result.icon = "group"
                    break;

                case 2:
                    result.title = this.$vuetify.lang.t('$vuetify.batchModeAttachmentsStep')
                    result.icon = "all_inbox"
                    break;

                case 3:
                    result.title = this.$vuetify.lang.t('$vuetify.batchModePreviewStep')
                    result.icon = "visibility"
                    break;

                default:
                    break;
            }
            return result
        },

        acceptedTypes() {
            return this.allowedExtensions.map(x => Mime.getType(x)).join()
        },

        extraFilesArraySecure: {
            get() {
                return this.extraFiles ? this.extraFiles : []
            },

            set(value) {
                this.extraFiles = value
            }
        },

        isValid() {
            return (this.currentStep == 1 && !this.csvError && this.csvData && this.csvData.length > 0) ||
              (this.currentStep == 2 && ([...this.requiredFiles].every(x => this.extraFilesArraySecure.some(y => y.name === x))))
        }
    },

    methods: {
        resetCSVErrors() {
            this.csvError = false
            this.csvErrorDetail = null
        },

        removeCSV() {
            this.csvFile = null
            this.csvData = null
            this.requiredFiles = []
            this.extraFiles = []
            this.fileTableError = null
            this.currentFileSizeBytes = 0
            this.$set(this.controlBar.info, "text", this.$vuetify.lang.t('$vuetify.launchDataKoBatchModeStep1'))
        },

        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (this.requiredFiles && !this.requiredFiles.has(newFile.name)) {
                    this.fileTableError = this.$vuetify.lang.t('$vuetify.batchModeResourceTableErrorFilenameInvalid')
                    this.$set(this.errorDialog, "dialogTitle", this.$vuetify.lang.t('$vuetify.extraFileError'))
                    this.$set(this.errorDialog, "error", this.fileTableError)
                    this.$set(this.errorDialog, "visible", true)

                    return prevent()
                }

                if (newFile.size > this.fileSizeLimitBytes.file) {
                    const errorDetail = this.$vuetify.lang.t('$vuetify.extraFileErrorDialogDescription').replace('{fileSizeLimit}', Math.trunc(this.fileSizeLimitBytes.file * 0.000001)).replace('{totalSizeLimit}', this.fileSizeLimitBytes.total)
                    this.fileTableError = this.$vuetify.lang.t('$vuetify.batchModeResourceTableErrorFileSizeLimit').replace("{size}", Math.trunc(convertBytesToMegabytes(this.fileSizeLimitBytes.file)))

                    this.$set(this.errorDialog, "dialogTitle", this.$vuetify.lang.t('$vuetify.extraFileError'))
                    this.$set(this.errorDialog, "error", this.fileTableError)
                    this.$set(this.errorDialog, "errorDetail", errorDetail)
                    this.$set(this.errorDialog, "visible", true)

                    return prevent()
                }

                if ((newFile.size + this.currentFileSizeBytes) >= this.fileSizeLimitBytes.total) {
                    this.fileTableError = this.$vuetify.lang.t('$vuetify.batchModeResourceTableErrorTotalFileSizeLimit').replace("{size}", convertBytesToGigabytes(this.fileSizeLimitBytes.total))
                    this.$set(this.errorDialog, "dialogTitle", this.$vuetify.lang.t('$vuetify.extraFileError'))
                    this.$set(this.errorDialog, "error", this.fileTableError)
                    this.$set(this.errorDialog, "visible", true)

                    return prevent()
                }

                if (!this.rowFileSizeMap.some(x => x.filenames.includes(newFile.name) && (x.currentSize - newFile.size) < 1)) {
                    const rowsToUpdate = this.rowFileSizeMap.filter(x => x.filenames.includes(newFile.name))
                    rowsToUpdate.forEach(row => {
                        row.currentSize -= newFile.size
                    })

                } else {
                    const affectedRows = this.rowFileSizeMap.filter(x => x.filenames.includes(newFile.name) && (x.currentSize - newFile.size) < 1).map(x => x.row)
                    this.fileTableError = this.$vuetify.lang.t('$vuetify.extraFileErrorTransactionSizeLimit').replace("{rows}", affectedRows.join(", "))

                    this.$set(this.errorDialog, "dialogTitle", this.$vuetify.lang.t('$vuetify.extraFileError'))
                    this.$set(this.errorDialog, "error", this.fileTableError)
                    this.$set(this.errorDialog, "errorDetail", this.$vuetify.lang.t('$vuetify.csvErrorDialogDescription'))
                    this.$set(this.errorDialog, "visible", true)
                    return prevent()
                }

                if (this.extraFiles.some(x => x.name == newFile.name)) {
                    const index = this.extraFiles.findIndex(x => x.name == newFile.name)
                    this.currentFileSizeBytes -= this.extraFiles[index].size
                    this.extraFiles.splice(index, 1)

                    this.fileTableInfo = this.$vuetify.lang.t('$vuetify.batchModeResourceTableInfoFileReplaced').replace("{filename}", newFile.name)
                    setTimeout(() => {
                        this.fileTableInfo = null
                    }, 5000);
                }

                this.fileTableError = null
                this.currentFileSizeBytes += newFile.size
            }
        },

        createSampleCSV() {
            const fields = Object.keys(this.process_fields).filter(x => !Object.keys(this.behaviorLayer.contactFieldMapping).includes(x))
            const extraFields = this.processData.process_metadata.languages.length > 1 ? ["language"] : []

            const requiredRecipientFields = [
                ...this.recipient_fields,
                ...this.optional_recipient_fields.filter(x => Object.values(this.behaviorLayer.contactFieldMapping).includes(x))
            ]

            this.headers = [...requiredRecipientFields, ...fields, ...extraFields]

            const csv = Papa.unparse({data: this.headers}, {delimiter: ';'})
            const blob = new Blob(["\ufeff", csv], {type: 'text/plain'})
            const filename = `CSV-${this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_NAME].replace(" ", "_")}.csv`

            return new File([blob], filename, {type: "text/csv"})
        },

        downloadSampleFile() {
            const link = document.createElement('a')
            const url = URL.createObjectURL(this.sampleFile)

            link.href = url
            link.download = this.sampleFile.name
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        },

        getFileNames(csvRow) {
            const fileFields = Object.keys(this.process_fields).filter(k => "file" === this.process_fields[k].type)
            const arrayFileFields = Object.keys(this.process_fields).filter(k => "array_file" === this.process_fields[k].type)

            return [...fileFields.map(x => csvRow[x]), ...arrayFileFields.map(x => csvRow[x].split(",")).flat()]
        },

        async parseInputCSVFile(file, encoding) {
            const recipientParser = new RecipientFileParser(file)
            const parsingResult = await recipientParser.processFile(this.headers, this.process_fields, encoding)

            if (!parsingResult.error) {
                this.csvData = parsingResult.data
                const {fileFields, arrayFileFields} = recipientParser.__extractFileFields(this.process_fields)

                this.rowFileSizeMap = this.csvData.map((row, index) => {
                    return {
                        row: index + 1,
                        currentSize: this.fileSizeLimitBytes.transaction,
                        filenames: [...fileFields, ...arrayFileFields].map(x => row[x])
                    }
                })

                this.requiredFiles = recipientParser.extractExtraResourcesFilenames(this.process_fields)

            } else {
                this.csvError = true
                switch (parsingResult.message) {
                    case "Malformed file":
                        this.csvErrorDetail = this.$vuetify.lang.t('$vuetify.csvErrorDetailStructure')
                        break

                    case "File headers and process headers are different":
                        this.csvErrorDetail = this.$vuetify.lang.t('$vuetify.csvErrorDetailIncorrectFields')
                        break

                    case "File extension not allowed":
                        this.csvErrorDetail = this.$vuetify.lang.t('$vuetify.csvErrorDetailIncorrectExtensions')
                        this.csvErrorDetail += parsingResult.detail.map(err => ` Fila ${err.row}: ${err.errors.map(x => x.fieldName)}`)

                        break
                }

                this.$set(this.errorDialog, "dialogTitle", this.$vuetify.lang.t('$vuetify.csvError'))
                this.$set(this.errorDialog, "error", this.csvErrorDetail)
                this.$set(this.errorDialog, "errorDetail", this.$vuetify.lang.t('$vuetify.csvErrorDialogDescription'))
                this.$set(this.errorDialog, "visible", true)
            }

        },

        handleFileActionClick(actionName, file) {
            switch (actionName) {
                case "remove":
                    this.$refs.upload.remove(file)
                    break;

                case "upload":
                    this.$refs.upload.update(file, {active: true})
                    break;

                default:
                    break;
            }
        },

        async endProcess() {
            await this.sendDescriptor()
            const closeRes = await closeProcess(this.processData.process_code, this.batchId)
            this.processingError = closeRes.data.error != 0
        },

        async sendDescriptor() {
            const transformCSVRowToTransaction = (row) => {
                const result = {
                    recipient: {},
                    parameters: {}
                }

                if (this.processData.process_metadata.languages.length == 1) {
                    result.parameters.language = this.processData.process_metadata.languages[0].code
                }

                Object.keys(row).forEach(col => {
                    if (col.startsWith("contact_")) {
                        result.recipient[col] = row[col]
                    } else {
                        result.parameters[col] = row[col]
                    }
                })

                const fieldMapping = Object.entries(this.behaviorLayer.contactFieldMapping).map(([key, value]) => ({[key]: `%%${value}%%`}))

                result.parameters = {
                    ...result.parameters,
                    ...fieldMapping.reduce((obj, item) => Object.assign(obj, {[Object.keys(item).pop()]: Object.values(item).pop()}), {})
                }

                return result
            }

            const metadata = {
                original_filename: this.csvFile ? this.csvFile.name : null,
                transaction_count: this.csvData.length,
                username: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME],
                process_code: this.processData.process_code,
                master_account_code: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE],
                cost_center: this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_COST_CENTER]["cost_center_id"]
            }

            const transactions = this.csvData.map(transformCSVRowToTransaction)

            const payload = {
                batch_id: this.batchId,
                metadata,
                transactions

            }

            const requestRes = await uploadDescriptor(this.processData.process_code, this.batchId, payload)
            if (requestRes.data.error != 0) {
                this.processingError = true
            }
        },

        async beginUploadProcess() {
            this.$refs.upload.active = true
        },

        async fetchBatchId() {
            const res = await getBatchId(this.processData.process_code)
            if (res.data.error == 1) {
                console.error("Error", res.data)
            } else {
                this.batchId = res.data.extra.batch_id
            }
        },

        handleControlBarNext() {
            if (this.currentStep === 3) {
                this.beginUploadProcess()
            }

            this.currentStep = this.currentStep === 1 && this.requiredFiles.size == 0 ? 3 : this.currentStep + 1
        },

        handleControlBarBack() {
            this.fileTableError = null

            if (this.currentStep === 3 && this.requiredFiles.size == 0) {
                this.currentStep -= 2

            } else {
                this.currentStep -= 1
            }
        },

        updateControlBarNextButton() {
            let infoText = this.isValid ? this.$vuetify.lang.t('$vuetify.launchDataOkBatchMode') : this.$vuetify.lang.t('$vuetify.launchDataKoBatchMode')

            if (this.isValid) {
                switch (this.currentStep) {
                    case 1:
                        infoText = this.$vuetify.lang.t('$vuetify.launchDataOkBatchModeStep1')
                        break;

                    case 2:
                        infoText = this.$vuetify.lang.t('$vuetify.launchDataOkBatchModeStep2')
                        break;

                    default:
                        break;
                }
            } else {
                switch (this.currentStep) {
                    case 1:
                        infoText = this.csvData && this.csvData.length === 0 ? this.$vuetify.lang.t('$vuetify.batchModeCSVTableSubtitleNoData') : this.$vuetify.lang.t('$vuetify.launchDataKoBatchModeStep1')
                        break;

                    case 2:
                        infoText = this.$vuetify.lang.t('$vuetify.launchDataKoBatchModeStep2')
                        break;

                    default:
                        break;
                }
            }

            this.$set(this.controlBar.info, "icon", this.isValid ? "check_circle" : "error")
            this.$set(this.controlBar.info, "color", this.isValid ? "green" : "red")
            this.$set(this.controlBar.info, "text", infoText)

            this.$set(this.controlBar.continue, "disabled", !this.isValid)
        }
    },

    created() {
        this.sampleFile = this.createSampleCSV()
    },

    watch: {
        uploadingFiles(_, oldValue) {
            if (oldValue !== null) {
                this.endProcess()
            }
        },

        currentStep(newValue) {
            this.$set(this.controlBar.continue, "text", this.$vuetify.lang.t('$vuetify.continue'))
            this.$set(this.controlBar.continue, "icon", const_icons.NEXT)

            if ((newValue == 2 || this.requiredFiles.size == 0 && newValue == 3) && !this.batchId) {
                this.fetchBatchId()
            }

            this.updateControlBarNextButton()
            this.$set(this.controlBar.back, "visible", newValue > 1)

            if (newValue == 3) {
                this.$set(this.controlBar.info, "icon", "check_circle")
                this.$set(this.controlBar.info, "color", "green")
                this.$set(this.controlBar.info, "text", this.$vuetify.lang.t('$vuetify.launchDataOkBatchMode'))

                this.$set(this.controlBar.continue, "icon", const_icons.SEND)
                this.$set(this.controlBar.continue, "text", this.$vuetify.lang.t('$vuetify.send'))
                this.$set(this.controlBar.continue, "disabled", false)

            }
        },

        async csvFile(newFile) {
            if (newFile) {
                this.resetCSVErrors()
                let csv = newFile
                if (["xlsx", "xls"].includes(Mime.getExtension(newFile.type))) {
                    csv = await convertExcelFileToCSV(newFile)
                }

                const encoding = await getFileEncoding(newFile)
                this.parseInputCSVFile(csv, encoding)
            }
        },

        csvData: {
            handler() {
                this.updateControlBarNextButton()
            },
            deep: true
        },

        extraFiles: {
            handler() {
                this.updateControlBarNextButton()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.batchmode-file-added-container {
    width: 100%;
    height: 6vh;
    border: 1px solid var(--primary);
}

// .csv-recipient-panel-content .v-expansion-panel-content__wrap {
//   padding: 0px !important;
// }

// .csv-recipient-panel {
//   border: 1px solid var(--card-border);
//   border-radius: 0px !important;

//   &-icon {
//     border: 1px solid var(--card-border);
//     border-radius: 0;
//     background-color: var(--lighten);
//   }

//   &-content-row {
//     border-bottom: 1px solid var(--card-border);
//   }

//   &::after {
//     border: none !important;
//   }
// }

.table-dropzone {
    width: 100%;
    // height: 400px;
    background-color: var(--lighten);
    border: 1px dashed var(--primary);
    cursor: pointer !important;
}

label[for="resource_file"] {
    cursor: pointer !important;
}
</style>