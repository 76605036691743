<template>
    <v-row cols="12" class="pa-2 ma-0 cts-box-preview-title d-flex flex-row align-center">
      <v-col cols="1" class="ma-0 pa-0 d-flex align-center">
        <v-icon id="dialog-img-icon-desktop" class="cts-icon-small cts-bgcolor-clear" color="var(--primary) !important" size="23"> {{ icon }} </v-icon>
      </v-col>
      <v-col cols="10" class="pa-0 ma-0 pl-2 d-flex align-center">
        <h3 id="dialog-text-title-desktop" class="cts-color-primary text-uppercase font-weight-bold pa-0 ma-0">{{ text }}</h3>
      </v-col>
        <v-col v-if="close" cols="1" class="ma-0 pa-0 text-right">
          <v-icon id="dialog-img-close_icon-desktop" class="cts-icon-small float-right" size="23" @click="closeModal()">close</v-icon>
        </v-col>
      </v-row>
</template>

<script>
  import { NAMESPACE as FUNCTIONALITY_NAMESPACE } from "@/constants/vuex/functionality"

  export default {
    name: "dialogTitle",
    props: {
      icon: String,
      text: String,
      close: Boolean,
      event: String,
      vuex_dialog_action: String
    },

    methods: {
      // TODO: Cambiar esta función y emitir al parent directamente
      closeModal() {
        this.$emit('close')
        if( this.vuex_dialog_action ) {
          this.$store.dispatch(FUNCTIONALITY_NAMESPACE + "/" + this.vuex_dialog_action, false)
        } else {
          this.$emit('close', "exit")
          if (this.event) {
            this.$emit(this.event)
          }
        }
      }
    }
  }
</script>
