/* Language */
import { STATE, MUTATIONS, ACTIONS, GETTERS } from "@/constants/vuex/location"
import ca from '../../assets/locales/ca'
import de from '../../assets/locales/de'
import en from '../../assets/locales/en'
import es from '../../assets/locales/es'
import eu from '../../assets/locales/eu'
import fr from '../../assets/locales/fr'
import gl from '../../assets/locales/gl'
import it from '../../assets/locales/it'
import nl from '../../assets/locales/nl'
import pt from '../../assets/locales/pt'
import locations from "@/constants/locations"

const state = {
    // [STATE.S_AVAILABLE_LOCATIONS]: null,
    // [STATE.S_LOCALES]: {},
    [STATE.S_AVAILABLE_LOCATIONS]: locations,
    [STATE.S_LOCALES]: null,
    [STATE.S_CURRENT_LOCATION]: {
      code: "es",
      name: "SPA",
      iso: "spa"
    },
}
const getters = {
    [GETTERS.G_CURRENT_LOCATION](state) {
      return state[STATE.S_CURRENT_LOCATION]
    },
    [GETTERS.G_LOCALES]() {
      return { ca, de, en, es, eu, fr, gl, it, nl, pt }
    },
    [GETTERS.G_AVAILABLE_LOCATIONS](state) {
      return state.availableLocations;
    }
}
const mutations = {
  [MUTATIONS.M_AVAILABLE_LOCATIONS](state, availableLocations) {
    state[STATE.S_AVAILABLE_LOCATIONS] = availableLocations
  },

  [MUTATIONS.M_LOCATION](state, newLocation) {
    state[STATE.S_CURRENT_LOCATION] = newLocation
  },

  [MUTATIONS.M_LOCALES](state, newLocale) {
    state[STATE.S_LOCALES] = newLocale
  }
}

const actions = {
  [ACTIONS.A_SET_AVAILABLE_LOCATIONS]({ commit }, locations) {
    commit(MUTATIONS.M_AVAILABLE_LOCATIONS, locations)
  },

  [ACTIONS.A_SET_LOCATION]({ commit }, newLocation) {
    commit(MUTATIONS.M_LOCATION, newLocation)
  },

  [ACTIONS.A_UPDATE_LOCALES]({ commit, state }, newLocale) {
    const newLocales = state[STATE.S_LOCALES]
    newLocales[Object.keys(newLocale)[0]] = newLocale[Object.keys(newLocale)[0]]
    commit(MUTATIONS.M_LOCALES, newLocales)
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
