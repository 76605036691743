<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon left size="19" class="mr-1">{{const_icons.CHANGE_PASS}}</v-icon>
        {{$vuetify.lang.t('$vuetify.passwordchange')}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-sm-4">
      <v-row dense class="ma-0 pa-0">
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="ma-0 pa-0">
          <v-row>
            <v-col cols="12" xl="4" md="6" class="cts-field-medium-block pa-0 px-sm-3">
              <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      class="cts-form-login mt-4"
              >
                <v-col cols="12" md="12" class="cts-field-medium-block">
                  <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.oldpassword')}}</span>
                  <v-text-field
                          outlined
                          autocomplete="off"
                          v-model="input.old_password"
                          :value="input.old_password"
                          name="oldPassword"
                          type="password"
                          :aria-label="$vuetify.lang.t('$vuetify.oldpassword')"
                          :placeholder="$vuetify.lang.t('$vuetify.oldpassword')"
                          required
                  />
                </v-col>
                <v-col cols="12" md="12" class="cts-field-medium-block">
                  <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.newpassword')}}</span>
                  <v-text-field outlined
                                v-model="input.password"
                                :value="input.password"
                                name="password"
                                :rules="passwordRules"
                                type="password"
                                :aria-label="$vuetify.lang.t('$vuetify.newpassword')"
                                :placeholder="$vuetify.lang.t('$vuetify.newpassword')"
                  />
                </v-col>
                <v-col cols="12" md="12" class="cts-field-medium-block">
                  <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.repeatpassword')}}</span>
                  <v-text-field outlined
                                v-model="input.repeat_password"
                                name="passwordRepeat"
                                autocomplete="on"
                                :rules="confirmPasswordRules"
                                type="password"
                                :aria-label="$vuetify.lang.t('$vuetify.repeatpassword')"
                                :placeholder="$vuetify.lang.t('$vuetify.repeatpassword')"
                  />
                </v-col>
                <v-expand-transition>
                  <v-row v-if="errorMessage != null" dense class="ma-0 cts-login-error-message">
                    <span class="caption">{{errorMessage}}</span>
                  </v-row>
                </v-expand-transition>
              </v-form>
            </v-col>
            <v-col cols="12" xl="8" lg="6" md="6" sm="12">
              <transition name="hint" appear>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="pb-2">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.passwordrequirements')}} </span>
                  </v-col>
                  <v-col cols="12" xl="4" lg="12" md="12" sm="12" class="py-0">
                    <div class="cts-passwordrequirements-paragraph mt-0 pa-0">
                      <h4 v-for="(rule ,index) in rules" :key="index" class="mb-0">
                        <v-row dense class="ma-0 pa-0" v-for="(error ,index) in passwordValidation.errors" :key="index">
                          <v-col cols="1" class="ma-0 pa-0">
                            <v-icon v-if="rule.message === error.message" :class="error.validField ? 'cts-field-ok' : 'cts-field-error'" size="20">check_circle</v-icon>
                          </v-col>
                          <v-col cols="11" class="ma-0 pa-0">
                            <span class="ml-2" v-if="rule.message === error.message">{{error.message}}</span>
                          </v-col>
                        </v-row>
                      </h4>
                      <v-row v-if="!allValidationsAreOK" class="ma-0 pa-0">
                        <v-col class="ma-0 pa-0">
                          <div v-if='notSamePasswords'>
                            <span class="cts-form-labeled-input mt-4 cts-color-error font-weight-bold">{{$vuetify.lang.t('$vuetify.notequalpasswords')}} </span>
                          </div>
                          <div v-if='samePasswordThanOldPassword'>
                            <span class="cts-form-labeled-input mt-4 cts-color-error font-weight-bold">{{$vuetify.lang.t('$vuetify.samepasswordthanolder')}} </span>
                          </div>
                          <div v-if="passwordsFilled && input.old_password === ''">
                            <span class="cts-form-labeled-input mt-4 cts-color-error font-weight-bold"> {{$vuetify.lang.t('$vuetify.emptyOldPassword')}} </span>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </transition>
            </v-col>
          </v-row>
        </v-col>
  </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

  import const_global from "@/constants/global";
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, GETTERS as AUTHENTICATION_GETTERS, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
  import const_icons from "@/constants/icons";

  export default {
    name: "sectionUserPassChange",
    data() {
      return {
        const_icons,
        valid: false,
        minRequirements: false,
        requirements: null,
        validHash: false,
        errorMessage: null,
        loggingIn: false,
        loader: false,
        id: null,
        token: null,
        visible: false,
        input: {
          old_password: "",
          password: "",
          repeat_password: ""
        },
        username: null,
        passwordRules: [
          v => !!v || this.$vuetify.lang.t('$vuetify.passwordRequired'),
          v => (v && v.length >= 8) || this.$vuetify.lang.t('$vuetify.passwordrequirementsMoreThanEightChars'),
          v => (
                (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v) ) ||
                (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v) ) ||
                (/^(?=.*[A-Z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v) ) ||
                (/^(?=.*[a-z])(?=.{8,})/.test(v) && /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/.test(v) && /^(?=.*[0-9])(?=.{8,})/.test(v) )
              ) || this.$vuetify.lang.t('$vuetify.passwordFieldError'),
        ],
        confirmPasswordRules: [
          v => !!v || this.$vuetify.lang.t('$vuetify.passwordCompares')
        ],
        rules: [
          {message: this.$vuetify.lang.t('$vuetify.passwordMinOneLowercase'), regex: /[a-z]+/},
          {message: this.$vuetify.lang.t('$vuetify.passwordMinOneUppercase'), regex: /[A-Z]+/},
          {message: this.$vuetify.lang.t('$vuetify.passwordMinOneNumber'), regex: /[0-9]+/},
          {message: this.$vuetify.lang.t('$vuetify.passwordMinOneSpecialChar'), regex: /[!@#$%^&*(),.?":{}|<>]+/},
          {message: this.$vuetify.lang.t('$vuetify.passwordMinEightChars'), regex: /.{8,}/}
        ],
        const_global,
        userInfo:{}
      }
    },
    computed: {
      passwordsFilled() {
        return (this.input.password !== '' && this.input.repeat_password !== '')
      },

      notSamePasswords() {
        return this.passwordsFilled ? (this.input.password !== this.input.repeat_password) : false
      },

      samePasswordThanOldPassword() {
        if (this.input.password !== '' && this.input.old_password !== '') {
          return (this.input.password == this.input.old_password)
        }

        return false
      },

      passwordValidation() {
        let errors = []
        let count = 0
        for (const element of this.rules) {
          let condition = element
          if (condition.regex.test(this.input.password) && element.message === condition.message) {
            condition.validField = 'ok'
          } else {
            condition.validField = null
          }

          errors.push(condition)

          if (errors.length === 5) {
            for (const errorPos in errors) {
              if (errors[errorPos].validField === 'ok') {
                ++count
              }
            }
          }

        }

        if (count >= 4) {
          this.requirementsOK(count)
        } else {
          this.requirementsOK(null)
        }

        if (errors.length === 0 && this.input.password != "") {
          return {valid: true, errors}
        }
        return {valid: false, errors}

      },

      allValidationsAreOK() {
        let validation
        if (this.samePasswordThanOldPassword || this.requirements <4 || !this.minRequirements || this.input.repeat_password === '' ||
            this.notSamePasswords || (this.input.old_password === '' || this.input.password === '' || (this.input.password.length < 8 || this.input.repeat_password < 8))) {
          validation = false
          this.$emit("activateSaveButton", false)
        } else {
          validation = true
          this.$emit("activateSaveButton", {
            section: "userPassChanged",
            data: this.userData
          })
        }

        return validation
      },

      userData() {
        return {
          username: this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME],
          new_password: this.input.repeat_password,
          old_password: this.input.old_password,
          from_remember: false
        }
      }
    },
    created() {
      this.username = this.$store.getters[`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_GETTERS.G_LOGIN_DATA}`].username
    },
    methods: {
      setErrorMessage(error_message) {
        this.errorMessage = error_message
      },

      requirementsOK(count) {
        this.minRequirements = true
        this.requirements = count
      }
    }
  }
</script>
