import Papa from "papaparse"
export default class RecipientFileParser {  
  constructor (file, delimiter=";", skipEmptyLines=true, extraConfig={}) {
    this.file = file
    this.delimiter = delimiter
    this.skipEmptyLines = skipEmptyLines
    this.extraConfig = extraConfig

    this.csvData = null
  }

  __parsingCompletedCallback(results) {
    if (results.errors.length > 0) {
      return { error: true, errors: results.errors }
    }

    return { headers: results.meta.fields, data: results.data }
  }

  __validateHeadersWithProcessHeaders(fileHeaders, processHeaders) {
    if (fileHeaders && processHeaders) {
      return fileHeaders.toString() === processHeaders.toString()
    }

    return null
  }

  __extractFileFields(processFields) {
    return {
      fileFields: Object.keys(processFields).filter(k => "file" === processFields[k].type),
      arrayFileFields: Object.keys(processFields).filter(k => "array_file" === processFields[k].type)
    }
  }

  extractExtraResourcesFilenames(processFields) {
    if (this.csvData) {
      const {fileFields, arrayFileFields} = this.__extractFileFields(processFields)
  
      const allFilenames = this.csvData
        .map(row => {
          return [...fileFields.map(x => row[x]), ...arrayFileFields.map(x => row[x].split(",")).flat()]
        })
        .flat()
        .filter(x => x && x != "")
  
      return new Set(allFilenames)
    }
  }

  __validateFileExtensions(processFields) {
    if (this.csvData) {
      const {fileFields, arrayFileFields} = this.__extractFileFields(processFields)
      const fileFieldsWithExtensionValidation = [...fileFields, ...arrayFileFields]
        .filter(x => {
          return processFields[x].validations?.allowed_extensions
        })
        .map(x => ({
          fieldName: x,
          extensions: processFields[x].validations?.allowed_extensions.map(x => x.toLowerCase())
        }))

        const result = this.csvData.map((row, index) => {
          const errors = fileFieldsWithExtensionValidation.filter((field) => {
            const filenames = row[field.fieldName].split(",")
            const extensions = filenames.map(x => x.toLowerCase().split("."))
  
            return extensions.some(x => x[0] == "" || x[1] !== "" && !field.extensions.includes(x[1]))
          })
          
          return { row: index + 1, errors }
        })

      const rowsWithErrors = result.filter(x => x.errors.length > 0)

      if (rowsWithErrors.length > 0) {
        return { error: true, message: rowsWithErrors }
      }

      return { error: false, message: null }
    }
  }

  async processFile(processHeaders, processFields, encoding="utf-8") {
    const res = await new Promise((resolve) => {
      Papa.parse(this.file, {
        header: true,
        encoding,
        skipEmptyLines: this.skipEmptyLines,
        config: { delimiter: this.delimiter, ...this.extraConfig },
        complete: (results) => {
          resolve(this.__parsingCompletedCallback(results))
        }
      })
    })

    if (res.error) {
      // throw new Error('Malformed file')
      return { error: true, message: "Malformed file" }
    }

    if (!this.__validateHeadersWithProcessHeaders(res.headers, processHeaders)) {
      // throw new Error('File headers and process headers are different')
      return { error: true, message: "File headers and process headers are different" }
    }

    this.csvData = res.data
    const extensionValidationResult = this.__validateFileExtensions(processFields)

    if (extensionValidationResult.error) {
      return { error: true, message: "File extension not allowed", detail: extensionValidationResult.message }
    }

    return { error: false, ...res }
  }
}