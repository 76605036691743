<template>
  <div class="recipient-preview-expandable">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(row, index) in recipients ? recipients.slice(0, rowsToShow) : []"
        :key="index"
        class="mb-2 px-2 csv-recipient-panel" 
      >
        <v-expansion-panel-header class="pa-0 py-2" hide-actions>
          <template v-slot:default="{ open }">
            <v-list-item class="pa-0">
              <v-list-item-icon class="ma-0 my-auto mr-4 csv-recipient-panel-icon">
                <v-icon color="var(--primary)" class="pa-2">person</v-icon>
              </v-list-item-icon>

              <v-list-item-content two-line class="py-1">
                <v-list-item-title class="cts-font-size-4">
                  {{ `${row.contact_name} ${row.contact_surname}` }}
                </v-list-item-title>
                <v-list-item-subtitle class="cts-font-size-4">
                  {{ `Id: ${row.contact_id}` }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <span id="recipient-panel-action" class="cts-font-size-4 mr-2" style="color: var(--primary-alternative)">
                <v-icon id="recipient-panel-action-icon" color="var(--primary-alternative)" x-small class="pr-1">{{ open ? 'visibility_off' : 'visibility' }}</v-icon>
                <strong id="recipient-panel-action-see_less" v-if="open">{{ $vuetify.lang.t('$vuetify.seeLess') }}</strong>
                <strong id="recipient-panel-action-see_more" v-else>{{ $vuetify.lang.t('$vuetify.seeMore') }}</strong>
              </span>
            </v-list-item>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="csv-recipient-panel-content pa-0">
          <v-divider style="background-color: var(--primary)"></v-divider>
          <v-row id="recipient-panel-content" dense class="pa-1">
            <v-col class="pa-3">
              <v-row dense v-for="(col, index) in row" :key="index" class="cts-font-size-4 csv-recipient-panel-content-row">
                <v-col cols="6" lg="3" class="font-weight-bold">{{ index }}</v-col>
                <v-col cols="6" lg="9" class="text-truncate">
                  {{ (row[index].startsWith("%%") && row[index].endsWith("%%")) ? row[row[index].replace(/%%/g, "")] : row[index] }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    name: "RecipientPreviewExpandableTable",
    props: {
      recipients: Array,
      rowsToShow: Number
    },

    data() {
      return {
        actionText: this.$vuetify.lang.t('$vuetify.seeMore')
      }
    },

    methods: {
      changeText() {
        if (this.actionText === this.$vuetify.lang.t('$vuetify.seeMore')) {
          this.actionText = this.$vuetify.lang.t('$vuetify.seeLess')
        } else {
          this.actionText = this.$vuetify.lang.t('$vuetify.seeMore')
        }
      }
    }
  }
</script>

<style lang="scss">
  .recipient-preview-expandable {
    .v-expansion-panels {
      z-index: 0 !important;
    }
  }

  .csv-recipient-panel {
    border: 1px solid var(--card-border);
    border-radius: 4px !important;

    &-icon {
      border: 1px solid var(--card-border);
      border-radius: 4px;
      background-color: var(--lighten);
    }

    &-content-row {
      border-bottom: 1px solid var(--card-border);
    }

    &::after {
      border: none !important;
    }
  }

  .csv-recipient-panel-content .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
</style>