<template>
  <v-row dense class="ma-0 pa-0">
    <v-col cols="11" xl="6" lg="6" md="8" sm="11" class="ma-0 px-2 py-0">
      <h5 class="ma-0 py-0">{{process.name}}</h5>
    </v-col>
    <v-col cols="1" xl="1" lg="1" md="1" sm="1" class="ma-0 pa-0">
      <v-sheet class="pa-0 ma-0">
        <v-checkbox
          :readonly="!checkPermission(const_permissions.GROUP_EDIT_PROCESSES)"
          :disabled="disabled"
          class="cts-tabs-checkboxes pa-0 ma-0"
          v-model="assigned"
          @change="changeStatus($event)"
          aria-label="checkbox-permission"
          color="var(--primary)"
          @click="$emit('click')"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import {ServerBus} from "@/main"
  import const_global from "@/constants/global"
  import { checkPermission } from "@/util/store_utils"
  import const_permissions from "@/constants/permissions"

  export default {
    name: "processAssignedRow",
    props: {
      process: Object,
      subaccountAssigned: Boolean
    },
    data(){
      return{
        checkPermission,
        const_permissions,
        disabled: false,
        assigned: false
      }
    },
    methods:{
      changeStatus(value){
        this.$emit('changeProcessAssigned',value)
      }
    },
    created() {
      this.disabled = !this.subaccountAssigned
      this.assigned = this.process.assigned

      ServerBus.$on(const_global.SERVER_BUS_EVENT_PERMISSION_SUBACCOUNT, (sa) => {
        if (this.process.subaccount === sa[const_global.SUB_ACCOUNT_CODE]) {
          this.assigned = false
          this.disabled = !sa.assigned
        }
      })
    },
    beforeDestroy() {
      ServerBus.$off(const_global.SERVER_BUS_EVENT_PERMISSION_SUBACCOUNT)
    }
  }
</script>

<style scoped>

</style>
