export const NAMESPACE = "vuex_administration"

export const STATE = {
  S_GROUPS: "state_groups",
  S_GROUP_CODE_SELECTED: "state_group_code_selected",
  S_GROUP_DETAILS: "state_group_details",
  S_NEW_GROUP_DIALOG: "state_new_group_dialog",
  S_USERS: "state_users",
  S_USER_DETAILS_DIALOG: "state_user_details_dialog"
}

export const MUTATIONS = {
  M_SET_GROUPS: "mutation_groups",
  M_SET_GROUP_CODE_SELECTED: "mutation_group_code_selected",
  M_SET_NEW_GROUP_DIALOG: "mutation_new_group_dialog",
  M_SET_GROUP_DETAILS: "mutation_group_details",
  M_SET_USERS: "mutation_set_users",
  M_SET_USER_DETAILS_DIALOG: "mutation_user_details_dialog"
}

export const ACTIONS = {
  A_SET_GROUPS: "action_groups",
  A_SET_GROUP_CODE_SELECTED: "action_group_code_selected",
  A_SET_NEW_GROUP_DIALOG: "action_new_group_dialog",
  A_SET_GROUP_DETAILS: "action_group_details",
  A_SET_USERS: "action_set_users",
  A_SET_USER_DETAILS_DIALOG: "action_user_details_dialog"
}

export const GETTERS = {
  G_GROUP_DETAILS: "getter_group_details"
}

export default {
  NAMESPACE,
  STATE,
  MUTATIONS,
  ACTIONS,
  GETTERS
}