<template>
    <v-row :class="currentStep && currentStep.model === 'digital_signature' ? 'ma-0 pa-0' : 'ma-0 pt-4' " dense>
        <v-col v-if="batchMode" class="mt-0 mb-3 ml-2 mr-2">
            <batch-mode
              v-model="batchModeObject"
              :processData="processData"
            />
        </v-col>

        <v-col v-else class="mt-0 mb-3 ml-2 mr-2">
            <StandardModel
              v-if="processData[const_global.PROCESS_STEPS] == null"
              :behavior-layer="processData.process_metadata ? processData.process_metadata.behaviorLayer:null"
              :process-fields="processData.process_fields"
            />

            <ModelPostal
              v-else-if="currentStep.model === 'postal'"
              :mapping="currentStep.mapping"
              :process-fields="processData.process_fields"
              :processData="processData"
            />

            <ModelEmail
              v-else-if="currentStep.model === 'email'"
              :extra="currentStep.extra"
              :mapping="currentStep.mapping"
              :process-fields="processData.process_fields"
            />
            <ModelDigitalSignature
              v-else-if="currentStep.model === 'digital_signature'"
              :extra="currentStep.extra"
              :mapping="currentStep.mapping"
              :metadata="processData.process_metadata"
              :process-fields="processData.process_fields"/>

        </v-col>
    </v-row>
</template>

<script>
import const_global from '@/constants/global'
import BatchMode from '@/components/sections/processLaunch/models/batchMode/batchMode'
import StandardModel from '@/components/sections/processLaunch/models/standardModel'
import ModelPostal from "@/components/sections/processLaunch/models/postal/model_postal"
import ModelEmail from "@/components/sections/processLaunch/models/email/ProcessEmailModel"
import ModelDigitalSignature
    from "@/components/sections/processLaunch/models/digitalSignature/ProcessDigitalSignatureModel"

export default {
    name: "ProcessModelContainer",
    components: {
        BatchMode,
        ModelEmail,
        ModelPostal,
        StandardModel,
        ModelDigitalSignature
    },

    props: {
        processData: Object,
        batchMode: Boolean
    },

    data() {
        return {
            const_global,
            batchModeObject: {currentStep: 1, valid: false},
        }
    },

    computed: {
        currentStep() {
            return this.processData.process_steps ? this.processData.process_steps[0] : null
        }
    }
}
</script>
