<template>
  <v-dialog
    v-model="visible"
    max-width="600px"
    content-class="cts-process-recovery-modal"
    @click:outside="visible = false; $emit('click:outside')"
  >
    <v-card class="col-lg-12 mx-auto d-block" tile flat>
      <v-card-title class="ma-0 pa-0 mb-6">
        <dialog-title
          event="closeDialog"
          icon="error"
          :text="dialogTitle"
          :close="true"
          @closeDialog="visible = false; $emit('close')"
        />
      </v-card-title>

      <v-card-text class="cts-dialog-contextual pa-0">
        <p id="batchmode-dialog-errorname" v-html="errorName"></p>
        <p id="batchmode-dialog-errordetail" v-html="errorDetail"></p>
      </v-card-text>

      <v-card-actions class="cts-dialog-actions mx-auto mt-4">
        <v-row justify="center">
          <v-btn
            id="batchmode-dialog-back_button"
            class="cts-button-standard-block"
            block
            small
            color="primary"
            @click="visible = false; $emit('close')"
          >
            {{$vuetify.lang.t('$vuetify.back')}}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogTitle from '@/components/structures/dialogTitle'
  export default {
    name: "BatchModeErrorDialog",
    components: { DialogTitle },
    props: {
      dialogTitle: String,
      errorName: String,
      errorDetail: String
    },

    data() {
      return {
        visible: true
      }
    } 
  }
</script>
