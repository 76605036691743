<template>
  <div>
    <section-title
      :text="$vuetify.lang.t('$vuetify.summary')"
      icon="assignment"
      idName="data_preview_resume-text-title"
    />

    <p
      v-if="!availableBillingUnits || availableBillingUnits.length == 0"
      id="data_preview_resume-text-resume"
      class="cts-font-size-4 mt-3"
    >
      {{$vuetify.lang.t('$vuetify.processDataPreviewTextWithoutCC')}}
    </p>

    <p v-else class="cts-font-size-4 mt-3">
      {{$vuetify.lang.t('$vuetify.processDataPreviewText')}}
    </p>

    <v-row class="ma-0 py-1" dense>
      <span
        id="data_preview_resume-text-number_of_transactions"
        class="cts-font-size-4"
      >
        {{$vuetify.lang.t('$vuetify.numberOfTransactions')}}:
      </span>
      <span
        id="data_preview_resume-text-number_of_transactions_value"
        class="cts-font-size-4 font-weight-bold ml-2"
      >
        {{nOfRecipients}}
      </span>
    </v-row>

    <v-row
      v-if="availableBillingUnits && availableBillingUnits.length > 0" class="ma-0 py-1"
      dense
    >
      <span class="cts-font-size-4">
        {{$vuetify.lang.t('$vuetify.associatedCECO')}}:
      </span>

      <span
        class=" cts-font-size-4 font-weight-bold ml-2"
      >
        {{selectedBillingUnit.code}}
      </span>

      <span class=" cts-font-size-4 cts-link ml-2">
        <a
          v-if="availableBillingUnits && availableBillingUnits.length > 1 && checkPermission(const_permissions.ACCOUNT_CHANGE_COST_CENTER)"
          @click="changeCostCenterDialog = true"
        >
          ({{$vuetify.lang.t('$vuetify.change')}})
        </a>
      </span>
    </v-row>

    <v-row v-if="showLanguageSelector && active_language" class="ma-0 py-1" dense>
      <span class="cts-font-size-4">{{$vuetify.lang.t('$vuetify.language')}}:</span>
      <span class=" cts-font-size-4 font-weight-bold ml-2">{{active_language.name}}</span>
      <span class=" cts-font-size-4 cts-link ml-2">
        <a id="data_preview_resume-changeLanguage-button"
           @click="changeLanguageDialog = true">({{$vuetify.lang.t('$vuetify.change')}})</a>
      </span>
    </v-row>

    <v-dialog
      v-if="availableBillingUnits && availableBillingUnits.length > 1 &&  checkPermission(const_permissions.ACCOUNT_CHANGE_COST_CENTER)"
      v-model="changeCostCenterDialog"
      max-width="600px" persistent>
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title :text="$vuetify.lang.t('$vuetify.costCenterSelectedTitle')" icon="point_of_sale"/>
        </v-card-title>
        <v-card-text class="">
          <v-row class="ma-0 pt-4" dense>
            <p class="cts-font-size-4 text-justify">{{$vuetify.lang.t('$vuetify.costCenterDialogText')}}</p>
          </v-row>
          <v-row class="ma-0" dense>
            <v-col class="mt-auto pa-0" cols="8" lg="8">
              <v-row class="ma-0 " dense>
                <span class="cts-font-size-4">{{$vuetify.lang.t('$vuetify.userCECOCodeTitle')}}</span>
              </v-row>
              <v-autocomplete
                v-model="cost_center_selected"
                :items="availableBillingUnits"
                :placeholder="$vuetify.lang.t('$vuetify.selectCostCenter')"
                dense
                hide-details
                item-text="code"
                item-value="code"
                outlined
                required
                single-line
              />
            </v-col>
            <v-col class="mt-auto pb-0" cols="4" lg="4">
              <v-btn block class="cts-button-field cts-button-standard-block " dark
                     small @click="changeCostCenterDialog = false">
                <v-icon class="float-right" dense>check</v-icon>
                <span>{{$vuetify.lang.t('$vuetify.accept')}}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="process_languages" v-model="changeLanguageDialog" max-width="600px" persistent>
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title :text="$vuetify.lang.t('$vuetify.language')" icon="language"/>
        </v-card-title>
        <v-card-text class="">
          <v-row class="ma-0" dense>
            <v-col class="mt-auto pa-0" cols="8" lg="8">
              <v-row class="ma-0 " dense>
                <span class="cts-font-size-4">{{$vuetify.lang.t('$vuetify.languageSelector')}}</span>
              </v-row>
              <v-autocomplete
                v-model="active_language"
                :items="process_languages"
                :placeholder="$vuetify.lang.t('$vuetify.language')"
                dense
                hide-details
                item-text="name"
                item-value="code"
                outlined
                required
                return-object
                single-line
              ></v-autocomplete>
            </v-col>
            <v-col class="mt-auto pb-0" cols="4" lg="4">
              <v-btn block class="cts-button-field cts-button-standard-block " dark
                     small @click="changeLanguage()">
                <v-icon class="float-right" dense>check</v-icon>
                <span>{{$vuetify.lang.t('$vuetify.accept')}}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE} from "@/constants/vuex/authentication"
import {
  NAMESPACE as PROCESSES_NAMESPACE,
  STATE as PROCESSES_STATE,
  ACTIONS as PROCESSES_ACTIONS
} from "@/constants/vuex/processes"
import {refreshSession} from "@/services/userServices"
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import DialogTitle from "@/components/structures/dialogTitle"
import {checkPermission} from "@/util/store_utils"
import const_permissions from "@/constants/permissions"

export default {
  name: "SectionDataPreviewResume",
  components: {SectionTitle, DialogTitle},
  props: {
    nOfRecipients: Number,
    showLanguageSelector: Boolean
  },

  data() {
    return {
      checkPermission,
      const_permissions,
      active_language: null,
      process_languages: [],
      locations: require('@/constants/locations').default,
      changeCostCenterDialog: false,
      cost_center_selected: null,
      changeLanguageDialog: false,
    }
  },

  computed: {
    availableBillingUnits() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS]
    },

    selectedBillingUnit: {
      get() {
        return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_COST_CENTER]
      },
      set(val) {
        this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_COST_CENTER}`, val)
        refreshSession()
      }
    },
  },

  methods: {
    configureBillingUnit() {
      let has_default = false
      this.availableBillingUnits.forEach(cc => {
        if (cc["is_default"]) {
          has_default = true
          this.selectedBillingUnit = cc.code
        }
      })
      if (!has_default && this.availableBillingUnits.length > 0) {
        has_default = true
        this.selectedBillingUnit = this.availableBillingUnits[0].code
      }

      if (!has_default) {
        this.selectedBillingUnit = null
      }
    },

    changeLanguage() {
      let newLanguages = this.process_languages
      newLanguages.forEach(nl => {
        nl.is_default = nl.code == this.active_language.code ? true : false
      })

      this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PROCESS_METADATA_VALUE}`, {languages: newLanguages})
      this.changeLanguageDialog = false

    }
  },

  created() {
    if (this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_METADATA]) {
      Object.assign({}, this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_METADATA]).languages.forEach(pl => {
        pl.name = this.locations.find(loc => loc.iso == pl.code).name
        this.process_languages.push(pl)
      })
      this.active_language = this.process_languages.find(pl => pl.is_default == true)
    }

    this.configureBillingUnit()
  }
}
</script>

<style lang="scss" scoped>

</style>