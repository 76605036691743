<template>
  <span>
    <v-tooltip v-if="mode=='icon'" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn  icon  @click.stop="downloadTransactionCertificate" v-bind="attrs" v-on="on">
          <v-icon aria-hidden="false" class="float-left cts-color-primary-alternative">{{const_icons.DOWNLOAD_DOCUMENT}}</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.transactionDownloadFile') }}</span>
    </v-tooltip>
    <v-btn v-else :disabled="buttonDisabled" :class="mobile ? 'cts-button-standard-mobile' : 'cts-button-standard'" small color="primary" @click="downloadTransactionCertificate">
    <v-icon aria-hidden="false" small class="float-right">description</v-icon>
      {{ $vuetify.lang.t('$vuetify.transactionDownloadFile') }}
    </v-btn>
  </span>
</template>

<script>
  import const_icons from "@/constants/icons";
  import { getTransactionCertification } from "@/services/transactionServices";

  export default {
    name: "downloadCertificate",
    components: {},
    props:{
      hash: String,
      buttonDisabled: Boolean,
      mode: String,
      mobile: Boolean
    },
    data() {
      return {
        const_icons,
        contextMessage: false,
        error: null,
        errorMessage: ''
      }
    },
    methods:{
      downloadTransactionCertificate(){
        getTransactionCertification(this.$props.hash)
      }
    }
  }
</script>

<style scoped>

</style>
