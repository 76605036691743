<template>
  <v-container v-if="newDesign === true" class="transaction-launched-container" fluid>
    <v-row class="mt-12 mb-6" dense>
      <v-col v-if="transaction_status === const_global.TRANSACTION_PROCESSING" class="text-center">
        <v-icon id="processes-transactions_launched-text-icon_loading" class="result-icon cts-icon-animated"
                color="orange" large>
          cached
        </v-icon>
        <h1 id="processes-transaction_launched-text-title_processing"
            class="cts-color-tertiary mt-2">
          {{$vuetify.lang.t('$vuetify.transactionProcessing')}}
        </h1>

        <v-row dense justify="center">
          <v-col class="text-center pt-8" cols="12" md="8">
            <p id="processes-transaction_launched-text-processing_message">
              {{$vuetify.lang.t('$vuetify.transactionProcessingMessage')}}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else-if="transaction_status === const_global.TRANSACTION_ERROR" class="text-center">
        <v-icon id="processes-transactions_launched-text-icon_ko" class="result-icon" color="orange" large>
          {{const_icons.ERROR}}
        </v-icon>
        <h1 id="processes-transaction_launched-text-title_processing"
            class="cts-color-tertiary mt-2">
          {{$vuetify.lang.t('$vuetify.transactionError')}}
        </h1>
        <v-row v-if="transaction_result && transaction_result.extra" dense justify="center">
          <p v-if="transaction_result.extra.length === 1" id="processes-transaction_launched-text-error_message"
             class="text-center">
            {{$vuetify.lang.t('$vuetify.transactionErrorMessage')}}
          </p>
          <p v-else id="processes-transaction_launched-text-errors_messages" class="text-center">
            {{$vuetify.lang.t('$vuetify.transactionsErrorMessage')}}:
          </p>
        </v-row>
      </v-col>
      <v-col v-else-if="transaction_status === const_global.TRANSACTION_OK" class="text-center">
        <v-icon id="processes-transactions_launched-text-icon_ok" class="result-icon" color="green" large>
          check_circle
        </v-icon>
        <h1 v-if="transaction_result.extra.length === 1 && transactionName"
            id="processes-transactions_launched-text-title_ok"
            class="cts-color-tertiary mt-2"
            v-html="$vuetify.lang.t('$vuetify.transactionDigitalProcessed').replace('{transactionName}', '<span style=\'color:var(--primary)\'>'+this.transactionName+'</span>')">
        </h1>
        <h1 v-else-if="transaction_result.extra.length === 1"
            id="processes-transactions_launched-text-title_ok">
          {{$vuetify.lang.t('$vuetify.transactionProcessed')}}
        </h1>
        <h1 v-else
            id="processes-transactions_launched-text-title_ok">
          {{$vuetify.lang.t('$vuetify.transactionsProcessed')}}
        </h1>
        <v-row v-if="transaction_result && transaction_result.extra" dense justify="center">
          <v-col class="text-center pt-8" cols="12" md="8">
            <p v-if="transaction_result.extra.length === 1"
               id="processes-transaction_launched-text-ok_message">
              {{$vuetify.lang.t('$vuetify.transactionOkMessage')}}
            </p>
            <p v-else id="processes-transaction_launched-text-errors_messages">
              {{$vuetify.lang.t('$vuetify.transactionsOkMessage')}}
            </p>
          </v-col>
        </v-row>
        <v-row
          v-if="transaction_result?.extra && transaction_result.extra.length > 1 && transaction_result.extra[0].recipient && transaction_result.extra[0].recipient.contact_id"
          dense
          justify="center">
          <v-col

            cols="12" md="4">
            <v-data-table
              id="processes-transaction_launched-table"
              :items="transaction_result.extra"
              :items-per-page="5"
              class="elevation-0 col-12 cts-transactions-datatable cts-processed-transactions-datatable px-0 px-lg-2 pt-0"
              dense
              sort-by="errors"
              sort-desc
            >
              <template v-slot:item="{item}">
                <v-card
                  :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}`"
                  class="mx-auto my-2 cts-card-transaction cts-w-100" outlined>
                  <v-list-item class="cts-list-item-card px-2">
                    <v-list-item-content class="py-2">
                      <v-list-item-title>
                        <v-row class="ma-0" dense>
                          <v-col class="pa-0 ma-0 py-1 text-left" cols="10">
                            <v-icon
                              v-if="item.errors && item.errors.length !== 0"
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error_icon`"
                              color="red" small>
                              {{const_icons.ERROR}}
                            </v-icon>
                            <v-icon
                              v-else
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-ok_icon`"
                              color="green" small>{{const_icons.CHECK}}
                            </v-icon>
                            <span
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-contact_id`"
                              class="cts-font-size-5 font-weight-bold cts-white-space ml-4">{{
                                item.recipient.contact_id
                              }} </span>
                            <span
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-fullname`">{{" - " + item.recipient.contact_name + " " + item.recipient.contact_surname}}</span>
                          </v-col>
                          <v-col class="pa-0 ma-0 text-right py-1" cols="2">
                            <span
                              v-if="item.errors && item.errors.length !== 0"
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error`"
                              class="red--text">
                              {{$vuetify.lang.t('$vuetify.invalidTransaction')}}
                            </span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="transaction_result && transaction_result.extra" class="mb-12" dense justify="center">
      <v-btn
        v-if="transaction_status === const_global.TRANSACTION_OK && transaction_result.extra.length === 1 && checkPermission(const_permissions.SECTION_TRANSACTIONS)"
        id="processes-transaction_launched-button-go_to_transaction"
        class=" mx-1"
        color="var(--primary-alternative)"
        dark
        @click="executeItem(transaction_result.extra[0].instance_code)">
        {{face2face ? $vuetify.lang.t('$vuetify.goToPresentialCeremony') : $vuetify.lang.t('$vuetify.transactionRedirectToTransactionDetail')}}
      </v-btn>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row v-if="transaction_result && transaction_result.extra" dense justify="end">
      <v-btn
        id="processes-transaction_launched-button-go_to_processes"
        class="cts-color-tertiary mx-1"
        @click="goToProcessesView">
        {{$vuetify.lang.t('$vuetify.transactionRedirectToProcesses')}}
      </v-btn>
      <v-btn
        v-if="transaction_status === const_global.TRANSACTION_OK && checkPermission(const_permissions.SECTION_TRANSACTIONS)"
        id="processes-transaction_launched-button-go_to_transactions"
        class="cts-color-tertiary mx-1"
        @click="goToTransactionsView">
        {{$vuetify.lang.t('$vuetify.transactionRedirectToTransactions')}}
      </v-btn>
    </v-row>
  </v-container>
  <v-col v-else class="cts-transaction-launched-content" cols="11" md="10">
    <v-row class="my-2" dense>
      <h1 v-if="transaction_status === const_global.TRANSACTION_PROCESSING"
          id="processes-transaction_launched-text-title_processing"
          class="cts-color-darken">
        <v-icon id="processes-transactions_launched-text-icon_loading" class="mr-2 cts-icon-animated" color="orange">
          cached
        </v-icon>
        {{$vuetify.lang.t('$vuetify.transactionProcessing')}}
      </h1>
      <h1 v-if="transaction_status === const_global.TRANSACTION_ERROR" id="processes-transaction_launched-title_ko"
          class="cts-color-darken">
        <v-icon id="processes-transactions_launched-text-icon_ko" class="mr-2" color="orange">{{const_icons.ERROR}}
        </v-icon>
        <span v-if="transaction_result">
          <span v-if="transaction_result.extra.length === 1" id="processes-transactions_launched-text-title_ko">
              {{$vuetify.lang.t('$vuetify.transactionError')}}
          </span>
          <span v-else id="processes-transaction_launched-text-title_ko">
            {{$vuetify.lang.t('$vuetify.transactionsError')}}
          </span>
        </span>
      </h1>
      <h1 v-if="transaction_status === const_global.TRANSACTION_OK" id="processes-transaction_launched-text-ok"
          class="cts-color-darken">
        <v-icon id="processes-transactions_launched-text-icon_ok" class="mr-2" color="green">{{const_icons.CHECK}}
        </v-icon>
        <span v-if="transaction_result">
          <span
            v-if="transaction_result.extra.length === 1 && transactionName"
            id="processes-transactions_launched-text-title_ok"
            v-html="$vuetify.lang.t('$vuetify.transactionDigitalProcessed').replace('{transactionName}', `<i>${transactionName}</i>`)"
          >
          </span>
          <span v-else-if="transaction_result.extra.length === 1" id="processes-transactions_launched-text-title_ok">
           {{$vuetify.lang.t('$vuetify.transactionProcessed')}}
          </span>
          <span v-else id="processes-transaction_launched-text-title_ok">
            {{$vuetify.lang.t('$vuetify.transactionsProcessed')}}
          </span>
        </span>
      </h1>
    </v-row>

    <v-row class=" ma-0 mt-6" dense>
      <v-col cols="12">
        <v-row class="ma-0 mb-4" dense>
          <v-col class="pa-0 px-2" md="12">
            <p v-if="transaction_status === const_global.TRANSACTION_OK" class="ma-0 text-justify">
              <span v-if="transaction_result">
                <span v-if="transaction_result.extra.length === 1"
                      id="processes-transaction_launched_ok-text-description">
              {{$vuetify.lang.t('$vuetify.transactionLaunchedMessage')}}
                </span>
                <span v-else id="processes-transactions_launched_ok-text-description">
                  {{$vuetify.lang.t('$vuetify.transactionsLaunchedMessage')}}
                </span>
                <a
                  v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                  id="processes-transaction_launched-link-description"
                  class="cts-link text-lowercase" @click="goToTransactionsView"
                >
                   {{$vuetify.lang.t('$vuetify.transactions')}}
                </a>

                <span v-else class="text-lowercase">
                  {{$vuetify.lang.t('$vuetify.transactions')}}
                </span>.
              </span>
            </p>
            <p v-if="transaction_status === const_global.TRANSACTION_PROCESSING"
               id="processes-transaction_launched-text-processing_message" class="ma-0 text-justify">{{
                $vuetify.lang.t('$vuetify.transactionProcessingMessage')
              }}</p>
            <p v-if="transaction_status === const_global.TRANSACTION_ERROR" class="ma-0 text-justify">
               <span v-if="transaction_result">
                <span v-if="transaction_result.extra.length === 1"
                      id="processes-transaction_launched-text-error_message">
              {{$vuetify.lang.t('$vuetify.transactionErrorMessage')}}:
                </span>
                <span v-else id="processes-transaction_launched-text-errors_messages">
              {{$vuetify.lang.t('$vuetify.transactionsErrorMessage')}}:
                </span>
              </span>
            </p>
          </v-col>
        </v-row>

        <v-row v-if="transaction_result" class="ma-0 " dense>
          <v-col class="pa-0" md="12">
            <v-data-table
              v-if="!transactionName"
              id="processes-transaction_launched-table"
              :items="transaction_result.extra"
              :items-per-page="5"
              class="elevation-0 col-12 cts-transactions-datatable cts-processed-transactions-datatable px-0 px-lg-2 pt-0"
              dense
              sort-by="errors"
              sort-desc
            >
              <template v-slot:item="{item}">
                <v-card
                  :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}`"
                  class="mx-auto my-2 cts-card-transaction cts-w-100" outlined>
                  <v-list-item class="cts-list-item-card px-2">
                    <v-list-item-content class="py-2">
                      <v-list-item-title>
                        <v-row class="ma-0 justify-space-between" dense>
                          <v-col class="pa-0 ma-0 py-1" cols="10">
                            <v-icon
                              v-if="item.errors && item.errors.length !== 0"
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error_icon`"
                              color="red" small>
                              {{const_icons.ERROR}}
                            </v-icon>
                            <v-icon
                              v-else
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-ok_icon`"
                              color="green" small>{{const_icons.CHECK}}
                            </v-icon>
                            <span
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-contact_id`"
                              class="cts-font-size-5 font-weight-bold cts-white-space ml-4">{{
                                item.recipient.contact_id
                              }} </span>
                            <span
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-fullname`">{{" - " + item.recipient.contact_name + " " + item.recipient.contact_surname}}</span>
                          </v-col>
                          <v-col class="pa-0 ma-0 text-right py-1" cols="2">
                            <span
                              v-if="item.errors && item.errors.length !== 0"
                              :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error`"
                              class="red--text">
                              {{$vuetify.lang.t('$vuetify.invalidTransaction')}}
                            </span>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row v-if="transaction_status !== const_global.TRANSACTION_PROCESSING" class="ma-0 mt-8" dense>
          <v-col class="pa-0" cols="12">
            <v-row class="ma-0 pa-0 justify-end hidden-md-and-down" dense>
              <v-btn
                v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                id="processes-transaction_launched-button-go_to_transactions"
                class="cts-button-tertiary mr-4"
                small
                @click="goToTransactionsView"
              >
                {{
                  face2face ?
                    $vuetify.lang.t('$vuetify.transactionRedirectToTransactionDetail') :
                    $vuetify.lang.t('$vuetify.transactionRedirectToTransactions')
                }}

                <v-icon class="float-right" small>
                  {{const_icons.TRANSACTIONS}}
                </v-icon>
              </v-btn>

              <v-btn
                id="processes-transaction_launched-button-go_to_processes"
                class="cts-button-tertiary"
                small
                @click="goToProcessesView"
              >
                {{$vuetify.lang.t('$vuetify.transactionRedirectToProcesses')}}
                <v-icon class="float-right" small>{{const_icons.PROCESSES}}</v-icon>
              </v-btn>
            </v-row>

            <v-row class="ma-0 pa-0 justify-end hidden-lg-and-up" dense>
              <v-btn
                v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                id="processes-transaction_launched-button-go_to_transactions2"
                class="cts-button-tertiary-block col-12 my-2"
                small
                @click="goToTransactionsView"
              >
                {{
                  face2face ?
                    $vuetify.lang.t('$vuetify.transactionRedirectToTransactionDetail') :
                    $vuetify.lang.t('$vuetify.transactionRedirectToTransactions')
                }}

                <v-icon class="float-right" small>
                  {{const_icons.TRANSACTIONS}}
                </v-icon>
              </v-btn>

              <v-btn id="processes-transaction_launched-button-go_to_processes2"
                     class="cts-button-tertiary-block col-12 my-2"
                     small @click="goToProcessesView">
                {{$vuetify.lang.t('$vuetify.transactionRedirectToProcesses')}}
                <v-icon class="float-right" small>{{const_icons.PROCESSES}}</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {checkPermission} from "@/util/store_utils"
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import {NAMESPACE as PROCESSES_NAMESPACE, STATE as PROCESSES_STATE} from "@/constants/vuex/processes"
import const_permissions from "@/constants/permissions"
import {NAMESPACE as TRANSACTIONS_NAMESPACE, ACTIONS as TRANSACTIONS_ACTIONS} from "@/constants/vuex/transactions"
import {redirect} from "@/util/router_utils"
import {TRANSACTIONS, PROCESSES, TRANSACTION_VIEW} from "@/constants/router/routes"

export default {
  name: "sectionTransactionLaunched",
  data() {
    return {
      const_icons,
      const_global,
      const_permissions,
      checkPermission
    }
  },

  props: {
    transactionName: {
      type: String,
      required: false
    },

    face2face: {
      type: Boolean,
      required: false
    },
    newDesign: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    transaction_status() {
      return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_TRANSACTION_STATUS]
    },
    transaction_result() {
      return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_TRANSACTION_RESULT]
    }
  },
  methods: {
    //TODO: Gestionar varias transacciones y envío por lotes
    goToTransactionsView() {
      if (!this.face2face) {
        redirect(TRANSACTIONS)
      } else {
        this.executeItem(this.transaction_result.extra[0].instance_code)
      }


    },
    goToProcessesView() {
      redirect(PROCESSES)
    },

    executeItem(instance_code) {
      this.$store.dispatch(
        `${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION_SELECTED}`,
        instance_code
      );
      redirect(TRANSACTION_VIEW);
    },
  }
}
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.cts-icon-animated {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.transaction-launched-container {
  .result-icon {
    font-size: 46px !important;
    margin-bottom: 12px;
  }

  p {
    color: var(--tertiary);
    font-size: 0.95rem;
  }
}
</style>
